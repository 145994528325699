import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, SubmissionError, formValueSelector } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { Link, browserHistory } from "react-router";
import { CAD_CONTRACT_CONFIA_LOAD, CAD_CONTRACT_CONFIA_UNLOAD, CAD_CONTRACT_UF_LOAD, CAD_CONTRACT_SENT_SUCCESS } from "../../actions/Constants";
import api from "../../api/apiUtil";
import MaskCPF from "../../components/Util/MaskCPF";
import DropdownListField from "../../components/Fields/DropdownListField";
import SelectField from "../../components/Fields/SelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextField from "../../components/Fields/TextField";
import { toastr } from "react-redux-toastr";
import Dropzone from "react-dropzone";

class ContratoConfia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      veiculos: [],
      devedores: [],
      files: [],
    };
  }

  componentDidMount() {
    Promise.resolve(api.Financial.get(this.props.currentUser.idFinanceira)).then((financeira) => {
      this.props.onListLoad(api.Confia.allByCnpj(financeira.cnpj));
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  preparaUFs(event) {
    this.props.dispatch(change("ContratoConfiaForm", "uf", null));
    if (event) {
      var filtered = this.props.companies.filter((comp) => comp.id == event.id);
      this.props.onUfLoad([{ sigla: "" }, ...this.props.currentUser.ufs.filter((item) => filtered[0].ufs.some((item2) => item2.uf === item.sigla))]);
    }
  }

  formSubmit(values) {
    var props = this.props;
    let info = {
      idFinanceira: values.credor.id,
      numContrato: values.contrato,
      veiculos: this.state.veiculos,
      partes: this.state.devedores,
      uf: values.uf,
    };
    let param = {
      dados: JSON.stringify(info),
    };
    Promise.resolve(api.Confia.sendContratoConfia(this.state.files[0], param))
      .then((response) => {
        toastr.success("Sucesso", "Contrato cadastrado com Sucesso.");
        this.props.onContractSentSuccess();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  addDevedor(values) {
    const errors = this.validateValuesDev(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (this.state.devedores.filter((item) => item.cpf == values.cpf).length > 0) {
      toastr.error("Atenção", "CPF já adicionado.");
    } else {
      let devedor = {
        //cpf: values.cpf.replaceAll('.', '').replace('-', ''),
        cpf: values.cpf,
        nome: values.nome,
        telefone: values.ddd + values.telefone.replace("-", ""),
        email: values.email,
      };
      this.setState({ devedores: [...this.state.devedores, devedor] });
      this.props.dispatch(change("ContratoConfiaForm", "cpf", null));
      this.props.dispatch(change("ContratoConfiaForm", "nome", null));
      this.props.dispatch(change("ContratoConfiaForm", "ddd", null));
      this.props.dispatch(change("ContratoConfiaForm", "telefone", null));
      this.props.dispatch(change("ContratoConfiaForm", "email", null));
    }
  }

  addVeiculo(values) {
    const errors = this.validateValuesVei(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (this.state.veiculos.filter((item) => item.chassi == values.chassi).length > 0) {
      toastr.error("Atenção", "Chassi já adicionado.");
    } else {
      let veiculo = {
        chassi: values.chassi,
        gravame: values.gravame,
      };
      this.setState({ veiculos: [...this.state.veiculos, veiculo] });
      this.props.dispatch(change("ContratoConfiaForm", "chassi", null));
      this.props.dispatch(change("ContratoConfiaForm", "gravame", null));
    }
  }

  validateValuesDev(values) {
    const errors = {};
    if (!values.cpf || values.cpf.length === 0) {
      errors.cpf = "Campo obrigatório";
    }
    if (!values.nome || values.nome.length === 0) {
      errors.nome = "Campo obrigatório";
    }
    if (!values.ddd || values.ddd.length === 0) {
      errors.ddd = "Campo obrigatório";
    }
    if (!values.telefone || values.telefone.length === 0) {
      errors.telefone = "Campo obrigatório";
    }
    return errors;
  }

  validateValuesVei(values) {
    const errors = {};
    if (!values.chassi || values.chassi.length === 0) {
      errors.chassi = "Campo obrigatório";
    }
    return errors;
  }

  deleteDevedor = (value) => {
    var array = [...this.state.devedores]; // make a separate copy of the array
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ devedores: array });
    }
  };

  deleteVeiculo = (value) => {
    var array = [...this.state.veiculos]; // make a separate copy of the array
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ veiculos: array });
    }
  };

  onDrop(files) {
    if (!files[0] || files[0].type !== "application/pdf") {
      toastr.info("Atenção", "É permitido anexar somente PDF.");
    } else {
      this.setState({
        files: files,
      });
    }
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Cadastrar Novo Contrato" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Contrato</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-5">
                        <Field
                          name="credor"
                          label="Credor"
                          data={this.props.companies}
                          credor={true}
                          required={true}
                          textField="razaoSocialCnpj"
                          valueField="id"
                          component={DropdownListField}
                          type="text"
                          blank={true}
                          onChange={this.preparaUFs.bind(this)}
                        />
                      </div>
                      <div className="col-md-2">
                        <Field name="uf" label="UF" data={this.props.ufs} textField="sigla" valueField="sigla" component={SelectField} type="text" required={true} />
                      </div>
                      <div className="col-md-4">
                        <Field
                          name="contrato"
                          label="Número do contrato"
                          validate={[FieldValidation.nospace]}
                          maxlength={45}
                          component={TextField}
                          type="text"
                          inline={false}
                          required={true}
                          normalize={FieldNormalize.UPPERCASE}
                        />
                      </div>
                    </div>
                    <span className="font-bold">Veículos</span>
                    <div className="panel-body ">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-5">
                              <Field
                                name="chassi"
                                label="Chassi"
                                component={TextField}
                                type="text"
                                maxlength={17}
                                validate={[FieldValidation.chassi, FieldValidation.nospace]}
                                required={true}
                                normalize={FieldNormalize.UPPERCASE}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field name="gravame" label="Nr do Gravame" component={TextField} type="text" maxlength={8} normalize={FieldNormalize.NUMBER} validate={[FieldValidation.nospace]} />
                            </div>
                            <div className="col-lg-2 align-self-end form-group">
                              <button className="btn btn-info" type="button" onClick={handleSubmit(this.addVeiculo.bind(this))}>
                                Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            {this.state.veiculos && this.state.veiculos.length > 0 && (
                              <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th className="text-center align-middle">Chassi</th>
                                    <th className="text-center align-middle">Nr do Gravame</th>
                                    <th className="text-center align-middle">Excluir</th>
                                  </tr>
                                </thead>
                                <tbody>{this.renderVeiculos()}</tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <span className="font-bold">Dados dos Signatários</span>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-2">
                          <Field name="cpf" label="CPF" normalize={FieldNormalize.CPF} component={TextField} type="text" required={true} validate={[FieldValidation.cpf_cnpj]} />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="nome"
                            label="Nome"
                            component={TextField}
                            type="text"
                            description="Nome completo do devedor"
                            maxlength={80}
                            validate={[FieldValidation.nospace]}
                            normalize={FieldNormalize.UPPERCASE}
                            required={true}
                          />
                        </div>
                        <div className="col-md-1">
                          <Field name="ddd" label="DDD" normalize={FieldNormalize.DDD} component={TextField} type="text" validate={[FieldValidation.maxLength2]} required={true} />
                        </div>
                        <div className="col-md-2">
                          <Field name="telefone" label="Telefone" normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" required={true} />
                        </div>
                        <div className="col-md-3">
                          <Field name="email" label="Email" component={TextField} maxlength={40} type="text" normalize={FieldNormalize.UPPERCASE} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 align-self-end form-group text-right">
                          <button className="btn btn-info" type="button" onClick={handleSubmit(this.addDevedor.bind(this))}>
                            Adicionar
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {this.state.devedores && this.state.devedores.length > 0 && (
                          <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th className="text-center align-middle">CPF / CNPJ</th>
                                <th className="text-center align-middle">Nome</th>
                                <th className="text-center align-middle">Telefone</th>
                                <th className="text-center align-middle">Email</th>
                                <th className="text-center align-middle">Excluir</th>
                              </tr>
                            </thead>
                            <tbody>{this.renderDevedores()}</tbody>
                          </table>
                        )}
                      </div>
                    </div>
                    <p></p>
                    <span className="font-bold">Documento</span>
                    <div className="panel-body ">
                      <div className="row">
                        <div className="col-md-2">
                          <Dropzone accept="application/pdf" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDrop.bind(this)}>
                            <Link className="btn btn-xs">
                              <i className="fas fa-upload" /> Anexar Documento
                            </Link>
                          </Dropzone>
                        </div>
                        <div className="col-md-3">
                          <span>{this.state.files.length > 0 ? "Título: " + this.state.files[0].name : ""}</span>
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                          <button
                            className="btn btn-info"
                            disabled={
                              !this.props.selectedCredor ||
                              !this.props.selectedUf ||
                              !this.props.selectedContrato ||
                              this.state.veiculos.length == 0 ||
                              this.state.devedores.length == 0 ||
                              this.state.files.length == 0
                            }
                            onClick={handleSubmit(this.formSubmit.bind(this))}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderVeiculos() {
    return this.state.veiculos.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{r.chassi}</td>
          <td className="text-center align-middle">{r.gravame}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default" onClick={() => this.deleteVeiculo(r)}>
                <i className="far fa-trash-alt" title="Excluir"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderDevedores() {
    return this.state.devedores.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(r.cpf.replaceAll(".", "").replace("-", ""))}</td>
          <td className="text-center align-middle">{r.nome}</td>
          <td className="text-center align-middle">{r.telefone}</td>
          <td className="text-center align-middle">{r.email}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default" onClick={() => this.deleteDevedor(r)}>
                <i className="far fa-trash-alt" title="Excluir"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: CAD_CONTRACT_CONFIA_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: CAD_CONTRACT_CONFIA_UNLOAD, payload }),
  onUfLoad: (payload) => dispatch({ type: CAD_CONTRACT_UF_LOAD, payload }),
  onContractSentSuccess: (payload) => dispatch({ type: CAD_CONTRACT_SENT_SUCCESS, payload }),
});

const selector = formValueSelector("ContratoConfiaForm");

const mapStateToProps = (state) => ({
  ...state.consultaConfia,
  currentUser: state.common.currentUser,
  companies: state.common.companies,
  selectedCredor: selector(state, "credor"),
  selectedUf: selector(state, "uf"),
  selectedContrato: selector(state, "contrato"),
});

const form = reduxForm({
  form: "ContratoConfiaForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ContratoConfia));
