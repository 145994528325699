import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { Link } from "react-router";
import { CONTRACT_CONFIA_LOAD, CONTRACT_CONFIA_UNLOAD } from "../../actions/Constants";
import api from "../../api/apiUtil";
import ReactTable from "react-table";
import MaskDate from "../../components/Util/MaskDate";
import MaskCPF from "../../components/Util/MaskCPF";
import SvgIcon from "react-icons-kit";
import { documentInverted } from "react-icons-kit/entypo/documentInverted";
import { spinner11 } from "react-icons-kit/icomoon/spinner11";
import fileDownload from "js-file-download";
import { toastr } from "react-redux-toastr";
class ConsultaConfia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detalhar: [],
    };
  }

  componentDidMount() {
    Promise.resolve(api.Financial.get(this.props.currentUser.idFinanceira)).then((financeira) => {
      this.props.onListLoad(api.Confia.allByCnpj(financeira.cnpj));
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  async recuperaDadosHash(value) {
    if (this.state.detalhar.findIndex((val) => val.hash == value) == -1) {
      await Promise.resolve(api.Confia.getDetailsByHash(value)).then((res) => {
        _.forEach(res, function (r) {
          r.hash = value;
        });
        this.setState({ detalhar: [...this.state.detalhar, ...res] });
      });
    }
  }

  filteredDetails(hash) {
    return this.state.detalhar
      .filter((detalhe) => detalhe.hash == hash)
      .map((det, index) => {
        return (
          <tr key={index}>
            <td className="text-center align-middle">{det.name}</td>
            <td className="text-center align-middle">{MaskCPF.TO_CPF(det.cpf.replaceAll(".", "").replace("-", ""))}</td>
            <td className="text-center align-middle">{det.email}</td>
            <td className="text-center align-middle">{det.phone}</td>
            <td className="text-center align-middle">{det.status == 0 ? "Aguardando assinatura" : "Assinado"}</td>
            <td className="text-center align-middle">{det.signDate ? MaskDate.TO_BR_DATETIME(det.signDate) : "-"}</td>
          </tr>
        );
      });
  }

  atualizaConsulta() {
    Promise.resolve(api.Financial.get(this.props.currentUser.idFinanceira)).then((financeira) => {
      this.props.onListLoad(api.Confia.allByCnpj(financeira.cnpj));
    });
  }

  handleClickDownload = (value) => {
    var props = this.props;
    Promise.resolve(api.Confia.getArquivo(value.hash))
      .then((response) => {
        fileDownload(response, "Contrato.pdf");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Consulta de Contratos para Assinatura" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  Registros
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-right">
                        <Link className="btn btn-xs btn-info" onClick={() => this.atualizaConsulta()}>
                          <SvgIcon size={12} icon={spinner11} /> Atualizar
                        </Link>
                        <Link className="btn btn-xs btn-info" to={`/secure/ContratoConfia`}>
                          <SvgIcon size={12} icon={documentInverted} />
                          Novo Contrato Para Assinatura
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <ReactTable
                      className="-striped -highlight"
                      defaultPageSize={20}
                      data={this.props.all}
                      loadingText="Carregando..."
                      noDataText="Nenhuma Informação encontrada"
                      rowsText="linhas"
                      showPageSizeOptions={false}
                      minRows={1}
                      showPagination={true}
                      previousText="Anterior"
                      nextText="Próximo"
                      pageText="página"
                      ofText="de"
                      freezeWhenExpanded={true}
                      collapseOnDataChange={false}
                      getTdProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: async (e, handleOriginal) => {
                            if (column.Header == "Detalhar") {
                              await this.recuperaDadosHash(rowInfo.original.hash);
                            }

                            if (handleOriginal) {
                              handleOriginal();
                            }
                            // IMPORTANT! React-Table uses onClick internally to trigger
                            // events like expanding SubComponents and pivots.
                            // By default a custom 'onClick' handler will override this functionality.
                            // If you want to fire the original onClick handler, call the
                            // 'handleOriginal' function.
                          },
                        };
                      }}
                      SubComponent={(row) => {
                        return (
                          <div className="panel-body">
                            <div className="table-responsive">
                              <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th className="text-center align-middle">Nome</th>
                                    <th className="text-center align-middle">CPF</th>
                                    <th className="text-center align-middle">Email</th>
                                    <th className="text-center align-middle">Telefone</th>
                                    <th className="text-center align-middle">Situação</th>
                                    <th className="text-center align-middle">Data Assinatura</th>
                                  </tr>
                                </thead>
                                <tbody>{this.filteredDetails(row.original.hash)}</tbody>
                              </table>
                            </div>
                          </div>
                        );
                      }}
                      className="-striped -highlight"
                      columns={[
                        {
                          expander: true,
                          Header: "Detalhar",
                          width: 65,
                          headerClassName: "font-extra-bold",
                          Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <span>&#x2bc6;</span> : <span>&#x2bc8;</span>}</div>,
                          style: {
                            cursor: "pointer",
                            fontSize: 15,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none",
                          },
                        },
                        {
                          Header: "UF",
                          accessor: "uf",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (uf) => <div>{JSON.parse(uf.original.metadata).uf}</div>,
                        },
                        {
                          Header: "Número Contrato",
                          accessor: "nrContrato",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (nrContrato) => <div>{JSON.parse(nrContrato.original.metadata).contrato}</div>,
                        },
                        {
                          Header: "Chassi",
                          accessor: "chassi",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (chassi) => <div>{this.renderVehicleList(JSON.parse(chassi.original.metadata).veiculos, "chassi")}</div>,
                        },
                        {
                          Header: "Gravame",
                          accessor: "gravame",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (gravame) => <div>{this.renderVehicleList(JSON.parse(gravame.original.metadata).veiculos, "gravame")}</div>,
                        },
                        {
                          Header: "Data envio",
                          accessor: "rowData",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (rowData) => {
                            return (
                              <div>
                                <span>{MaskDate.TO_BR(rowData.original.registrationDate)}</span>
                                <br />
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Status Confia",
                          accessor: "tipo",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (tipo) => <div>{tipo.original.status == 0 ? "Aguardando assinatura(s)" : tipo.original.status == 1 ? "Assinado" : ""}</div>,
                        },
                        {
                          Header: "Ação",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 190,
                          Cell: (row) => (
                            <div className="btn-group">
                              {row.original.status == 1 && (
                                <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                  {" "}
                                  Download{" "}
                                </Link>
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderVehicleList(veiculos, field) {
    if (!veiculos || veiculos.length === 0) {
      return <div></div>;
    }
    return veiculos.map((v, index) => {
      return (
        <div>
          {v[field]}
          <br />
        </div>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: CONTRACT_CONFIA_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: CONTRACT_CONFIA_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.consultaConfia,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "ConsultaConfiaForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConsultaConfia));
