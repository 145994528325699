import React, {Component, } from 'react'; import PropTypes from 'prop-types';

class LabelField extends Component {

  render() {
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? 'has-error' : ''} ${this.props.inline ? 'row' : ''}`}>
        <label className={`font-weight-bold ${this.props.inline ? 'col-sm-2  col-form-label' : ''}`}>{this.props.label}</label>
        <div className={`${this.props.inline ? 'col-sm-10' : ''}`}>
          <input title={this.props.title} type={this.props.type} disabled={true} value={this.props.valueData} className={`${this.props.small ? 'form-control form-control-sm' : 'form-control'}`} />
          {
            this.props.description && <span className="help-block small">{this.props.description}</span>
          }
          <p className="text-danger">
            {this.props.meta.touched ? this.props.meta.error : ''}
          </p>
        </div>
      </div>
    );
  }
}

LabelField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  inline: PropTypes.bool,
  description: PropTypes.string,
  small: PropTypes.bool,
  valueData: PropTypes.string.isRequired,
};

export default LabelField;
