import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import Dropzone from "react-dropzone";

import { CARGA_BNDES_LIST_LOAD, CARGA_BNDES_LIST_UNLOAD, CARGA_BNDES_LOAD_UFS } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
import fileDownload from "js-file-download";
import { BeatLoader } from "react-spinners";
import SelectField from "../../components/Fields/SelectField";

class CargaBNDES extends React.Component {
  componentDidMount() {
    this.props.onListLoad(api.Contracts.getLoteFinanceira(this.props.currentUser.idFinanceira));
    Promise.resolve(api.Financial.ufsCadastradas(this.props.currentUser.idFinanceira)).then((ufs) => {
      //this.props.onLoadUf([...ufs.filter(uf => uf.uf === "BA")]);      FOI COMENTADO SO PRA APROVEITAR A FUNCIONALIDADE E RODAR EM SP
      this.props.onLoadUf([...ufs.filter((uf) => uf.uf === "SP" || uf.uf === "AC" || uf.uf === "PB")]);
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.sendLoteBNDES(this.props.selectedUf, filesToUpload[0]))
      .then((response) => {
        this.props.onListLoad(api.Contracts.getLoteFinanceira(this.props.currentUser.idFinanceira));
        toastr.removeByType("info");
        toastr.success("Sucesso", "Planilha enviada.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.removeByType("info");
          toastr.warning("Atenção", resp.response.body);
        }
      });
  };

  handleClickDownload = (value) => {
    var props = this.props;
    Promise.resolve(api.Contracts.getLote(value.id))
      .then((response) => {
        fileDownload(response, value.nome.replace(".XLSX", "_resposta.XLSX"));
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Upload/Download Arquivos" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Planilhas enviadas</div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="uf" label="UF" data={this.props.ufs} textField="uf" valueField="uf" component={SelectField} required type="text" blank={true} />
                    </div>
                    <div className="col-lg-2 align-self-end form-group">
                      <Dropzone accept=".xlsx" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                        <button className="btn btn-success" disabled={!this.props.selectedUf ? true : false} type="button">
                          <i className="fa fa-upload" /> Enviar Nova Planilha
                        </button>
                      </Dropzone>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={20}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        columns={[
                          {
                            Header: "Planilha",
                            accessor: "nome",
                            headerClassName: "font-extra-bold",
                          },
                          {
                            Header: "UF",
                            accessor: "uf",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                          },
                          {
                            Header: "Data Inclusão",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowData.original.data)}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Ação",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 190,
                            Cell: (row) => (
                              <div className="btn-group">
                                <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                  {" "}
                                  Download{" "}
                                </Link>
                              </div>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: CARGA_BNDES_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: CARGA_BNDES_LIST_UNLOAD, payload }),
  onLoadUf: (payload) => dispatch({ type: CARGA_BNDES_LOAD_UFS, payload }),
});

const selector = formValueSelector("CargaBNDESForm");
const mapStateToProps = (state) => ({
  ...state.cargaBNDES,
  currentUser: state.common.currentUser,
  selectedUf: selector(state, "uf"),
});

const form = reduxForm({
  form: "CargaBNDESForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(CargaBNDES));
