import {CONTRACT_LOT_LIST_LOAD, CONTRACT_LOT_LIST_UNLOAD} from '../../actions/Constants';

const INITIAL_STATE = { all: [], contractLot: null};

export default function contractLot(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTRACT_LOT_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };

    case CONTRACT_LOT_LIST_UNLOAD:
      return INITIAL_STATE;

    default:
      return state;
  }
}
