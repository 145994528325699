import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link, browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";

import { USERS_LIST_LOAD, USERS_LOAD, USERS_USER_SELECT, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import jsPDF from "jspdf";
import fileDownload from "js-file-download";
import { BeatLoader } from "react-spinners";

class FinancialInvoices extends React.Component {
  componentWillMount() {
    //this.props.onLoad(Promise.all([api.Roles.financialRoles()]));
    this.props.onListLoad(api.Charging.allById(this.props.params.slug));
  }

  handleOnCreateInvoice() {
    this.refs.btn.setAttribute("disabled", "disabled");
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Charging.create(this.props.params.slug)).then((auth) => {
      toastr.removeByType("info");
      toastr.success("Sucesso", "Faturamentos criados");
      this.refs.btn.removeAttribute("disabled");
      this.props.onListLoad(api.Charging.allById(this.props.params.slug));
    });
  }

  handleOnPaid = (id) => (ev) => {
    Promise.resolve(api.Charging.pago(id)).then((auth) => {
      toastr.success("Sucesso", "Faturamento marcado como pago");
      this.props.onListLoad(api.Charging.allById(this.props.params.slug));
    });
  };

  handleOnClose = (id) => (ev) => {
    Promise.resolve(api.Charging.fechado(id)).then((auth) => {
      toastr.success("Sucesso", "Faturamento marcado como fechado");
      this.props.onListLoad(api.Charging.allById(this.props.params.slug));
    });
  };

  handleOnOpen = (id) => (ev) => {
    Promise.resolve(api.Charging.aberto(id)).then((auth) => {
      toastr.success("Sucesso", "Faturamento marcado Aberto");
      this.props.onListLoad(api.Charging.allById(this.props.params.slug));
    });
  };

  printDetails = (r) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Financial.get(r.idFinanceira)).then((financeira) => {
      Promise.resolve(api.Charging.registrosSemContestacao(r.id)).then((dt) => {
        this.exportRegisterPDF(financeira, r, dt);
      });
    });
  };

  gerarBoleto = (r) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    Promise.resolve(api.Charging.gerarBoleto(r.id))
      .then((response) => {
        fileDownload(response, "Boleto.pdf");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleOnDelete = (id) => (ev) => {
    Promise.resolve(api.Charging.delete(id)).then((auth) => {
      toastr.success("Sucesso", "Faturamento excluído");
      this.props.onListLoad(api.Charging.allById(this.props.params.slug));
    });
  };

  onClickUploadFile() {
    this.refs.btn.setAttribute("disabled", "disabled");
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Faturamentos" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <button ref="btn" className="btn btn-info" onClick={this.handleOnCreateInvoice.bind(this)}>
                      {" "}
                      Gerar Faturamento
                    </button>
                    <Link className="btn btn-default " onClick={browserHistory.goBack}>
                      <i className="fas fa-arrow-left"></i> Voltar
                    </Link>
                  </div>
                  <p>Faturamentos</p>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Período</th>
                          <th className="text-center align-middle">Qtd Registros</th>
                          <th className="text-center align-middle">Valor da Fatura</th>
                          <th className="text-center align-middle">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{this.renderInvoicesList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  exportRegisterPDF(financeira, r, dt) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    var title = `Relatório de Faturamento`;

    title = title + `\n\nFinanceira: ${financeira.razaoSocial}`;
    startTableY = startTableY + 15;
    title = title + `\nCNPJ: ${financeira.cnpj}`;
    startTableY = startTableY + 15;
    title = title + `\nPeríodo: ${MaskDate.TO_BR(r.periodoInicio)} a ${MaskDate.TO_BR(r.periodoFim)}`;
    startTableY = startTableY + 15;
    title = title + `\nQtd Registros: ${r.quantidade}`;
    startTableY = startTableY + 15;
    title = title + `\nValor da Fatura: ${MaskDecimal.TO_BRL(r.valorTotal)}`;
    startTableY = startTableY + 15;
    title = title + `\nStatus: ${r.status === 1 ? "Aberto" : r.status === 2 ? "Pago" : r.status === 3 ? "Fechado" : r.status}`;
    startTableY = startTableY + 15;

    const headers = [["Nº REGISTRO", "CONTRATO", "VEÍCULO", "DATA REGISTRO"]];

    const data = dt.map((row) => [
      row.numRegistro,
      row.numero,
      `Placa: ${row.ufPlaca ? row.ufPlaca : ""} - ${row.numPlaca ? row.numPlaca : ""}\n Renavam: ${row.renavam ? row.renavam : ""}\n Chassi: ${row.chassi ? row.chassi : ""}`,
      MaskDate.TO_BR(row.data),
    ]);

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
        3: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioFaturamento.pdf`);
  }

  renderInvoicesList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">
            {MaskDate.TO_BR(r.periodoInicio)} a {MaskDate.TO_BR(r.periodoFim)}
          </td>
          <td className="text-center align-middle">{r.quantidade}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(r.valorTotal)}</td>
          <td className="text-center align-middle">{r.status === 1 ? "Aberto" : r.status === 2 ? "Pago" : r.status === 3 ? "Fechado" : r.status}</td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {r.status === 3 && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnPaid(r.id)}>
                  <i className="fas fa-check"></i> Marcar como pago
                </Link>
              )}
              {r.status === 1 && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnClose(r.id)}>
                  <i className="fas fa-check"></i> Fechar
                </Link>
              )}
              {r.status === 2 && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnOpen(r.id)}>
                  <i className="fas fa-check"></i> Abrir Faturamento
                </Link>
              )}
              <Link to={`/alias/financialInvoiceDetails/${r.id}`} className="btn btn-sm btn-default">
                <i className="fas fa-edit" /> Editar
              </Link>
              <Link className="btn btn-sm btn-default" onClick={this.printDetails(r)}>
                <i className="fas fa-file-alt" /> Relatório
              </Link>
              {r.status === 1 && (
                <Link className="btn btn-sm btn-default" onClick={this.gerarBoleto(r)}>
                  <i className="far fa-money-bill-alt" /> Boleto
                </Link>
              )}
              {r.status === 1 && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnDelete(r.id)}>
                  <i className="fas fa-trash-alt"></i> Excluir
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: USERS_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: USERS_LIST_LOAD, payload }),
  onUserSelect: (payload) => dispatch({ type: USERS_USER_SELECT, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: "FinancialInvoicesForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialInvoices));
