import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, reset, change, untouch } from "redux-form";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import ReactTable from "react-table";
import Dropzone from "react-dropzone";

import TextField from "../../components/Fields/TextField";
import ComboboxField from "../../components/Fields/ComboboxField";
import MultiSelectField from "../../components/Fields/MultiSelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import MaskCPF from "../../components/Util/MaskCPF";
import MaskDate from "../../components/Util/MaskDate";

import { FINANCIAL_INVOICE_LIST_LOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";

const columns1 = [
  {
    Header: "Nº Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Veículo",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        <small>
          Placa: {row.original.ufPlaca} - {row.original.numPlaca}
        </small>
        <br />
        <small>Renavam: {row.original.renavam}</small>
        <br />
        <small>Chassi: {row.original.chassi}</small>
        <br />
      </div>
    ),
  },
  {
    Header: "Data Registro", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "data",
    Cell: (row) => <div>{MaskDate.TO_BR(row.value)}</div>,
  },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="btn-group-vertical">
        {row.original.statusVeiculoCobranca === 1 && (
          <Link className="btn btn-sm btn-default" onClick={() => FinancialInvoiceDetails.handleContestado(row.original.id, row.original.idCobranca)}>
            <i className="fa fa-info-circle"></i> Contestar
          </Link>
        )}
        {row.original.statusVeiculoCobranca === 3 && <div>Contestado</div>}
      </div>
    ),
  },
];

class FinancialInvoiceDetails extends React.Component {
  componentWillMount() {
    this.props.onListLoad(api.Charging.registros(this.props.params.slug));
  }

  handleContestado = (id, idCobranca) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Charging.contestarRegistro(id, idCobranca)).then((auth) => {
      toastr.success("Sucesso", "Registro contestado");
      this.props.onListLoad(api.Charging.registros(this.props.params.slug));
    });
  };

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    var idCobranca = this.props.params.slug;
    for (var i = 0; i < filesToUpload.length; ++i) {
      console.log("OPS:", filesToUpload[i]);

      Promise.resolve(api.Charging.sendImage(idCobranca, filesToUpload[i]))
        .then((response) => {
          toastr.success("Sucesso", "NFe enviada.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Detalhes do GRE" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-default btn-xs" onClick={browserHistory.goBack}>
                      <i className="fas fa-arrow-left"></i> Voltar
                    </Link>
                    <Dropzone name="file" className="dropzone-blank fallback" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                      <button type="button" className="btn btn-primary btn-xs">
                        <i className="fa fa-upload" /> Enviar NFe
                      </button>
                    </Dropzone>
                  </div>
                  <p>Registros / Averbações</p>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <ReactTable
                      className="-striped -highlight"
                      data={this.props.registros}
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum registro encontrado"
                      pageText="página"
                      ofText="de"
                      rowsText="linhas"
                      columns={[
                        {
                          Header: "Nº Registro",
                          accessor: "numRegistro",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                        },
                        {
                          Header: "Contrato",
                          accessor: "numero",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                        },
                        {
                          Header: "Veículo",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (row) => (
                            <div>
                              <small>
                                Placa: {row.original.ufPlaca} - {row.original.numPlaca}
                              </small>
                              <br />
                              <small>Renavam: {row.original.renavam}</small>
                              <br />
                              <small>Chassi: {row.original.chassi}</small>
                              <br />
                            </div>
                          ),
                        },
                        {
                          Header: "Data Registro", // Custom header components!
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          accessor: "data",
                          Cell: (row) => <div>{MaskDate.TO_BR(row.value)}</div>,
                        },
                        {
                          Header: "", // Custom header components!
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          Cell: (row) => (
                            <div className="btn-group-vertical">
                              {row.original.statusVeiculoCobranca === 1 && (
                                <Link className="btn btn-sm btn-default" onClick={this.handleContestado(row.original.id, row.original.idCobranca)}>
                                  <i className="fa fa-info-circle"></i> Contestar
                                </Link>
                              )}
                              {row.original.statusVeiculoCobranca === 3 && <div>Contestado</div>}
                            </div>
                          ),
                        },
                      ]}
                      defaultPageSize={10}
                      showPageSizeOptions={false}
                      minRows={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInvoicesList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{user.cpf}</td>
          <td className="text-center align-middle">{user.email}</td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {user.blocked === 0 && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnBlockClick(user.id)}>
                  <i className="fas fa-ban"></i> Marcar como pago
                </Link>
              )}
              <button className="btn btn-sm btn-default" onClick={this.handleOnSelectUserClick(user)}>
                <i className="far fa-edit"></i> Editar
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderRoles(perfis) {
    if (perfis) {
      return perfis.map((perfil, index) => {
        return (
          <div className="m-b-xs" key={index}>
            <span className="label m-r-xs">
              <small>{perfil.nome}</small>
            </span>
            <br />
          </div>
        );
      });
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: FINANCIAL_INVOICE_LIST_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.financial,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: "FinancialInvoiceDetailsForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialInvoiceDetails));
