import {FINANCIAL_REPORT_LOAD, FINANCIAL_REPORT_UNLOAD, FINANCIAL_REPORT_LIST_LOAD, FINANCIAL_REPORT_DETAILS_LOAD, FINANCIAL_REPORT_DETAILS_UNLOAD} from '../../actions/Constants';

const INITIAL_STATE = { companies: [], chargings: [], details: [] };

export default function chargingReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_REPORT_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
        chargings: [],
        details: [],
      };

    case FINANCIAL_REPORT_LIST_LOAD:
      return {
        ...state,
        chargings: action.payload ? action.payload : null,
      };

    case FINANCIAL_REPORT_DETAILS_LOAD:
      return {
        ...state,
        details: action.payload ? action.payload : [],
      };

    case FINANCIAL_REPORT_DETAILS_UNLOAD:
      return {
        ...state,
        details: [],
      };

    case FINANCIAL_REPORT_UNLOAD:
      return INITIAL_STATE;


    default:
      return state;
  }
}
