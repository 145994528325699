import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import Modal from "react-bootstrap4-modal";
import CheckboxField from "../../components/Fields/CheckboxField";
import { CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE } from "../../actions/Constants";
import api from "../../api/apiUtil";
import { toastr } from "react-redux-toastr";

class ConfirmGerenciarAutenticacao extends Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
    document.getElementById("check").checked = this.props.gerenciarAutenticacao === 1 ? "checked" : undefined;
  }

  formSubmit(values) {
    var props = this.props;
    var values = {
      id: this.props.idFinanceira,
      desabilitaSenhaOperacao: document.getElementById("check").checked,
    };
    Promise.resolve(api.Financial.desabilitaSenhaOperacao(values))
      .then((ret) => {
        toastr.success("Sucesso", "Ajuste realizado.");
        this.props.reset();
        this.props.onClose();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit } = this.props;
    return (
      <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
        <form onSubmit={handleSubmit(this.formSubmit)}>
          <div className="modal-header" style={{ padding: "10px 10px" }}>
            <div>
              <h7 className="modal-title">Gerenciar Autenticação</h7>
            </div>
          </div>
          <div className="modal-body">
            <div>
              <h6 className="font-bold">Desabilitando a funcionalidade, reduzirá a margem de segurança. </h6>
              <h6 className="font-bold">Lembrando que a dupla validação é utilizada para impedir acessos não autorizados a conta.</h6>
            </div>
            <p></p>
            <div>
              <h6>
                <label for="check">
                  <input type="checkbox" id="check" name="check" defaultChecked={this.props.gerenciarAutenticacao === 1 ? "checked" : undefined} />
                  &nbsp; Desativar senha de operação para comunicações com os Detrans.
                </label>
              </h6>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.onCloseClick} title="Não confirmar a operação e voltar para a tela anterior">
              Cancelar
            </button>
            <button type="button" className="btn btn-primary" title="Confirma a operação" onClick={handleSubmit(this.formSubmit.bind(this))}>
              Confirmar
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
  ...state.confirmGerenciarAutenticacao,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: (payload) => dispatch({ type: CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE }),
});

const form = reduxForm({
  form: "ConfirmGerenciarAutenticacaoForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfirmGerenciarAutenticacao));
