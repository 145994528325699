import { LOTE_LIST_LOAD, LOTE_LIST_UNLOAD } from "../../actions/Constants";

const INITIAL_STATE = { all: [] };

export default function loteSNG(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOTE_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case LOTE_LIST_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
