const TO_DATABASE = value => {
  if (value === null || !value) {
    return null;
  }
  return value ? value.replace(/\./g, '').replace(/,/g, '.') : value;
}

const TO_BR = value => {
  return value ? value.toLocaleString('pt-BR') : value;
}

const TO_BR_NO_DOTS = value => {
  if(!value) return value;

  let valueNoDot = value.toLocaleString('pt-BR')
                        .replace(".", "")
                        .replace(",",".");
  
  // if (valueNoDot.split(".").length === 2) {
  //   if (valueNoDot.split(".")[1].length == 1) {
  //     valueNoDot += "0";
  //     console.log(valueNoDot);
  //   }
  //   console.log(valueNoDot)
  // } else {
  //   valueNoDot += ".0";
  //   console.log(valueNoDot);
  // }

  return valueNoDot;
}

const TO_BR_EDIT = value => {
  if(!value) return value;

  const newValue = TO_BR(value);
  
  if(newValue.includes(',')){
    const splitValues = newValue.split(',');
    if(splitValues[1].length > 1){
      return newValue;
    }else{
      return newValue+'0';
    }
  }else{
    return newValue+',00';
  }
}

const TO_BRL = value => {
  return value ? parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : value;
}

const TO_XLS = value => {
  if (value === null || !value) {
    return null;
  }
  value = ''+value;
  return value ? value.replace(/\./g, ',') : value;
}

const TO_NUMBER = value => {
  return value && value != 0 ? typeof value === 'number' ? value : parseFloat(value) : undefined;
}

const TO_BR_3_DECIMALS = value => {
  //return value ? value.toFixed(3).replace('.', ',') : value;
  return value === 0 || value ? value.toFixed(3).replace('.', ',') : value;
}

const TO_BR_TO_REAL = value => {
  if(value){
    var numero = value.toFixed(2).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  }else{
    return value;
  }
  
}

export default {
  TO_DATABASE,
  TO_BR,
  TO_BRL,
  TO_BR_NO_DOTS,
  TO_BR_EDIT,
  TO_XLS,
  TO_NUMBER,
  TO_BR_3_DECIMALS,
  TO_BR_TO_REAL
};
