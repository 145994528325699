import { CARGA_BNDES_LIST_LOAD, CARGA_BNDES_LIST_UNLOAD, CARGA_BNDES_LOAD_UFS} from '../../actions/Constants';

const INITIAL_STATE = { all: [], ufs: []};

export default function cargaBNDES(state = INITIAL_STATE, action) {
    switch (action.type) {    
        case CARGA_BNDES_LIST_LOAD:
            return {
                ...state,
                all: action.payload ? action.payload : null,
            };
        case CARGA_BNDES_LOAD_UFS:
            return {
                ...state,
                ufs: action.payload ? action.payload : [],
            };
        case CARGA_BNDES_LIST_UNLOAD:
            return INITIAL_STATE;    
        default:
        return state;
  }
}
