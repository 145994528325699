import {FINANCIAL_SHIPPING_LIST_LOAD, FINANCIAL_SHIPPING_LIST_UNLOAD} from '../../actions/Constants';

const INITIAL_STATE = { all: [], financialShipping: null};

export default function financialShipping(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_SHIPPING_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };

    case FINANCIAL_SHIPPING_LIST_UNLOAD:
      return INITIAL_STATE;

    default:
      return state;
  }
}
