import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { toastr } from "react-redux-toastr";

import { NEW_PASSWORD } from "../../actions/Constants";
import api from "../../api/apiUtil";
import backgroundLogin from "../../img/e-registro-new-login.jpg";
import TextField from "../../components/Fields/TextField";
import fieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import logo from "../../img/e-registro-logo.png";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
      recuperouConfig: false,
    };
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    Promise.resolve(api.Configuracao.getPorAtributo("template")).then((res) => {
      if (res) {
        this.setState({
          config: JSON.parse(res.valor),
        });
      }
      this.setState({ recuperouConfig: true });
    });
  }

  render() {
    const { handleSubmit } = this.props;
    if (!this.state.recuperouConfig) {
      return null;
    }
    return (
      <div
        className="login-container login-background"
        style={{ backgroundImage: `url(${this.state.config && this.state.config.img_fundo_login ? "data:image/png;base64," + this.state.config.img_fundo_login : backgroundLogin})` }}
      >
        <div className="splash">
          {" "}
          <div className="color-line"></div>
          <div className="splash-title">
            <h1>E-REGISTRO</h1>
            <p>Registro de contrato de financiamento de veículos </p>
            <div className="spinner">
              {" "}
              <div className="rect1"></div> <div className="rect2"></div> <div className="rect3"></div> <div className="rect4"></div> <div className="rect5"></div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="color-line-ereg"></div>

        <div className="login-form">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body">
                  <div className="login-img-container">
                    <img src={this.state.config && this.state.config.logo_sistema ? `data:image/jpeg;base64,${this.state.config.logo_sistema}` : logo} />
                  </div>
                  <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
                    <Field
                      name="username"
                      label="CPF"
                      component={TextField}
                      normalize={fieldNormalize.CPF}
                      validate={[FieldValidation.required, FieldValidation.cpf]}
                      type="text"
                      placeholder="000.000.000-00"
                      title="Digite seu CPF cadastrado no sistema"
                    />

                    <button type="submit" className="btn btn-alternative btn-login btn-block" to="/secure">
                      RECUPERAR SENHA
                    </button>

                    <Link className="btn btn-default btn-login btn-block" to="/login">
                      VOLTAR
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  formSubmit(values) {
    Promise.resolve(api.Auth.newPassword(values.username.replace(/[^\d]/g, "")))
      .then((auth) => {
        this.props.onNewPassword();
        this.props.dispatch(change("ForgotPasswordForm", "username", null));
        this.props.reset();
        toastr.info("Atenção", "As instruções foram enviadas para o email cadastrado no sistema!");
      })
      .catch(function (resp) {
        toastr.info("Atenção", resp.response.text);
      });
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onNewPassword: (payload) => dispatch({ type: NEW_PASSWORD, payload }),
});

const form = reduxForm({
  form: "ForgotPasswordForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ForgotPassword));
