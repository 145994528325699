import { CONSULTA_RSNG_LOAD, CONSULTA_RSNG_UNLOAD } from "../../actions/Constants";
const INITIAL_STATE = { all: [] };

export default function consultaSNG(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONSULTA_RSNG_LOAD:
      return {
        ...state,
        result: action.payload ? action.payload : [],
      };
    case CONSULTA_RSNG_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
