import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Popup from "reactjs-popup";
import ReactTable from "react-table";

import MaskDate from "../../components/Util/MaskDate";

const Card = ({ logAuditoria }) => (
  <div className="card">
    <div className="header">
      <div>
        <b>Alterações</b>
      </div>
    </div>
    <div className="content">
      <div className="table-responsive">
        {logAuditoria && logAuditoria[0] && logAuditoria[0].alteracoes && logAuditoria[0].alteracoes.length > 0 ? (
          <ReactTable
            className="-striped -highlight"
            defaultPageSize={50}
            data={logAuditoria[0].alteracoes}
            loadingText="Carregando..."
            noDataText="Nenhuma Informação encontrada"
            showPageSizeOptions={false}
            minRows={1}
            showPagination={false}
            columns={[
              {
                Header: "Campo",
                accessor: "label",
                className: "align-middle text-center",
                headerClassName: "font-extra-bold",
              },
              {
                Header: "Valor Anterior",
                accessor: "oldValue",
                className: "align-middle text-center",
                headerClassName: "font-extra-bold",
              },
              {
                Header: "Valor Atual",
                accessor: "newValue",
                className: "align-middle text-center",
                headerClassName: "font-extra-bold",
              },
            ]}
          />
        ) : (
          <div className="Alcenter">Nenhuma Alteração Encontrada.</div>
        )}
      </div>
    </div>
  </div>
);
class ContractLog extends Component {
  vehicleData(dadosVeiculo) {
    try {
      if (dadosVeiculo) {
        var obj = JSON.parse(dadosVeiculo);
        var dadosRetorno = obj.ufPlaca ? `Placa: ${obj.ufPlaca} ${obj.numPlaca}` : "";
        dadosRetorno = dadosRetorno + (obj.renavam ? ` Renavam: ${obj.renavam}` : "");
        dadosRetorno = dadosRetorno + (obj.chassi ? ` Chassi: ${obj.chassi}` : "");
        dadosRetorno = dadosRetorno + (obj.numGravame ? ` Gravame: ${obj.numGravame}` : "");
        return dadosRetorno;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  vehicleError(dadosVeiculo) {
    try {
      if (dadosVeiculo) {
        var obj = JSON.parse(dadosVeiculo);
        return obj.messages;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  renderLogs() {
    if (!this.props.logs || this.props.logs === 0) {
      return null;
    }

    var idsContrato = [];
    var idsVeiculo = [];
    var contrato90Alterado = false;
    var logsHtml = this.props.logs.map((log, index) => {
      var contrato = false;
      var novoContrato = false;
      var averbacao = false;
      var textoStatus90Contrato = "";

      if (log.statusContrato && log.statusContrato === 8) {
        averbacao = true;
      }

      if (log.idContrato) {
        contrato = true;
        contrato90Alterado = false;
        if (idsContrato.indexOf(log.idContrato) > -1) {
          novoContrato = false;
        } else {
          novoContrato = true;
        }
        idsContrato = [...idsContrato, log.idContrato];
      }
      var veiculo = false;
      var novoVeiculo = false;
      if (log.idVeiculo) {
        veiculo = true;
        if (idsVeiculo.indexOf(log.idVeiculo) > -1) {
          novoVeiculo = false;
        } else {
          novoVeiculo = true;
        }
        idsVeiculo = [...idsVeiculo, log.idVeiculo];
      }

      if (contrato && log.statusContrato === 90) {
        if (log.logAuditoria && log.logAuditoria[0] && log.logAuditoria[0].alteracoes && log.logAuditoria[0].alteracoes.length > 0) {
          textoStatus90Contrato = "Contrato Editado";
          contrato90Alterado = true;
        } else {
          textoStatus90Contrato = "Erro no registro do Contrato";
        }
      }

      if (contrato && (log.statusContrato === 1 || log.statusContrato === 9)) {
        return null;
      }

      if (veiculo && (log.statusVeiculo === 1 || (log.statusVeiculo === 3 && !log.detran) || log.statusVeiculo === 4 || log.statusVeiculo === 9 || log.statusVeiculo === 10)) {
        return null;
      }

      return (
        <div className="vertical-timeline-block" key={index}>
          <div className="vertical-timeline-icon navy-bg">
            <i className="far fa-calendar-alt" style={{ display: "block", position: "relative", left: "50%", top: "50%", marginLeft: "-8px", marginTop: "-9px" }}></i>
          </div>
          <div className="vertical-timeline-content">
            <div className="p-sm">
              <span className="vertical-date float-right">
                {" "}
                {log.nome} <br />{" "}
                <small>
                  {" "}
                  {MaskDate.TO_BR_DATETIME(log.data)} {log.ip ? "(ip: " + log.ip + ")" : ""}{" "}
                </small>{" "}
              </span>

              {contrato && (
                <div>
                  <h2>
                    {novoContrato === true ? (
                      averbacao ? (
                        "Averbação de Contrato Criada"
                      ) : (
                        "Contrato Criado"
                      )
                    ) : log.statusContrato === 0 || log.statusContrato === 8 ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Contrato Editado</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 2 && !log.detran ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Contrato Registrado</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 2 && log.detran && log.detran === "2" ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Alteração de Contrato Enviada</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 3 && !log.detran ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Imagem do Contrato Enviada</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 3 && log.detran && log.detran === "2" ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Alteração de Contrato Enviada</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 4 ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Contrato Homologado</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 5 ? (
                      <span>
                        Contrato Baixado
                        {this.props.user && !this.props.user.idFinanceira && (
                          <span>
                            <br />
                            <p>{log.justificativa ? "Justificativa: " + log.justificativa : ""}</p>
                          </span>
                        )}
                      </span>
                    ) : log.statusContrato === 6 ? (
                      <span>
                        Contrato Cancelado
                        {this.props.user && !this.props.user.idFinanceira && (
                          <span>
                            <br />
                            <p>{log.justificativa ? "Justificativa: " + log.justificativa : ""}</p>
                          </span>
                        )}
                      </span>
                    ) : log.statusContrato === 7 ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>Contrato Averbado</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : log.statusContrato === 10 ? (
                      <div>
                        <h2>Contrato não homologado</h2>
                        <br />
                        <p>{log.justificativa}</p>
                      </div>
                    ) : log.statusContrato === 11 ? (
                      "Contrato Em Processamento"
                    ) : log.statusContrato === 90 ? (
                      <Popup
                        className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                        trigger={<h5>{textoStatus90Contrato}</h5>}
                        position={this.props.position ? this.props.position : "top center"}
                        on="hover"
                      >
                        <Card logAuditoria={log.logAuditoria} />
                      </Popup>
                    ) : (
                      `${log.statusContrato}`
                    )}
                  </h2>
                </div>
              )}

              {veiculo && (
                <div>
                  {novoVeiculo ? (
                    <div>
                      <h2>Veículo Adicionado ao Contrato e Registrado</h2>
                      <br />
                      <p>{this.vehicleData(log.dadosVeiculo)}</p>
                    </div>
                  ) : (
                    <div>
                      <h2>
                        {console.log(log.statusVeiculo, log.detran)}
                        {log.statusVeiculo == 0 ? (
                          <Popup
                            className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                            trigger={<h5>Veículo Alterado</h5>}
                            position={this.props.position ? this.props.position : "top center"}
                            on="hover"
                          >
                            <Card logAuditoria={log.logAuditoria} />
                          </Popup>
                        ) : log.statusVeiculo == 91 ? (
                          "Veículo Excluído"
                        ) : log.statusVeiculo == 2 && !log.detran ? (
                          <Popup
                            className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                            trigger={<h5>Veículo Registrado</h5>}
                            position={this.props.position ? this.props.position : "top center"}
                            on="hover"
                          >
                            <Card logAuditoria={log.logAuditoria} />
                          </Popup>
                        ) : (log.statusVeiculo == 2 || log.statusVeiculo == 3) && log.detran && log.detran == "2" ? (
                          <Popup
                            className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                            trigger={<h5>Alteração de Veículo Enviada</h5>}
                            position={this.props.position ? this.props.position : "top center"}
                            on="hover"
                          >
                            <Card logAuditoria={log.logAuditoria} />
                          </Popup>
                        ) : log.statusVeiculo == 5 ? (
                          "Veículo Baixado"
                        ) : log.statusVeiculo == 6 ? (
                          "Veículo Cancelado"
                        ) : log.statusVeiculo == 7 ? (
                          "Veículo Averbado"
                        ) : log.statusVeiculo == 8 ? (
                          <Popup
                            className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                            trigger={<h5>Veículo Alterado</h5>}
                            position={this.props.position ? this.props.position : "top center"}
                            on="hover"
                          >
                            <Card logAuditoria={log.logAuditoria} />
                          </Popup>
                        ) : log.statusVeiculo == 11 ? (
                          "Veículo Em Processamento"
                        ) : log.statusVeiculo == 90 ? (
                          <Popup
                            className={this.props.position == "top center" ? "popup-content" : "popup-content-content"}
                            trigger={<h5>{contrato90Alterado ? "Veículo Alterado" : "Erro no registro do veículo"}</h5>}
                            position={this.props.position ? this.props.position : "top center"}
                            on="hover"
                          >
                            <Card logAuditoria={log.logAuditoria} />
                          </Popup>
                        ) : (
                          log.statusVeiculo
                        )}
                      </h2>
                      <br />
                      <p>{log.statusVeiculo == 90 ? this.vehicleError(log.dadosVeiculo) : this.vehicleData(log.dadosVeiculo)}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });

    return logsHtml.reverse();
  }

  render() {
    return <div>{this.renderLogs()}</div>;
  }
}

ContractLog.propTypes = {
  logs: PropTypes.array,
  user: PropTypes.object,
};

export default ContractLog;
