import { TAXA_DETRAN_LIST_LOAD, TAXA_DETRAN_LIST_UNLOAD, TAXA_DETRAN_ALL_COMPANIES_LOAD} from '../../actions/Constants';

const INITIAL_STATE = { all: [], allCompanies: []};

export default function taxasDetran(state = INITIAL_STATE, action) {
    switch (action.type) {    
        case TAXA_DETRAN_LIST_LOAD:
            return {
                ...state,
                all: action.payload ? action.payload : null,
            };
        case TAXA_DETRAN_ALL_COMPANIES_LOAD:
            return {
                ...state,
                allCompanies: action.payload ? action.payload : null,
            };
        case TAXA_DETRAN_LIST_UNLOAD:
            return INITIAL_STATE;    
        default:
        return state;
  }
}
