import { USER_ALIAS_PARCEIRO_LOAD, USER_ALIAS_UNLOAD, USER_ALIAS_LOAD, USER_ALIAS_ROLES } from "../../actions/Constants";

const INITIAL_STATE = { all: [], parceiros: [], roles: [] };

export default function userAlias(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_ALIAS_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case USER_ALIAS_PARCEIRO_LOAD:
      return {
        ...state,
        parceiros: action.payload ? action.payload : [],
      };
    case USER_ALIAS_ROLES:
      return {
        ...state,
        roles: action.payload ? action.payload : [],
      };
    case USER_ALIAS_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
