import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, change, formValueSelector } from "redux-form";
import _, { values } from "lodash";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { CONFIG_SNG_LOAD_UFS } from "../../actions/Constants";

import api from "../../api/apiUtil";
import TextField from "../../components/Fields/TextField";
import Dropzone from "react-dropzone";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import OtherUtils from "../../components/Util/OtherUtils";
import SelectField from "../../components/Fields/SelectField";
import { Link, browserHistory } from "react-router";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import ToggleButton from "react-toggle-button";
import ReactTable from "react-table";
import Modal from "react-bootstrap4-modal";
import TextIconField from "../../components/Fields/TextIconField";

class ConfiguracaoSng extends React.Component {
  constructor() {
    super();
    this.state = {
      ufs: [],
    };
  }

  componentDidMount() {
    let ufs = this.props.currentUser.ufs.slice(1);
    this.setState({
      ufs: ufs.map((obj) => ({
        ...obj,
        habilitado: ["MG"].includes(obj.sigla) ? true : false,
      })),
    });
  }

  componentWillUnmount() {}

  clearFormSng = () => {
    this.props.dispatch(change("IntegrationForm", "codigo", null));
    this.props.dispatch(change("IntegrationForm", "subcodigo", null));
    this.props.dispatch(change("IntegrationForm", "login1", null));
    this.props.dispatch(change("IntegrationForm", "login2", null));
    this.props.dispatch(change("IntegrationForm", "senhaSng", null));
    this.props.dispatch(change("IntegrationForm", "cpf", null));
    this.props.dispatch(change("IntegrationForm", "email", null));
  };

  formSubmit = async (values) => {};

  cadastrarSNG = (values) => {};

  atualiza = (value, index) => {
    let clone = [...this.state.ufs];
    clone[index].habilitado = !value;
    this.setState({
      ufs: [...clone],
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <form className="form-horizontal">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="hpanel">
                      <div className="panel-heading">Credenciais do usuário Master SNG </div>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="codigo" label="Código" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="subcodigo" label="Subcódigo" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="login1" label="Login" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                          <div className="align-self-end form-group">
                            <h4>/</h4>
                          </div>
                          <div className="col-md-3">
                            <Field name="login2" label="&nbsp;" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              required={true}
                              name="senhaSng"
                              component={TextIconField}
                              type="password"
                              description="Senha de Acesso SNG"
                              placeholder="******"
                              label="Senha"
                              describePassword={true}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              name="cpf"
                              normalize={FieldNormalize.CPF}
                              validate={[FieldValidation.cpf]}
                              description="CPF do usuário do SNG"
                              label="CPF"
                              component={TextField}
                              type="text"
                              required={true}
                            />
                          </div>
                          <div className="col-md-7">
                            <Field required={true} name="email" label="Email" component={TextField} type="text" description="Email de Acesso SNG" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="hpanel">
                      <div className="panel-heading">Usuários operadores SNG </div>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="subcodigo" label="Subcódigo" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                          <div className="col-md-3">
                            <Field name="login1" label="Login" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                          <div className="align-self-end form-group">
                            <h4>/</h4>
                          </div>
                          <div className="col-md-3">
                            <Field name="login2" label="&nbsp;" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                          </div>
                          <div className="col-md-3 col-lg-2 align-self-end form-group">
                            <button type="submit" className="btn btn-info">
                              {" "}
                              Adicionar
                            </button>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="table-responsive">
                            <ReactTable
                              className="-striped -highlight"
                              defaultPageSize={20}
                              data={this.props.all}
                              loadingText="Carregando..."
                              noDataText="Nenhuma Informação encontrada"
                              rowsText="linhas"
                              showPageSizeOptions={false}
                              minRows={1}
                              showPagination={true}
                              previousText="Anterior"
                              nextText="Próximo"
                              pageText="página"
                              ofText="de"
                              columns={[
                                {
                                  Header: "Subcódigo",
                                  accessor: "subcodigo",
                                  headerClassName: "font-extra-bold",
                                },
                                {
                                  Header: "login",
                                  accessor: "chassi",
                                  className: "align-middle text-center",
                                  headerClassName: "font-extra-bold",
                                },

                                {
                                  Header: "Ação",
                                  className: "align-middle text-center",
                                  headerClassName: "font-extra-bold",
                                  width: 190,
                                  Cell: (row) => (
                                    <div className="btn-group">
                                      <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                        {" "}
                                        Download{" "}
                                      </Link>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="hpanel">
                      <div className="panel-heading">Configurações de execução </div>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              name="execucao"
                              label="Execução"
                              data={[
                                { id: "1", name: "Desabilitado" },
                                { id: "2", name: "Automático" },
                                { id: "3", name: "Manual" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                            />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="periodicidade"
                              label="Periodicidade"
                              data={[
                                { id: "5", name: "05 Minutos" },
                                { id: "15", name: "15 Minutos" },
                                { id: "30", name: "30 Minutos" },
                                { id: "60", name: "01 Hora" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                              disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}
                            />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="horaInicio"
                              normalize={FieldNormalize.HHMM}
                              validate={[FieldValidation.HHMM]}
                              label="Hora início"
                              component={TextField}
                              type="text"
                              required={true}
                              disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}
                            />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="horaFim"
                              normalize={FieldNormalize.HHMM}
                              validate={[FieldValidation.HHMM]}
                              label="Hora Fim"
                              component={TextField}
                              type="text"
                              required={true}
                              disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}
                            />
                          </div>
                          {/* <div className="col-md-3 col-lg-2 align-self-end form-group">
                            <button type="submit" className="btn btn-info" disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}>
                              {" "}
                              Salvar
                            </button>
                          </div> */}
                        </div>
                        <div className="row">
                          {this.state.ufs.map((value, index) => {
                            return (
                              <div className="col-md-3">
                                <ul className="list-group m-t-sm">
                                  <li className="list-group-item">
                                    <div className="d-flex justify-content-between">
                                      <div>{value.sigla} </div>
                                      <div>
                                        <ToggleButton
                                          disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}
                                          value={value.habilitado}
                                          onToggle={(item) => this.atualiza(item, index)}
                                          inactiveLabelStyle={{ color: "rgb(250, 250, 250)" }}
                                          colors={{
                                            activeThumb: {
                                              base: "rgb(250,250,250)",
                                            },
                                            inactiveThumb: {
                                              base: "rgb(250,250,250)",
                                            },
                                            active: {
                                              base: "rgb(98, 203, 49)",
                                              hover: "rgb(98, 203, 49)",
                                            },
                                            inactive: {
                                              base: "rgb(255, 80, 80)",
                                              hover: "rgb(255, 80, 80)",
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="hpanel">
                      <div className="col-md-12">
                        <div className="text-right">
                          <button className="btn btn-info" to={`/alias/userLogAudit/${this.props.params.slug}`} disabled={!this.props.selectedExecucao || this.props.selectedExecucao == 1}>
                            {" "}
                            Salvar informações
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  // onLoadUf: (payload) => dispatch({ type: CONFIG_SNG_LOAD_UFS, payload }),
});

const selector = formValueSelector("ConfiguracaoSngForm");
const mapStateToProps = (state) => ({
  ...state.configuracaoSng,
  currentUser: state.common.currentUser,
  selectedExecucao: selector(state, "execucao"),
});

const form = reduxForm({
  form: "ConfiguracaoSngForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfiguracaoSng));
