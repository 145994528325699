import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import Modal from "react-bootstrap4-modal";
import { toastr } from "react-redux-toastr";
import { CONFIRM_CHANGE_PASSWORD_CLOSE, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";
import TextField from "../../components/Fields/TextField";
import FieldValidation from "../../components/Validation/FieldValidation";
import _ from "lodash";
import { SubmissionError } from "redux-form";
import TextIconField from "../../components/Fields/TextIconField";

class ConfirmChangePassword extends Component {
  componentWillUnmount() {
    this.props.onClose();
  }

  handleOnLogoutClick(event) {
    event.preventDefault();
    api.Auth.logout();
    this.props.onLogout();
  }

  formSubmit(props) {
    var propsProperty = this.props;
    Promise.resolve(api.Auth.checkOperationPassword(this.props.currentUser.user, props.passwordOperation))
      .then((auth) => {
        this.props.onSuccess();
        this.props.reset();
        this.props.onClose();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          propsProperty.reset();
          propsProperty.onClose();
          api.Auth.logout();
          propsProperty.onLogout();
        } else {
          toastr.warning("Atenção", "Senha inválida!");
        }
      });
  }

  formSubmitSenhas(values) {
    var props = this.props;
    var user = {
      id: this.props.currentUser.id,
      password: values.password,
      passwordOperation: values.passwordOperation,
      primeiroAcesso: 1,
    };

    Promise.resolve(api.Users.update(user))
      .then((ret) => {
        toastr.success("Sucesso", "Senha alterada.");
        this.props.reset();
        this.props.onClose();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (resp.message === "Unprocessable Entity") {
          toastr.error("Erro", "Algum caractere especial inserido na senha não foi aceito.");
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit } = this.props;

    return (
      <Modal visible={this.props.open} dialogClassName="modal-max-width">
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
          <div className="modal-header">
            <div>
              <h5 className="modal-title">Altere Suas Senha</h5>
              <h6>Primeiro Acesso</h6>
            </div>
          </div>
          <div className="modal-body">
            {!this.props.currentUser.loginMicrosoft ? (
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="password"
                    label="Nova senha de login"
                    component={TextIconField}
                    type="password"
                    description="Sua senha secreta"
                    validate={[FieldValidation.passwordValid]}
                    required={true}
                    describePassword={true}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="confirmPassword"
                    label="Confirmação da nova senha de login"
                    component={TextIconField}
                    type="password"
                    description="Digite novamente sua senha para confirmar"
                    validate={[FieldValidation.passwordValid]}
                    required={true}
                    describePassword={true}
                  />
                </div>
                <div className="col-md-12">
                  <h5 className="font-bold">Senha específica para acessar a Plataforma E-Registro.</h5>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr />
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="passwordOperation"
                  label="Nova senha de operação"
                  component={TextIconField}
                  type="password"
                  description="Sua senha secreta utilizada para confirmar operações"
                  validate={[FieldValidation.passwordValid]}
                  required={true}
                  describePassword={true}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="confirmPasswordOperation"
                  label="Confirmação da nova senha de operação"
                  component={TextIconField}
                  type="password"
                  description="Digite novamente sua senha de operação para confirmar"
                  validate={[FieldValidation.passwordValid]}
                  required={true}
                  describePassword={true}
                />
              </div>
            </div>

            <div>
              <h5 className="font-bold">Senha específica para comunicações (envio de informações) aos Detrans.</h5>
            </div>
            {/* <div>
              <h5 className="font-bold">Alias prezando pela maior segurança de seus usuários!</h5>
            </div> */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.handleOnLogoutClick.bind(this)} title="Não confirmar a operação e sair do sistema">
              Cancelar
            </button>

            <button
              className="btn btn-info"
              type="button"
              onClick={this.props.handleSubmit((values) => {
                return new Promise((resolve, reject) => {
                  const errors = _.assign(validateSenhas(values, this.props.currentUser));
                  if (errors && !_.isEmpty(errors)) {
                    reject(new SubmissionError(errors));
                  } else {
                    this.formSubmitSenhas(values);
                    resolve();
                  }
                });
              })}
            >
              {" "}
              Confirmar operação
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

function validateSenhas(values, currentUser) {
  const errors = {};

  if (!currentUser.loginMicrosoft && (!values.password || values.password.length === 0 || !values.password.trim())) {
    errors.password = "Campo obrigatório";
  }
  if (!currentUser.loginMicrosoft && (!values.confirmPassword || values.confirmPassword.length === 0 || !values.confirmPassword.trim())) {
    errors.confirmPassword = "Campo obrigatório";
  }
  if (!currentUser.loginMicrosoft && values.confirmPassword) {
    if (values.password != values.confirmPassword) {
      errors.password = "A senha e a confirmação de senha não conferem";
    }
  }
  if (!values.passwordOperation || values.passwordOperation.length === 0 || !values.passwordOperation.trim()) {
    errors.passwordOperation = "Campo obrigatório";
  }
  if (!values.confirmPasswordOperation || values.confirmPasswordOperation.length === 0 || !values.confirmPasswordOperation.trim()) {
    errors.confirmPasswordOperation = "Campo obrigatório";
  }
  if (values.confirmPasswordOperation) {
    if (values.passwordOperation != values.confirmPasswordOperation) {
      errors.passwordOperation = "A senha de operação e a confirmação de senha de operação não conferem";
    }
  }

  return errors;
}

const mapStateToProps = (state) => ({
  ...state.common,
  ...state.confirmChangePassword,
  initialValues: { username: state.common.currentUser ? state.common.currentUser.user : "", passwordOperation: "" },
});

const mapDispatchToProps = (dispatch) => ({
  onClose: (payload) => dispatch({ type: CONFIRM_CHANGE_PASSWORD_CLOSE }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const form = reduxForm({
  form: "ConfirmChangePasswordForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfirmChangePassword));
