import {FINANCIAL_REPORT_SC_LOAD, FINANCIAL_REPORT_SC_UNLOAD, FINANCIAL_REPORT_DETAILS_SC_LOAD, FINANCIAL_REPORT_SC_LIST_LOAD} from '../../../actions/Constants';

const INITIAL_STATE = { companies: [], detailsList: [], all: []};

export default function financialReportSC(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_REPORT_SC_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case FINANCIAL_REPORT_SC_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case FINANCIAL_REPORT_DETAILS_SC_LOAD:
      return {
        ...state,
        detailsList: action.payload ? action.payload[0] : [],
      };
    case FINANCIAL_REPORT_SC_UNLOAD:
      return INITIAL_STATE;


    default:
      return state;
  }
}
