import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";
import TextField from "../../components/Fields/TextField";
import { BeatLoader } from "react-spinners";
import SelectField from "../../components/Fields/SelectField";
import { CONTRACT_REGISTER_PR_UNLOAD, CONTRACT_REGISTER_PR_LOAD, UF_SYSTEM_LOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
class ConsultaChassiPR extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterChassi")) {
      document.getElementById("filterChassi").click();
    }
  };

  componentDidMount() {
    Promise.resolve(api.Ufs.sigla_system()).then((all) => {
      this.props.onUfSystemLoad(all.filter((item) => item.sigla === "BA" || item.sigla === "PR" || item.sigla === "SP" || item.sigla === "RJ"));
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  formSubmit(values) {
    var props = this.props;
    const errors = {};
    if (!values.findChassi || values.findChassi.length === 0) {
      errors.findChassi = "Campo obrigatório";
    }
    if (!values.uf || values.uf.length === 0) {
      errors.uf = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
      throw new SubmissionError(errors);
    }
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    if (values.uf === "BA") {
      Promise.resolve(api.Contracts.recuperaContratoRegistradoBA(values.findChassi))
        .then((res) => {
          this.props.onListLoad(res);
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (!res) {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else if (values.uf === "PR") {
      Promise.resolve(api.Contracts.recuperaContratoRegistradoPR(values.findChassi))
        .then((res) => {
          this.props.onListLoad(res);
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (!res) {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else if (values.uf === "SP") {
      Promise.resolve(api.Contracts.recuperaContratoRegistradoSP(values.findChassi))
        .then((res) => {
          this.props.onListLoad(res);
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (!res) {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else {
      Promise.resolve(api.Contracts.recuperaContratoRegistradoRJ(values.findChassi))
        .then((res) => {
          this.props.onListLoad(res);
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (!res) {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Consulta de Contrato por Chassi" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Realizar Consulta
                </div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-2">
                        <Field name="uf" label="UF" data={this.props.ufSystem} textField="sigla" component={SelectField} type="text" required blank={true} />
                      </div>
                      <div className="col-md-3">
                        <Field name="findChassi" label="Chassi" required={true} component={TextField} type="text" maxlength={17} />
                      </div>
                      <div className="col-lg-2 align-self-end form-group">
                        <button className="btn btn-info" type="submit" disabled={this.state.pesquisando} id="filterChassi">
                          {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                        </button>
                      </div>
                    </div>
                  </form>
                  {this.props.all && (
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-12">
                          <pre>{JSON.stringify(this.props.all).replace(/[,]+/g, "\n")}</pre>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: CONTRACT_REGISTER_PR_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: CONTRACT_REGISTER_PR_UNLOAD, payload }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.consultaChassiPR,
});

const form = reduxForm({
  form: "ConsultaChassiPRForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConsultaChassiPR));
