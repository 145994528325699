import React from "react";

function renderBlank(blank) {
  if (blank) {
    return <option></option>;
  } else {
    return null;
  }
}

function renderOptions(options, valueField, textField, input) {
  if (options) {
    return options.map((option) => {
      return (
        <option value={option[valueField]} key={option[valueField]}>
          {option[textField]}
        </option>
      );
    });
  } else {
    return null;
  }
}

const SelectField = ({ input, label, type, meta: { touched, error, valid }, data, valueField, textField, blank, inline, small, description, required, disabled }) => (
  <div className={`form-group ${touched && !valid ? "has-error" : ""} ${inline ? "row" : ""} field-select-fixo`}>
    <label className={`font-weight-bold ${inline ? "col-sm-2  col-form-label" : ""}`}>{label}</label>
    <div className={`${inline ? "col-sm-10" : ""}`}>
      <select
        {...input}
        disabled={disabled}
        className={`${small ? "form-control custom-select custom-select-sm" : "form-control custom-select"} ${required ? (valid && input.value ? "field-valid" : "field-required") : ""}`}
        size="1"
      >
        {renderBlank(blank)}
        {renderOptions(data, valueField ? valueField : "id", textField ? textField : "name", input)}
      </select>
      {description && <span className="help-block small">{description}</span>}
      <p className="text-danger">{touched ? error : ""}</p>
    </div>
  </div>
);

export default SelectField;
