import { CONTRACT_CONFIA_LOAD, CONTRACT_CONFIA_UNLOAD, CAD_CONTRACT_CONFIA_UNLOAD, CAD_CONTRACT_CONFIA_LOAD, CAD_CONTRACT_UF_LOAD } from '../../actions/Constants';

const INITIAL_STATE = { all: [], ufs:[]};

export default function consultaConfia(state = INITIAL_STATE, action) {
    switch (action.type) {    
        case CONTRACT_CONFIA_LOAD:
            return {
                ...state,
                all: action.payload ? action.payload : null,
            };
        case CAD_CONTRACT_UF_LOAD:
            return {
                ...state,
                ufs: action.payload ? action.payload : null,
            }        
        case CAD_CONTRACT_CONFIA_UNLOAD:
            return INITIAL_STATE;
        case CONTRACT_CONFIA_UNLOAD:
            return INITIAL_STATE;               
        default:
        return state;
  }
}
