import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import Dropzone from "react-dropzone";

import { LOTE_RJ_LIST_LOAD, LOTE_RJ_LIST_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
import fileDownload from "js-file-download";
class LoteRJ extends React.Component {
  componentDidMount() {
    this.props.onListLoad(api.Contracts.getLotRJ());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    Promise.resolve(api.Contracts.sendLoteRJ(filesToUpload[0]))
      .then((response) => {
        this.props.onListLoad(api.Contracts.getLoteRJ());
        toastr.success("Sucesso", "Planilha enviada.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.warning("Atenção", resp.response.body);
        }
      });
  };

  handleClickDownload = (value) => {
    var props = this.props;
    Promise.resolve(api.Contracts.verificaLoteRJ(value.id))
      .then((ret) => {
        Promise.resolve(api.Contracts.getLoteRJ(value.id))
          .then((response) => {
            fileDownload(response, value.nome.replace(".csv", "_resposta.csv"));
          })
          .catch(function (resp) {
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          });
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.warning("Atenção", resp.response.body);
        }
      });
  };

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Upload/Download Integra+ RJ" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Dropzone accept=".csv" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                      <button type="button" className="btn btn-primary btn-xs">
                        <i className="fa fa-upload" /> Enviar Nova Planilha
                      </button>
                    </Dropzone>
                  </div>
                  Planilhas enviadas
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={50}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        columns={[
                          {
                            Header: "Planilha",
                            accessor: "nome",
                            headerClassName: "font-extra-bold",
                          },
                          {
                            Header: "Data Inclusão",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowData.original.data)}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Ação",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 190,
                            Cell: (row) => (
                              <div className="btn-group">
                                <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                  {" "}
                                  Download Respostas{" "}
                                </Link>
                              </div>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: LOTE_RJ_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: LOTE_RJ_LIST_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.loteRJ,
});

const form = reduxForm({
  form: "LoteRJForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(LoteRJ));
