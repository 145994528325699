import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
var UmapiVinDecode = require('umapi_vin_decode').default

class TextField extends Component {
  constructor() {
    super();
    this.state = {
      chassiDecode: null,
    };
  }
  decodeChassi(){
    try {
      var vin = new UmapiVinDecode(this.props.tooltipChassi);
      var result = `${!vin.identifyModelYear() ?
        ''                    :
        `<p>Ano modelo: ${vin.identifyModelYear()}</p>`
      }
      ${!vin.identifyCountry()?
        ''                    :
        `<p>Fabricação: ${vin.identifyCountry().region +' - '+ vin.identifyCountry().name}</p>`
      }
      ${!vin.identifyManufacturer() ?
        ''                    :
        `<p>Fabricante: ${vin.identifyManufacturer()}</p>`
      }`      
      return result;
    } catch (err) {
      return null;
    }
  }

  render() {
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? 'has-error' : ''} ${this.props.inline ? 'row' : ''}`}>
        <label className={`font-weight-bold ${this.props.inline ? 'col-sm-2  col-form-label' : ''}`}>{this.props.label}</label>
        {this.props.textTooltip &&
          <span data-tip data-for={this.props.label}>
            &nbsp;
            <i className="fas fa-question-circle"></i>
            <ReactTooltip id={this.props.label} place="top" effect="solid" html={true} className="tooltipLabel">
              {this.props.textTooltip}
            </ReactTooltip>
          </span>        
        }     
        {this.props.tooltipChassi &&
          <span data-tip data-for={this.props.label}>
            &nbsp;
            <i className="fas fa-question-circle"></i>
            <ReactTooltip id={this.props.label} place="top" effect="solid" html={true} className="tooltipLabel">
              {this.decodeChassi() == null ? 'Informações de Chassi não disponíveis' : this.decodeChassi()}
            </ReactTooltip>
          </span>
        }   
        <div className={`${this.props.inline ? 'col-sm-10' : ''}`}>
          <input {...this.props.input} title={this.props.title} type={this.props.type} disabled={this.props.disabled} placeholder={this.props.placeholder} maxlength={this.props.maxlength ? this.props.maxlength : ''} className={`${this.props.required ? this.props.meta.valid && this.props.input.value ? 'field-valid' : 'field-required' : ''} ${this.props.small ? 'form-control form-control-sm' : 'form-control'}`}  />
          {
            this.props.description && <span className="help-block small">{this.props.description}</span>
          }
          <p className="text-danger">
            {this.props.meta.touched ? this.props.meta.error : ''}
          </p>
        </div>
      </div>
    );
  }
}

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  inline: PropTypes.bool,
  description: PropTypes.string,
  small: PropTypes.bool,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  textTooltip: PropTypes.string,
  tooltipChassi: PropTypes.string,
};

export default TextField;
