import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import api from '../../api/apiUtil';

class CityFilteredField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cities: [], 
    };
  }

  componentWillMount() {
    if (this.props.selectedUf) {
      Promise.resolve(api.Ufs.cities(this.props.selectedUf)).then(values => { this.setState({cities: values}); });
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedUf) {
      if ((this.props.selectedUf !== nextProps.selectedUf) && nextProps.selectedUf) {
        Promise.resolve(api.Ufs.cities(nextProps.selectedUf.sigla ? nextProps.selectedUf.sigla : nextProps.selectedUf)).then(values => { this.setState({cities: values}); });
      }
    }
  }

  render() {
    const {label, options, input, rest} = this.props;
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? 'has-error' : ''} ${this.props.inline ? 'row' : ''}`}>
          <label className={`font-weight-bold ${this.props.inline ? 'col-sm-2  col-form-label' : ''}`}>{this.props.label}</label>
          <div className={`${this.props.inline ? 'col-sm-10' : ''}`}>
            <Select {...this.props} className={`${this.props.required ? this.props.meta.valid && this.props.input.value ? 'field-valid-select' : 'form-control field-required-select' : ''}`}
              value={this.props.input.value ? this.props.input.value.municipioId ? this.state.cities.filter(option => option.municipioId == this.props.input.value.municipioId) : this.state.cities.filter(option => option.municipio == this.props.input.value) : null}
              getOptionValue={option => this.props.input.name == "devMunicipio" ? option.municipioId :  option.municipio}
              getOptionLabel={option => option.municipio}
              options={this.state.cities}
              isClearable={true}
              noOptionsMessage={() => "Nenhuma opção encontrada."}
              placeholder=''
              onChange={(value) => this.props.input.onChange(value)}
              onBlur={() => this.props.input.onBlur(this.props.input.value)}
              filterOption={createFilter({ matchFrom: 'start' })}
            />
            {
              this.props.description && <span className="help-block small">{this.props.description}</span>
            }
            <p className="text-danger">
              {this.props.meta.touched ? this.props.meta.error : ''}
            </p>
          </div>
      </div>
    );
  }
}

CityFilteredField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  validate: PropTypes.array,
  selectedUf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  valueField: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object,
  input: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ...state.uf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CityFilteredField);
