import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { UF_LOAD } from '../../actions/Constants';
import api from '../../api/apiUtil';


function renderBlank(blank) {
  if (blank) {
    return (
      <option></option>
    )
  } else {
    return null;
  }

}

function renderOptions(options) {
  if (options) {
    return options.map((option) => {
      return (
        <option value={option.id} key={option.id}>{option.razaoSocial} - {option.cnpj}</option>
      )
    })
  } else {
    return (
      null
    );
  }
}

const SelectField = ({ input, label, type, meta: { touched, error, valid }, data, valueField, textField, blank, inline, small, description, required }) => (
  <div className={`form-group ${touched && !valid ? 'has-error' : ''} ${inline ? 'row' : ''}`}>
    <label className={`font-weight-bold ${inline ? 'col-sm-2  col-form-label' : ''}`}>{label}</label>
    <div className={`${inline ? 'col-sm-10' : ''}`}>
      <select {...input} className={`${small ? 'form-control custom-select custom-select-sm' : 'form-control custom-select'} ${required ? valid && input.value ? 'field-valid' : 'field-required' : ''}`} size="1">
        {renderBlank(data.length > 0)}
        {renderOptions(data)}
      </select>
      {
        description && <span className="help-block small">{description}</span>
      }
      <p className="text-danger">
        {touched ? error : ''}
      </p>
    </div>
  </div>
);

class CompanyField extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {

  }

  render() {
    if (this.props.data) {
      return (
      <Field name={this.props.name} required={this.props.required} label={this.props.label} data={this.props.data} textField="cnpj" valueField="id" component={SelectField} type="text" inline={this.props.inline} description={this.props.description} small={this.props.small}/>
      )
    }
    else {
      return null;
    }
  }
}

CompanyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  validate: PropTypes.array,
  data:  PropTypes.array,
  small: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool
};



export default CompanyField;
