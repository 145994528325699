import { PROFILE_LOAD, PROFILE_UNLOAD, LOGOUT, PROFILE_UPDATE } from "../../actions/Constants";

const INITIAL_STATE = { user: null };

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROFILE_LOAD:
      return {
        ...state,
        user: action.payload ? action.payload : null,
      };
    case PROFILE_UPDATE:
      return {
        ...state,
        user: action.payload,
      };
    case PROFILE_UNLOAD:
      return INITIAL_STATE;
    case LOGOUT:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}
