import React from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import { reduxForm, Field, reset, change, untouch, formValueSelector } from "redux-form";
import { SubmissionError } from "redux-form";
import SelectField from "../../components/Fields/SelectField";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";

import { FINANCIAL_REPORT_LOAD, FINANCIAL_REPORT_LIST_LOAD, FINANCIAL_REPORT_UNLOAD, FINANCIAL_REPORT_DETAILS_LOAD, FINANCIAL_REPORT_DETAILS_UNLOAD, LOGOUT } from "../../actions/Constants";

import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";

import jsPDF from "jspdf";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import DropdownListField from "../../components/Fields/DropdownListField";
import { BeatLoader } from "react-spinners";
import OtherUtils from "../../components/Util/OtherUtils";

const ufs = [
  { label: "BA", value: "BA" },
  { label: "PE", value: "PE" },
  { label: "SC", value: "SC" },
];

var agentesList;
var totalPago = 0;
var qtdeChassis = 0;
var valorTotal = 0;

class ChargingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    };
  }

  componentWillMount() {
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(api.Financial.filtroPorUf()).then((all) => {
        this.props.onLoad(all);
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  showDetails = (id) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Charging.chargingDetails(id)).then((dt) => {
      this.props.onDetailsLoad(dt);
    });
  };

  printDetails = (row) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Charging.chargingDetails(row.original.id)).then((dt) => {
      this.exportRegisterPDF(row, dt);
    });
  };

  printDetailsXLS = (row) => (ev) => {
    Promise.resolve(api.Charging.chargingDetails(row.original.id)).then((dt) => {
      this.exportRegisterXLS(row, dt);
    });
  };

  async filter(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    this.props.onListLoad(await Promise.resolve(api.Charging.chargingByPeriod(values.mesReferencia, values.anoReferencia, values.agente.cnpj, values.uf)));
    this.setState({ pesquisando: false });
    toastr.removeByType("info");
    if (!this.props.chargings || this.props.chargings.length == 0) {
      toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
    }
  }

  detalhar(values) {
    var props = this.props;
    var anoReferencia = values.anoReferencia;
    var mesReferencia = values.mesReferencia;
    var agente = values.agente.cnpj;

    var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
    var lastDay = new Date(anoReferencia, mesReferencia, 0);

    var params = {
      periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
      periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      pagina: "1",
    };
    Promise.resolve(
      api.Financial.relatorioCobranca(params)
        .then((response) => {
          this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  }

  detalharPagina(values, page) {
    var props = this.props;
    var anoReferencia = values.anoReferencia;
    var mesReferencia = values.mesReferencia;
    var agente = values.agente.cnpj;

    var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
    var lastDay = new Date(anoReferencia, mesReferencia, 0);

    var params = {
      periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
      periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      pagina: page,
    };
    Promise.resolve(
      api.Financial.relatorioCobranca(params)
        .then((response) => {
          this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  }

  voltar = () => (ev) => {
    ev.preventDefault();
    this.props.onDetailsUnload();
  };

  validateValues(values) {
    const errors = {};

    if (!values.anoReferencia || values.anoReferencia.length === 0 || !values.anoReferencia.trim()) {
      errors.anoReferencia = "Campo obrigatório";
    }
    if (!values.mesReferencia || values.mesReferencia.length === 0 || !values.mesReferencia.trim()) {
      errors.mesReferencia = "Campo obrigatório";
    }
    if (values.agente == undefined || !values.agente.cnpj) {
      errors.agente = "Campo obrigatório";
    }
    if (!values.uf || (values.uf && (values.uf.length === 0 || !values.uf.trim()))) {
      errors.uf = "Campo obrigatório";
    }
    return errors;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  render() {
    const { handleSubmit, reset } = this.props;

    if (this.props.details && this.props.details.length > 0) {
      return (
        <div>
          <Title routes={this.props.routes} params={this.props.params} description="DAEs DETRAN" />{" "}
          <div className="content">
            <div className="row">
              <div className="col-lg-12">
                <div className="hpanel">
                  <Link className="btn btn-default" onClick={this.voltar()}>
                    {" "}
                    Voltar
                  </Link>
                  <div className="panel-heading">Detalhes da Cobrança</div>
                  <div className="panel-body">
                    <div className="table-responsive">{this.renderChargingDetails()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Title routes={this.props.routes} params={this.props.params} description="DAEs DETRAN" />{" "}
          <div className="content">
            <div className="row">
              <div className="col-lg-12">
                <Field name="uf" blank={true} label="UF" required data={ufs} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-6">
                <Field name="mesReferencia" required label="Mês de Referência" data={OtherUtils.monthsWithOptSel} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-6">
                <Field name="anoReferencia" required label="Ano de referência" data={OtherUtils.yearsWithOptSel} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-12">
                <div className="md-4">
                  <Field
                    name="agente"
                    blank={true}
                    required
                    label="Credor"
                    data={this.props.companies}
                    credor={true}
                    textField="razaoSocial"
                    valueField="cnpj"
                    component={DropdownListField}
                    type="text"
                  />
                </div>
                <div className="hpanel">
                  <Link
                    className="btn btn-info"
                    disabled={this.state.pesquisando}
                    onClick={this.props.handleSubmit((values) => {
                      return new Promise((resolve, reject) => {
                        var errors;
                        if (this) {
                          errors = this.validateValues(values);
                        }
                        if (errors && !this.isEmpty(errors)) {
                          reject(new SubmissionError(errors));
                        } else {
                          this.filter(values);
                          resolve();
                        }
                      });
                    })}
                  >
                    {" "}
                    {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                  </Link>
                  <Link className="btn btn-default" to="/alias/charging-report" onClick={reset}>
                    {" "}
                    Cancelar
                  </Link>
                  <div className="panel-heading">Consultar Cobranças DETRAN</div>
                  <div className="panel-body">
                    <div className="table-responsive">{this.renderChargings()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderChargings() {
    const { handleSubmit } = this.props;
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.chargings}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={[
          {
            Header: "Numero DETRAN",
            accessor: "numeroDetran",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
          },
          {
            Header: "Data Geração",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (row) => <div>{MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}</div>,
          },
          {
            Header: "Período",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (row) => (
              <div>
                {MaskDate.TO_BR(row.original.periodoInicio)} - {MaskDate.TO_BR(row.original.periodoFim)}
              </div>
            ),
          },
          {
            Header: "Qtd. Registros",
            accessor: "qtdRegistros",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
          },
          {
            Header: "Vencimento",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataVencimento)}</div>,
          },
          {
            Header: "Valor",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (row) => <div>{MaskDecimal.TO_BRL(row.original.valorTotal)}</div>,
          },
          {
            Header: "Linha Digitável",
            accessor: "linhaDigitavel",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
          },
          {
            Header: "",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            width: 120,
            Cell: (row) => (
              <div>
                <Link className="btn btn-sm btn-default" onClick={this.showDetails(row.original.id)}>
                  <i className="fa fa-info-circle" Title="Visualizar Registros"></i>
                </Link>
                <Link className="btn btn-sm btn-default" onClick={this.printDetails(row)}>
                  {" "}
                  <SvgIcon size={14} Title="Imprimir Registros PDF" icon={filePdf} />
                </Link>
                <Link className="btn btn-sm btn-default" onClick={this.printDetailsXLS(row)}>
                  {" "}
                  <SvgIcon size={14} Title="Imprimir Registros XLS" icon={fileExcelO} />
                </Link>
              </div>
            ),
          },
        ]}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  exportRegisterPDF(row, dt) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    var title = `Relatório de Cobranças E-Registro`;

    title = title + `\n\nData Geração: ${MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}`;
    startTableY = startTableY + 15;
    title = title + `\nPeríodo: ${MaskDate.TO_BR(row.original.periodoInicio)} - ${MaskDate.TO_BR(row.original.periodoFim)}`;
    startTableY = startTableY + 15;
    title = title + `\nNúmero Detran: ${row.original.numeroDetran}`;
    startTableY = startTableY + 15;
    title = title + `\nQtd. Registros: ${row.original.qtdRegistros}`;
    startTableY = startTableY + 15;
    title = title + `\nVencimento: ${MaskDate.TO_BR(row.original.dataVencimento)}`;
    startTableY = startTableY + 15;
    title = title + `\nValor: ${MaskDecimal.TO_BRL(row.original.valorTotal)}`;
    startTableY = startTableY + 15;
    title = title + `\nLinha Digitável: ${row.original.linhaDigitavel}`;
    startTableY = startTableY + 15;

    const headers = [["CHASSI", "GRAVAME", "DATA REGISTRO"]];

    const data = dt.map((row) => [row.chassi, row.numeroGravame, MaskDate.TO_BR(row.dataRegistro)]);

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioCobrancas.pdf`);
  }

  exportRegisterXLS(row, dt) {
    var fileName = "";
    fileName = "RelatorioCobrancas";

    var head = [["Chassi", "Número Gravame", "Data Registro"]];
    var ref = ["chassi", "numeroGravame", "dataRegistro"];
    var data = [];

    data = dt.map((row) => {
      return {
        chassi: row.chassi,
        numeroGravame: row.numeroGravame,
        dataRegistro: MaskDate.TO_BR(row.dataRegistro),
      };
    });

    data.push({ chassi: "" });
    data.push({ chassi: `Data Geração: ${MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}` });
    data.push({ chassi: `Período: ${MaskDate.TO_BR(row.original.periodoInicio)} - ${MaskDate.TO_BR(row.original.periodoFim)}` });
    data.push({ chassi: `Qtd. Registros: ${row.original.qtdRegistros}` });
    data.push({ chassi: `Vencimento: ${MaskDate.TO_BR(row.original.dataVencimento)}` });
    data.push({ chassi: `Valor: ${MaskDecimal.TO_XLS(row.original.valorTotal)}` });
    data.push({ chassi: `Linha Digitável: ${row.original.linhaDigitavel}` });

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  }

  renderChargingDetails() {
    const { handleSubmit } = this.props;
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.details}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={[
          {
            Header: "Chassi",
            accessor: "chassi",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
          },
          {
            Header: "Gravame",
            accessor: "numeroGravame",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
          },
          {
            Header: "Data Registro",
            accessor: "row",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataRegistro)}</div>,
          },
        ]}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_LIST_LOAD, payload }),
  onUnload: () => dispatch({ type: FINANCIAL_REPORT_UNLOAD }),
  onDetailsLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_DETAILS_LOAD, payload }),
  onDetailsUnload: () => dispatch({ type: FINANCIAL_REPORT_DETAILS_UNLOAD }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const selector = formValueSelector("ChargingReportForm");
const mapStateToProps = (state) => ({
  ...state.chargingReport,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  anoReferencia: selector(state, "anoReferencia"),
  mesReferencia: selector(state, "mesReferencia"),
  initialValues: {
    mesReferencia: OtherUtils.getPreviousMonth(),
    anoReferencia: new Date().getFullYear().toString(),
  },
});

const form = reduxForm({
  form: "ChargingReportForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ChargingReport));
