import { UF_SYSTEM_LOAD, VALORES_DETRAN_LOAD, VALORES_DETRAN_UNLOAD } from '../../actions/Constants';

const INITIAL_STATE = { ufSystem: [], valoresDetran: []};

export default function valorDetranUf(state = INITIAL_STATE, action) {
    switch (action.type) {    
        case UF_SYSTEM_LOAD:
            return {
                ...state, 
                ufSystem: action.payload ? action.payload : []
            }
        case VALORES_DETRAN_LOAD:
            return {
                ...state, 
                valoresDetran: action.payload ? action.payload : []
            }
        case VALORES_DETRAN_UNLOAD:
            return INITIAL_STATE;  
        default:
        return state;
  }
}
