import React, { Component } from "react";
import PropTypes from "prop-types";
import SvgIcon from "react-icons-kit";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
class TextIconField extends Component {
  constructor() {
    super();
    this.state = {
      type: null,
    };
  }
  clickIcon = () => {
    if (this.props.describePassword) {
      if (this.state.type == "text") {
        this.setState({ type: "password", icon: "fa-eye-slash" });
      } else {
        this.setState({ type: "text", icon: "fa fa-eye" });
      }
    }
  };

  render() {
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? "has-error" : ""} ${this.props.inline ? "row" : ""}`}>
        <label className={`font-weight-bold ${this.props.inline ? "col-sm-2  col-form-label" : ""}`}>{this.props.label}</label>
        <div className={`${this.props.inline ? "col-sm-10" : ""}`}>
          <div class="form-group input-with-icon-wrapper">
            <input
              {...this.props.input}
              title={this.props.title}
              type={this.state.type ? this.state.type : this.props.type}
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              maxlength={this.props.maxlength ? this.props.maxlength : ""}
              className={`${this.props.icon ? "input-with-icon" : ""} ${this.props.required ? (this.props.meta.valid && this.props.input.value ? "field-valid" : "field-required") : ""} ${
                this.props.small ? "form-control form-control-sm" : "form-control"
              }`}
            />
            {this.props.describePassword && <SvgIcon size={20} icon={this.state.type == "text" ? eye : eyeBlocked} onClick={this.clickIcon} className="input-icon input-icon-right" />}
            {this.props.icon && <i className={`fa ${this.props.icon} input-icon `}></i>}
          </div>
          {this.props.description && <span className="help-block small">{this.props.description}</span>}
          <p className="text-danger">{this.props.meta.touched ? this.props.meta.error : ""}</p>
        </div>
      </div>
    );
  }
}

TextIconField.propTypes = {
  input: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  inline: PropTypes.bool,
  description: PropTypes.string,
  small: PropTypes.bool,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  describePassword: PropTypes.bool,
};

export default TextIconField;
