import {VIDEO_OPEN, VIDEO_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false };

export default function video(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIDEO_OPEN:
      console.log({...state,open: true})
      return {
        ...state,
        open: true,
      };
    case VIDEO_CLOSE:
    return {
      ...state,
      open: false,
    };

    // initial state
    default:
      return state;
  }
}
