import { FINANCIAL_PROFILE_LIST_LOAD,
    FINANCIAL_FEATURES_LIST_LOAD,
    FINANCIAL_PROFILE_LIST_UNLOAD,
    FINANCIAL_FEATURES_LIST_UNLOAD,
    FINANCIAL_FEATURES_LIST_PERFIL_UNLOAD,
    FINANCIAL_FEATURES_LIST_PERFIL_LOAD,
    FINANCIAL_GROUP_VERIFY_LOAD,
    FINANCIAL_GROUP_VERIFY_UNLOAD,
    FINANCIAL_GROUP_CLICK,
    FINANCIAL_GROUP_UNLOAD_CLICK
} from '../../actions/Constants';

const INITIAL_STATE = { allProfile: [], allfeatures: [], allfeaturesProfile: [], allGruposVerificados: [], disabledEnabledClicado: false};

export default function financialProfile(state = INITIAL_STATE, action) {
    switch (action.type) {
    case FINANCIAL_PROFILE_LIST_LOAD:
        return {
          ...state,
          allProfile: action.payload ? action.payload : null,
        };

    case FINANCIAL_FEATURES_LIST_LOAD:
        return {
            ...state,
            allfeatures: action.payload ? action.payload : null,
        };
    case FINANCIAL_FEATURES_LIST_PERFIL_LOAD:
        return {
            ...state,
            allfeaturesProfile: action.payload ? action.payload : null,
        };
    case FINANCIAL_GROUP_VERIFY_LOAD:
        return {
            ...state,
            allGruposVerificados: action.payload ? action.payload : null,
        };
    case FINANCIAL_GROUP_CLICK:
        return {
            ...state,
            disabledEnabledClicado: true,
        }
    case FINANCIAL_GROUP_UNLOAD_CLICK:
        return {
            ...state,
            disabledEnabledClicado: false,
        }
    case FINANCIAL_GROUP_VERIFY_UNLOAD:
    case FINANCIAL_PROFILE_LIST_UNLOAD:        
    case FINANCIAL_FEATURES_LIST_UNLOAD:
    case FINANCIAL_FEATURES_LIST_PERFIL_UNLOAD:
        return INITIAL_STATE;
    default:
        return state;
    }
  }