import React from "react";
import { connect } from 'react-redux';
import { reduxForm, Field, reset, change, untouch } from 'redux-form';
import { Link, browserHistory } from 'react-router';
import _ from 'lodash';
import MaskDate from '../../components/Util/MaskDate';
import MaskCPF from '../../components/Util/MaskCPF';
import { USER_LOG_AUDIT_LOAD } from '../../actions/Constants';
import api from '../../api/apiUtil';
import Title from '../../components/Layout/Title';

class DetailUserLogAudit extends React.Component {

  componentWillMount() {
    this.props.onLoad(api.Users.getAuditoriaUser(this.props.params.slug));
  }

  render() {
    const {handleSubmit, reset} = this.props;
    return (
        <div>
          <Title routes = {this.props.routes} params = {this.props.params} description = "Log de Auditoria de Eventos" /> <div className="content">

          <div className="row">
            <div className = "col-lg-12" >
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-default " onClick={browserHistory.goBack}><i className="fas fa-arrow-left"></i> Voltar</Link>
                  </div>
                  <p>Dados do Evento</p>
                </div>
                {this.props.user && this.props.user.length > 0 &&                
                <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          Nome: 
                        </span>&nbsp;
                          {this.props.user[0].nome}
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          CPF: 
                        </span>&nbsp;
                          {MaskCPF.TO_CPF(this.props.user[0].cpf)}
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          IP: 
                        </span>&nbsp;
                          {this.props.user[0].ip}
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          Data: 
                        </span>&nbsp;
                          {MaskDate.TO_BR_DATETIME(this.props.user[0].data)}
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          Tipo do Evento: 
                        </span>&nbsp;
                          {this.props.user[0].eventoFormat}
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <span className="font-bold">
                          Dados: 
                        </span>
                        <div className="panel-body">                            
                          <div className="row">
                            <div className="col-md-12">
                              <pre>{this.props.user[0].dados.replace(/[,]+/g, '\n')}</pre>
                            </div>                      
                          </div>  
                        </div>
                      </div>                      
                    </div> 
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: USER_LOG_AUDIT_LOAD, payload }),
});

const mapStateToProps = state => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: 'DetailUserLogAuditForm'
});

export default connect(mapStateToProps,mapDispatchToProps)(form(DetailUserLogAudit));
