import React, { Component } from "react";
import PropTypes from "prop-types";

class CheckboxField extends Component {
  render() {
    const { label, input, name, disabled } = this.props;
    const isChecked = input.value === 1 || input.value === true;

    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? "has-error" : ""}`}>
        <input {...input} name={name} type="checkbox" disabled={disabled} checked={isChecked} id={name} style={{ width: "20px", height: "20px" }} />
        <label className="font-weight-bold control-label m-l-xs">{label}</label>
        <p className="text-danger">{this.props.meta.touched ? this.props.meta.error : ""}</p>
      </div>
    );
  }
}

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CheckboxField;
