import {CONFIRM_TERMS_OF_USE_OPEN, CONFIRM_TERMS_OF_USE_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false};

export default function termsOfUse(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_TERMS_OF_USE_OPEN:
      return {
        ...state,
        open: true
      };
    case CONFIRM_TERMS_OF_USE_CLOSE:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}