import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LOGOUT, APP_LOADED } from '../../actions/Constants';
import api from '../../api/apiUtil';

class PrivateRoute extends Component {

  componentWillMount() {

    var userProfile = api.loadUserProfile();
    const token = window.localStorage.getItem('jwt');
    if (userProfile) {
      this.props.onLoad(userProfile,token);
    }
  }

  //Browser Refresh: https://medium.com/@rajaraodv/securing-react-redux-apps-with-jwt-tokens-fcfe81356ea0

  render() {

    return this.props.children;

    if (this.props.currentUser) {
      //Verificar o acesso do perfil na página
      return this.props.children;
    }
    if (this.props.appLoaded) {
      this.props.logout(this.props.location);
    } else {
      this.props.logout();
    }


    return null;

  }
}

PrivateRoute.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
};

PrivateRoute.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  logout: payload => dispatch({ type: LOGOUT, payload }),
  onLoad: (payload, token) => dispatch({ type: APP_LOADED, payload, token }),
});


export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
