import { SUPORTE_LOAD, SUPORTE_UNLOAD, SUPORTE_ALL_COMPANIES_LOAD, SUPORTE_LOAD_UFS } from '../../actions/Constants';

const INITIAL_STATE = { all: [], allCompanies: [], ufs: [] };

export default function suporte(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SUPORTE_LOAD:
            return {
                ...state,
                all: action.payload ? action.payload : null
            }
        case SUPORTE_ALL_COMPANIES_LOAD:
            return {
                ...state,
                allCompanies: action.payload ? action.payload : null
            };
        case SUPORTE_LOAD_UFS:
            return {
                ...state,
                ufs: action.payload ? action.payload : []
            };
        case SUPORTE_UNLOAD:
            return INITIAL_STATE;
        default:
            return state;
    }
}