import React, { Component } from "react";
import { Link } from "react-router";
import _ from "lodash";
import { connect } from "react-redux";

class MenuHorizontal extends Component {
  render() {
    return (
      <span>
        {_.findIndex(this.props.currentUser.perfis, function (perfil) {
          return perfil === "ALIAS";
        }) === -1 &&
          _.findIndex(this.props.currentUser.perfis, function (perfil) {
            return perfil === "DETRAN";
          }) === -1 && (
            <div class="dropdown">
              <Link to="/secure" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                Dashboard
              </Link>
            </div>
          )}
        {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
          <>
            {this.props.currentUser.funcionalidades.includes(36) && (
              <div class="dropdown">
                <Link to="/secure/contract" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Novo Contrato
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(36) && (
              <div class="dropdown">
                <Link to="/secure/contracts" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Contratos
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(34) && (
              <div class="dropdown">
                <Link to="/secure/users" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Usuários
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(43) && this.props.currentUser.integracaoConfia && this.props.currentUser.integracaoConfia == "S" && (
              <div class="dropdown">
                <Link to="/secure/consultaConfia" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Assinaturas
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(41) && (
              <div class="dropdown">
                <Link to="/secure/invoices" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Financeiro
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(33) && (
              <div class="dropdown">
                <Link to="/secure/FilesUpload" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Imagens
                </Link>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(45) && (
              <div class="dropdown">
                <Link to="/secure/integration" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Integrações
                </Link>
              </div>
            )}

            {/* {this.props.currentUser.funcionalidades.includes(51) && (
              <div class="dropdown">
                <Link to="/secure/consultaSNG" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Consulta CRV
                </Link>
              </div>
            )} */}
          </>
        )}

        {_.findIndex(this.props.currentUser.perfis, function (perfil) {
          return perfil === "ALIAS";
        }) > -1 && (
          <div class="dropdown">
            <Link to="/alias" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
              Dashboard
            </Link>
          </div>
        )}

        {_.findIndex(this.props.currentUser.perfis, function (perfil) {
          return perfil === "ALIAS";
        }) > -1 && (
          <div class="dropdown">
            <Link to="/alias/aprove" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
              Contratos
            </Link>
          </div>
        )}
        {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
          <>
            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 && (
              <div class="dropdown">
                <Link class="dropbtn-menu">Faturamento</Link>
                <div class="dropdown-content" style={{ minWidth: "160px", top: "39px" }}>
                  <Link to="/alias/faturamento" onClick={() => this.clearFiltrosListContracts()}>
                    Emissão
                  </Link>
                  <Link to="/alias/financial-report" onClick={() => this.clearFiltrosListContracts()}>
                    Boletos MS/PR/RN
                  </Link>
                  <Link to="/alias/charging-report" onClick={() => this.clearFiltrosListContracts()}>
                    Boletos SC/BA/PE
                  </Link>
                  <Link to="/alias/relatorioDetranMA" onClick={() => this.clearFiltrosListContracts()}>
                    Rel. Detran MA
                  </Link>
                  <Link to="/alias/taxasDetran" onClick={() => this.clearFiltrosListContracts()}>
                    Taxa Detran BA
                  </Link>
                </div>
              </div>
            )}

            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 && (
              <div class="dropdown">
                <Link to="/alias/financial" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Financeiras
                </Link>
              </div>
            )}

            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 &&
              process.env.REACT_APP_UF === "MG" && (
                <div class="dropdown">
                  <Link to="/alias/financial-shipping" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                    Rem. Financeira
                  </Link>
                </div>
              )}

            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "DETRAN";
            }) > -1 && (
              <div class="dropdown">
                <Link to="/sdetran" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Imagens
                </Link>
              </div>
            )}

            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ADMIN";
            }) > -1 &&
              process.env.REACT_APP_UF === "MG" && (
                <div class="dropdown">
                  <Link to="/secure/contractLot" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                    Arq. Lote
                  </Link>
                </div>
              )}

            {["33660564000100"].indexOf(this.props.currentUser.cnpj) >= 0 && (
              <div class="dropdown">
                <Link to="/secure/cargaBNDES" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                  Carga
                </Link>
              </div>
            )}

            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) === -1 &&
              ["00000000000191", "01149953000189", "59588111000103", "01858774000110", "59285411000113", "03017677000120", "62063177000194", "92228410000102", "60850229000147"].indexOf(
                this.props.currentUser.cnpj
              ) >= 0 && (
                <div class="dropdown">
                  <Link to="/secure/lote" class="dropbtn-menu" onClick={() => this.clearFiltrosListContracts()}>
                    Lote
                  </Link>
                </div>
              )}
          </>
        )}
        {(_.findIndex(this.props.currentUser.perfis, function (perfil) {
          return perfil === "ALIAS";
        }) > -1 ||
          this.props.currentUser.funcionalidades.includes(35)) && (
          <div class="dropdown">
            <Link class="dropbtn-menu">Relatórios</Link>
            <div class="dropdown-content" style={{ minWidth: "160px", top: "39px" }}>
              {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) > -1 && (
                <Link to="/alias/management-report" onClick={() => this.clearFiltrosListContracts()}>
                  Gerencial
                </Link>
              )}
              {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
                <>
                  {this.props.currentUser.funcionalidades.includes(39) && (
                    <Link to="/secure/management-report" onClick={() => this.clearFiltrosListContracts()}>
                      Gerencial
                    </Link>
                  )}
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    <Link to="/alias/management-report-error" onClick={() => this.clearFiltrosListContracts()}>
                      Comunicação Detran
                    </Link>
                  )}
                  {this.props.currentUser.funcionalidades.includes(38) && (
                    <Link to="/secure/management-report-error" onClick={() => this.clearFiltrosListContracts()}>
                      Comunicação Detran
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
          <>
            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 && (
              <div class="dropdown">
                <Link class="dropbtn-menu">Detran</Link>
                <div class="dropdown-content" style={{ minWidth: "160px", top: "39px" }}>
                  <Link to="/alias/consultaChassiPR" onClick={() => this.clearFiltrosListContracts()}>
                    Consulta
                  </Link>
                  <Link to="/alias/duda" onClick={() => this.clearFiltrosListContracts()}>
                    Duda RJ
                  </Link>
                  <Link to="/alias/financeiroPE" onClick={() => this.clearFiltrosListContracts()}>
                    Financeiro PE
                  </Link>
                  <Link to="/alias/statusDetran" onClick={() => this.clearFiltrosListContracts()}>
                    Status
                  </Link>
                  {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                    <>
                      <Link to="/alias/valorDetranUf" onClick={() => this.clearFiltrosListContracts()}>
                        Taxas
                      </Link>
                      <Link to="/alias/configuracaoUf" onClick={() => this.clearFiltrosListContracts()}>
                        Status
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}
            {((_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 &&
              !this.props.currentUser.tipo) ||
              !this.props.currentUser.tipo.includes("F")) && (
              <div class="dropdown">
                <Link class="dropbtn-menu">Configurações</Link>
                <div class="dropdown-content" style={{ minWidth: "160px", top: "39px" }}>
                  <Link to="/alias/configuracoes" onClick={() => this.clearFiltrosListContracts()}>
                    Interface
                  </Link>
                  <Link to="/alias/suporte" onClick={() => this.clearFiltrosListContracts()}>
                    Suporte
                  </Link>
                  <Link to="/alias/news" onClick={() => this.clearFiltrosListContracts()}>
                    Novidades
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </span>
    );
  }
  clearFiltrosListContracts() {
    localStorage.removeItem("filtros");
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  ...state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuHorizontal);
