import React from "react";
import { connect } from 'react-redux';

import api from '../../api/apiUtil';
import { SERVICE_STATUS_LOAD, SERVICE_STATUS_DETRANS_LOAD } from '../../actions/Constants';

class ServiceStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        statusDetrans: []
    }
  }

  componentDidMount() {
    var props = this.props;
    Promise.resolve(api.Detrans.status()).then(status => {
      this.props.onLoad(true);
      if(!props.currentUser.idFinanceira){
        Promise.resolve(api.Ufs.system()).then(res => {
            res.forEach(function (uf) {
                this.recuperaStatus(uf);
            }.bind(this))
        })            
      }else{
        Promise.resolve(props.currentUser.ufs).then(res => {
          res.forEach(function (uf) {
            if(uf.sigla.length === 2){ //existe a sigla todas
              this.recuperaStatus(uf.sigla);
            }              
          }.bind(this))
      })
      }
    }).catch(status => {
      this.props.onLoad(false);
    })
  }

  recuperaStatus(uf){
    var props = this.props;
    var ret = {};    
    Promise.resolve(api.Detrans.statusDetranUF(uf)).then(res => {            
        ret[Object.keys(res)] = {}
        ret[Object.keys(res)] = {
            "status": res[Object.keys(res)].status
        }
        this.setState({statusDetrans: [...this.state.statusDetrans, ret]})
      }).catch(function(resp) {
        if (resp.message === 'Unauthorized' || resp.message === 'jwt expired') {
            api.Auth.logout();
            props.onLogout();
        }else{
            ret[uf] = {}
            ret[uf] = {
                "status": 'Erro'
            }
            this.setState({statusDetrans: [...this.state.statusDetrans, ret]})
        }
    }.bind(this));
}

  render() {
    if (this.props.serviceOn) {
      if(this.state.statusDetrans){
        return (
          <span>
            {this.exibeAlerta()}         
          </span>
        );
      }else{
        return null;
      }      
    } else {
      return (
        <div className="alert alert-warning" style={{margin: '-20px -25px', marginBottom: '10px'}}>
            <i className="fas fa-exclamation text-danger"></i> <spam>O sistema foi atualizado, por favor atualize a página.</spam>
        </div>
      );
    }
  }

  exibeAlerta() {
    if (!this.state.statusDetrans) {
      return null;
    }
    var estados = [];
    this.state.statusDetrans.forEach((key, index) => {
      if(this.state.statusDetrans[index][Object.keys(key)].status !== "OK"){
          estados.push(key);
      }
    })
    return estados.map((value, index) => {
      return (
        <div className="alert alert-danger" style={{margin: '-20px -25px', marginBottom: '10px'}}>
          <i className="fas fa-exclamation text-danger"></i> <spam>{`Sem comunicação com o DETRAN ${Object.keys(value)}`}</spam>
        </div>
      )
    })
  }
}

const mapStateToProps = state => ({
  ...state.common
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: SERVICE_STATUS_LOAD, payload }),
  onLoadStatusDentrans: payload => dispatch({ type: SERVICE_STATUS_DETRANS_LOAD, payload }),
});


export default connect(mapStateToProps,mapDispatchToProps)(ServiceStatus);
