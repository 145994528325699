import {CONTRACT_EDIT_LOAD,
  CONTRACT_EDIT_UNLOAD,
  CONTRACT_EDIT_CONTRACT_LOAD,
  CONTRACT_EDIT_VEHICLE_ADD,
  CONTRACT_EDIT_LOG_LOAD,
  CONTRACT_EDIT_ERR_MSG_LOAD,
  CONTRACT_EDIT_VEHICLE_LOAD,
  CONTRACT_EDIT_VEHICLE_SELECTED,
  CONTRACT_LIST_COMPANIES_LOAD,
  CONTRACT_EDIT_COMPANY_ERROR,
  CONTRACT_VIEW_COMPANY_LOAD,
  CONTRACT_EDIT_UPLOAD,
  CONTRACT_ADDITIVE_CREATED,
  CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD,
  CONTRACT_EDIT_RESET,
  CONTRACT_EDIT_LAST_LOG_LOAD,
  CONTRACT_COMUNICA_DETRAN,
  SHOW_VEHICLE_FORM,
  HIDE_VEHICLE_FORM,
  SHOW_AVERBACAO,
  CONTRACT_UF_LOAD,
  CONTRACT_CLEAR_VEHICLE
  } from '../../actions/Constants';

const INITIAL_STATE = {registred: [], contract: null, vehicles: [], log: [],
                 selectedVehicleIndex: null, detranCompanyError: null, company: null, isUploading: false, errorMessage: null,
                 lastLog: null, comunicacaoDetran: null, showVehicleForm: true, showAverbacao: false, ufs: []} ;

export default function contract(state = INITIAL_STATE, action) {
switch (action.type) {
case CONTRACT_EDIT_LOAD:
return {
  ...state,
};
case CONTRACT_EDIT_RESET:
return {
  ...state = INITIAL_STATE,
  contract: null,
};
case CONTRACT_EDIT_UNLOAD:
return INITIAL_STATE;
case CONTRACT_EDIT_CONTRACT_LOAD:
return {
  ...state,
  contract: action.payload ? action.payload : {},
}
case CONTRACT_EDIT_VEHICLE_ADD:
return {
  ...state,
  //vehicles: [...state.vehicles, action.payload],
  //selectedVehicleIndex: null,
  showVehicleForm: false,
}
case CONTRACT_EDIT_VEHICLE_SELECTED:
return {
  ...state,
  selectedVehicleIndex: action.payload,
}
case CONTRACT_EDIT_LOG_LOAD:
return {
  ...state,
  log: action.payload,
}
case CONTRACT_EDIT_ERR_MSG_LOAD:
return {
  ...state,
  errorMessage: action.payload,
}
case CONTRACT_EDIT_LAST_LOG_LOAD:
return {
  ...state,
  lastLog: action.payload,
}
case CONTRACT_COMUNICA_DETRAN:
return {
  ...state,
  comunicacaoDetran: action.payload,
}
case CONTRACT_EDIT_COMPANY_ERROR:
return {
  ...state,
  detranCompanyError: action.payload,
}
case CONTRACT_EDIT_VEHICLE_LOAD:
return {
  ...state,
  vehicles: action.payload,
  selectedVehicleIndex: null,
}
case CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD:
return {
  ...state,
  contract: action.payload[0],
  vehicles: action.payload[1],
  selectedVehicleIndex: null,
}
case CONTRACT_LIST_COMPANIES_LOAD:
return {
  ...state,
  companies: action.payload ? action.payload : null,
};
case CONTRACT_VIEW_COMPANY_LOAD:
return {
  ...state,
  company: action.payload ? action.payload : null,
};
case CONTRACT_EDIT_UPLOAD:
return {
  ...state,
  isUploading: action.payload ? action.payload : null,
};
case CONTRACT_ADDITIVE_CREATED:
return {
  ...state = INITIAL_STATE,
  contract: null,
};
case SHOW_VEHICLE_FORM:
return {
  ...state,
  showVehicleForm: true,
}
case HIDE_VEHICLE_FORM:
return {
  ...state,
  showVehicleForm: false,
  selectedVehicleIndex: null,
}
case SHOW_AVERBACAO:
return {
  ...state,
  showAverbacao: true,
}
case CONTRACT_UF_LOAD:
return {
  ...state,
  ufs: action.payload ? action.payload : null,
}
case CONTRACT_CLEAR_VEHICLE:
return {
  ...state,
  selectedVehicleIndex: null,
  vehicles: [],
  contract: null
};
// initial state
default:
return state;
}
return state;
}
