import {UPLOAD_ARCHIVE_OPEN, UPLOAD_ARCHIVE_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, description: null, filesToUpload: null, id: null, onSuccess: null };

export default function uploadArchive(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_ARCHIVE_OPEN:
      return {
        ...state,
        open: true,
        description: action.payload.description,
        filesToUpload: action.payload,
        id: action.payload.id,
        onSuccess: action.payload.onSuccess,
      };
    case UPLOAD_ARCHIVE_CLOSE:
    return {
      ...state,
      open: false,
      description: null,
      filesToUpload: null,
      id: null,
      onSuccess: null,
    };

    // initial state
    default:
      return state;
  }
}
