import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError, change, untouch } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { UF_SYSTEM_LOAD, VALORES_DETRAN_LOAD, VALORES_DETRAN_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import SelectField from "../../components/Fields/SelectField";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import Modal from "react-bootstrap4-modal";

class ValorDetranUf extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.props.onUfSystemLoad(api.Ufs.sigla_system());
  }

  componentWillUnmount() {
    this.props.onValoresDetranUnload();
  }

  async formSubmit(values) {
    var props = this.props;
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    }
    var taxa = {
      uf: values.uf,
      dataVigencia: MaskDate.TO_DATABASE(values.dataVigencia),
      valorTaxa: MaskDecimal.TO_DATABASE(values.valorTaxa),
      valorTaxaAlt: MaskDecimal.TO_DATABASE(values.valorTaxaAlt) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAlt),
      valorTaxaERC: MaskDecimal.TO_DATABASE(values.valorTaxaERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaERC),
      valorTaxaAltERC: MaskDecimal.TO_DATABASE(values.valorTaxaAltERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAltERC),
    };
    Promise.resolve(api.Financial.createValorDetran(taxa))
      .then((ret) => {
        this.setState({ modalOpen: false });
        toastr.success("Sucesso", "Taxa cadastrada");
        this.props.onValoresDetranLoad(api.Financial.getValorDetran(taxa.uf));
        this.clearForm();
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  validateValues(values) {
    const errors = {};
    if (!values.dataVigencia || values.dataVigencia === 0) {
      errors.dataVigencia = "Campo obrigatório";
    }
    if (!values.valorTaxa || values.valorTaxa === 0) {
      errors.valorTaxa = "Campo obrigatório";
    }
    return errors;
  }

  clearForm = () => {
    this.props.dispatch(change("ValorDetranUfForm", "dataVigencia", null));
    this.props.dispatch(untouch("ValorDetranUfForm", "dataVigencia"));
    this.props.dispatch(change("ValorDetranUfForm", "valorTaxa", null));
    this.props.dispatch(untouch("ValorDetranUfForm", "valorTaxa"));
    this.props.dispatch(change("ValorDetranUfForm", "valorTaxaAlt", null));
    this.props.dispatch(untouch("ValorDetranUfForm", "valorTaxaAlt"));
    this.props.dispatch(change("ValorDetranUfForm", "valorTaxaERC", null));
    this.props.dispatch(untouch("ValorDetranUfForm", "valorTaxaERC"));
    this.props.dispatch(change("ValorDetranUfForm", "valorTaxaAltERC", null));
    this.props.dispatch(untouch("ValorDetranUfForm", "valorTaxaAltERC"));
  };

  async recuperaValores(event) {
    this.props.onValoresDetranLoad(await Promise.resolve(api.Financial.getValorDetran(event.target.value)));
  }

  delete = (id) => {
    var props = this.props;
    Promise.resolve(api.Financial.delValorDetran(id))
      .then((ret) => {
        toastr.success("Sucesso", "Taxa excluída");
        this.props.onValoresDetranLoad(api.Financial.getValorDetran(props.selectedUf));
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Valores" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="row">
                  <div className="col-md-2">
                    <Field name="uf" label="Selecione a UF" data={this.props.ufSystem} blank={true} textField="sigla" component={SelectField} type="text" onChange={this.recuperaValores.bind(this)} />
                  </div>
                </div>
                {this.props.selectedUf && (
                  <div className="panel-body">
                    {this.props.currentUser.funcionalidades.includes(57) && (
                      <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                        <div className="row">
                          <div className="col-lg-2">
                            <Field
                              name="dataVigencia"
                              label="Data ínicio vigência"
                              required={true}
                              placeholder="00/00/0000"
                              data={this.props.dataVigencia}
                              component={TextDatePickerField}
                              type="text"
                              validate={[FieldValidation.date, FieldValidation.required]}
                              normalize={FieldNormalize.DATE}
                            />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="valorTaxa"
                              label="Valor DETRAN"
                              required={true}
                              validate={[FieldValidation.requiredNot0]}
                              component={TextField}
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && (
                            <div className="col-md-2">
                              <Field
                                name="valorTaxaERC"
                                label="Valor ERC - Registradora"
                                required={true}
                                validate={[FieldValidation.requiredNot0]}
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                          )}
                          <div className="col-md-2">
                            <Field
                              name="valorTaxaAlt"
                              label="Valor Alteração DETRAN"
                              component={TextField}
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && (
                            <div className="col-md-2">
                              <Field
                                name="valorTaxaAltERC"
                                label="Valor Alteração ERC - Reg."
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                          )}
                          <div className="col-lg-2 align-self-end form-group">
                            <Link className="btn btn-success" onClick={() => this.setState({ modalOpen: true })}>
                              Gravar Valores
                            </Link>
                          </div>
                        </div>
                      </form>
                    )}
                    <hr></hr>
                    {this.props.valoresDetran && this.props.valoresDetran.length > 0 ? (
                      <div className="table-responsive">
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th className="text-center align-middle">UF</th>
                              <th className="text-center align-middle">Data ínicio vigência</th>
                              <th className="text-center align-middle">Valor DETRAN</th>
                              {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && <th className="text-center align-middle">Valor ERC - Registradora</th>}
                              <th className="text-center align-middle">Valor Alteração DETRAN</th>
                              {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && (
                                <th className="text-center align-middle">Valor Alteração ERC - Registradora</th>
                              )}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal visible={this.state.modalOpen}>
          <form>
            <div className="modal-header">
              <div className="text-center">
                <h5 className="modal-title font-weight-bold" style={{ color: "red" }}>
                  ATENÇÃO
                </h5>
                <hr></hr>
                <h6>
                  <h4>
                    Você está cadastrando novos valores para o <strong>Detran {this.props.selectedUf}</strong>.
                  </h4>
                  <h4>
                    <strong>Todos os registros</strong> a partir da data de vigência seguirão esses valores.
                  </h4>
                  <h4>
                    <strong>Deseja mesmo realizar o cadastro para o Detran {this.props.selectedUf}?</strong>
                  </h4>
                </h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                Cancelar
              </button>
              <button type="button" className="btn btn-primary" onClick={this.props.handleSubmit(this.formSubmit.bind(this))}>
                Confirmar
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  renderList() {
    if (!this.props.valoresDetran) {
      return null;
    }
    return this.props.valoresDetran.map((valor, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{valor.uf}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR(valor.dataVigencia)}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(valor.valorTaxa)}</td>
          {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && (
            <td className="text-center align-middle">{valor.valorTaxaERC ? MaskDecimal.TO_BRL(valor.valorTaxaERC) : "Não informado"}</td>
          )}
          <td className="text-center align-middle">{valor.valorTaxaAlt ? MaskDecimal.TO_BRL(valor.valorTaxaAlt) : "Não informado"}</td>
          {this.props.ufSystem.find((item) => item.sigla == this.props.selectedUf && item.precoPublico) && (
            <td className="text-center align-middle">{valor.valorTaxaAltERC ? MaskDecimal.TO_BRL(valor.valorTaxaAltERC) : "Não informado"}</td>
          )}
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(58) && (
                <Link className="btn btn-sm btn-default" onClick={() => this.delete(valor.id)}>
                  <i className="fas fa-trash" title="Excluir"></i>
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onValoresDetranLoad: (payload) => dispatch({ type: VALORES_DETRAN_LOAD, payload }),
  onValoresDetranUnload: (payload) => dispatch({ type: VALORES_DETRAN_UNLOAD, payload }),
});

const selector = formValueSelector("ValorDetranUfForm");
const mapStateToProps = (state) => ({
  ...state.valorDetranUf,
  selectedUf: selector(state, "uf"),
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "ValorDetranUfForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ValorDetranUf));
