import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";

import { FINANCIAL_PROFILE_LIST_LOAD, FINANCIAL_PROFILE_LIST_UNLOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextField from "../../components/Fields/TextField";
import { toastr } from "react-redux-toastr";
import { ic_settings } from "react-icons-kit/md/ic_settings";
import SvgIcon from "react-icons-kit";

var idPerfil = null;

class FinancialProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      adicionar: true,
    };
  }

  componentWillMount() {
    this.props.onListLoadProfile(Promise.resolve(api.Profile.all(this.props.params.slug)));
  }

  componentWillUnmount() {
    this.props.onUnloadProfile();
  }

  handleOnCancel = () => {
    this.setState({ adicionar: true });
    idPerfil = null;
    this.props.dispatch(change("FinancialProfileForm", "nome", null));
    this.props.dispatch(untouch("FinancialProfileForm", "nome"));
    this.props.dispatch(change("FinancialProfileForm", "descricao", null));
    this.props.dispatch(untouch("FinancialProfileForm", "descricao"));
  };

  handleOnSelectValueClick = (value) => (ev) => {
    this.setState({ adicionar: false });
    idPerfil = value.id;
    this.props.dispatch(change("FinancialProfileForm", "nome", value.nome));
    this.props.dispatch(change("FinancialProfileForm", "descricao", value.descricao));
    this.HandleScroll();
  };

  formSubmit(values) {
    var props = this.props;
    var perfil = {
      nome: values.nome,
      descricao: values.descricao,
      idFinanceira: this.props.params.slug,
    };
    if (idPerfil) {
      perfil.id = idPerfil;
      Promise.resolve(api.Profile.update(perfil))
        .then((ret) => {
          toastr.success("Sucesso", "Perfil alterado");
          this.props.onListLoadProfile(Promise.resolve(api.Profile.all(this.props.params.slug)));
          this.props.reset();
          this.setState({ adicionar: true });
          idPerfil = null;
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else {
      Promise.resolve(api.Profile.create(perfil))
        .then((ret) => {
          toastr.success("Sucesso", "Perfil cadastrado");
          this.props.onListLoadProfile(Promise.resolve(api.Profile.all(this.props.params.slug)));
          this.props.reset();
          this.setState({ adicionar: true });
          idPerfil = null;
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Configuração de Perfil de Usuário" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Perfil</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-4">
                        <Field name="nome" label="Perfil" component={TextField} type="text" required={true} maxlength={45} validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-8">
                        <Field name="descricao" label="Descrição do Perfil" component={TextField} type="text" maxlength={200} required={true} validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link
                            className="btn btn-default"
                            to={
                              _.findIndex(this.props.currentUser.perfis, function (perfil) {
                                return perfil === "ALIAS";
                              }) > -1
                                ? `/alias/financialProfile/${this.props.params.slug}`
                                : `/secure/financialProfile/${this.props.params.slug}`
                            }
                            onClick={this.handleOnCancel}
                          >
                            {" "}
                            Cancelar
                          </Link>
                          <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            {this.state.adicionar ? "Adicionar" : "Alterar"}
                          </button>
                          <Link className="btn btn-default" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Perfis cadastrados
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.allProfile && this.props.allProfile.length > 0 ? (
                      <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center align-middle">Perfil</th>
                            <th className="text-center align-middle">Descrição do Perfil</th>
                            <th className="text-center align-middle" style={{ width: "5%" }}>
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderListProfile()}</tbody>
                      </table>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderListProfile() {
    if (!this.props.allProfile) {
      return null;
    }
    return this.props.allProfile.map((perfil, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{perfil.nome}</td>
          <td className="text-center align-middle">{perfil.descricao}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default" onClick={this.handleOnSelectValueClick(perfil)}>
                <i className="far fa-edit" title="Editar"></i>
              </button>
              <Link
                className="btn btn-sm btn-default"
                to={
                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1
                    ? `/alias/financialProfileFeatures/${perfil.id}`
                    : `/secure/financialProfileFeatures/${perfil.id}`
                }
              >
                {" "}
                <SvgIcon size={18} icon={ic_settings} title="Configurar" />
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoadProfile: (payload) => dispatch({ type: FINANCIAL_PROFILE_LIST_LOAD, payload }),
  onUnloadProfile: (payload) => dispatch({ type: FINANCIAL_PROFILE_LIST_UNLOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.financialProfile,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "FinancialProfileForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialProfile));
