import React, { Component, } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Modal from 'react-bootstrap4-modal';
import {toastr} from 'react-redux-toastr'

import { UPLOAD_ARCHIVE_CLOSE, LOGOUT } from '../../actions/Constants';
import api from '../../api/apiUtil';

import TextField from '../../components/Fields/TextField';
import TextareaField from '../../components/Fields/TextareaField';
import FieldValidation from '../../components/Validation/FieldValidation';

class UploadArchive extends Component {

  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
  }

  formSubmit(values) {
    var props = this.props;
    var params = {
      descricao: values.descriptionArchive,
      id: this.props.id,
    }
    Promise.resolve(api.Financial.registerDocument(this.props.filesToUpload.filesToUpload[0], params)).then(response => {
      
      this.props.onSuccess();
      this.props.reset();
      this.props.onClose();
    }).catch(function(resp) {
      if (resp.message === 'Unauthorized' || resp.message === 'jwt expired') {
        api.Auth.logout();
        props.onLogout();
      }else{
        toastr.warning('Atenção','Senha inválida!');
      }
    });
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
        <div className="modal-header">
          <div>
            <h5 className="modal-title">Enviar arquivo</h5>
            <h5 className="font-bold">{this.props.description}</h5>
          </div>
        </div>
        <div className="modal-body">
          <Field name="descriptionArchive" label="Descrição" component={TextareaField} type="text" rows={3} validate={[ FieldValidation.required, FieldValidation.maxLength180 ]}/>
        </div>
        <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={this.onCloseClick} title="Não confirmar a operação e voltar para a tela anterior">
            Cancelar
          </button>
          <button type="button" className="btn btn-primary" title="Confirma a operação" onClick={handleSubmit(this.formSubmit.bind(this))}>
            Confirmar
          </button>
          
        </div>
        </form>
      </Modal>
    )

  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.uploadArchive,
});

const mapDispatchToProps = dispatch => ({
  onClose: payload => dispatch({ type: UPLOAD_ARCHIVE_CLOSE }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const form = reduxForm({
  form: 'UploadArchiveForm'
});

export default connect(mapStateToProps, mapDispatchToProps)(form(UploadArchive));
