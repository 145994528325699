import _ from "lodash";

import {
  LOGOUT,
  LOGIN,
  APP_LOADED,
  REDIRECTED,
  AUTHORIZED_COMPANIES_LOAD,
  SERVICE_STATUS_LOAD,
  CONTRACT_ADDITIVE_CREATED,
  CAD_CONTRACT_SENT_SUCCESS,
  CONFIG_LOAD,
  SERVICE_STATUS_DETRANS_LOAD,
  CONTRACT_HOMOLOGATED,
  CONTRACT_NOT_HOMOLOGATED,
  CONTRACT_SENT_SUCCESS,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_EDIT_UPLOAD_SUCCESS,
  CONTRACT_CANCEL_SUCCESS,
  CONTRACT_SHELVE_SUCCESS,
  CONTRACT_CANCEL_ALIAS,
  CONTRACT_BAIXA_ALIAS,
  HOME_VIEW_NEW,
} from "../actions/Constants";

const INITIAL_STATE = {
  token: null,
  refreshToken: null,
  currentUser: null,
  configLayout: null,
  appLoaded: false,
  permissions: [],
  redirectTo: null,
  from: null,
  companies: [],
  serviceOn: true,
  servicesDetran: [],
  viewNew: false,
};

export default function common(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        redirectTo: "/",
        token: null,
        refreshToken: null,
        currentUser: null,
        appLoaded: false,
        configLayout: null,
        from: action.payload ? action.payload : null,
        viewNew: false,
      };
    case LOGIN:
      return {
        ...state,
        redirectTo: state.from
          ? state.from.pathname
          : _.findIndex(action.payload.user.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1
          ? "/alias"
          : _.findIndex(action.payload.user.perfis, function (perfil) {
              return perfil === "DETRAN";
            }) > -1
          ? "sdetran"
          : "/secure",
        token: action.error ? null : action.payload.id_token,
        //refreshToken: action.error ? null : action.payload.refresh_token,
        currentUser: action.error ? null : action.payload.user,
        from: null,
      };
    case APP_LOADED:
      return {
        ...state,
        appLoaded: true,
        token: action.token || null,
        currentUser: action.payload ? action.payload : null,
        //permissions: action.payload ? action.payload : null
      };
    case AUTHORIZED_COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case CONFIG_LOAD:
      return {
        ...state,
        configLayout: action.payload ? action.payload : null,
      };
    case SERVICE_STATUS_LOAD:
      return {
        ...state,
        serviceOn: action.payload ? action.payload : null,
      };
    case SERVICE_STATUS_DETRANS_LOAD:
      return {
        ...state,
        servicesDetran: action.payload ? action.payload : null,
      };
    case CONTRACT_ADDITIVE_CREATED:
      return {
        ...state,
        redirectTo: "/secure/contract-annotation/" + action.payload,
      };

    case CONTRACT_CANCEL_ALIAS:
      return {
        ...state,
        redirectTo: "/alias/aprove",
      };

    case CONTRACT_BAIXA_ALIAS:
      return {
        ...state,
        redirectTo: "/alias/aprove",
      };

    case CONTRACT_HOMOLOGATED:
      return {
        ...state,
        redirectTo: "/alias",
      };

    case CONTRACT_NOT_HOMOLOGATED:
      return {
        ...state,
        redirectTo: "/alias",
      };

    case REDIRECTED:
      return { ...state, redirectTo: null };

    case CONTRACT_SENT_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/contracts",
      };

    case CONTRACT_DELETE_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/contracts",
      };

    case CONTRACT_EDIT_UPLOAD_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/contracts",
      };

    case CONTRACT_CANCEL_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/contracts",
      };

    case CONTRACT_SHELVE_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/contracts",
      };

    case CAD_CONTRACT_SENT_SUCCESS:
      return {
        ...state,
        redirectTo: "/secure/consultaConfia",
      };
    case HOME_VIEW_NEW:
      return {
        ...state,
        viewNew: true,
      };

    // initial state
    default:
      return state;
  }
}
