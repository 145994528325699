import {APROVE_COMPANIES_LOAD, APROVE_REGISTERED_LOAD, APROVE_UNLOAD, UF_SYSTEM_LOAD} from '../../actions/Constants';

const INITIAL_STATE = {registred: [], companies: [], ufSystem: []} ;

export default function aprove(state = INITIAL_STATE, action) {
  switch (action.type) {

    case APROVE_UNLOAD:
      return INITIAL_STATE;
    case APROVE_REGISTERED_LOAD:
      return {
        ...state,
        registered: action.payload ? action.payload : null,
      };
    case APROVE_COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
    };
    case UF_SYSTEM_LOAD:
      return {
        ...state, 
        ufSystem: action.payload ? action.payload : []
    }
    // initial state
    default:
      return state;
  }
  return state;
}
