import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router";
import Modal from "react-bootstrap4-modal";
import { toastr } from "react-redux-toastr";

import { CONFIRM_NOT_HOMOLOGATE_CLOSE, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import TextField from "../../components/Fields/TextField";
import TextareaField from "../../components/Fields/TextareaField";
import LabelField from "../../components/Fields/LabelField";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextIconField from "../../components/Fields/TextIconField";

class ConfirmNotHomologate extends Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
  }

  formSubmit(props) {
    var propsProperty = this.props;
    Promise.resolve(api.Auth.checkOperationPassword(this.props.currentUser.user, props.passwordOperation))
      .then((auth) => {
        this.props.onSuccess(props.description);
        this.props.reset();
        this.props.onClose();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          propsProperty.reset();
          propsProperty.onClose();
          api.Auth.logout();
          propsProperty.onLogout();
        } else {
          toastr.warning("Atenção", "Senha inválida!");
        }
      });
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
          <div className="modal-header">
            <div>
              <h5 className="modal-title">Confirmar Operação</h5>
              <small className="font-bold">{this.props.description}</small>
            </div>
          </div>
          <div className="modal-body">
            <Field label="Usuário" name="usuario" component={LabelField} valueData={this.props.currentUser.user} type="text" />
            <Field
              name="passwordOperation"
              label="Senha de operação"
              component={TextIconField}
              type="password"
              description="Sua senha de operação"
              placeholder="******"
              title="Digite sua senha de operação"
              validate={[FieldValidation.required]}
              describePassword={true}
            />
            <Field name="description" label="Justificativa" component={TextareaField} type="text" description="Justificativa da não aprovação" rows={10} validate={[FieldValidation.required]} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.onCloseClick} title="Não confirmar a operação e voltar para a tela anterior">
              Cancelar
            </button>
            <button type="button" className="btn btn-primary" title="Confirma a operação" onClick={handleSubmit(this.formSubmit.bind(this))}>
              Confirmar operação
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
  ...state.confirmNotHomologate,
  initialValues: { username: state.common.currentUser ? state.common.currentUser.user : "", passwordOperation: "" },
});

const mapDispatchToProps = (dispatch) => ({
  onClose: (payload) => dispatch({ type: CONFIRM_NOT_HOMOLOGATE_CLOSE }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const form = reduxForm({
  form: "ConfirmNotHomologateForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfirmNotHomologate));
