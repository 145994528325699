import React, {Component, } from 'react'; import PropTypes from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox'

require('react-widgets/lib/scss/react-widgets.scss');

class ComboboxField extends Component {

  render() {
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? 'has-error' : ''} ${this.props.inline ? 'row' : ''}`}>
        <label className={`font-weight-bold ${this.props.inline ? 'col-sm-2  col-form-label' : ''}`}>{this.props.label}</label>
        <div className={`${this.props.inline ? 'col-sm-10' : ''}`}>
          <Combobox {...this.props.input} data={this.props.data ? this.props.data : []}
            valueField={this.props.valueField ? this.props.valueField : null}
            textField={this.props.textField ? this.props.textField : null}
            defaultValue={this.props.defaultValue} disabled={this.props.disabled}
          onChange={e => this.props.input.onChange(typeof(e) === 'string' ? e : e[this.props.valueField])}
          onBlur={_ => this.props.input.onBlur()}
          onSelect={this.props.input.onSelect} itemComponent={this.props.itemComponent}
            className={`${this.props.small ? 'form-control form-control-sm' : 'form-control'} ${this.props.required ? 'field-required' : ''}`} style={{padding: '0px', boxSizing: 'content-box'}}
            />
          {
            this.props.description && <span className="help-block small">{this.props.description}</span>
          }
          <p className="text-danger">
            {this.props.meta.touched ? this.props.meta.error : ''}
          </p>
        </div>
      </div>
    );
  }
}

ComboboxField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  inline: PropTypes.bool,
  description: PropTypes.string,
  small: PropTypes.bool,
  data: PropTypes.array,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  itemComponent: PropTypes.object
};

export default ComboboxField;
