import { CONFIG_UF_SYSTEM_LOAD, CONFIG_UF_SYSTEM_UNLOAD } from "../../actions/Constants";

const INITIAL_STATE = { ufs: [] };

export default function configuracaoUf(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIG_UF_SYSTEM_LOAD:
      return {
        ...state,
        ufs: action.payload ? action.payload : null,
      };
    case CONFIG_UF_SYSTEM_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
