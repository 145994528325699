import { INTEGRATION_UNLOAD } from "../../actions/Constants";
const INITIAL_STATE = { ufs: [] };

export default function configuracaoSng(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INTEGRATION_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
