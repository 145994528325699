import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import Dropzone from "react-dropzone";

import { LOTE_LIST_LOAD, LOTE_LIST_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
import fileDownload from "js-file-download";

class LoteSNG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detalhar: [],
      arquivos: [],
      type: 0,
      itensProcessar: [],
    };
  }

  componentDidMount() {
    this.props.onListLoad(api.Contracts.getLoteFormatoFinanceira(this.props.currentUser.idFinanceira, "SNG"));
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleClickDownload = (value, tipo) => {
    var props = this.props;
    Promise.resolve(api.Contracts.getLote(value.id, tipo))
      .then((response) => {
        if (tipo) {
          if (tipo == "entrada") {
            fileDownload(response, value.nome);
          } else if (tipo == "entrada2") {
            fileDownload(response, value.nome2);
          }
        }
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  async recuperaDadosContrato(value) {
    if (this.state.detalhar.findIndex((val) => val.idLoteApi == value) == -1) {
      await Promise.resolve(api.Contracts.getContractsByIdLote(value)).then((res) => {
        let loteApi = {
          [value]: [],
        };
        _.forEach(res, function (r) {
          r.hash = value;
          if (r.status == "A Processar") {
            r.checked = true;
            loteApi[value].push(r.id);
          } else {
            r.checked = false;
          }
        });
        if (loteApi[value].length > 0) {
          this.setState({ detalhar: [...this.state.detalhar, ...res], itensProcessar: [...this.state.itensProcessar, ...[loteApi]] });
        } else {
          this.setState({ detalhar: [...this.state.detalhar, ...res] });
        }
      });
    }
  }

  addOrRemoveItens(e) {
    let existe = this.state.itensProcessar.find((item) => {
      return e.target.name in item;
    });
    if (e.target.checked) {
      if (existe) {
        existe[e.target.name].push(e.target.id);
        let itens = this.state.itensProcessar.filter((i) => {
          return Object.keys(i) != e.target.name;
        });
        this.setState({ itensProcessar: [...itens, ...[existe]] });
      } else {
        let loteApi = [
          {
            [e.target.name]: [e.target.id],
          },
        ];
        this.setState({ itensProcessar: [...this.state.itensProcessar, ...loteApi] });
      }
      this.state.detalhar.find((item) => {
        return item.id == e.target.id;
      }).checked = true;
    } else {
      if (existe) {
        const index = existe[e.target.name].findIndex((i) => {
          return i == e.target.id;
        });
        if (index > -1) {
          existe[e.target.name].splice(index, 1);
        }
        let itens = this.state.itensProcessar.filter((i) => {
          return Object.keys(i) != e.target.name;
        });
        if (existe[e.target.name].length > 0) {
          this.setState({ itensProcessar: [...itens, ...[existe]] });
        } else {
          this.setState({ itensProcessar: [...itens] });
        }
        this.state.detalhar.find((item) => {
          return item.id == e.target.id;
        }).checked = false;
      }
    }
  }

  filteredDetails(id) {
    return this.state.detalhar
      .filter((detalhe) => detalhe.idLoteApi == id)
      .map((det, index) => {
        return (
          <tr key={index}>
            <td>
              {det.status == 0 || det.status == 8 || det.status == 2 || det.status == 3 || det.status == 11 || det.status == 7 ? (
                <span style={{ color: "green", fontSize: "20px" }}>&#10003;</span>
              ) : det.status == 5 || det.status == 6 || det.status == 91 || det.status == 90 ? (
                <span style={{ color: "red", fontSize: "20px" }}>&#10539;</span>
              ) : (
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={det.id}
                    name={det.idLoteApi}
                    disabled={det.status != "A Processar"}
                    checked={det.checked}
                    onChange={this.addOrRemoveItens.bind(this)}
                  />
                  <label class="custom-control-label" for={det.id}></label>
                </div>
              )}
            </td>
            <td className="text-center align-middle">{det.uf}</td>
            <td className="text-center align-middle">{det.numero}</td>
            <td className="text-center align-middle">{det.dataProcessamento ? MaskDate.TO_BR(det.dataProcessamento) : "-"}</td>
            <td className="text-center align-middle">
              {det.status == 0 || det.status == 8
                ? "Contrato Criado"
                : det.status == 2
                ? "Contrato Registrado"
                : det.status == 3
                ? "Imagem do Contrato Enviada"
                : det.status == 5
                ? "Contrato Baixado"
                : det.status == 6
                ? "Contrato Cancelado"
                : det.status == 7
                ? "Contrato Averbado"
                : det.status == 11
                ? "Contrato Em Processamento"
                : det.status == 90
                ? "Erro no registro do Contrato"
                : det.status}
            </td>
            <td className="text-center align-middle">{det.chassi}</td>
            <td className="text-center align-middle">{det.numGravame}</td>
          </tr>
        );
      });
  }

  onDrop = (files) => {
    if (!files[0]) {
      return;
    } else if ((this.state.type == "1" || this.state.type == "2") && files[0].type !== "text/plain") {
      toastr.warning("Atenção", "É permitido anexar somente PDF no anexo 1 e 2.");
    } else {
      let name = `arquivo${this.state.type}`;
      let arquivosSemRepeticao = this.state.arquivos.filter((item) => {
        return item.name !== name;
      });
      this.setState({
        arquivos: [...arquivosSemRepeticao, { file: files[0], name: name }],
      });
    }
  };

  enviarArquivos = () => {
    console.log(this.state.arquivos);
    if (this.state.arquivos.length == 2) {
      Promise.resolve(api.Contracts.sendMultipleImagesWithName(this.state.arquivos))
        .then((response) => {
          this.setState({ arquivos: [] });
          this.props.onListLoad(api.Contracts.getLoteFormatoFinanceira(this.props.currentUser.idFinanceira, "SNG"));
          toastr.success("Sucesso", "Arquivos enviados.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            if (resp.response.body.message) {
              toastr.error("Erro", resp.response.body.message);
            } else {
              toastr.error("Erro", resp.response.body);
            }
          }
        });
    }
  };

  processarContratos(idLoteApi) {
    let itens = this.state.itensProcessar.find((item) => {
      return idLoteApi in item;
    })[idLoteApi];

    var props = this.props;
    Promise.resolve(api.Contracts.processaRegistrosLote({ ids: itens }))
      .then((response) => {
        this.props.onListLoad(api.Contracts.getLoteFormatoFinanceira(this.props.currentUser.idFinanceira, "SNG"));
        this.setState({
          detalhar: [...this.state.detalhar.filter((val) => val.idLoteApi !== idLoteApi)],
          itensProcessar: [...this.state.itensProcessar.filter((val) => Object.keys(val) != idLoteApi)],
        });
        this.recuperaDadosContrato(idLoteApi);
        toastr.success("Sucesso", "Contratos enviados para processamento!");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Upload/Download Lote Arquivos" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <form className="form-horizontal">
                <div className="hpanel">
                  <div className="panel-heading">ARQUIVOS PARA PROCESSAMENTO</div>
                  <div className="panel-body col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <Dropzone accept=".txt" name="file" className="dropzone-blank fallback" multiple={false} onClick={() => this.setState({ type: 1 })} onDrop={this.onDrop.bind(this)}>
                          <button className={this.state.arquivos.find((i) => i.name == "arquivo1") ? "btn btn-success" : "btn btn-warning"} type="button">
                            <i className="fa fa-upload" /> Anexar arquivo Envio
                          </button>
                        </Dropzone>
                      </div>
                      <div className="col-md-3">
                        <Dropzone accept=".txt" name="file" className="dropzone-blank fallback" multiple={false} onClick={() => this.setState({ type: 2 })} onDrop={this.onDrop.bind(this)}>
                          <button className={this.state.arquivos.find((i) => i.name == "arquivo2") ? "btn btn-success" : "btn btn-warning"} type="button">
                            <i className="fa fa-upload" /> Anexar arquivo Retorno
                          </button>
                        </Dropzone>
                      </div>
                      <div className="col-md-6 text-right">
                        <button className="btn btn-default" type="button" onClick={() => this.setState({ arquivos: [] })}>
                          Limpar arquivos anexados
                        </button>
                        <button className="btn btn-success" type="button" onClick={() => this.enviarArquivos()} disabled={this.state.arquivos.length < 2}>
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={50}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        collapseOnDataChange={false}
                        getTdProps={(state, rowInfo, column, instance) => {
                          return {
                            onClick: async (e, handleOriginal) => {
                              if (column.Header == "Detalhar") {
                                await this.recuperaDadosContrato(rowInfo.original.id);
                              }

                              if (handleOriginal) {
                                handleOriginal();
                              }
                              // IMPORTANT! React-Table uses onClick internally to trigger
                              // events like expanding SubComponents and pivots.
                              // By default a custom 'onClick' handler will override this functionality.
                              // If you want to fire the original onClick handler, call the
                              // 'handleOriginal' function.
                            },
                          };
                        }}
                        SubComponent={(row) => {
                          return (
                            <div className="panel-body">
                              <div className="table-responsive">
                                <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th className="text-center align-middle" style={{ width: "5%" }}>
                                        Processar
                                      </th>
                                      <th className="text-center align-middle">UF</th>
                                      <th className="text-center align-middle">Número do Contrato</th>
                                      <th className="text-center align-middle">Data Processamento</th>
                                      <th className="text-center align-middle">Situação</th>
                                      <th className="text-center align-middle">Chassi</th>
                                      <th className="text-center align-middle">Gravame</th>
                                    </tr>
                                  </thead>
                                  <tbody>{this.filteredDetails(row.original.id)}</tbody>
                                </table>
                              </div>
                              {/* <div className="panel-tools">
                                <button
                                  className="btn btn-xs btn-info"
                                  id={row.original.id}
                                  disabled={
                                    !this.state.itensProcessar.find((item) => {
                                      return Object.keys(item) == row.original.id && item[row.original.id].length > 0;
                                    })
                                  }
                                  onClick={() => this.processarContratos(row.original.id)}
                                >
                                  <i className="fas fa-book" /> Processar contratos selecionados
                                </button>
                              </div> */}
                            </div>
                          );
                        }}
                        columns={[
                          {
                            expander: true,
                            Header: "Detalhar",
                            width: 65,
                            headerClassName: "font-extra-bold",
                            Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <span>&#x2bc6;</span> : <span>&#x2bc8;</span>}</div>,
                            style: {
                              cursor: "pointer",
                              fontSize: 15,
                              padding: "0",
                              textAlign: "center",
                              userSelect: "none",
                            },
                          },
                          {
                            Header: "Data Inclusão",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowData.original.data)}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Nome do Arquivo",
                            accessor: "nome",
                            headerClassName: "font-extra-bold",
                            Cell: (rowDataNome) => {
                              return (
                                <div>
                                  {rowDataNome.original.nome && !rowDataNome.original.nome2 ? (
                                    <span>{rowDataNome.original.nome}</span>
                                  ) : (
                                    <>
                                      <span>Arquivo Envio: {rowDataNome.original.nome}</span>
                                      <br />
                                      <span>Arquivo Retorno: {rowDataNome.original.nome2}</span>
                                    </>
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Ação",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            //width: 550,
                            Cell: (row) => {
                              return (
                                <div>
                                  <div className="btn-group">
                                    <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original, "entrada")}>
                                      {" "}
                                      Download 1º arquivo
                                    </Link>
                                    <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original, "entrada2")}>
                                      {" "}
                                      Download 2º arquivo
                                    </Link>
                                    <button
                                      className="btn btn-sm btn-info"
                                      id={row.original.id}
                                      disabled={
                                        !this.state.itensProcessar.find((item) => {
                                          return Object.keys(item) == row.original.id && item[row.original.id].length > 0;
                                        })
                                      }
                                      onClick={() => this.processarContratos(row.original.id)}
                                    >
                                      Processar contratos selecionados
                                    </button>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: LOTE_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: LOTE_LIST_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.loteSNG,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "LoteSNGForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(LoteSNG));
