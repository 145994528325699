import {
  INVOICES_LIST_QUANTITIES_LOAD,
  INVOICES_LIST_UNLOAD,
  INVOICES_LIST_COMPANIES_QUANTITY_LOAD,
  INVOICES_LIST_LOAD,
  INVOICE_DETAIL_INVOICES_LOAD,
  INVOICE_DETAIL_CHARGING_LOAD,
  INVOICE_ANEXOS_LIST_LOAD,
  INVOICE_DETAIL_INVOICES_DIVERGENCY,
  INVOICES_LIST_FINANCIAL_LOAD,
} from "../../actions/Constants";

const INITIAL_STATE = {
  registrado: 0,
  averbado: 0,
  naoRegistrado: 0,
  companiesQuantity: 0,
  faturas: [],
  registrosFatura: [],
  fatura: null,
  tipoAnexos: [],
  registrosDivergentes: [],
  correspondentes: [],
};

export default function invoice(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INVOICES_LIST_QUANTITIES_LOAD:
      return {
        ...state,
        registrado: action.payload ? action.payload[0] : null,
        averbado: action.payload ? action.payload[1] : null,
        naoRegistrado: action.payload ? action.payload[2] : null,
      };

    case INVOICE_DETAIL_CHARGING_LOAD:
      return {
        ...state,
        fatura: action.payload ? action.payload : null,
      };

    case INVOICES_LIST_COMPANIES_QUANTITY_LOAD:
      return {
        ...state,
        companiesQuantity: action.payload ? action.payload : null,
      };

    case INVOICES_LIST_LOAD:
      return {
        ...state,
        faturas: action.payload ? action.payload : [],
      };

    case INVOICE_DETAIL_INVOICES_LOAD:
      return {
        ...state,
        registrosFatura: action.payload ? action.payload : [],
      };

    case INVOICE_ANEXOS_LIST_LOAD:
      return {
        ...state,
        tipoAnexos: action.payload ? action.payload : [],
      };
    case INVOICE_DETAIL_INVOICES_DIVERGENCY:
      return {
        ...state,
        registrosDivergentes: action.payload ? action.payload : [],
      };
    case INVOICES_LIST_FINANCIAL_LOAD:
      return {
        ...state,
        correspondentes: action.payload ? action.payload : [],
      };
    case INVOICES_LIST_UNLOAD:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}
