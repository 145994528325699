import {LOGOUT, LOGIN, TOKEN_REFRESH} from '../../actions/Constants';

const INITIAL_STATE = { };

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : '/secure',
      };
    case LOGOUT:
      return state;
    case TOKEN_REFRESH:
      return state;


    // initial state
    default:
      return state;
  }
}
