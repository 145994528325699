import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";

import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";

import Icon from "react-icons-kit";
import { drawer2 } from "react-icons-kit/icomoon/drawer2";
import ReactTable from "react-table";

import Title from "../../components/Layout/Title";

import { INVOICES_LIST_FINANCIAL_LOAD, INVOICES_LIST_LOAD, INVOICES_LIST_UNLOAD } from "../../actions/Constants";
import api from "../../api/apiUtil";

const columns = [
  {
    Header: "Registros/Averbações",
    accessor: "quantidade",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Valor",
    accessor: "valorTotal",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => <div>{MaskDecimal.TO_BRL(row.value)}</div>, // Custom cell components!
  },
  {
    Header: "Período",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {MaskDate.TO_BR(row.original.periodoInicio)} a {MaskDate.TO_BR(row.original.periodoFim)}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "UF",
    accessor: "uf",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  // {
  //   Header: "Sit. Pagamento",
  //   accessor: "rowSituacao",
  //   className: "align-middle text-center",
  //   headerClassName: "font-extra-bold",
  //   width: 150,
  //   Cell: (rowSituacao) => {
  //     return rowSituacao.original.situacaoPagamento == 1 ? "Em andamento" : rowSituacao.original.situacaoPagamento == 2 ? "Pago" : rowSituacao.original.situacaoPagamento == 3 ? "Em aberto" : "";
  //   },
  // },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    accessor: "id",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <Link to={`/secure/invoicedetail/${row.value}`} className="btn btn-default btn-xs">
        <i className="fas fa-search-plus"></i> Detalhar
      </Link>
    ),
  },
];

class InvoiceList extends React.Component {
  componentWillMount() {
    let idFinanceira = api.loadUserProfile().idFinanceira;
    this.props.onListLoad(api.Charging.allByIdClose(idFinanceira));
    Promise.resolve(api.Companies.get(idFinanceira)).then((comp) => {
      if (!comp.delegaPara && comp.visualizaFaturamentoFiliais) {
        Promise.resolve(api.Companies.authorized(idFinanceira)).then((all) => {
          this.props.onCorrespondentesLoad(all);
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  renderCompanies() {
    if (!this.props.companiesQuantity || this.props.companiesQuantity.length === 0) {
      return (
        <tr>
          <td className="align-middle text-center" colSpan="2">
            <Icon icon={drawer2} /> Nenhum registro
          </td>
        </tr>
      );
    } else {
      return this.props.companiesQuantity.map((c, index) => {
        return (
          <tr key={index}>
            <td className="text-center align-middle">
              <br />
              <small>{c.cnpj}</small>
            </td>
            <td className="text-center align-middle">{c.total}</td>
          </tr>
        );
      });
    }
  }

  changeSelectedFilter = (e) => {
    this.props.onListLoad(api.Charging.allByIdClose(e.target.value));
  };

  financeirasOptions() {
    if (!this.props.correspondentes) {
      return null;
    }
    return this.props.correspondentes.map((correspondente) => (
      <option key={correspondente.razaoSocial} value={correspondente.id} selected={api.loadUserProfile().idFinanceira == correspondente.id ? true : false}>
        {correspondente.razaoSocial}
      </option>
    ));
  }

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Gestão financeira" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                {this.props.correspondentes.length > 1 && (
                  <div>
                    Selecione a Financeira
                    <div className="row">
                      <div className="col-lg-4 m-t-sm">
                        <select className="form-control custom-select" onChange={(e) => this.changeSelectedFilter(e)}>
                          {this.financeirasOptions()}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="panel-heading">Faturas</div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <ReactTable
                      className="-striped -highlight"
                      data={this.props.faturas}
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum registro encontrado"
                      pageText="página"
                      ofText="de"
                      rowsText="linhas"
                      columns={columns}
                      defaultPageSize={10}
                      showPageSizeOptions={false}
                      minRows={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: INVOICES_LIST_LOAD, payload }),
  onCorrespondentesLoad: (payload) => dispatch({ type: INVOICES_LIST_FINANCIAL_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: INVOICES_LIST_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.invoice,
  ...state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
