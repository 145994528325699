import { INTEGRATION_CERT_LOAD, INTEGRATION_UNLOAD } from "../../actions/Constants";
const INITIAL_STATE = { dadosCert: null };

export default function integration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INTEGRATION_CERT_LOAD:
      return {
        ...state,
        dadosCert: action.payload ? action.payload : null,
      };
    case INTEGRATION_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
