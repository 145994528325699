import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList'

const messages = {
  emptyList: "Sem itens a serem exibidos.",
  emptyFilter: "O filtro não retornou resultados." 
};

let ListItem = ({ item }) => (
  <span>
    {item.cnpjCorrespondente || item.delegaPara ?
      item.razaoSocial
    :
      <strong>{item.razaoSocial}</strong>
    }    
  </span>
);

const DropdownListField = ({ input, label, meta: { touched, error, valid }, description, required, small, inline, credor, ...rest }) => (
    <div className={`form-group ${touched && !valid ? 'has-error' : ''}`}>
      <label className={`font-weight-bold ${inline ? 'col-sm-2  col-form-label' : ''}`}>{label}</label>
      <DropdownList {...input}
        filter='contains'
        className={`${required ? valid && input.value ? 'field-valid-drop' : 'form-control field-required-drop' : ''}`} style={{padding: '0rem 0rem', boxSizing: 'content-box'}}
        value={input.value || []} // requires value to be an array
        messages={messages}
        itemComponent={credor ? ListItem : ''}
        {...rest}/>
        {
        description && <span className="help-block small">{description}</span>
        }
      <p className="text-danger">
        {touched ? error : ''}
      </p>
    </div>
);

export default DropdownListField;
