import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const ButtonWithBadge = ({ label, count, active, onClick }) => (
  <div style={{ position: "relative", display: "inline-block", margin: "0 5px" }}>
    <Button style={{ fontSize: "12px" }} bsStyle="success" bsSize="xsmall" active={active} onClick={onClick}>
      {label}
    </Button>
    <span
      style={{
        position: "absolute",
        top: "-15px",
        right: "-4px",
        backgroundColor: "red",
        color: "white",
        borderRadius: "50%",
        padding: "2px 6px",
        fontSize: "10px",
      }}
    >
      {count}
    </span>
  </div>
);

export default ButtonWithBadge;
