import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { reduxForm, Field, formValueSelector, SubmissionError } from "redux-form";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import SelectField from "../../components/Fields/SelectField";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";
import ReactTable from "react-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import "@kenshooui/react-multi-select/dist/style.css";
import MultiSelect from "@kenshooui/react-multi-select";
import { gear } from "react-icons-kit/fa/gear";
import { checkCircle } from "react-icons-kit/fa/checkCircle";
import { MANAGEMENT_REPORT_ERROR_LOAD, MANAGEMENT_REPORT_ERROR_UNLOAD, MANAGEMENT_REPORT_ERROR_LIST, UF_SYSTEM_LOAD } from "../../actions/Constants";
import api from "../../api/apiUtil";
import Title from "../../components/Layout/Title";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import DropdownListField from "../../components/Fields/DropdownListField";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";

const correspondente = [
  { label: "Não", value: "N" },
  { label: "Sim", value: "S" },
];

const tipoErro = [
  { label: "Todos", value: "" },
  { label: "Erro", value: "1" },
  { label: "Sucesso", value: "2" },
];

class ManagementReportError extends React.Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    // this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      items: [
        { id: 0, label: "N°. Contrato" },
        { id: 1, label: "Nome Devedor" },
        { id: 2, label: "CPF/CNPJ Devedor" },
        { id: 3, label: "N°. Gravame" },
        { id: 4, label: "Chassi" },
        { id: 5, label: "Mensagem" },
      ],
      selectedItemsError: [
        { id: 0, label: "N°. Contrato" },
        { id: 1, label: "Nome Devedor" },
        { id: 2, label: "CPF/CNPJ Devedor" },
        { id: 3, label: "N°. Gravame" },
        { id: 4, label: "Chassi" },
        { id: 5, label: "Mensagem" },
      ],
      showToggle: false,
      all: false,
      data: [],
      admin: false,
      financeira: [],
      pesquisando: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterRelGerError")) {
      document.getElementById("filterRelGerError").click();
    }
  };

  exportChartToXLSX = () => {
    var DataExcel = [];
    const headersExcell = [["N°. Contrato", "Nome Devedor", "CPF/CNPJ Devedor", "N°. Gravame", "Chassi", "Mensagem"]];

    DataExcel.length = 0;
    var veicJaAdd = false;
    this.props.data.registros.map((r) => {
      veicJaAdd = false;
      if (r.dados.veiculos) {
        r.dados.veiculos.map((veic) => {
          if (!veicJaAdd) {
            DataExcel.push({
              nrContrato: r.dados.numero,
              devNome: r.dados.devNome,
              devCpfCnpj: r.dados.devCpfCnpj,
              numGravame: veic.numGravame,
              chassi: veic.chassi,
              mensagem: r.mensagem,
            });
          } else {
            DataExcel.push({
              nrContrato: r.dados.numero,
              devNome: r.dados.devNome,
              devCpfCnpj: r.dados.devCpfCnpj,
              numGravame: veic.numGravame,
              chassi: veic.chassi,
              mensagem: r.mensagem,
            });
          }
          veicJaAdd = true;
        });
      }
    });

    DataExcel.push({ nrContrato: "" });
    {
      this.props.data.quantidade && DataExcel.push({ nrContrato: `Financeira: ${this.state.all ? "TODAS AS FINANCEIRAS" : this.state.financeira}` });
      DataExcel.push({ nrContrato: `Data Início: ${MaskDate.TO_BR(this.props.data.dataInicio)}` });
      DataExcel.push({ nrContrato: `Data Fim: ${MaskDate.TO_BR(this.props.data.dataFim)}` });
      DataExcel.push({ nrContrato: "" });
      DataExcel.push({ nrContrato: `Quantidade de eventos: ${this.props.data.quantidade}` });
    }

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "RelatorioComunicacãoDetran");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioComunicacãoDetran.xlsx`);
  };

  handleLoad(selectedItemsError) {
    this.setState({ selectedItemsError });
  }

  handleChange(selectedItemsError) {
    Promise.resolve(api.Users.reportFields(this.props.currentUser.id)).then((fields) => {
      if (fields.length > 0) {
        //SO TEM FINANCEIRO ANTIGO
        fields = {};
        fields["camposRelatorioGerencial"] = selectedItemsError;
      } else if (Object.keys(fields).length > 0) {
        //NAO TEM FINANCEIRO ANTIGO
        fields["camposRelatorioGerencial"] = selectedItemsError;
      } else {
        //NÃO EXITE GRAVACAO DE CAMPOS
        fields = {};
        fields["camposRelatorioGerencial"] = selectedItemsError;
      }
      // api.Users.updateReportFields(this.props.currentUser.id, JSON.stringify(fields));
    });
    this.setState({ selectedItemsError });
  }

  handleToggle() {
    this.setState(function (prevState) {
      return { showToggle: !prevState.showToggle };
    });
  }

  setCols = () => {
    const columns = [
      {
        Header: "N°. Contrato",
        accessor: "dados.numero",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numero,
      },
      {
        Header: "Nome Devedor",
        accessor: "dados.devNome",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devNome,
      },
      {
        Header: "CPF/CNPJ Devedor",
        accessor: "dados.devCpfCnpj",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devCpfCnpj,
      },
      {
        Header: "N°. Gravame",
        accessor: "numGravame",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (numGravame) => <div>{this.renderVehicleList(numGravame.original.dados.veiculos, "numGravame")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.numGravame).join("\n") : null),
      },
      {
        Header: "Chassi",
        accessor: "chassi",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (chassi) => <div>{this.renderVehicleList(chassi.original.dados.veiculos, "chassi")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.chassi).join("\n") : null),
      },
      {
        Header: "Mensagem",
        accessor: "mensagem",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.mensagem,
      },
    ];
    return columns.filter((col) => this.state.selectedItemsError.find((item) => item.label === col.Header));
  };

  componentWillMount() {
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(api.Financial.filtroPorUf()).then((all) => {
        var todas = {
          id: 0,
          razaoSocial: "Todos",
        };
        this.setState({ admin: true });
        all.unshift(todas);
        this.props.onLoad(all);
      });
      Promise.resolve(api.Ufs.sigla_system()).then((all) => {
        this.props.onUfSystemLoad(all);
      });
    } else {
      var todas = null;
      Promise.resolve(api.Financial.get(this.props.currentUser.idFinanceira)).then((financeira) => {
        if (!financeira.delegaPara) {
          todas = {
            id: 0,
            razaoSocial: "Todos",
          };
        }
        Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((all) => {
          if (todas !== null) {
            all.unshift(todas);
          }
          this.props.onLoad(all);
        });
      });
    }
    this.handleLoad(this.state.items);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  filter(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(
      api.Management.managementReportError(
        values.agente.id,
        values.dataInicio ? MaskDate.TO_DATABASE_DATE(values.dataInicio) : null,
        values.dataFim ? MaskDate.TO_DATABASE_DATE(values.dataFim) : null,
        values.tipoErro,
        values.correspondente,
        values.uf
      )
    ).then(
      (all) => {
        this.props.onListLoad(all);
        var filtered = this.props.companies.filter((comp) => comp.id == values.agente.id);
        this.setState({ financeira: filtered[0].razaoSocial, pesquisando: false });
        toastr.removeByType("info");
        if (!all || all.registros.length == 0) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  validateValues(values) {
    const errors = {};

    if (values.agente == undefined || (!values.agente.id && values.agente.id != 0)) {
      errors.agente = "Campo obrigatório";
    }
    return errors;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  renderVehicleListPDF(veiculos) {
    if (!veiculos || veiculos.length === 0) {
      return "";
    }
    return veiculos.map((v) => {
      return v.chassi + "\n";
    });
  }

  toggle() {
    this.setState(function (prevState) {
      return { showToggle: prevState.showToggle };
    });
  }

  exportPDF = () => (ev) => {
    const cols = this.setCols();
    const sortedData = this.selectTable.getResolvedState().sortedData;
    ev.preventDefault();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 70;

    var title = `Relatório Gerencial E-Registro\n\n${`Financeira: ${this.state.all ? "TODAS AS FINANCEIRAS" : this.state.financeira}`}`;
    if (this.props.data.dataInicio) {
      title = title + `\nData Início: ${MaskDate.TO_BR(this.props.data.dataInicio)} `;
      startTableY = startTableY + 15;
    }
    if (this.props.data.dataFim) {
      title = title + `\nData Fim: ${MaskDate.TO_BR(this.props.data.dataFim)} `;
      startTableY = startTableY + 15;
    }

    const headers = [cols.map((col) => [col.Header])];
    var totais = `Quantidade total de eventos: ${this.props.data.quantidade} `;

    const data = sortedData.map((row) => {
      row.original = row._original;
      return cols.map((col) => col.ReportCell(row));
    });

    var footer = function () {
      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      didDrawPage: function () {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.text(totais, marginLeft, doc.autoTable.previous.finalY + 20);
    doc.save("RelatorioComunicacãoDetran.pdf");
  };

  result(params) {
    console.log(params);
  }
  render() {
    const { items, selectedItemsError } = this.state;
    const messages = {
      searchPlaceholder: "Procurar campos...",
      noItemsMessage: "Sem Itens",
      noneSelectedMessage: "Nenhum selecionado",
      selectedMessage: "selecionados",
      selectAllMessage: "Selecionar tudo",
      clearAllMessage: "Limpar tudo",
      disabledItemsTooltip: "Você só pode selecionar 8 campos",
    };
    // remover a opcao Todos das ufs do usuario
    var ufsUser = [...this.props.currentUser.ufs];
    if (ufsUser && ufsUser.length > 0) {
      ufsUser.shift();
    }
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="de Contratos Registrados" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <Field name="uf" blank={true} label="UF" required data={this.state.admin ? this.props.ufSystem : ufsUser} textField="sigla" component={SelectField} type="text" />
            </div>
            <div className={this.state.admin ? "col-lg-6" : "col-lg-12"}>
              <Field name="agente" blank={true} credor={true} required label="Credor" data={this.props.companies} textField="razaoSocial" valueField="id" component={DropdownListField} type="text" />
            </div>
            {this.state.admin && (
              <div className="col-lg-6">
                <Field name="correspondente" label="Correspondente" data={correspondente} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
            )}
            <div className="col-lg-6">
              <Field
                name="dataInicio"
                label="Data Início"
                required
                placeholder="00/00/0000"
                data={this.props.dataInicio}
                component={TextDatePickerField}
                type="text"
                validate={[FieldValidation.date, FieldValidation.required]}
                normalize={FieldNormalize.DATE}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="dataFim"
                label="Data Fim"
                required
                placeholder="00/00/0000"
                data={this.props.dataFim}
                component={TextDatePickerField}
                type="text"
                validate={[FieldValidation.date, FieldValidation.required]}
                normalize={FieldNormalize.DATE}
              />
            </div>
            <div className="col-lg-6">
              <Field name="tipoErro" label="Tipo de mensagem" data={tipoErro} textField="label" valueField="value" component={SelectField} type="text" />
            </div>

            <div className="col-lg-12">
              <div className="hpanel">
                <Link
                  className="btn btn-info"
                  id="filterRelGerError"
                  disabled={this.state.pesquisando}
                  onClick={this.props.handleSubmit((values) => {
                    //Guarda se o usuário escolheu TODAS FINANCEIRAS/Financeira específica
                    this.setState({ all: values.agente == 0 });
                    return new Promise((resolve, reject) => {
                      var errors;
                      if (this) {
                        errors = this.validateValues(values);
                      }
                      if (errors && !this.isEmpty(errors)) {
                        reject(new SubmissionError(errors));
                      } else {
                        this.filter(values);
                        resolve();
                      }
                    });
                  })}
                >
                  {" "}
                  {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                </Link>

                <div className="panel-heading">
                  {this.props.data.quantidade ? `Quantidade de eventos: ${this.props.data.quantidade} ` : ""}
                  <br />
                </div>
                <div className="panel-body">
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(20)) ? (
                    <Link className="btn btn-default float-right" onClick={() => this.exportChartToXLSX()} title="Gerar Excel">
                      {" "}
                      <SvgIcon size={20} icon={fileExcelO} />
                    </Link>
                  ) : null}
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(19)) ? (
                    <Link className="btn btn-default float-right" onClick={this.exportPDF()} title="Gerar PDF">
                      {" "}
                      <SvgIcon size={20} icon={filePdf} />
                    </Link>
                  ) : null}
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(18)) ? (
                    <Link className="btn btn-default float-right" onClick={this.handleToggle} title="Configurar Colunas da Tabela">
                      {" "}
                      <SvgIcon size={20} icon={this.state.showToggle ? checkCircle : gear} />
                    </Link>
                  ) : null}
                  {this.state.showToggle ? (
                    <MultiSelect
                      items={items}
                      selectedItems={selectedItemsError}
                      onChange={this.handleChange}
                      showSelectAll={true}
                      showSelectedItems={true}
                      maxSelectedItems={8}
                      messages={messages}
                      itemHeight={38}
                      filterFunction={(value) => (item) => FieldNormalize.NO_SP_CHARACTERS(String(item.label)).includes(FieldNormalize.NO_SP_CHARACTERS(value))}
                    />
                  ) : null}
                  <div className="table-responsive">{this.renderDataList()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderVehicleList(veiculos, field) {
    if (!veiculos || veiculos.length === 0) {
      return <div></div>;
    }
    return veiculos.map((v) => {
      return (
        <div>
          {v[field]}
          <br />
        </div>
      );
    });
  }

  renderDataList() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.data.registros}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando.."
        noDataText="Nenhum registro encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        columns={this.setCols()}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
        ref={(r) => {
          this.selectTable = r;
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: MANAGEMENT_REPORT_ERROR_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: MANAGEMENT_REPORT_ERROR_LIST, payload }),
  onUnload: () => dispatch({ type: MANAGEMENT_REPORT_ERROR_UNLOAD }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
});

const selector = formValueSelector("ManagementReportErrorForm");
const mapStateToProps = (state) => ({
  ...state.managementReportError,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  dataInicio: selector(state, "dataInicio"),
  dataFim: selector(state, "dataFim"),
  uf: selector(state, "uf"),
});

const form = reduxForm({
  form: "ManagementReportErrorForm",
  destroyOnUnmount: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ManagementReportError));
