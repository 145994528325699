import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, reset, change, untouch } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";

import { FINANCIAL_SHIPPING_LIST_LOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";

import fileDownload from "js-file-download";
import Dropzone from "react-dropzone";
import { toastr } from "react-redux-toastr";

class FinancialShipping extends React.Component {
  componentWillMount() {
    this.props.onListLoad(api.Charging.allOpen());
  }

  handleClickDownload = () => (ev) => {
    ev.preventDefault();
    var props = this.props;
    Promise.resolve(api.Charging.gerarRemessa())
      .then((response) => {
        fileDownload(response, "Remessa.txt");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    Promise.resolve(api.Charging.retornoRemessa(filesToUpload[0]))
      .then((response) => {
        this.props.onListLoad(api.Charging.allOpen());
        toastr.success("Sucesso", "Arquivo processado.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Faturamentos" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-default btn-sm" onClick={browserHistory.goBack}>
                      <i className="fas fa-arrow-left"></i> Voltar
                    </Link>
                    {this.props.all && (
                      <button type="button" className="btn btn-primary btn-sm" onClick={this.handleClickDownload()}>
                        <i className="fas fa-file-alt"></i> Gerar arquivo Remessa
                      </button>
                    )}
                    {this.props.all && (
                      <Dropzone name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                        <button type="button" className="btn btn-primary btn-sm">
                          <i className="fas fa-reply" /> Retorno arquivo Remessa
                        </button>
                      </Dropzone>
                    )}
                  </div>
                  <p>Cobranças em Aberto</p>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Financeira</th>
                          <th className="text-center align-middle">CNPJ</th>
                          <th className="text-center align-middle">Período</th>
                          <th className="text-center align-middle">Qtd Registros</th>
                          <th className="text-center align-middle">Valor da Fatura</th>
                          <th className="text-center align-middle">Status</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderInvoicesList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInvoicesList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{r.razaoSocial}</td>
          <td className="text-center align-middle">{r.cnpj}</td>
          <td className="text-center align-middle">
            {MaskDate.TO_BR(r.periodoInicio)} a {MaskDate.TO_BR(r.periodoFim)}
          </td>
          <td className="text-center align-middle">{r.quantidade}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(r.valorTotal)}</td>
          <td className="text-center align-middle">{r.status === 1 ? "Aberto" : r.status === 2 ? "Pago" : r.status === 3 ? "Fechado" : r.status}</td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: FINANCIAL_SHIPPING_LIST_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.financialShipping,
});

const form = reduxForm({
  form: "FinancialShippingForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialShipping));
