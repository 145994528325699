import { UF_SYSTEM_LOAD, FINANCEIRO_LIST_UNLOAD, FINANCEIRO_LIST_LOAD, FINANCEIRO_FILES_TO_SEND, FINANCEIRO_FILE_SENT, FINANCEIRO_LOT_CLOSED } from "../../actions/Constants";

const INITIAL_STATE = { all: [], files: [], sentFiles: 0, qttFilesToSend: 0 };

export default function faturamento(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UF_SYSTEM_LOAD:
      return {
        ...state,
        ufSystem: action.payload ? action.payload : [],
      };
    case FINANCEIRO_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case FINANCEIRO_FILES_TO_SEND:
      return {
        ...state,
        qttFilesToSend: action.payload[0],
        files: action.payload[1],
      };
    case FINANCEIRO_FILE_SENT:
      return {
        ...state,
        sentFiles: state.sentFiles + 1,
      };
    case FINANCEIRO_LOT_CLOSED:
      return {
        files: [],
        sentFiles: 0,
        qttFilesToSend: 0,
      };
    case FINANCEIRO_LIST_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
