import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch, formValueSelector, SubmissionError } from "redux-form";
import _ from "lodash";
import { Link, browserHistory } from "react-router";
import Title from "../../components/Layout/Title";

import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import SelectField from "../../components/Fields/SelectField";
import DropdownListField from "../../components/Fields/DropdownListField";
import api from "../../api/apiUtil";
import MaskCPF from "../../components/Util/MaskCPF";
import { toastr } from "react-redux-toastr";
import { BeatLoader } from "react-spinners";
import SvgIcon from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import { wrench } from "react-icons-kit/icomoon/wrench";

import { USER_ALIAS_PARCEIRO_LOAD, USER_ALIAS_UNLOAD, USER_ALIAS_LOAD, USER_ALIAS_ROLES } from "../../actions/Constants";
import MultiSelectField from "../../components/Fields/MultiSelectField";

const perfis = [
  // { label: "Normal", value: null },
  { label: "Gestor", value: "G" },
  { label: "Financeiro", value: "F" },
  { label: "Parceiro", value: "P" },
];

class UserAlias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.props.onListLoad(api.Users.getUsuariosAlias());
    this.props.onLoadParceiro(api.Financial.getParceiros());
    this.props.onLoadRoles(api.Profile.allAlias());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  async formSubmit(values) {
    var props = this.props;
    const errors = {};
    if (!values.roles || values.roles.length == 0) {
      errors.roles = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
      throw new SubmissionError(errors);
    }

    var user = {
      cpf: values.cpf.replace(/[^\d]/g, ""),
      email: values.email,
      nome: values.nome,
      tipo: null,
      idParceiro: null,
      perfis: [],
    };

    if (this.props.perfis.some((item) => item.id == 5)) {
      user.tipo = "P";
      user.idParceiro = values.idParceiro;
    }

    _.forEach(values.roles, function (role) {
      user.perfis = [role.id, ...user.perfis];
    });

    if (this.state.user) {
      user.id = this.state.user.id;
      Promise.resolve(api.Users.updateAlias(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário alterado");
          this.props.onListLoad(api.Users.getUsuariosAlias());
          this.setState({ user: null });
          this.props.reset();
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    } else {
      Promise.resolve(api.Users.createAlias(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário cadastrado");
          this.props.onListLoad(api.Users.getUsuariosAlias());
          this.setState({ user: null });
          this.props.reset();
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    }
  }

  handleOnBlockClick = (id) => {
    Promise.resolve(api.Users.block(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário bloqueado");
      this.props.onListLoad(api.Users.getUsuariosAlias());
    });
  };

  handleOnUnblockClick = (id) => {
    Promise.resolve(api.Users.unblock(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário desbloqueado");
      this.props.onListLoad(api.Users.getUsuariosAlias());
    });
  };

  exportToXLSX = () => {
    var DataExcel = [];
    const headersExcell = [["NOME", "CPF", "EMAIL", "SITUAÇÃO", "PERFIL"]];

    DataExcel = this.props.all.map((row) => {
      return {
        nome: row.nome,
        cpf: MaskCPF.TO_CPF(row.cpf),
        email: row.email,
        situacao: row.blocked ? "Bloqueado" : "Ativo",
        // parceiro: row.tipo && row.tipo.includes("P") ? "Sim" : "Não",
        perfil: row.perfis
          .map((p, index) => {
            return p.nome.split(", ");
          })
          .join(", "),
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ListaDeUsuariosAlias");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `ListaDeUsuariosAlias.xlsx`);
  };

  handleOnSelectUserClick = (usr) => {
    this.setState({ user: usr });
    this.props.dispatch(change("UserAliasForm", "nome", usr.nome));
    this.props.dispatch(change("UserAliasForm", "cpf", usr.cpf));
    this.props.dispatch(change("UserAliasForm", "email", usr.email));
    // this.props.dispatch(change("UserAliasForm", "tipo", usr.tipo && usr.tipo.includes("P") ? "P" : ""));
    this.props.dispatch(change("UserAliasForm", "idParceiro", usr.idParceiro));
    this.props.dispatch(change("UserAliasForm", "roles", usr.perfis));
    this.HandleScroll();
  };

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  handleOnCancel = () => (ev) => {
    ev.preventDefault();
    this.setState({ user: null });
    this.props.dispatch(change("UserAliasForm", "nome", null));
    this.props.dispatch(untouch("UserAliasForm", "nome"));
    this.props.dispatch(change("UserAliasForm", "cpf", null));
    this.props.dispatch(untouch("UserAliasForm", "cpf"));
    this.props.dispatch(change("UserAliasForm", "email", null));
    this.props.dispatch(untouch("UserAliasForm", "email"));
    // this.props.dispatch(change("UserAliasForm", "tipo", null));
    // this.props.dispatch(untouch("UserAliasForm", "tipo"));
    this.props.dispatch(change("UserAliasForm", "idParceiro", null));
    this.props.dispatch(untouch("UserAliasForm", "idParceiro"));
    this.props.dispatch(change("UserAliasForm", "roles", null));
    this.props.dispatch(untouch("UserAliasForm", "roles"));
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Consultar Usuários Alias" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {this.props.currentUser.funcionalidades.includes(78) && (
                      <Link className="btn btn-xs btn-info" to={`/alias/aliasProfile`}>
                        {" "}
                        <SvgIcon size={20} icon={wrench} />
                        Gerenciar Perfis
                      </Link>
                    )}
                  </div>
                  <br />
                </div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-2">
                        <Field
                          name="cpf"
                          label="CPF"
                          component={TextField}
                          type="text"
                          normalize={FieldNormalize.CPF}
                          validate={[FieldValidation.cpf, FieldValidation.required]}
                          required
                          disabled={this.state.user}
                        />
                      </div>
                      <div className="col-md-3">
                        <Field name="nome" label="Nome" component={TextField} type="text" validate={[FieldValidation.required]} required />
                      </div>
                      <div className="col-md-3">
                        <Field name="email" label="E-mail" component={TextField} type="text" validate={[FieldValidation.email, FieldValidation.required]} required />
                      </div>
                      <div className="col-md-3">
                        <Field name="roles" label="Perfil" data={this.props.roles} component={MultiSelectField} type="text" textField="nome" valueField="sigla" validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-2">
                        <Field
                          name="tipo"
                          label="Parceiro"
                          data={[
                            { label: "Não", value: "" },
                            { label: "Sim", value: "P" },
                          ]}
                          component={SelectField}
                          type="text"
                          textField="label"
                          valueField="value"
                        />
                      </div> */}
                      {this.props.perfis && this.props.perfis.some((item) => item.id == 5) && (
                        <div className="col-lg-2">
                          <Field
                            name="idParceiro"
                            blank={true}
                            label="Parceria"
                            data={this.props.parceiros}
                            textField="nome"
                            valueField="id"
                            component={SelectField}
                            type="text"
                            validate={[FieldValidation.required]}
                            required
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" onClick={this.handleOnCancel()}>
                            Cancelar
                          </Link>
                          {this.state.user && (
                            <Link className="btn btn-info" id="filterUsers" onClick={handleSubmit(this.formSubmit.bind(this))}>
                              {" "}
                              {"Alterar"}
                            </Link>
                          )}
                          {this.props.currentUser.funcionalidades.includes(74) && !this.state.user && (
                            <Link className="btn btn-info" id="filterUsers" onClick={handleSubmit(this.formSubmit.bind(this))}>
                              {" "}
                              {"Adicionar"}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hpanel">
                      <div className="panel-heading">Usuários cadastrados</div>
                      <div className="panel-body">
                        {this.props.all && this.props.all.length > 0 && this.props.currentUser.funcionalidades.includes(77) && (
                          <Link className="btn btn-default float-right" onClick={() => this.exportToXLSX()} title="Gerar Excel">
                            {" "}
                            <SvgIcon size={20} icon={fileExcelO} />
                          </Link>
                        )}
                        <div className="table-responsive">
                          {this.props.all && this.props.all.length > 0 ? (
                            <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">NOME</th>
                                  <th className="text-center align-middle">CPF</th>
                                  <th className="text-center align-middle">EMAIL</th>
                                  <th className="text-center align-middle">SITUAÇÃO</th>
                                  {/* <th className="text-center align-middle">PARCEIRO</th> */}
                                  <th className="text-center align-middle">PERFIL</th>
                                  <th className="text-center align-middle">AÇÕES</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderList()}</tbody>
                            </table>
                          ) : (
                            <small>Nenhum registro encontrado</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.email}</td>
          <td className="text-center align-middle">{user.blocked ? "Bloqueado" : "Ativo"}</td>
          {/* <td className="text-center align-middle">{user.tipo && user.tipo.includes("P") ? "Sim" : "Não"}</td> */}
          <td className="text-center align-middle">{this.renderRoles(user.perfis)}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(75) && (
                <>
                  {user.blocked === 0 && (
                    <Link className="btn btn-sm btn-default" onClick={() => this.handleOnBlockClick(user.id)} title="Bloquear">
                      <i className="fas fa-ban"></i>
                    </Link>
                  )}
                  {user.blocked >= 1 && (
                    <Link className="btn btn-sm btn-default" onClick={() => this.handleOnUnblockClick(user.id)} title="Desbloquear">
                      <i className="far fa-check-circle"></i>
                    </Link>
                  )}
                </>
              )}

              {this.props.currentUser.funcionalidades.includes(76) && (
                <button className="btn btn-sm btn-default" onClick={() => this.handleOnSelectUserClick(user)} title="Editar">
                  <i className="far fa-edit"></i>
                </button>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  renderRoles(perfis) {
    if (perfis) {
      return perfis.map((perfil, index) => {
        return (
          <div className="m-b-xs" key={index}>
            <span className="label m-r-xs">
              <small>{perfil.nome}</small>
            </span>
            <br />
          </div>
        );
      });
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: USER_ALIAS_LOAD, payload }),
  onLoadParceiro: (payload) => dispatch({ type: USER_ALIAS_PARCEIRO_LOAD, payload }),
  onUnload: () => dispatch({ type: USER_ALIAS_UNLOAD }),
  onLoadRoles: (payload) => dispatch({ type: USER_ALIAS_ROLES, payload }),
});

const selector = formValueSelector("UserAliasForm");
const mapStateToProps = (state) => ({
  ...state.userAlias,
  // tipo: selector(state, "tipo"),
  currentUser: state.common.currentUser,
  perfis: selector(state, "roles"),
});

const form = reduxForm({
  form: "UserAliasForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(UserAlias));
