import {CONFIRM_CHANGE_PASSWORD_OPEN, CONFIRM_CHANGE_PASSWORD_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, description: null, onSuccess: null };

export default function confirmChangePassword(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_CHANGE_PASSWORD_OPEN:
      return {
        ...state,
        open: true,
      };
    case CONFIRM_CHANGE_PASSWORD_CLOSE:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}
