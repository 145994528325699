import React from "react";
import { connect } from 'react-redux';
import { reduxForm, Field, change, untouch } from 'redux-form';
import { Link, browserHistory } from 'react-router';
import _ from 'lodash';

import { USER_LIST_LOG_AUDIT_LOAD, USER_LIST_LOG_AUDIT_UNLOAD, EVENTS_LIST_LOAD, USERS_LIST_LOAD_AUDIT, USERS_LIST_LOAD_AUDIT_UNLOAD} from '../../actions/Constants';
import api from '../../api/apiUtil';

import Title from '../../components/Layout/Title';
import FieldValidation from '../../components/Validation/FieldValidation';
import SelectField from '../../components/Fields/SelectField';
import TextDatePickerField from '../../components/Fields/TextDatePickerField';
import FieldNormalize from '../../components/Normalize/FieldNormalize';
import ReactTable from 'react-table';
import MaskDate from '../../components/Util/MaskDate';
import MaskCPF from '../../components/Util/MaskCPF';

import SvgIcon from 'react-icons-kit';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { filePdf } from 'react-icons-kit/icomoon/filePdf'
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import XLSX from 'xlsx/dist/xlsx.mini.min';
import * as FileSaver from 'file-saver';
import DropdownListField from '../../components/Fields/DropdownListField';
import { BeatLoader } from 'react-spinners';
import {toastr} from 'react-redux-toastr'
class UserLogAudit extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    }
  }

  componentWillMount() {
    Promise.resolve(api.Users.companyUsers(this.props.params.slug, false)).then(all => {
      var todas = {
        id: 0,
        nome: 'Todos',
      }
      all.unshift(todas);
      this.props.onListLoadUsers(all);
    });
    Promise.resolve(api.Users.getEvents()).then(allEvents => {
      var todas = {
        tipoEvento: 0,
        eventoFormat: 'Todos'
      }
      allEvents.unshift(todas);
      this.props.onListLoadEvents(allEvents);
    });
    this.props.onListLoadAllUsers(api.Users.getAuditoria(this.props.params.slug));
  }

  componentWillUnmount() {
    this.props.onUnloadUsers();
    this.props.onUnloadAllUsers();
  }

  formSubmit(values) {
    this.setState({ pesquisando: true });
    toastr.info('Aguarde', 'Realizando consulta...', {
      timeOut: 0, component: () => {
        return (
          <BeatLoader
            size={15}
            margin={2}
            color={'#58abc3'}
            loading={true}
          />);
      }
    });
    Promise.resolve(api.Users.getAuditoria(this.props.params.slug, values.usuario ? values.usuario.id : null, values.evento, MaskDate.TO_DATABASE_DATE(values.dtInicial),  MaskDate.TO_DATABASE_DATE(values.dtFinal))).then(res => {
      this.props.onListLoadAllUsers(res);
      this.setState({ pesquisando: false });
      toastr.removeByType('info');
      if(!res || res.length == 0){
        toastr.info('Aviso','Não foram encontrados resultados para essa pesquisa.');
      }
    }, (err) => {
      console.log(err)
    });
  }

  exportPDF = () => ev => {
    ev.preventDefault();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;
    doc.setFontSize(12);
    var startY = 50;
    var footer = function () {
      doc.setFontSize(7);
      doc.text(width - 50, height - 30, 'Página ' + doc.page);
      doc.page++;
    };
    
    var title = '';
    var fileName = '';
    var content = {};
    var body = [];
    var head = [
      [
        { content: 'Nome' },
        { content: 'CPF' },
        { content: 'IP' },
        { content: 'Último acesso' },
        { content: 'Evento' },
      ],
    ];
    var styles = {
      cellPadding: 1.5,
      overflow: 'linebreak',
      valign: 'middle',
      halign: 'center',
      lineColor: [0, 0, 0],
      lineWidth: 0.2,
      fontSize: 10
    };
      title = 'Log de Auditoria de Eventos';
      fileName = 'LogDeAuditoria';

      body = this.props.all.map((row) =>
        [
          row.nome,
          FieldNormalize.CPF_CNPJ(row.cpf),
          row.ip,
          MaskDate.TO_BR_DATETIME(row.data),
          row.eventoFormat,
        ]);
      
    content = {
      startY,
      head,
      body,
      bodyStyles: { valign: 'top' },
      styles,
      didDrawPage: function (data) {
        footer();
      }
    };
    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`LogDeAuditoria.pdf`)
  }

  exportChartToXLSX = () => {
  
    var DataExcel = [];
    const headersExcell = [
      ["Nome", "CPF", "IP", "Último acesso", "Evento"]
    ];

    var fileName = '';

        fileName = 'Log de Auditoria.';

        DataExcel = this.props.all.map(row =>{
          return {
            nome:row.nome,
            cpf:FieldNormalize.CPF_CNPJ(row.cpf),
            ip:row.ip,
            data:MaskDate.TO_BR_DATETIME(row.data),
            tipoEvento:row.eventoFormat,
          }
        });
  
    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2"
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LogDeAuditoria");
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `LogDeAuditoria.xlsx`);
  }

  render() {
    const {handleSubmit, reset} = this.props;
    return (
        <div>
          <Title routes = {this.props.routes} params = {this.props.params} description = "Log de Auditoria de Eventos" /> <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                  <Link className="btn btn-default " onClick={browserHistory.goBack}><i className="fas fa-arrow-left"></i> Voltar</Link>
                  </div>
                  <p>Eventos</p>
                </div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-5">
                        <Field name="usuario" credor={false} blank={true} label="Usuário" data={this.props.allUsersAudit} textField="nome" valueField="id" component={DropdownListField} type="text" />
                      </div>
                      <div className="col-md-3">
                        <Field name="evento" label="Tipo de Evento" data={this.props.allEvents} textField="eventoFormat" valueField="tipoEvento" component={SelectField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field name="dtInicial" label="Data Inicial" validate={[ FieldValidation.date ]} normalize={FieldNormalize.DATE} placeholder="00/00/0000" data={this.props.dtInicial} component={TextDatePickerField} type="text" inline={false}/>
                      </div>
                      <div className="col-md-2">
                        <Field name="dtFinal" label="Data Final" validate={[ FieldValidation.date ]} normalize={FieldNormalize.DATE} placeholder="00/00/0000" data={this.props.dtFinal} component={TextDatePickerField} type="text" inline={false}/>
                      </div>                      
                    </div> 
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <button className="btn btn-info" disabled={this.state.pesquisando} onClick={handleSubmit(this.formSubmit.bind(this))}>{this.state.pesquisando ? 'Aguarde...' : 'Pesquisar'}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className = "col-lg-12" >
              <div className="hpanel">
                <div className="panel-body">
                      <Link className="btn btn-default float-right" onClick={() => this.exportChartToXLSX()} title="Gerar Excel"> <SvgIcon size={20} icon={fileExcelO} /></Link>
                      <Link className="btn btn-default float-right" onClick={this.exportPDF()} title="Gerar PDF"> <SvgIcon size={20} icon={filePdf} /></Link>
                  <div className="table-responsive">
                    <ReactTable className="-striped -highlight"
                      data={this.props.all} previousText="Anterior" nextText="Próximo" loadingText="Carregando..."
                      noDataText="Nenhum registro encontrado" pageText="página" ofText="de" rowsText="linhas"
                      columns={[{
                        Header: 'Nome',
                        accessor: 'nome',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                      },{
                        Header: 'CPF',
                        accessor: 'rowCpf',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                        Cell: rowCpf => (<div>{MaskCPF.TO_CPF(rowCpf.original.cpf)}</div>)
                      },{
                        Header: 'IP',
                        accessor: 'ip',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                      },{
                        Header: 'Último acesso',
                        accessor: 'row',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                        Cell: row => (<div>{MaskDate.TO_BR_DATETIME(row.original.data)}</div>)
                      },{
                        Header: 'Evento',
                        accessor: 'eventoFormat',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                      },{
                        Header: 'Detalhes',
                        className: "align-middle text-center",
                        headerClassName: "font-extra-bold",
                        Cell: row => (
                          <div className="btn-group-vertical">
                            <Link className="btn btn-sm btn-default" to={(_.findIndex(this.props.currentUser.perfis, function(perfil) { return perfil === 'ALIAS'; }) > -1) ? `/alias/detailUserLogAudit/${row.original.id}` : `/secure/detailUserLogAudit/${row.original.id}`}><i className="fa fa-info-circle"></i> Detalhes</Link>
                          </div>
                          )
                      }]} defaultPageSize={20} showPageSizeOptions={false} minRows={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onListLoadUsers: payload => dispatch({ type: USER_LIST_LOG_AUDIT_LOAD, payload }),
  onUnloadUsers: payload => dispatch({ type: USER_LIST_LOG_AUDIT_UNLOAD, payload }),
  onListLoadEvents: payload => dispatch({ type: EVENTS_LIST_LOAD, payload }),
  onListLoadAllUsers: payload => dispatch({ type: USERS_LIST_LOAD_AUDIT, payload }),
  onUnloadAllUsers: payload => dispatch({ type: USERS_LIST_LOAD_AUDIT_UNLOAD, payload }),
});

const mapStateToProps = state => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: 'UserLogAuditForm'
});

export default connect(mapStateToProps,mapDispatchToProps)(form(UserLogAudit));
