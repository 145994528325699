import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../stylesheets/main.css";
import ReduxToastr from "react-redux-toastr";
import _ from "lodash";

import PrintProvider, { Print, NoPrint } from "react-easy-print";

import {
  REDIRECTED,
  LOGOUT,
  UF_LOAD,
  APP_LOADED,
  AUTHORIZED_COMPANIES_LOAD,
  VIDEO_OPEN,
  CONFIRM_TERMS_OF_USE_OPEN,
  CONFIRM_CHANGE_PASSWORD_OPEN,
  CONFIG_LOAD,
  SERVICE_STATUS_DETRANS_LOAD,
} from "../../actions/Constants";
import api from "../../api/apiUtil";

import Header from "../../components/Layout/Header";
import Menu from "../../components/Layout/Menu";
import Footer from "../../components/Layout/Footer";
import ConfirmOperation from "../Confirm/ConfirmOperation";
import Video from "../Video/Video";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import ConfirmNotHomologate from "../ConfirmNotHomologate/ConfirmNotHomologate";
import ConfirmChangePassword from "../ConfirmChangePassword/ConfirmChangePassword";
import ConfirmGerenciarAutenticacao from "../ConfirmGerenciarAutenticacao/ConfirmGerenciarAutenticacao";
import UploadArchive from "../UploadArchive/UploadArchive";
import TermsOfUse from "../TermsOfUse/TermsOfUse";
import TawkTo from "@dannyfranca/any-chat/lib/chats/tawkto";
import "react-widgets/dist/css/react-widgets.css";
import "filepond/dist/filepond.min.css";
import socketIOClient from "socket.io-client";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "../App/IdleModal";
import { createGlobalStyle } from "styled-components";
//const socket = socketIOClient(window.location.origin, { path: '/api/socket.io' });
const Chat = new TawkTo(process.env.REACT_APP_TAWKTO_API);
var tinycolor = require("tinycolor2");
var horarioOpenDialog;
var timerId = 0;
let timer = null;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 10000 * 90,
      maxTempOpenDialog: 1000 * 32,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
    this._mountParameters();
  }

  _onActive(e) {
    this._mountParameters();
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.logout();
    } else {
      this.horarioOpenDialog = new Date().getTime();
      setTimeout(
        function () {
          this.setState({ showModal: true });
        }.bind(this),
        1000
      ); //necessario essa pausa que timer e o clearInterval juntamente com a abertura da dialog fiquem sincronizados

      if (!this.timerId) {
        var _30Seconds = 30,
          display = document.querySelector("#time");
        this.startTimer(_30Seconds, display);
      }
      //this.idleTimer.reset();
      this.setState({ isTimedOut: true });
      this.timer = setTimeout(() => this._mountParameters(), this.state.maxTempOpenDialog);
    }
  }

  _mountParameters() {
    if (this.state.showModal) {
      var tempoPercorrido = Math.abs(new Date().getTime() - this.horarioOpenDialog);
      if (tempoPercorrido > this.state.maxTempOpenDialog) {
        clearTimeout(timer);
        this.logout();
      }
    } else {
      this.setState({ isTimedOut: false });
    }
  }

  handleClose() {
    clearInterval(this.timerId);
    this.idleTimer.reset();
    this.setState({ showModal: false });
    this.timerId = 0;
    this.horarioOpenDialog = null;
  }

  handleLogout() {
    this.setState({ showModal: false });
    clearInterval(this.timerId);
    clearTimeout(timer);
    this.timerId = 0;
    this.logout();
  }

  startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    this.timerId = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  componentWillMount() {
    var props = this.props;
    this.props.onUfLoad(api.Ufs.ufs());

    var userProfile = api.loadUserProfile();
    const token = window.localStorage.getItem("jwt");
    if (userProfile) {
      this.props.onLoad(userProfile, token);
      this.props.onCompaniesLoad(api.Companies.authorized(userProfile.idFinanceira));
      //socket.emit('join', userProfile);
      Promise.resolve(api.Configuracao.getPorAtributo("template")).then((res) => {
        if (res) {
          this.props.onConfigLoad(JSON.parse(res.valor));
        }
      });
      if (
        userProfile.id &&
        //!userProfile.dataAceite ||
        userProfile.primeiroAcesso == 0
      ) {
        //USUARIO NUNCA REALIZOU LOGIN
        Promise.resolve(api.Users.get(userProfile.id)).then((e) => {
          //if(!e.dataAceite){
          //  this.props.onTermsOfUseOpen();
          //}else
          //if(e.primeiroAcesso == 0){
          this.props.onChangePasswordOpen();
          //}
        });
      }
      // if(!userProfile.idFinanceira){//ALIAS
      //   Promise.resolve(api.Ufs.system()).then(res => {
      //     console.log('TESTE ' +res)
      //     res.forEach(function (uf) {
      //       console.log('UFFFFFFFFFFFF ' +uf)
      //       props.onLoadStatusDentrans(api.Detrans.statusDetran2(uf));
      //     });
      //   })
      // }
    } else {
      api.Auth.logout();
      this.props.onLogout();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      this.context.router.replace(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  logout() {
    //socket.emit('logout', this.props.currentUser);
    api.Auth.logout();
    this.props.onLogout();
  }

  render() {
    if (this.props.currentUser && this.props.currentUser.perfis) {
      const GlobalStyles = createGlobalStyle`
        :root {
          ${
            this.props.configLayout && this.props.configLayout.color1
              ? `--color1: ${"#" + this.props.configLayout.color1};
            --color1-lighten6: ${tinycolor("#" + this.props.configLayout.color1)
              .lighten(6)
              .toString()};
            --color1-darken10: ${tinycolor("#" + this.props.configLayout.color1)
              .darken(10)
              .toString()};
            --color1-darken6: ${tinycolor("#" + this.props.configLayout.color1)
              .darken(6)
              .toString()};  
            --color1-darken5: ${tinycolor("#" + this.props.configLayout.color1)
              .darken(5)
              .toString()};
            --color1-darken4: ${tinycolor("#" + this.props.configLayout.color1)
              .darken(4)
              .toString()};`
              : `--color1-lighten6: ${tinycolor("#62cb31").lighten(6).toString()};
            --color1-darken10: ${tinycolor("#62cb31").darken(10).toString()};
            --color1-darken6: ${tinycolor("#62cb31").darken(6).toString()};  
            --color1-darken5: ${tinycolor("#62cb31").darken(5).toString()};
            --color1-darken4: ${tinycolor("#62cb31").darken(4).toString()};`
          }
          ${
            this.props.configLayout && this.props.configLayout.color2
              ? `--color2: ${"#" + this.props.configLayout.color2};
            --color2-lighten6: ${tinycolor("#" + this.props.configLayout.color2)
              .lighten(6)
              .toString()};
            --color2-darken6: ${tinycolor("#" + this.props.configLayout.color2)
              .darken(6)
              .toString()};
            --color2-darken5: ${tinycolor("#" + this.props.configLayout.color2)
              .darken(5)
              .toString()};`
              : `--color2-lighten6: ${tinycolor("#3498db").lighten(6).toString()};
            --color2-darken6: ${tinycolor("#3498db").darken(6).toString()};
            --color2-darken5: ${tinycolor("#3498db").darken(5).toString()};`
          }
          ${
            this.props.configLayout && this.props.configLayout.color3
              ? `--color3: ${"#" + this.props.configLayout.color3};
            --color3-lighten6: ${tinycolor("#" + this.props.configLayout.color3)
              .lighten(6)
              .toString()};
            --color3-darken10: ${tinycolor("#" + this.props.configLayout.color3)
              .darken(10)
              .toString()};
            --color3-darken6: ${tinycolor("#" + this.props.configLayout.color3)
              .darken(6)
              .toString()};
            --color3-darken5: ${tinycolor("#" + this.props.configLayout.color3)
              .darken(5)
              .toString()};`
              : `--color3-lighten6: ${tinycolor("#e74c3c").lighten(6).toString()};
            --color3-darken10: ${tinycolor("#e74c3c").darken(10).toString()};
            --color3-darken6: ${tinycolor("#e74c3c").darken(6).toString()};
            --color3-darken5: ${tinycolor("#e74c3c").darken(5).toString()};`
          }
          ${
            this.props.configLayout && this.props.configLayout.color4
              ? `--color4: ${"#" + this.props.configLayout.color4};
            --color4-darken10: ${tinycolor("#" + this.props.configLayout.color4)
              .darken(10)
              .toString()};
            --color4-darken4: ${tinycolor("#" + this.props.configLayout.color4)
              .darken(4)
              .toString()};
            --color4-lighten5: ${tinycolor("#" + this.props.configLayout.color4)
              .lighten(5)
              .toString()};
            --color4-lighten10: ${tinycolor("#" + this.props.configLayout.color4)
              .lighten(10)
              .toString()};
            --color4-lighten15: ${tinycolor("#" + this.props.configLayout.color4)
              .lighten(15)
              .toString()};
            --color4-lighten20: ${tinycolor("#" + this.props.configLayout.color4)
              .lighten(20)
              .toString()};
            --color4-lighten25: ${tinycolor("#" + this.props.configLayout.color4)
              .lighten(25)
              .toString()};`
              : `--color4-darken10: ${tinycolor("#6a6c6f").darken(10).toString()};
            --color4-darken4: ${tinycolor("#6a6c6f").darken(4).toString()};
            --color4-lighten5: ${tinycolor("#6a6c6f").lighten(5).toString()};
            --color4-lighten10: ${tinycolor("#6a6c6f").lighten(10).toString()};
            --color4-lighten15: ${tinycolor("#6a6c6f").lighten(15).toString()};
            --color4-lighten20: ${tinycolor("#6a6c6f").lighten(20).toString()};
            --color4-lighten25: ${tinycolor("#6a6c6f").lighten(25).toString()};`
          }
          ${
            this.props.configLayout && this.props.configLayout.color5
              ? `--color5: ${"#" + this.props.configLayout.color5};
            --color5-darken4: ${tinycolor("#" + this.props.configLayout.color5)
              .darken(4)
              .toString()};
            --color5-darken3: ${tinycolor("#" + this.props.configLayout.color5)
              .darken(3)
              .toString()};
            --color5-lighten3: ${tinycolor("#" + this.props.configLayout.color5)
              .lighten(3)
              .toString()};
            --color5-lighten2: ${tinycolor("#" + this.props.configLayout.color5)
              .lighten(2)
              .toString()};`
              : `--color5-darken4: ${tinycolor("#f1f3f6").darken(4).toString()};
            --color5-darken3: ${tinycolor("#f1f3f6").darken(3).toString()};
            --color5-lighten3: ${tinycolor("#f1f3f6").lighten(3).toString()};
            --color5-lighten2: ${tinycolor("#f1f3f6").lighten(2).toString()};`
          }
          ${this.props.configLayout && this.props.configLayout.orientacaoMenu && this.props.configLayout.orientacaoMenu === "1" && `--width0: 0px`}

        }
      `;
      return (
        <div className="notranslate">
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />
          <IdleTimeOutModal showModal={this.state.showModal} handleClose={this.handleClose} handleLogout={this.handleLogout} />

          <PrintProvider>
            <NoPrint>
              <ReduxToastr timeOut={10000} newestOnTop={false} preventDuplicates transitionIn="bounceInDown" transitionOut="bounceOutUp" progressBar />

              <ConfirmOperation />
              <ConfirmAction />
              <ConfirmNotHomologate />
              <ConfirmChangePassword />
              <UploadArchive />
              <Video />
              <TermsOfUse />
              <ConfirmGerenciarAutenticacao />
              <GlobalStyles />

              <Header
                logout={this.logout.bind(this)}
                currentUser={this.props.currentUser}
                video={this.props.onOpen}
                header={this.props.configLayout && this.props.configLayout.header ? this.props.configLayout.header : null}
                orientacaoMenu={this.props.configLayout && this.props.configLayout.orientacaoMenu ? this.props.configLayout.orientacaoMenu : null}
              />
              {(this.props.configLayout === null || (this.props.configLayout && this.props.configLayout.orientacaoMenu && this.props.configLayout.orientacaoMenu === "0")) && (
                <Menu currentUser={this.props.currentUser} />
              )}
              <div id="wrapper">
                {this.props.children}
                <Footer
                  copyright={this.props.configLayout && this.props.configLayout.copyright ? this.props.configLayout.copyright : null}
                  footer={this.props.configLayout && this.props.configLayout.footer ? this.props.configLayout.footer : null}
                />
              </div>
            </NoPrint>
          </PrintProvider>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = (dispatch) => ({
  onRedirect: () => dispatch({ type: REDIRECTED }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onUfLoad: (payload) => dispatch({ type: UF_LOAD, payload }),
  onLoad: (payload, token) => dispatch({ type: APP_LOADED, payload, token }),
  onOpen: (payload) => dispatch({ type: VIDEO_OPEN, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: AUTHORIZED_COMPANIES_LOAD, payload }),
  onTermsOfUseOpen: (payload) => dispatch({ type: CONFIRM_TERMS_OF_USE_OPEN, payload }),
  onChangePasswordOpen: (payload) => dispatch({ type: CONFIRM_CHANGE_PASSWORD_OPEN, payload }),
  onConfigLoad: (payload) => dispatch({ type: CONFIG_LOAD, payload }),
  onLoadStatusDentrans: (payload) => dispatch({ type: SERVICE_STATUS_DETRANS_LOAD, payload }),
});

App.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
