import api from '../api/apiUtil';
import {LOGOUT, LOGIN, TOKEN_REFRESH, APP_LOADED} from '../actions/Constants';


const localStorageMiddleware = store => next => action => {
  if (action.type === LOGIN || action.type === TOKEN_REFRESH) {
    console.log('LocalStorageMiddleware => ', action.type);
    if (!action.error) {

      console.log('-----=-=-==-=-=-=-=-=-=-');
      console.log(action.payload);

      window.localStorage.setItem('jwt', action.payload.id_token);
      api.setToken(action.payload.id_token);

      /*
      window.localStorage.setItem('refreshToken', action.payload.refresh_token);
      api.setRefreshToken(action.payload.refresh_token);
      */

      action.payload = {...action.payload, user: api.decodeUserProfile(action.payload.id_token)};
    }
  } else if (action.type === LOGOUT) {
    console.log('LocalStorageMiddleware => ', action.type);
    window.localStorage.setItem('jwt', '');
    api.setToken(null);
  }

  next(action);
};



export { localStorageMiddleware }
