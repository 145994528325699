import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, reset, change, untouch } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";

import { CONTRACT_LOT_LIST_LOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";

import fileDownload from "js-file-download";
import Dropzone from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { BeatLoader } from "react-spinners";

class ContractLot extends React.Component {
  componentWillMount() {
    this.props.onListLoad(api.Contracts.getLots());
  }

  handleClickDownloadIn = (id) => (ev) => {
    var props = this.props;
    ev.preventDefault();
    Promise.resolve(api.Contracts.getLotIn(id))
      .then((response) => {
        fileDownload(response, "entrada.txt");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickDownloadOut = (id) => (ev) => {
    var props = this.props;
    ev.preventDefault();
    Promise.resolve(api.Contracts.getLotOut(id))
      .then((response) => {
        fileDownload(response, "saida.txt");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.sendLot(filesToUpload[0]))
      .then((response) => {
        this.props.onListLoad(api.Contracts.getLots());
        toastr.removeByType("info");
        toastr.success("Sucesso", "Arquivo processado.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.removeByType("info");
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-default btn-sm" onClick={browserHistory.goBack}>
                      <i className="fas fa-arrow-left"></i> Voltar
                    </Link>
                    {this.props.all && (
                      <Dropzone name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                        <button type="button" className="btn btn-primary btn-sm">
                          <i className="fas fa-upload" /> Enviar arquivo de lote
                        </button>
                      </Dropzone>
                    )}
                  </div>
                  <p>Lotes enviados</p>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Data</th>
                          <th className="text-center align-middle">Usuário</th>
                          <th className="text-center align-middle">Entrada</th>
                          <th className="text-center align-middle">Saída</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderLotList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLotList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATETIME(r.data)}</td>
          <td className="text-center align-middle">{r.nome}</td>
          <td className="text-center align-middle">
            <button type="button" className="btn btn-primary btn-sm" onClick={this.handleClickDownloadIn(r.id)}>
              <i className="fas fa-file-alt"></i> Download
            </button>
          </td>
          <td className="text-center align-middle">
            <button type="button" className="btn btn-primary btn-sm" onClick={this.handleClickDownloadOut(r.id)}>
              <i className="fas fa-file-alt"></i> Download
            </button>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: CONTRACT_LOT_LIST_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.contractLot,
});

const form = reduxForm({
  form: "ContractLotForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ContractLot));
