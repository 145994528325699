import {ASYNC_START, ASYNC_END, LOGOUT } from '../actions/Constants';

const promiseMiddleware = store => next => action => {
  console.log('promiseMiddleware:',isPromise(action.payload));
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    action.payload.then(
      res => {
        const currentState = store.getState()

        console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        console.log('promiseMiddleware: ERROR => ',error.message);
        console.log('promiseMiddleware: ERROR => ',error);

        if (error.status === 500) {
          console.log('Registrar erro na API');
        }

        if (error.status === 401) { //Verificar 498 ?
          console.log('Refresh no token');
        }

        const currentState = store.getState()

        if (error.response && error.response.text === '{"message":"jwt expired"}') {
          store.dispatch({ type: LOGOUT });
        }

        action.error = true;
        if (error.response) {
          action.payload = error.response.body;
        } else {
          action.payload = error.message;
        }

        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export { promiseMiddleware }
