import React from "react";
import { Link } from 'react-router';

// Not found page component
export default class NotFound extends React.Component {
  // render
  render() {
    return (
      <div>
      <div className="color-line-ereg"></div>

<div className="back-link">
    <Link to="/secure" className="btn btn-primary">Tela Inicial</Link>
</div>
<div className="error-container">
    <i className="pe-7s-way text-success big-icon"></i>
    <h1>404</h1>
    <strong>Página Não Encontrada</strong>
    <p>
        Não conseguimos encontrar a página que você procurava.
    </p>
    <Link to="/secure" className="btn btn-xs btn-success">Voltar à tela inicial</Link>
</div>
</div>
    );
  }
}
