import {
  HOME_CONTRACTS_LOAD,
  HOME_UNLOAD,
  HOME_CONTRACTS_ERRORS_LOAD,
  HOME_IMAGES_STATUS_LOAD,
  HOME_DASHBOARD_LIST_LOAD,
  HOME_CONTRACTS_EXPIRED_LOAD,
  HOME_CONTRACTS_EXPIRED_7_LOAD,
  HOME_CONTRACTS_EXPIRED_15_LOAD,
  HOME_CONTRACTS_EXPIRED_30_LOAD,
  HOME_CONTRACTS_NOT_REGISTRED_LOAD,
  HOME_CONTRACTS_REGISTRED_ERROR_LOAD,
  HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD,
  HOME_CONTRACTS_REGISTRED_LOAD,
  HOME_CONTRACTS_CANCEL_LOAD,
  HOME_DASHBOARD_SELECTED_MONTH_LOAD,
  HOME_DASHBOARD_COMPANIES_ID_LOAD,
  HOME_NEWS_LOAD,
  HOME_MONIT_EXPIRED_LOAD,
} from "../../actions/Constants";

const INITIAL_STATE = {
  contracts: [],
  errors: 0,
  contractsQuantity: 0,
  imagesPercent: 0,
  homeChart: {},
  contractsExpired: {},
  contractsExpired7: {},
  contractsExpired15: {},
  contractsExpired30: {},
  contractsNotRegistred: 0,
  contractsRegistredError: 0,
  contractsRegistredWithoutImage: 0,
  contractsRegistred: 0,
  contractsCancel: 0,
  selectedMonth: 2,
};

export default function home(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HOME_CONTRACTS_LOAD:
      return {
        ...state,
        contracts: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_ERRORS_LOAD:
      return {
        ...state,
        errors: action.payload ? action.payload : null,
      };
    case HOME_IMAGES_STATUS_LOAD:
      return {
        ...state,
        contractsQuantity: action.payload ? action.payload[0] : null,
        imagesPercent: action.payload ? action.payload[1] : null,
        imagesQuantity: action.payload ? action.payload[2] : null,
      };
    case HOME_DASHBOARD_LIST_LOAD:
      return {
        ...state,
        homeChart: action.payload ? action.payload : null,
      };
    case HOME_DASHBOARD_SELECTED_MONTH_LOAD:
      return {
        ...state,
        selectedMonth: action.payload || action.payload === 0 ? action.payload : null,
      };
    case HOME_DASHBOARD_COMPANIES_ID_LOAD:
      return {
        ...state,
        finsId: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_EXPIRED_LOAD:
      return {
        ...state,
        contractsExpired: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_EXPIRED_7_LOAD:
      return {
        ...state,
        contractsExpired7: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_EXPIRED_15_LOAD:
      return {
        ...state,
        contractsExpired15: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_EXPIRED_30_LOAD:
      return {
        ...state,
        contractsExpired30: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_NOT_REGISTRED_LOAD:
      return {
        ...state,
        contractsNotRegistred: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_REGISTRED_ERROR_LOAD:
      return {
        ...state,
        contractsRegistredError: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD:
      return {
        ...state,
        contractsRegistredWithoutImage: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_REGISTRED_LOAD:
      return {
        ...state,
        contractsRegistred: action.payload ? action.payload : null,
      };
    case HOME_CONTRACTS_CANCEL_LOAD:
      return {
        ...state,
        contractsCancel: action.payload ? action.payload : null,
      };
    case HOME_NEWS_LOAD:
      return {
        ...state,
        news: action.payload ? action.payload : null,
      };
    case HOME_MONIT_EXPIRED_LOAD:
      return {
        ...state,
        qtdExpiredMonit: action.payload ? action.payload : null,
      };
    case HOME_UNLOAD:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}
