import {UF_LOAD} from '../actions/Constants';

const INITIAL_STATE = { ufs: []};

export default function uf(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UF_LOAD:
      return {
        ...state,
        ufs: action.payload ? action.payload : null,
      };

    default:
      return state;
  }
}
