import React, { Component, } from 'react'; 
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-bootstrap4-modal';
import {toastr} from 'react-redux-toastr'
import { CONFIRM_CHANGE_PASSWORD_OPEN, CONFIRM_TERMS_OF_USE_CLOSE, LOGOUT } from '../../actions/Constants';
import api from '../../api/apiUtil';

class TermsOfUse extends Component { 

  componentWillUnmount() {
    this.props.onClose();
  }

  handleOnLogoutClick(event) {
    event.preventDefault();
    api.Auth.logout();
    this.props.onLogout();
  };

  formSubmit(values) {
    var props = this.props;
    Promise.resolve(api.Users.confirmTermsOfUse()).then(auth => {
      this.props.reset();
      this.props.onClose();
      toastr.success('Sucesso','Termo aceito.');
      var userProfile = api.loadUserProfile();
      Promise.resolve(api.Users.get(userProfile.id)).then(e => {
        if (e.primeiroAcesso === 0) {
          this.props.onChangePasswordOpen();
        }
      })
    }).catch(function(resp) {
      if (resp.message === 'Unauthorized' || resp.message === 'jwt expired') {
        api.Auth.logout();
        props.onLogout();
      }else{
        toastr.warning('Atenção','Senha inválida!');
      }        
    });

  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit} = this.props;

    return (
      <Modal visible={this.props.open} dialogClassName="modal-max-width">
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
        <div className="modal-header">
          <div>
            <h6><strong>TERMO DE ADESÃO E USO AO SISTEMA e-REGISTRO – REGISTRO DE CONTRATO.</strong></h6>
          </div>
        </div>
        <div>
          <div className="modal-body">
            <h6><strong>1. DAS DEFINIÇÕES</strong></h6>
            <h6>1.1. São aplicáveis as seguintes definições:</h6>
            <h6>(I) AUTO-ATENDIMENTO: utilização de facilidades e serviços do sistema, sem a necessidade de falar diretamente com atendentes ou telefonistas;</h6>
            <h6>(II) CENTRAL DE ATENDIMENTO: canal de comunicação entre o USUÁRIO e a PRESTADORA DE SERVIÇO, no qual o USUÁRIO poderá falar com atendentes do sistema para retirar dúvidas, obter informações e registrar reclamações;</h6>
            <h6>(III) SISTEMA E-REGISTRO: Sistema para Registro Eletrônico de Contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor;</h6>
            <h6>(IV) USUÁRIO: pessoa física cadastrado como operador do SISTEMA E-REGISTRO, por meio de seus dados pessoais, conforme autorização do AGENTE FINANCEIRO;</h6>
            <h6>(V) DISTRIBUIDORA: ALIAS TECNOLOGIA S/A, responsável pela intermediação de pagamentos do estacionamento rotativo entre o usuário e o órgão responsável, com sua matriz localizada na Rua João Negrão, 731, Sala 1107 - Centro, Curitiba/PR, CEP 80.010-200, inscrita no CNPJ nº 00.745.812/0001-66.</h6>
            <h6>(VI) CADASTRO: formulário eletrônico próprio da CONTRATADA, preenchido pelo USUÁRIO MASTER, pessoa física ou jurídica, contendo os seus dados cadastrais;</h6>
            <h6>(VII) SENHA: assinatura por meio eletrônico, formada por caracteres alfa numéricos, criada automaticamente pelo SISTEMA E-REGISTRO mediante o CADASTRO, e modificada a qualquer momento unicamente pelo USUÁRIO;</h6>
            <h6>(VIII) TERMO DE ADESÃO E USO: o presente instrumento;</h6>
            <h6>(IX) REGISTRO: toda e qualquer inclusão de informações de contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor para envio ao órgão competente;</h6>
            <h6>(X) SERVIÇOS: disponibilização de meio eletrônico para registro de contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor junto ao órgão competente;</h6>
            <h6>(XI) USUÁRIO MASTER: pessoa física cadastrado como operador do SISTEMA E-REGISTRO, por meio de seus dados pessoais, indicado como responsável do processo pelo AGENTE FINANCEIRO;</h6>
            <h6>(XII) AGENTE FINANCEIRO ou CONTRATANTE: pessoa jurídica aderente ao SISTEMA E-REGISTRO para tratamento de dados de pessoas físicas ou jurídicas, dos quais é o controlador e responsável pelas informações;</h6>
            <h6>(XIII) CONTRATO ou TERMO DE CONCESSÃO: Documento contratual assinado pela DISTRIBUIDORA e AGENTE FINANCEIRO ou CONTRATANTE que rege a relação comercial de acordo com o Estado de atuação;</h6>
            <h6>(XIV) ORGÃO COMPETENTE: Departamento de Trânsito do Estado pactuado no CONTRATO ou TERMO DE CONCESSÃO.</h6><br/>
            <h6>1.2. As definições e disposições deste TERMO DE ADESÃO E USO se aplicam às palavras e expressões no singular ou no plural.</h6><br/>
            <h6><strong>2. DA ADESÃO E USO DO SISTEMA E-REGISTRO: Sistema para Registro Eletrônico de Contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor.</strong></h6>
            <h6>2.1 A DISTRIBUIDORA fornece ao CONTRATANTE e seus USUÁRIOS, sujeito aos Termos de Uso abaixo, que podem ser modificados a qualquer momento, a prestação de serviços para o SISTEMA E-REGISTRO, tudo disponível por meio eletrônico de transmissão de dados via Internet (a distância).</h6>
            <h6>2.2 Os SERVIÇOS poderão ser modificados ou extintos a qualquer momento. Em qualquer hipótese de modificação ou extinção dos SERVIÇOS, o USUÁRIO será devidamente informado deste ato quando acessar o site de Relacionamento do Sistema, o que obriga o USUÁRIO a rever esse TERMO DE ADESÃO E USO de tempos em tempos, restando claro que o USUÁRIO subordina-se à aceitação do TERMO DE ADESÃO E USO vigente no momento de seu acesso ou faz uso dos serviços disponibilizados.</h6>
            <h6>2.3 Após clicar no botão "Li e concordo com os Termos de adesão e uso”, o USUÁRIO declara que leu e concordou expressamente com a versão mais recente do TERMO DE ADESÃO E USO, fato este que vinculará automaticamente o USUÁRIO às regras aqui contidas e qualquer de suas alterações futuras.</h6><br/>
            <h6><strong>3. DESCRIÇÃO DO SERVIÇO</strong></h6>
            <h6>3.1 Os serviços compreendem a licença de uso de solução informatizada denominada e-Registro – Registro de Contrato para a execução dos serviços de Registro Eletrônico dos Contratos de Financiamento de Veículos Automotores com cláusula de alienação fiduciária, de arrendamento mercantil, de compra e venda com reserva de domínio ou de penhor, bem como os serviços de treinamento, suporte e manutenção;</h6>
            <h6>3.2 Os SERVIÇOS são disponibilizados para o USUÁRIO por meio de endereço eletrônico de transmissão de dados via Internet (a distância).</h6>
            <h6>3.3 Os SERVIÇOS e condições aqui discriminados são para uso profissional do CONTRATANTE, o USUÁRIO não poderá modificar copiar, distribuir, transmitir, exibir, executar, reproduzir, publicar, licenciar, criar trabalhos derivados, transferir ou vender as informações, programas de computador (software), produtos ou serviços obtidos deste acordo.</h6>
            <h6>3.4 O USUÁRIO fica desde logo informado e concorda que os SERVIÇOS estarão disponíveis 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, mas que por motivos alheios à vontade da DISTRIBUIDORA, bem como em virtude da manutenção dos servidores, os SERVIÇOS poderão não estar disponíveis para as consultas e inclusões a todo momento.</h6>
            <h6>3.5 A DISTRIBUIDORA somente fornecerá assistência e suporte técnico com relação aos SERVIÇOS cobrados e através do CONTATO e/ou da CENTRAL DE ATENDIMENTO.</h6>
            <h6>3.6 Através do Sistema o USUÁRIO poderá realizar as seguintes operações:</h6>
            <h6>I – Registro de Contratos e Averbações de Contratos;</h6>
            <h6>II – Inclusão da imagem dos contratos registrados;</h6>
            <h6>III – Consulta da situação dos registros efetuados pelo Sistema;</h6>
            <h6>IV – Consulta de relatórios gerenciais e financeiros dos registros realizados pelo Sistema;</h6>
            <h6>3.7 Não caberá a DISTRIBUIDORA, em nenhuma hipótese, responsabilidade sobre as informações imputadas pelo CONTRATANTE e seus USUÁRIOS no SISTEMA E-REGISTRO.</h6>
            <h6>3.8 O CONTRATANTE e seus USUÁRIOS estão sujeitos à legislação de Registro Eletrônico de Contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor nacional e estadual, bem como sujeito às penalidades estabelecidas.</h6>
            <h6>3.9 Os SERVIÇOS descritos neste acordo devem ser acessados por meio eletrônico na modalidade de comunicação de dados. O USUÁRIO concorda e está ciente de que a utilização desta modalidade de comunicação depende exclusivamente de seu plano de telefonia, contratado de uma operadora de telefonia. Sendo assim, este SERVIÇO não se responsabiliza por quaisquer impedimentos, custos, tarifas, bloqueios ou demais circunstâncias que possam afetar ou estar relacionada com o acesso ao SERVIÇO provido nesta modalidade de comunicação e contratação.</h6><br/>
            <h6><strong>4. OBRIGAÇÕES DE REGISTRO DO USUÁRIO</strong></h6>
            <h6>4.1 Com vistas à utilização dos SERVIÇOS, de acordo com este TERMO DE ADESÃO E USO, o USUÁRIO se obriga a fornecer, quando do seu cadastro, informações pessoais verdadeiras, no diretório eletrônico do site da DISTRIBUIDORA/PRESTADORA DE SERVIÇO ou do Sistema, sendo que o mesmo USUÁRIO desde logo autoriza a manutenção destas informações em banco de dados da DISTRIBUIDORA.</h6>
            <h6>4.2 O USUÁRIO CADASTRADO autoriza a DISTRIBUIDORA a utilizar os dados coletados para os seguintes propósitos: (i) Identificação e comunicação com o USUÁRIO CADASTRADO por ligação telefônica, correio eletrônico, mala direta, mensagem eletrônica (SMS) e/ou outros meios de comunicação, sempre que for necessário para fins da prestação do serviço; (ii) otimizar a usabilidade e o acesso as informações pelos Usuários, durante o acesso e a navegação no SITE ; (iii) fazer pesquisas e elaborar estatísticas gerais sobre o desempenho do sistema; (iv) realizar campanhas de comunicação e orientação sobre o Sistema; (v) informar, mediante prévia autorização por parte do USUÁRIO CADASTRADO,  a respeito de novos serviços e produtos que possam ser acessados pelos mesmos, utilizando-se da plataforma de relacionamento com a DISTRIBUIDORA.</h6>
            <h6>4.3 O USUÁRIO que utilizar os SERVIÇOS ofertados pela DISTRIBUIDORA de forma indevida e/ou inadequada, ou ainda tentar burlar e/ou se beneficiar de alguma forma do SISTEMA E-REGISTRO, responderá cível e criminalmente pelos seus atos.</h6><br/>
            <h6><strong>5. DIREITO AUTORAL</strong></h6>
            <h6>5.1 O USUÁRIO reconhece e concorda que todo o conteúdo dos Sites de internet do presente serviço, são protegidos pela Lei de Direito Autoral (Lei 9.610/98), bem como por tratados internacionais aplicáveis a matéria. Nesse sentido, o USUÁRIO se compromete a respeitar tais direitos, obrigando-se a utilizar os SERVIÇOS apenas para fins de uso profissional, SENDO EXPRESSAMENTE VEDADA A UTILIZAÇÃO DOS SERVIÇOS PARA QUAISQUER OUTROS FINS, INCLUSIVE COMERCIAIS, SEM PRÉVIA E EXPRESSA AUTORIZAÇÃO DA DISTRIBUIDORA.</h6><br/>
            <h6><strong>6. VIGÊNCIA E RESCISÃO</strong></h6>
            <h6>6.1 O SERVIÇO terá início na data de sua adesão pelo USUÁRIO, e vigorará pelo período acordado no CONTRATO ou TERMO DE CONCESSÃO.</h6>
            <h6>6.2 O presente SERVIÇO está sujeito às autorizações, disponibilidade e regras do ORGÃO COMPETENTE, e a seu critério poderá ser encerrado, independentemente de qualquer tipo de comunicação prévia ao USUÁRIO.</h6><br/>
            <h6><strong>7. RESPONSABILIDADE</strong></h6>
            <h6>7.1 A DISTRIBUIDORA não poderá ser responsabilizada por qualquer falha ou não funcionamento dos serviços provenientes de terceiros ou decorrentes de caso fortuito ou força maior.</h6>
            <h6>7.2 A DISTRIBUIDORA é responsável pela transmissão de dados para registro eletrônico de Contratos com cláusula de Alienação Fiduciária em operações financeiras, consórcio, Arrendamento Mercantil, Reserva de Domínio ou Penhor para o órgão competente.</h6><br/>
            <h6><strong>8. DISPOSIÇÕES GERAIS</strong></h6>
            <h6>8.1 A não exigência, por qualquer uma das partes, do cumprimento de qualquer cláusula ou condição estabelecida, será considerada mera tolerância, não implicando na sua novação, e tão pouco na abdicação do direito de exigi-la no futuro, não afetando a validade deste instrumento e quaisquer de suas condições.</h6>
            <h6>8.2 Na hipótese de qualquer uma das disposições deste contrato vir a ser considerada contrária à lei brasileira, por qualquer autoridade governamental ou decisão judicial, as demais disposições não afetadas continuarão em vigor e as partes deverão alterar este instrumento de forma a adequá-lo a tal lei ou à decisão judicial.</h6><br/>
            <h6><strong>9. DO FORO</strong></h6>
            <h6>9.1 As partes elegem o foro da Comarca da Cidade de Curitiba, Estado do Paraná, para dirimir eventuais dúvidas ou controvérsias decorrentes do presente Contrato, excluindo-se qualquer outro, por mais privilegiado que seja.</h6>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-success">Li e concordo com os Termos de adesão e uso.</button>
          <button type="button" className="btn btn-secondary" onClick={this.handleOnLogoutClick.bind(this)} >Cancelar</button>
        </div>
        </form>
      </Modal>
    )

  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.termsOfUse,
});

const mapDispatchToProps = dispatch => ({
  onClose: payload => dispatch({ type: CONFIRM_TERMS_OF_USE_CLOSE }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onChangePasswordOpen: payload => dispatch({ type: CONFIRM_CHANGE_PASSWORD_OPEN, payload }),
});

const form = reduxForm({
  form: 'TermsOfUseForm'
});

export default connect(mapStateToProps, mapDispatchToProps)(form(TermsOfUse));
