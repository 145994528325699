import { DUDA_LOAD, DUDA_UNLOAD, DUDA_FILES_TO_SEND, DUDA_FILE_SENT, DUDA_LOT_CLOSED } from "../../actions/Constants";
const INITIAL_STATE = { all: [], files: [], sentFiles: 0, qttFilesToSend: 0 };

export default function duda(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DUDA_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : [],
      };
    case DUDA_FILES_TO_SEND:
      return {
        ...state,
        qttFilesToSend: action.payload[0],
        files: action.payload[1],
      };
    case DUDA_FILE_SENT:
      return {
        ...state,
        sentFiles: state.sentFiles + 1,
      };
    case DUDA_LOT_CLOSED:
      return {
        files: [],
        sentFiles: 0,
        qttFilesToSend: 0,
      };
    case DUDA_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
