import React, { Component, } from 'react';
import Modal from 'react-bootstrap4-modal';
import { VIDEO_CLOSE } from '../../actions/Constants';
import { connect } from 'react-redux';
import { Player, BigPlayButton } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";

const helpVideo = require('./video/HelpVideo.mp4');
// const helpVideoImage = require('./img/HelpVideoImage.png');

class Video extends Component {

  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
  }

  render() {
    return (
      <div>
      {this.props.open ?
      (
        <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
          <Player playsInline autoPlay
             src={helpVideo} aspectRatio="4:3" muted="true">
          <BigPlayButton position="center" />
          </Player>
        </Modal>
      ) : (null)
      }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.video,
});

const mapDispatchToProps = dispatch => ({
  onClose: payload => dispatch({ type: VIDEO_CLOSE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
