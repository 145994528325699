import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import common from "./Common";
import uf from "./Uf";

import login from "../containers/Login/Reducer";
import confirmOperation from "../containers/Confirm/Reducer";
import video from "../containers/Video/Reducer";
import confirmAction from "../containers/ConfirmAction/Reducer";
import confirmNotHomologate from "../containers/ConfirmNotHomologate/Reducer";
import confirmChangePassword from "../containers/ConfirmChangePassword/Reducer";
import confirmGerenciarAutenticacao from "../containers/ConfirmGerenciarAutenticacao/Reducer";
import uploadArchive from "../containers/UploadArchive/Reducer";
import contract from "../containers/Contract/Reducer";
import contractList from "../containers/ContractList/Reducer";
import users from "../containers/User/Reducer";
import profile from "../containers/Profile/Reducer";
import home from "../containers/Home/Reducer";
import invoice from "../containers/Invoice/Reducer";
import paymentSlipModal from "../containers/PaymentSlip/Reducer";
import filesUpload from "../containers/FilesUpload/Reducer";
import chargingReport from "../containers/Charging/Reducer";
import managementReport from "../containers/ManagementReport/Reducer";
import managementReportError from "../containers/ManagementReportError/Reducer";
import financialShipping from "../containers/FinancialShipping/Reducer";
import contractLot from "../containers/ContractLot/Reducer";
import financialDashboard from "../containers/FinancialDashboard/Reducer";
import news from "../containers/News/Reducer";
import termsOfUse from "../containers/TermsOfUse/Reducer";
import financialProfile from "../containers/FinancialProfile/Reducer";

import aprove from "../containers/Aprove/Reducer";
import financial from "../containers/Financial/Reducer";

import supervision from "../containers/Supervision/Reducer";

import taxasDetran from "../containers/TaxasDetran/Reducer";
import loteRJ from "../containers/LoteRJ/Reducer";
import consultaChassiPR from "../containers/ConsultaChassiPR/Reducer";
import financeiro from "../containers/Financeiro/Reducer";
import cargaBNDES from "../containers/CargaBNDES/Reducer";
import consultaConfia from "../containers/Confia/Reducer";
import configuracao from "../containers/Configuracao/Reducer";
import lote from "../containers/Lote/Reducer";
import valorDetranUf from "../containers/ValorDetranUf/Reducer";
import suporte from "../containers/Suporte/Reducer";
import statusDetran from "../containers/Detran/Reducer";
import searchUserList from "../containers/FinancialUserList/Reducer";
import financeiroPE from "../containers/FinanceiroPE/Reducer";
import duda from "../containers/Duda/Reducer";
import relatorioDetranMA from "../containers/RelatorioDetranMA/Reducer";
import integration from "../containers/Integration/Reducer";
import loteSNG from "../containers/LoteSNG/Reducer";
import configuracaoSng from "../containers/ConfiguracaoSng/Reducer";
import consultaSNG from "../containers/ConsultaSNG/Reducer";
import userAlias from "../containers/UserAlias/Reducer";
import spreadsheet from "../containers/Spreadsheet/Reducer";
import configuracaoUf from "../containers/ConfiguracaoUf/Reducer";
import erroDetranEmail from "../containers/ErroDetranEmail/Reducer";
import aliasProfile from "../containers/AliasProfile/Reducer";

/*PR*/
import financialReportPR from "../containers/Financial/PR/Reducer";

/*SC*/
import financialReportSC from "../containers/Financial/SC/Reducer";

// main reducers
export const reducers = combineReducers({
  routing: routerReducer,
  form: formReducer,
  toastr: toastrReducer,
  // your reducer here
  common,
  uf,
  login,
  uploadArchive,
  confirmGerenciarAutenticacao,
  confirmChangePassword,
  confirmNotHomologate,
  confirmOperation,
  confirmAction,
  video,
  contract,
  users,
  profile,
  home,
  invoice,
  aprove,
  financial,
  paymentSlipModal,
  supervision,
  filesUpload,
  chargingReport,
  managementReport,
  managementReportError,
  financialShipping,
  financialDashboard,
  contractLot,
  news,
  termsOfUse,
  financialProfile,
  contractList,
  taxasDetran,
  loteRJ,
  consultaChassiPR,
  financeiro,
  cargaBNDES,
  consultaConfia,
  configuracao,
  lote,
  valorDetranUf,
  suporte,
  statusDetran,
  searchUserList,
  financeiroPE,
  duda,
  relatorioDetranMA,
  integration,
  //PR
  financialReportPR,
  loteSNG,
  configuracaoSng,
  //shouldComponentUpdate: function(nextProps, nextState) {
  financialReportSC,
  consultaSNG,
  userAlias,
  spreadsheet,
  configuracaoUf,
  erroDetranEmail,
  aliasProfile,
});
