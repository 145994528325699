import { CONTRACT_REGISTER_PR_LOAD, CONTRACT_REGISTER_PR_UNLOAD, UF_SYSTEM_LOAD} from '../../actions/Constants';

const INITIAL_STATE = { all: null, ufSystem: []};

export default function consultaChassiPR(state = INITIAL_STATE, action) {
    switch (action.type) {    
        case CONTRACT_REGISTER_PR_LOAD:
            return {
                ...state,
                all: action.payload ? action.payload : null,
            };
        case UF_SYSTEM_LOAD:
            return {
                ...state, 
                ufSystem: action.payload ? action.payload : []
            }
        case CONTRACT_REGISTER_PR_UNLOAD:
            return INITIAL_STATE;    
        default:
        return state;
  }
}
