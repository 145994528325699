import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field } from "redux-form";
import ComboboxField from "../../components/Fields/ComboboxField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import SelectField from "../../components/Fields/SelectField";

import { UF_LOAD } from "../../actions/Constants";
import api from "../../api/apiUtil";

class UfField extends Component {
  componentWillMount() {}

  render() {
    const { label, options, input, rest } = this.props;
    if (this.props.ufs) {
      return (
        <Field
          name={this.props.name}
          label={this.props.label ? this.props.label : "UF"}
          component={SelectField}
          disabled={this.props.disabled}
          data={[{ sigla: "", nome: "" }].concat(this.props.ufs)}
          textField="sigla"
          valueField="sigla"
          required={this.props.required}
          normalize={FieldNormalize.UPPERCASE}
          type="text"
          description={this.props.description ? this.props.description : null}
          validate={this.props.validate ? this.props.validate : []}
          onBlur={this.props.onBlur}
          itemComponent={({ item }) => <span style={{ padding: "12px 16px" }}>{item.sigla}</span>}
        />
      );
    } else {
      return null;
    }
  }
}

UfField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  validate: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state.uf,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: UF_LOAD, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UfField);
