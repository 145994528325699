import {FILES_UPLOAD_LOAD, FILES_UPLOAD_UNLOAD, FILES_TO_SEND, LOT_CREATED, FILE_SENT, LOT_CLOSED, ALL_LOTE_USER_LOAD, ALL_LOTE_USER_UNLOAD} from '../../actions/Constants';

const INITIAL_STATE = { files: [], sentFiles: 0, qttFilesToSend: 0, lotId: 0, all: []};

export default function filesUpload(state = INITIAL_STATE, action) {
  switch (action.type) {

    case FILES_UPLOAD_LOAD:
      return INITIAL_STATE;
    case FILES_UPLOAD_UNLOAD:
      return INITIAL_STATE;
    case ALL_LOTE_USER_UNLOAD:
      return INITIAL_STATE;
    case FILES_TO_SEND:
      return {
        ...state,
        qttFilesToSend: action.payload[0],
        files: action.payload[1],
      }
    case LOT_CREATED:
      return {
        ...state,
        lotId: action.payload,
      }
    case FILE_SENT:
      return {
        ...state,
        sentFiles: state.sentFiles + 1,
      }
    case LOT_CLOSED:
      return INITIAL_STATE;
    case ALL_LOTE_USER_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };

    default:
      return state;
  }
}
