import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router";

import ReactTable from "react-table";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import { toastr } from "react-redux-toastr";

import Title from "../../components/Layout/Title";
import { SUPERVISION_COMPANIES_LOAD, SUPERVISION_REGISTERED_LOAD, SUPERVISION_UNLOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";
import { BeatLoader } from "react-spinners";

const columns = [
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        <small>{FieldNormalize.CNPJ(row.original.cnpj)}</small>
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.devNome}
        <br />
        {row.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Placa",
    accessor: "row",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.ufPlaca.split(",").map((item, index) => {
          return (
            <span>
              {item} {row.original.numPlaca.split(",")[index]}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="btn-group-vertical">
        <Link className="btn btn-sm btn-default" to={`/sdetran/contract/view/${row.original.id}`}>
          <i className="fa fa-info-circle"></i> Detalhes
        </Link>
      </div>
    ),
  },
];

class Supervision extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pesquisando: false,
    };
  }

  componentWillMount() {
    Promise.resolve(api.Companies.all()).then((companies) => {
      this.props.onCompaniesLoad(companies);
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  filterR(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });

    let params = [
      "3,7",
      values.registro,
      values.numero,
      values.devCpfCnpj,
      values.renavam,
      values.chassi,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "0",
      this.props.currentUser.ufs[1].sigla,
    ];
    let promises = this.props.companies.map(function (obj) {
      return api.Contracts.getRegistered(obj.cnpj, ...Object.values(params));
    });

    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      this.props.onRegisteredLoad(contratos);
      if (contratos.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  }

  renderRegistered() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.registered}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columns}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Lista de contratos" />{" "}
        <div className="content">
          <div className="row" style={{ marginRight: "-40px", marginLeft: "-40px" }}>
            <div className="col-md-3" style={{ paddingRight: "10px", paddingLeft: "10px" }}>
              <div className="hpanel">
                <div className="panel-body">
                  <div className="m-b-md">
                    <h4>Filtros</h4>
                    <small>Pesquise pelos filtros abaixo.</small>
                  </div>
                  <form>
                    <div className="form-group">
                      <Field name="registro" label="Número de registro" component={TextField} type="text" inline={false} />
                      <Field name="numero" label="Número do contrato" component={TextField} type="text" inline={false} />
                      <Field name="renavam" label="Renavam" component={TextField} type="text" inline={false} />
                      <Field name="chassi" label="Chassi" validate={[FieldValidation.chassi]} component={TextField} type="text" inline={false} />
                    </div>
                  </form>

                  <button className="btn btn-success" type="button" onClick={handleSubmit(this.filterR.bind(this))}>
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-9" style={{ paddingRight: "10px", paddingLeft: "5px" }}>
              <div className="hpanel">
                <div className="panel-body">
                  <div className="stats-title float-left m-b-sm">
                    <h4>
                      Quantidade: <b>{this.props.registered ? this.props.registered.length : 0}</b>
                    </h4>
                  </div>
                  <div className="table-responsive">{this.renderRegistered()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onRegisteredLoad: (payload) => dispatch({ type: SUPERVISION_REGISTERED_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: SUPERVISION_UNLOAD }),
  onCompaniesLoad: (payload) => dispatch({ type: SUPERVISION_COMPANIES_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.supervision,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "SupervisionForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Supervision));
