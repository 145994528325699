import { STATUS_DETRAN_LOAD, STATUS_DETRAN_UNLOAD, STATUS_DETRAN_LOAD_AC } from '../../actions/Constants';

const INITIAL_STATE = { statusDetrans: [], statusDetranAC: [] };

export default function statusDetran(state = INITIAL_STATE, action) {
    switch (action.type) {
        case STATUS_DETRAN_LOAD:
            return {
                ...state,
                statusDetrans: action.payload ? action.payload : []
            }
        case STATUS_DETRAN_LOAD_AC:
            return {
                ...state,
                statusDetranAC: action.payload ? action.payload : []
            }
        case STATUS_DETRAN_UNLOAD:
            return INITIAL_STATE;
        default:
            return state;
    }
}
