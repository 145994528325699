import React from "react";
import Multiselect from "react-widgets/lib/Multiselect";

const messages = {
  emptyList: "Sem itens a serem exibidos.",
};

const MultiSelectField = ({ input, label, meta: { touched, error, valid }, valueField, inline, small, description, ...rest }) => (
  <div className={`form-group ${touched && !valid ? "has-error" : ""} ${inline ? "row" : ""}`}>
    {label ? <label className={`font-weight-bold ${inline ? "col-sm-2  col-form-label" : ""}`}>{label}</label> : null}
    <Multiselect
      {...input}
      className={`${small ? "form-control form-control-sm" : "form-control"}`}
      style={{ padding: "0rem 0rem", boxSizing: "content-box" }}
      onBlur={() => input.onBlur()}
      value={input.value || []}
      messages={messages}
      {...rest}
    />
    {description && <span className="help-block small">{description}</span>}
    <p className="text-danger">{touched ? error : ""}</p>
  </div>
);

export default MultiSelectField;
