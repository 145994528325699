import { CONFIG_LOAD, CONFIG_UNLOAD } from '../../actions/Constants';

const INITIAL_STATE = {config: null };

export default function configuracao(state = INITIAL_STATE, action) {
    switch (action.type) {                       
      case CONFIG_LOAD:
        return {
          ...state,
          config: action.payload ? action.payload : null
        }
      case CONFIG_UNLOAD:
        return INITIAL_STATE;
      default:
      return state;
  }
}
