import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import _ from "lodash";
import MenuHorizontal from "./MenuHorizontal";
import HeaderAlias from "./HeaderAlias";

class Header extends React.Component {
  render() {
    return (
      <>
        <HeaderAlias></HeaderAlias>
        <div id="header">
          <div className="color-line  " />
          <div id="logo" className="light-version" style={process.env.REACT_APP_LOGO_BACKGROUND_COLOR ? { backgroundColor: process.env.REACT_APP_LOGO_BACKGROUND_COLOR } : {}}>
            {process.env.REACT_APP_LOGO_URL ? (
              <img alt="" src={process.env.REACT_APP_LOGO_URL} className="mr-3 align-self-center mx-auto" style={{ maxHeight: "30px" }} />
            ) : (
              <span>{this.props.header && this.props.header !== null ? this.props.header : "E-Registro"}</span>
            )}
            {this.props.currentUser && this.props.currentUser.idFinanceira && (
              <spam style={{ lineHeight: "-0.5" }}>
                <br />
                Portal Financeira
              </spam>
            )}
            {_.findIndex(this.props.currentUser.perfis, function (perfil) {
              return perfil === "ALIAS";
            }) > -1 && (
              <spam style={{ lineHeight: "-0.5" }}>
                <br />
                Portal Administrativo
              </spam>
            )}
          </div>
          {this.props.orientacaoMenu && this.props.orientacaoMenu === "1" ? (
            <div>
              <nav class="navbar navbar-dark bg-dark">
                <MenuHorizontal />
                <span className="row float-right align-items-center">
                  {this.props.currentUser && <h6 className="font-bold m-t-sm m-r-md class-font-text">{this.props.currentUser.nome}</h6>}
                  <Link to="/secure/profile" class="class-font-text">
                    <i title="Perfil" className="far fa-user fa-2x m-t-sm m-r-md"></i>
                  </Link>
                  {process.env.REACT_APP_UF && process.env.REACT_APP_UF === "RJ" && (
                    <Link onClick={this.handleVideoClick.bind(this)}>
                      <i title="Ajuda" className="far fa-question-circle fa-2x m-t-sm m-r-md"></i>
                    </Link>
                  )}
                  <Link to={"https://www.aliasnet.com.br/wp-content/uploads/2020/08/Manual_Usuario_e-Registro.pdf"} target="_blank" class="class-font-text">
                    <i title="Acessar manual" className="far fa-file-alt fa-2x m-t-sm m-r-lg"></i>
                  </Link>
                  <Link onClick={this.handleLogoutClick.bind(this)} class="class-font-text">
                    <i title="Sair" className="fas fa-sign-out-alt fa-2x m-t-sm m-r-lg"></i>
                  </Link>
                </span>
              </nav>
            </div>
          ) : (
            <div className="row float-right align-items-center">
              {this.props.currentUser && <h6 className="font-bold m-t-sm m-r-md class-font-text">{this.props.currentUser.nome}</h6>}
              {/*{ (process.env.REACT_APP_HELP_URL && ((_.findIndex(this.props.currentUser.perfis, function(perfil) { return perfil === 'ADMIN'; }) > -1) || (_.findIndex(this.props.currentUser.perfis, function(perfil) { return perfil === 'OPER'; }) > -1)) ) ?
          <a target="_blank" href={process.env.REACT_APP_HELP_URL}>
              <i className="far fa-lightbulb fa-2x m-t-sm m-r-md" ></i>
          </a>
          :
          <div></div>
          }*/}
              <Link to="/secure/profile" class="class-font-text">
                <i title="Perfil" className="far fa-user fa-2x m-t-sm m-r-md"></i>
              </Link>
              {process.env.REACT_APP_UF && process.env.REACT_APP_UF === "RJ" && (
                <Link onClick={this.handleVideoClick.bind(this)} class="class-font-text">
                  <i title="Ajuda" className="far fa-question-circle fa-2x m-t-sm m-r-md"></i>
                </Link>
              )}
              <Link to={"https://www.aliasnet.com.br/wp-content/uploads/2020/08/Manual_Usuario_e-Registro.pdf"} target="_blank" class="class-font-text">
                <i title="Acessar manual" className="far fa-file-alt fa-2x m-t-sm m-r-lg"></i>
              </Link>
              <Link></Link>
              <Link onClick={this.handleLogoutClick.bind(this)} class="class-font-text">
                <i title="Sair" className="fas fa-sign-out-alt fa-2x m-t-sm m-r-lg"></i>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }

  handleLogoutClick(event) {
    event.preventDefault();
    this.props.logout();
  }

  handleVideoClick(event) {
    event.preventDefault();
    this.props.video();
  }
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  video: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  header: PropTypes.string.isRequired,
  orientacaoMenu: PropTypes.string.isRequired,
};

export default Header;
