import { SEARCH_USER_LIST_LOAD, SEARCH_USER_LIST_UNLOAD, SEARCH_USER_LIST_LOAD_PERFIS, SEARCH_USER_LIST_ALL_COMPANIES_LOAD, UF_SYSTEM_LOAD } from "../../actions/Constants";

const INITIAL_STATE = { all: [], roles: [], allCompanies: [], ufSystem: [] };

export default function searchUserList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_USER_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case SEARCH_USER_LIST_LOAD_PERFIS:
      return {
        ...state,
        roles: action.payload ? action.payload : null,
      };
    case SEARCH_USER_LIST_ALL_COMPANIES_LOAD:
      return {
        ...state,
        allCompanies: action.payload ? action.payload : null,
      };
    case UF_SYSTEM_LOAD:
      return {
        ...state,
        ufSystem: action.payload ? action.payload : [],
      };
    case SEARCH_USER_LIST_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
