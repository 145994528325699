import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, untouch } from "redux-form";
import { Link, browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import TextField from "../../components/Fields/TextField";
import SelectField from "../../components/Fields/SelectField";
import MultiSelectField from "../../components/Fields/MultiSelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import UfField from "../UfFields/UfField";
import CityField from "../UfFields/CityField";
import CompanyField from "../../components/CompanyField/CompanyField";
import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";
import TextareaField from "../../components/Fields/TextareaField";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import DropdownListField from "../../components/Fields/DropdownListField";

import {
  FINANCIAL_EDIT_LOAD,
  FINANCIAL_EDIT_UNLOAD,
  UPLOAD_ARCHIVE_OPEN,
  DOCUMENT_LIST_LOAD,
  DOCUMENT_LIST_UNLOAD,
  FINANCIAL_LIST_LOG_LOAD,
  FINANCIAL_LIST_LOAD,
  FINANCIAL_LIST_VALUES_PERIOD_LOAD,
  UF_SYSTEM_LOAD,
  LOGOUT,
  FINANCIAL_PARCEIRO_LOAD,
  CONTACT_LIST_LOAD,
  CONFIRM_ACTION_OPEN,
} from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";
import ReactTable from "react-table";
import { SubmissionError } from "redux-form";
import _, { values } from "lodash";

var cnpjCorrespondente = null;
var idVigenciaValores = null;

const cargoContato = [
  { label: "Diretor", value: "1" },
  { label: "Gestor", value: "2" },
];
class FinancialEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      adicionar: true,
      utilizaIntegExt: false,
      contact: null,
    };
  }

  componentWillMount() {
    this.props.onLoadParceiro(Promise.resolve(api.Financial.getParceiros()));
    if (this.props.params.slug) {
      this.props.onLoad(Promise.resolve(api.Financial.get(this.props.params.slug)));
      this.props.onDocumentsLoad(Promise.resolve(api.Financial.allDocuments(this.props.params.slug)));
      this.props.onLogLoad(Promise.resolve(api.Financial.recuperaLog(this.props.params.slug)));
      this.props.onListLoad(api.Financial.allFinancial());

      Promise.resolve(api.Financial.allFinancial()).then((response) => {
        this.props.onListLoad(response.filter((comp) => comp.id !== this.props.params.slug));
      });

      this.props.onValoresVigenciaLoad(Promise.resolve(api.Financial.allValuesPeriod(this.props.params.slug)));
      this.props.onUfSystemLoad(Promise.resolve(api.Ufs.system()));
      this.props.onContactsLoad(Promise.resolve(api.Financial.allContacts(this.props.params.slug)));
    } else {
      this.props.onLoad();
      this.props.onDocumentsLoad();
      this.props.onLogLoad();
      this.props.onListLoad(api.Financial.allFinancial());
      this.props.onUfSystemLoad(Promise.resolve(api.Ufs.system()));
      this.props.dispatch(change("FinancialEditForm", "dataCriacao", MaskDate.TO_BR(new Date())));
    }
  }

  componentWillReceiveProps(nextProps) {
    // trata somatorios de taxas na inclusao
    var valores = {};
    nextProps.vlTaxa.forEach((vlTaxa) => {
      if (!valores[vlTaxa.uf]) {
        valores[vlTaxa.uf] = {};
      }
      valores[vlTaxa.uf].vlTaxa = vlTaxa.valor;
    });
    nextProps.vlTaxaERC.forEach((vlTaxaERC) => {
      if (!valores[vlTaxaERC.uf]) {
        valores[vlTaxaERC.uf] = {};
      }
      valores[vlTaxaERC.uf].vlTaxaERC = vlTaxaERC.valor;
    });
    nextProps.valorTaxaAlt.forEach((valorTaxaAlt) => {
      if (!valores[valorTaxaAlt.uf]) {
        valores[valorTaxaAlt.uf] = {};
      }
      valores[valorTaxaAlt.uf].valorTaxaAlt = valorTaxaAlt.valor;
    });
    nextProps.valorTaxaAltERC.forEach((valorTaxaAltERC) => {
      if (!valores[valorTaxaAltERC.uf]) {
        valores[valorTaxaAltERC.uf] = {};
      }
      valores[valorTaxaAltERC.uf].valorTaxaAltERC = valorTaxaAltERC.valor;
    });
    Object.keys(valores).map((uf) => {
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaTotal" + uf,
          MaskDecimal.TO_BRL(
            parseFloat(
              valores[uf].vlTaxa
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                valores[uf].vlTaxaERC
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaTotal" + uf,
          MaskDecimal.TO_BRL(
            parseFloat(
              valores[uf].vlTaxaERC
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                valores[uf].vlTaxa
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaAltTotal" + uf,
          MaskDecimal.TO_BRL(
            parseFloat(
              valores[uf].valorTaxaAlt
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                valores[uf].valorTaxaAltERC
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaAltTotal" + uf,
          MaskDecimal.TO_BRL(
            parseFloat(
              valores[uf].valorTaxaAltERC
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                valores[uf].valorTaxaAlt
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
    });

    // trata somatorios de taxas na edicao
    if (nextProps.vlTaxaUpd) {
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaTotal",
          MaskDecimal.TO_BRL(
            parseFloat(
              nextProps.vlTaxaUpd
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                nextProps.vlTaxaERCUpd
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
    }
    if (nextProps.vlTaxaERCUpd) {
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaTotal",
          MaskDecimal.TO_BRL(
            parseFloat(
              nextProps.vlTaxaERCUpd
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                nextProps.vlTaxaUpd
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
    }
    if (nextProps.valorTaxaAltUpd) {
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaAltTotal",
          MaskDecimal.TO_BRL(
            parseFloat(
              nextProps.valorTaxaAltUpd
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                nextProps.valorTaxaAltERCUpd
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
    }
    if (nextProps.valorTaxaAltERCUpd) {
      this.props.dispatch(
        change(
          "FinancialEditForm",
          "valorTaxaAltTotal",
          MaskDecimal.TO_BRL(
            parseFloat(
              nextProps.valorTaxaAltERCUpd
                .replace(/[^0-9,.]/g, "")
                .replace(/[.]/g, "")
                .replace(",", ".")
            ) +
              parseFloat(
                nextProps.valorTaxaAltUpd
                  .replace(/[^0-9,.]/g, "")
                  .replace(/[.]/g, "")
                  .replace(",", ".")
              )
          )
        )
      );
    }

    if (!nextProps.idParceiro) {
      this.setState({ utilizaIntegExt: false });
    } else {
      this.setState({ utilizaIntegExt: true });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
    this.props.onDocumentsUnload();
  }

  handleClickUpload = (filesToUpload) => {
    var onLoad = this.props.onLoad;
    var onDocumentsLoad = this.props.onDocumentsLoad;
    var onLogLoad = this.props.onLogLoad;
    var idFinancial = this.props.financial.id;
    this.props.onOpenUploadArchive({
      description: "Nome do Arquivo: " + filesToUpload[0].name,
      filesToUpload: filesToUpload,
      id: this.props.financial.id,
      onSuccess: function () {
        onLoad(Promise.resolve(api.Financial.get(idFinancial)));
        onDocumentsLoad(Promise.resolve(api.Financial.allDocuments(idFinancial)));
        onLogLoad(Promise.resolve(api.Financial.recuperaLog(idFinancial)));
        toastr.success("Sucesso", "Documento cadastrado!");
      },
    });
  };

  handleClickDownload = (idDocumento, nome) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    Promise.resolve(api.Financial.getDocument(this.props.financial.id, idDocumento))
      .then((response) => {
        fileDownload(response, nome);
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickDelete = (idDocumento) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    var onLoad = this.props.onLoad;
    var onDocumentsLoad = this.props.onDocumentsLoad;
    var onLogLoad = this.props.onLogLoad;
    var idFinancial = this.props.financial.id;
    Promise.resolve(api.Financial.deleteDocument(idDocumento))
      .then((response) => {
        onLoad(Promise.resolve(api.Financial.get(idFinancial)));
        onDocumentsLoad(Promise.resolve(api.Financial.allDocuments(idFinancial)));
        onLogLoad(Promise.resolve(api.Financial.recuperaLog(idFinancial)));
        toastr.success("Sucesso", "Documento excluído!");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickDelVigenciaValores = (idVigValores) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    var onValoresVigenciaLoad = this.props.onValoresVigenciaLoad;
    var idFinancial = this.props.financial.id;
    Promise.resolve(api.Financial.deleteVigenciaValores(idVigValores))
      .then((response) => {
        onValoresVigenciaLoad(Promise.resolve(api.Financial.allValuesPeriod(idFinancial)));
        this.props.onLogLoad(api.Financial.recuperaLog(idFinancial));
        this.handleOnCancel();
        toastr.success("Sucesso", "Registro Excluído!");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickInsertUpdateVigValores(values) {
    var props = this.props;
    var onValoresVigenciaLoad = this.props.onValoresVigenciaLoad;
    var idFinancial = this.props.financial.id;
    var vigenciaValores = {
      idFinanceira: idFinancial,
      valorTaxa: MaskDecimal.TO_DATABASE(values.valorTaxa) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxa),
      valorTaxaERC: MaskDecimal.TO_DATABASE(values.valorTaxaERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaERC),
      valorTaxaAlt: MaskDecimal.TO_DATABASE(values.valorTaxaAlt) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAlt),
      valorTaxaAltERC: MaskDecimal.TO_DATABASE(values.valorTaxaAltERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAltERC),
      dataVigencia: MaskDate.TO_DATABASE(values.dataVigencia),
      valorIntegracaoExterna: MaskDecimal.TO_DATABASE(values.valorIntegracaoExterna) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorIntegracaoExterna),
      uf: values.ufTaxa,
      codAgenteDetran: values.codAgenteDetran,
      // valorIntegracaoSNG: MaskDecimal.TO_DATABASE(values.valorIntegracaoSNG) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorIntegracaoSNG),
      // habilitaMonitoramentoCrv: values.habilitaMonitoramentoCrv,
      // valorMonitoramentoCrv: MaskDecimal.TO_DATABASE(values.valorMonitoramentoCrv) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorMonitoramentoCrv),
      taxaPagaPorFinanceira: values.taxaPagaPorFinanceira,
    };
    if (idVigenciaValores) {
      vigenciaValores.id = idVigenciaValores;
      Promise.resolve(api.Financial.updateVigenciaValores(idFinancial, vigenciaValores))
        .then((ret) => {
          toastr.success("Sucesso", "Registro alterado");
          this.props.onLogLoad(api.Financial.recuperaLog(idFinancial));
          onValoresVigenciaLoad(Promise.resolve(api.Financial.allValuesPeriod(idFinancial)));
          this.handleOnCancel();
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else {
      let valoresUF = this.props.valoresVigencia.filter((i) => i[0].uf == vigenciaValores.uf);
      if (valoresUF.length === 0 || MaskDate.IS_AFTER(vigenciaValores.dataVigencia, valoresUF[0][0].dataVigencia)) {
        Promise.resolve(api.Financial.createVigenciaValores(idFinancial, vigenciaValores))
          .then((ret) => {
            toastr.success("Sucesso", "Registro cadastrado");
            this.props.onLogLoad(api.Financial.recuperaLog(idFinancial));
            onValoresVigenciaLoad(Promise.resolve(api.Financial.allValuesPeriod(idFinancial)));
            this.handleOnCancel();
          })
          .catch(function (resp) {
            if (resp === "Unauthorized" || resp === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          });
      } else {
        toastr.warning("Atenção", `A data deve ser maior que ${MaskDate.TO_BR_DATE(valoresUF[0][0].dataVigencia)}!`);
      }
    }
  }

  handleBlurOperadoraCorrespondente(values, operadoras) {
    if (values.correspondente && values.correspondente === "S" && values.delegaPara) {
      if (values.delegaPara.cnpj) {
        cnpjCorrespondente = values.delegaPara.cnpj;
      } else {
        cnpjCorrespondente = this.props.all.filter((comp) => comp.id == values.delegaPara.id)[0].cnpj;
      }
    } else {
      cnpjCorrespondente = null;
    }
  }

  handleBlurCEP(event) {
    Promise.resolve(api.Ufs.cep(event.target.value)).then((e) => {
      console.log("===>", e);
      this.props.dispatch(change("FinancialEditForm", "endereco", e.logradouro ? e.logradouro.toUpperCase() : ""));
      this.props.dispatch(change("FinancialEditForm", "endComplemento", e.complemento ? e.complemento.toUpperCase() : ""));
      this.props.dispatch(change("FinancialEditForm", "endBairro", e.bairro ? e.bairro.substring(0, 45).toUpperCase() : ""));
      this.props.dispatch(change("FinancialEditForm", "endUf", e.uf));
      this.props.dispatch(change("FinancialEditForm", "endCodMunicipio", e.municipioId));
    });
  }

  handleBlurValidarCnpj(event) {
    if (!this.props.financial || !this.props.financial.id || this.props.financial.cnpj != event.target.value.replace(/[^\d]/g, "")) {
      Promise.resolve(api.Financial.getByCnpj(event.target.value.replace(/[^\d]/g, ""))).then((response) => {
        if (response && response.length > 0) {
          toastr.error("Erro", "CNPJ já existente!");
          this.props.dispatch(change("FinancialEditForm", "cnpj", null));
        }
      });
    }
  }

  handleOnSelectValueClick = (value) => (ev) => {
    this.setState({ adicionar: false });
    idVigenciaValores = value.id;
    this.props.dispatch(change("FinancialEditForm", "dataVigencia", MaskDate.TO_BR_DATE(value.dataVigencia)));
    this.props.dispatch(change("FinancialEditForm", "valorTaxa", MaskDecimal.TO_BR_EDIT(value.valorTaxa)));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaERC", MaskDecimal.TO_BR_EDIT(value.valorTaxaERC)));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAlt", MaskDecimal.TO_BR_EDIT(value.valorTaxaAlt)));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAltERC", MaskDecimal.TO_BR_EDIT(value.valorTaxaAltERC)));
    this.props.dispatch(change("FinancialEditForm", "valorIntegracaoExterna", MaskDecimal.TO_BR_EDIT(value.valorIntegracaoExterna)));
    // this.props.dispatch(change("FinancialEditForm", "valorIntegracaoSNG", MaskDecimal.TO_BR_EDIT(value.valorIntegracaoSNG)));
    this.props.dispatch(change("FinancialEditForm", "ufTaxa", value.uf));
    this.props.dispatch(change("FinancialEditForm", "codAgenteDetran", value.codAgenteDetran));
    // this.props.dispatch(change("FinancialEditForm", "habilitaMonitoramentoCrv", value.habilitaMonitoramentoCrv));
    // this.props.dispatch(change("FinancialEditForm", "valorMonitoramentoCrv", MaskDecimal.TO_BR_EDIT(value.valorMonitoramentoCrv)));
    this.props.dispatch(change("FinancialEditForm", "taxaPagaPorFinanceira", value.taxaPagaPorFinanceira));
    const el = document.querySelector(`[name="ufTaxa"]`);
    const position = el.getBoundingClientRect().top + document.documentElement.scrollTop;
    const offset = 100;
    window.scrollTo({ top: position - offset, behavior: "smooth" });
  };

  handleOnCancel = () => {
    this.setState({ adicionar: true });
    idVigenciaValores = null;
    this.props.dispatch(change("FinancialEditForm", "dataVigencia", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxa", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaERC", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAlt", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAltERC", null));
    this.props.dispatch(change("FinancialEditForm", "valorIntegracaoExterna", null));
    // this.props.dispatch(change("FinancialEditForm", "valorIntegracaoSNG", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaTotal", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAltTotal", null));
    this.props.dispatch(change("FinancialEditForm", "codAgenteDetran", null));
    // this.props.dispatch(change("FinancialEditForm", "habilitaMonitoramentoCrv", null));
    // this.props.dispatch(change("FinancialEditForm", "valorMonitoramentoCrv", null));
    this.props.dispatch(change("FinancialEditForm", "taxaPagaPorFinanceira", null));
  };

  handleChangeParceiro(event) {
    if (event.target.value) {
      this.setState({ utilizaIntegExt: true });
    } else {
      this.setState({ utilizaIntegExt: false });
    }
  }

  async validaRemocaoUf(values) {
    if (this.props.financial) {
      var props = this.props;
      var result = Object.values(values.ufs); //transformando em array
      var arr = this.props.financial.ufs.filter((item) => result.indexOf(item.uf) === -1);

      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          await api.Financial.recuperaContratosDaUF(arr[i].uf, this.props.financial.id)
            .then((response) => {
              if (response && response.length > 0) {
                result.push(arr[i].uf);
                toastr.error("Erro", `UF ${arr[i].uf} não pode ser removida, já possuí contrato.`);
              }
            })
            .catch(function (resp) {
              if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
                api.Auth.logout();
                props.onLogout();
              } else {
                toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
              }
            });
        }
        this.props.dispatch(change("FinancialEditForm", "ufs", result));
      }
    }
  }

  formSubmit(values) {
    if (this.props.financial && this.props.financial.id) {
      for (var i = 0; i < values.ufs.length; i++) {
        if (this.props.valoresVigencia.filter((vl) => vl[0].uf == values.ufs[i]).length < 1) {
          toastr.info("Atenção", "É necessário adicionar o valor das taxas em todas as ufs habilitadas a operar.");
          return;
        }
      }
    }
    if (values.correspondente && values.correspondente === "S" && !values.delegaPara) {
      toastr.error("Operadora é obrigatória caso a financeira seja correspondente!");
      return;
    }
    var props = this.props;
    this.handleBlurOperadoraCorrespondente(values, this.props.all);
    var credenciada = {
      cnpj: values.cnpj.replace(/[^\d]/g, ""),
      razaoSocial: values.razaoSocial,
      delegaPara: values.delegaPara ? values.delegaPara.id : null,
      status: 0,
      endereco: values.endereco,
      endNumero: values.endNumero,
      endComplemento: values.endComplemento,
      endBairro: values.endBairro,
      endCodMunicipio: values.endCodMunicipio,
      endUf: values.endUf,
      endCep: values.endCep,
      dddTelefone: values.dddTelefone,
      numTelefone: values.numTelefone,
      periodicidadeFaturamento: values.periodicidadeFaturamento,
      cobrancaAutomatica: values.cobrancaAutomatica,
      vigencia: MaskDate.TO_DATABASE(values.vigencia),
      nomeResponsavel: values.nomeResponsavel,
      emailResponsavel: values.emailResponsavel,
      cpfResponsavel: values.cpfResponsavel,
      dddTelefoneResponsavel: values.dddTelefoneResponsavel,
      numTelefoneResponsavel: values.numTelefoneResponsavel,
      funcaoResponsavel: values.funcaoResponsavel,
      tipo: values.tipo,
      idCredenciamento: values.idCredenciamento,
      inscricaoEstadual: values.inscricaoEstadual,
      numContrato: values.numContrato,
      dataAssinaturaContrato: MaskDate.TO_DATABASE(values.dataAssinaturaContrato),
      nomeFantasia: values.nomeFantasia,
      tipoCobranca: values.tipoCobranca,
      periodicidadeAlias: values.periodicidadeAlias,
      observacao: values.observacao,
      cnpjCorrespondente: cnpjCorrespondente,
      //integracaoExterna: values.integracaoExterna,
      integracaoApi: values.integracaoApi,
      matriculaResponsavel: values.matriculaResponsavel,
      nomeAgrupamento: values.nomeAgrupamento,
      ufs: values.ufs,
      valoresVigencia: {},
      integracaoConfia: values.integracaoConfia,
      emailsFaturamento: values.emailsFaturamento,
      idParceiro: values.idParceiro,
      // integracaoRoboSng: values.integracaoRoboSng,
      // monitoramentoCrv: values.monitoramentoCrv,
      emailsImagem: values.emailsImagem,
      periodicidadeImagem: values.periodicidadeImagem,
      dataCriacao: MaskDate.TO_DATABASE(values.dataCriacao),
      emailsNotificacaoErro: values.emailsNotificacaoErro,
      periodicidadeNotificacaoErro: values.periodicidadeNotificacaoErro,
      desabilitaFiliaisEmails: values.desabilitaFiliaisEmails,
      visualizaFaturamentoFiliais: values.visualizaFaturamentoFiliais,
      emailsCertificadoDigital: values.emailsCertificadoDigital,
      desabilitaEmailCertificadoDigital: values.desabilitaEmailCertificadoDigital,
    };

    if (values.ufs && values.ufs.length > 0) {
      values.ufs.map((uf) => {
        credenciada.valoresVigencia[uf] = {};
        credenciada.valoresVigencia[uf].valorTaxa = MaskDecimal.TO_DATABASE(values["valorTaxa" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorTaxa" + uf]);
        credenciada.valoresVigencia[uf].valorTaxaERC = MaskDecimal.TO_DATABASE(values["valorTaxaERC" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorTaxaERC" + uf]);
        credenciada.valoresVigencia[uf].valorTaxaAlt = MaskDecimal.TO_DATABASE(values["valorTaxaAlt" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorTaxaAlt" + uf]);
        credenciada.valoresVigencia[uf].valorTaxaAltERC = MaskDecimal.TO_DATABASE(values["valorTaxaAltERC" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorTaxaAltERC" + uf]);
        credenciada.valoresVigencia[uf].dataVigencia = MaskDate.TO_DATABASE(values["dataVigencia" + uf]);
        credenciada.valoresVigencia[uf].valorIntegracaoExterna =
          MaskDecimal.TO_DATABASE(values["valorIntegracaoExterna" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorIntegracaoExterna" + uf]);
        credenciada.valoresVigencia[uf].codAgenteDetran = values["codAgenteDetran" + uf];
        // credenciada.valoresVigencia[uf].valorIntegracaoSNG = MaskDecimal.TO_DATABASE(values["valorIntegracaoSNG" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorIntegracaoSNG" + uf]);

        // credenciada.valoresVigencia[uf].habilitaMonitoramentoCrv = values["habilitaMonitoramentoCrv" + uf];
        // credenciada.valoresVigencia[uf].valorMonitoramentoCrv =
        //   MaskDecimal.TO_DATABASE(values["valorMonitoramentoCrv" + uf]) == 0 ? null : MaskDecimal.TO_DATABASE(values["valorMonitoramentoCrv" + uf]);
        credenciada.valoresVigencia[uf].taxaPagaPorFinanceira = values["taxaPagaPorFinanceira" + uf];
      });
    }

    if (this.props.financial && this.props.financial.id) {
      credenciada.id = this.props.financial.id;
      Promise.resolve(api.Financial.update(credenciada))
        .then((ret) => {
          this.props.onLogLoad(api.Financial.recuperaLog(this.props.financial.id));
          toastr.success("Sucesso", "Financeira alterada");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else {
      Promise.resolve(api.Financial.create(credenciada))
        .then((ret) => {
          // var vigenciaValores = {
          //   idFinanceira: ret.id,
          //   valorTaxa: MaskDecimal.TO_DATABASE(values.valorTaxa) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxa),
          //   valorTaxaERC: MaskDecimal.TO_DATABASE(values.valorTaxaERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaERC),
          //   valorTaxaAlt: MaskDecimal.TO_DATABASE(values.valorTaxaAlt) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAlt),
          //   valorTaxaAltERC: MaskDecimal.TO_DATABASE(values.valorTaxaAltERC) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorTaxaAltERC),
          //   dataVigencia: MaskDate.TO_DATABASE(values.dataVigencia),
          //   valorIntegracaoExterna: MaskDecimal.TO_DATABASE(values.valorIntegracaoExterna) == 0 ? null : MaskDecimal.TO_DATABASE(values.valorIntegracaoExterna),
          // }
          // Promise.resolve(api.Financial.createVigenciaValores(ret.id, vigenciaValores)).then(vv => {
          this.props.onValoresVigenciaLoad(api.Financial.allValuesPeriod(ret.id));
          //   this.handleOnCancel();
          // })
          this.props.onLoad(api.Financial.get(ret.id));
          this.props.onLogLoad(api.Financial.recuperaLog(ret.id));
          //this.props.onListLoad(api.Financial.allFinancial());
          toastr.success("Sucesso", "Financeira cadastrada");
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  async recuperaValoresNovaFinanceira(uf) {
    var props = this.props;
    Promise.resolve(api.Financial.getValorDetran(uf))
      .then((ret) => {
        if (ret && ret.length > 0) {
          document.getElementsByName("valorTaxaERC" + uf)[0].removeAttribute("disabled", "disabled");
          document.getElementsByName("valorTaxaAltERC" + uf)[0].removeAttribute("disabled", "disabled");
          this.props.dispatch(change("FinancialEditForm", "dataVigencia" + uf, MaskDate.TO_BR_DATE(ret[0].dataVigencia)));
          this.props.dispatch(change("FinancialEditForm", "valorTaxa" + uf, MaskDecimal.TO_BR_EDIT(ret[0].valorTaxa)));
          this.props.dispatch(change("FinancialEditForm", "valorTaxaAlt" + uf, MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaAlt)));
          if (ret[0].valorTaxaERC) {
            this.props.dispatch(change("FinancialEditForm", "valorTaxaERC" + uf, MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaERC)));
            this.props.dispatch(change("FinancialEditForm", "valorTaxaAltERC" + uf, MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaAltERC)));
            document.getElementsByName("valorTaxaERC" + uf)[0].setAttribute("disabled", "disabled");
            document.getElementsByName("valorTaxaAltERC" + uf)[0].setAttribute("disabled", "disabled");
          }
        }
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  async recuperaValores(event) {
    this.props.dispatch(change("FinancialEditForm", "dataVigencia", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxa", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAlt", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaERC", null));
    this.props.dispatch(change("FinancialEditForm", "valorTaxaAltERC", null));
    var props = this.props;
    Promise.resolve(api.Financial.getValorDetran(event.target.value))
      .then((ret) => {
        if (ret && ret.length > 0) {
          document.getElementsByName("valorTaxaERC")[0].removeAttribute("disabled", "disabled");
          document.getElementsByName("valorTaxaAltERC")[0].removeAttribute("disabled", "disabled");
          this.props.dispatch(change("FinancialEditForm", "dataVigencia", MaskDate.TO_BR_DATE(ret[0].dataVigencia)));
          this.props.dispatch(change("FinancialEditForm", "valorTaxa", MaskDecimal.TO_BR_EDIT(ret[0].valorTaxa)));
          this.props.dispatch(change("FinancialEditForm", "valorTaxaAlt", MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaAlt)));
          if (ret[0].valorTaxaERC) {
            this.props.dispatch(change("FinancialEditForm", "valorTaxaERC", MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaERC)));
            this.props.dispatch(change("FinancialEditForm", "valorTaxaAltERC", MaskDecimal.TO_BR_EDIT(ret[0].valorTaxaAltERC)));
            document.getElementsByName("valorTaxaERC")[0].setAttribute("disabled", "disabled");
            document.getElementsByName("valorTaxaAltERC")[0].setAttribute("disabled", "disabled");
          }
        }
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnCancelContacts = () => {
    this.setState({ contact: null });
    this.props.dispatch(change("FinancialEditForm", "nomeContato", null));
    this.props.dispatch(untouch("FinancialEditForm", "nomeContato"));
    this.props.dispatch(change("FinancialEditForm", "emailContato", null));
    this.props.dispatch(untouch("FinancialEditForm", "emailContato"));
    this.props.dispatch(change("FinancialEditForm", "dddTelefoneContato", null));
    this.props.dispatch(untouch("FinancialEditForm", "dddTelefoneContato"));
    this.props.dispatch(change("FinancialEditForm", "numTelefoneContato", null));
    this.props.dispatch(untouch("FinancialEditForm", "numTelefoneContato"));
    this.props.dispatch(change("FinancialEditForm", "cargoContato", null));
    this.props.dispatch(untouch("FinancialEditForm", "cargoContato"));
  };

  formSubmitContact = (values) => {
    var props = this.props;
    const errors = this.validateContact(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (
      (!values.emailContato || values.emailContato.length === 0 || !values.emailContato.trim()) &&
      (!values.dddTelefoneContato || values.dddTelefoneContato.length === 0 || !values.dddTelefoneContato.trim()) &&
      (!values.numTelefoneContato || values.numTelefoneContato.length === 0 || !values.numTelefoneContato.trim())
    ) {
      toastr.warning("Atenção", "É obrigatório informar o e-mail ou o telefone do contato.");
    } else {
      var contact = {
        idFinanceira: this.props.financial.id,
        nome: values.nomeContato,
        email: values.emailContato,
        dddTelefone: values.dddTelefoneContato,
        numTelefone: values.numTelefoneContato,
        cargo: values.cargoContato,
      };
      if (this.state.contact) {
        contact.id = this.state.contact.id;
        Promise.resolve(api.Financial.updateContact(contact))
          .then((ret) => {
            toastr.success("Sucesso", "Contato alterado.");
            this.props.onContactsLoad(Promise.resolve(api.Financial.allContacts(props.financial.id)));
            this.handleOnCancelContacts();
          })
          .catch(function (resp) {
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", resp.response.body.message);
            }
          });
      } else {
        Promise.resolve(api.Financial.createContact(contact))
          .then((ret) => {
            toastr.success("Sucesso", "Contato cadastrado.");
            this.props.onContactsLoad(Promise.resolve(api.Financial.allContacts(props.financial.id)));
            this.handleOnCancelContacts();
          })
          .catch(function (resp) {
            if (resp === "Unauthorized" || resp === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", resp.response.body.message);
            }
          });
      }
    }
  };

  validateContact(values) {
    const errors = {};
    if (!values.nomeContato || values.nomeContato.length === 0 || !values.nomeContato.trim()) {
      errors.nomeContato = "Campo obrigatório";
    }
    if (values.dddTelefoneContato && (!values.numTelefoneContato || values.numTelefoneContato.length === 0 || !values.numTelefoneContato.trim())) {
      errors.numTelefoneContato = "Campo obrigatório";
    }
    if (values.numTelefoneContato && (!values.dddTelefoneContato || values.dddTelefoneContato.length === 0 || !values.dddTelefoneContato.trim())) {
      errors.dddTelefoneContato = "Campo obrigatório";
    }
    return errors;
  }

  handleOnSelectContactClick = (data) => {
    this.setState({ contact: data });
    this.props.dispatch(change("FinancialEditForm", "nomeContato", data.nome));
    this.props.dispatch(change("FinancialEditForm", "emailContato", data.email));
    this.props.dispatch(change("FinancialEditForm", "dddTelefoneContato", data.dddTelefone));
    this.props.dispatch(change("FinancialEditForm", "numTelefoneContato", data.numTelefone));
    this.props.dispatch(change("FinancialEditForm", "cargoContato", data.cargo));
  };

  onActionDelete() {
    this.props.onActionOpen({
      description: `Confirma a exclusão do contato?`,
      onSuccess: this.deleteContact.bind(this),
    });
  }

  deleteContact() {
    var props = this.props;
    Promise.resolve(api.Financial.deleteContact(this.state.contact.id))
      .then((ret) => {
        this.props.onContactsLoad(Promise.resolve(api.Financial.allContacts(props.financial.id)));
        this.setState({ contact: null });
        toastr.success("Sucesso", "Contato excluído.");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    if (!this.props.editLoad) {
      return null;
    }

    const { handleSubmit, ufs } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Cadastramento de instituições credenciadas pelo Detran para registro de contrato" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {this.props.financial && this.props.financial.id && this.props.currentUser.funcionalidades.includes(33) && (
                      <Link to={`/alias/financialUser/${this.props.financial.id}`} className="btn btn-xs btn-info" title="Usuários">
                        <i className="fas fa-users" /> Acessar Usuários
                      </Link>
                    )}
                  </div>
                  Financeira
                </div>
                <div className="panel-body">
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit((values) => {
                      return new Promise((resolve, reject) => {
                        const errors = validate(values);
                        if (errors && !_.isEmpty(errors)) {
                          reject(new SubmissionError(errors));
                        } else {
                          this.formSubmit(values);
                          resolve();
                        }
                      });
                    })}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <Field name="razaoSocial" label="Razão Social" required={true} component={TextField} type="text" validate={[FieldValidation.required, FieldValidation.maxLength40]} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="cnpj"
                          label="CNPJ"
                          required={true}
                          component={TextField}
                          type="text"
                          validate={[FieldValidation.required]}
                          normalize={FieldNormalize.CNPJ}
                          onBlur={this.handleBlurValidarCnpj.bind(this)}
                        />
                      </div>
                      <div className="col-md-2">
                        <Field name="inscricaoEstadual" label="Inscrição Estadual" component={TextField} type="text" validate={[FieldValidation.maxLength40]} normalize={FieldNormalize.NUMBER} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="tipo"
                          label="Tipo"
                          blank={true}
                          data={[
                            { id: "1", name: "Banco" },
                            { id: "2", name: "Banco Multiplo" },
                            { id: "3", name: "Financeira" },
                            { id: "4", name: "Consórcio" },
                            { id: "5", name: "Concessionária" },
                            { id: "6", name: "Revenda" },
                            { id: "7", name: "Arrendamento" },
                            { id: "99", name: "Outras" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="dataCriacao"
                          label="Data Criação"
                          placeholder="00/00/0000"
                          // data={this.props.dataAssinaturaContrato}
                          component={TextDatePickerField}
                          type="text"
                          validate={[FieldValidation.date]}
                          normalize={FieldNormalize.DATE}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field name="nomeFantasia" label="Nome Fantasia" component={TextField} type="text" maxlength={70} />
                      </div>
                      <div className="col-md-6">
                        <Field name="numContrato" label="Número do contrato" validate={[FieldValidation.nospace]} maxlength={45} component={TextField} type="text" inline={false} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field name="nomeAgrupamento" label="Nome Agrupamento" component={TextField} type="text" normalize={FieldNormalize.UPPERCASE} maxlength={70} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="dataAssinaturaContrato"
                          label="Data início contrato"
                          placeholder="00/00/0000"
                          data={this.props.dataAssinaturaContrato}
                          component={TextDatePickerField}
                          type="text"
                          validate={[FieldValidation.date]}
                          normalize={FieldNormalize.DATE}
                        />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="vigencia"
                          label="Data término contrato"
                          validate={[FieldValidation.date]}
                          placeholder="00/00/0000"
                          data={this.props.vigencia}
                          component={TextDatePickerField}
                          type="text"
                          normalize={FieldNormalize.DATE}
                        />
                      </div>
                      <div className="col-md-2">
                        <Field name="idCredenciamento" label="Código do Credor" maxlength={2} required={false} component={TextField} type="text" description="Regra para rateio Sicredi" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-1">
                        <Field name="dddTelefone" required={true} label="DDD" normalize={FieldNormalize.NUMBER} component={TextField} type="text" maxlength={2} validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field name="numTelefone" label="Telefone" required={true} normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="endCep"
                          label="CEP"
                          required={true}
                          small={false}
                          component={TextField}
                          type="text"
                          normalize={FieldNormalize.CEP}
                          validate={[FieldValidation.required, FieldValidation.cep]}
                          onBlur={this.handleBlurCEP.bind(this)}
                        />
                      </div>
                      <div className="col-md-2">
                        <UfField name="endUf" label="UF" required={true} validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-5">
                        <CityField name="endCodMunicipio" required={true} label="Município" valueField="municipioId" selectedUf={this.props.selectedUf} validate={[FieldValidation.required]} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-9">
                        <Field name="endereco" label="Endereço" required={true} component={TextField} type="text" validate={[FieldValidation.required, FieldValidation.maxLength30]} />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="endNumero"
                          label="Número"
                          required={true}
                          component={TextField}
                          type="text"
                          normalize={FieldNormalize.NUMBER}
                          validate={[FieldValidation.required, FieldValidation.maxLength5]}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Field name="endComplemento" label="Complemento" component={TextField} type="text" validate={[FieldValidation.maxLength20]} />
                      </div>
                      <div className="col-md-6">
                        <Field name="endBairro" label="Bairro" required={true} component={TextField} type="text" validate={[FieldValidation.required, FieldValidation.maxLength20]} />
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-3">
                        <Field
                          name="periodicidadeAlias"
                          label="Periodicidade Alias"
                          blank={true}
                          data={[
                            { id: "1", name: "Individual" },
                            { id: "2", name: "Semanal" },
                            { id: "3", name: "Quinzenal" },
                            { id: "4", name: "Mensal" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="tipoCobranca"
                          label="Tipo de Pagamento"
                          blank={true}
                          data={[
                            { id: "1", name: "Boleto" },
                            { id: "2", name: "Depósito" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="periodicidadeFaturamento"
                          label="Periodicidade Detran"
                          blank={true}
                          data={[
                            { id: "1", name: "Individual" },
                            { id: "2", name: "Semanal" },
                            { id: "3", name: "Quinzenal" },
                            { id: "4", name: "Mensal" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="cobrancaAutomatica"
                          label="Agendamento automático de faturamento"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-3">
                        <Field
                          name="delegaPara"
                          blank={true}
                          label="Operadora responsável"
                          data={this.props.all}
                          credor={false}
                          textField="razaoSocial"
                          valueField="id"
                          component={DropdownListField}
                          type="text"
                          description="Instituição responsável pela operação"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="correspondente"
                          label="Correspondente"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                          description="Indica se os registros serão enviados em nome da Operadora"
                        />
                      </div>
                      <div className="col-md-3">
                        {/* <Field name="integracaoExterna" label="Parceiro" blank={true} data={this.props.parceiros} onChange={this.handleChangeIntegExt.bind(this)}
                          component={SelectField} type="text" valueField='id' textField='name'/> */}
                        <Field
                          name="idParceiro"
                          label="Parceiro"
                          blank={true}
                          data={this.props.parceiros}
                          onChange={this.handleChangeParceiro.bind(this)}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="nome"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="integracaoApi"
                          label="Utiliza API"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <Field
                          name="ufs"
                          label="UFs habilitadas para operar"
                          onBlur={this.props.handleSubmit(this.validaRemocaoUf.bind(this))}
                          data={this.props.ufSystem}
                          value={this.state.ufs}
                          component={MultiSelectField}
                          type="text"
                          validate={!this.props.delegaPara ? [FieldValidation.required] : null}
                          description={!this.props.financial || !this.props.financial.id ? "Deixar em branco para replicar valores da Operadora responsável" : ""}
                        />
                      </div>
                      {/* <div className="col-md-3">
                        <Field
                          name="integracaoConfia"
                          label="Habilitar integração CONFIA"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div> */}
                      {/* <div className="col-md-3">
                        <Field
                          name="integracaoRoboSng"
                          label="Habilitar integração Robô SNG"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div> */}
                      {/* <div className="col-md-3">
                        <Field
                          name="monitoramentoCrv"
                          label="Habilitar monitoramento emissão CRV"
                          blank={false}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div> */}
                    </div>
                    <span>
                      <strong>Valores</strong>
                    </span>
                    {(!this.props.financial || !this.props.financial.id) &&
                      ufs &&
                      ufs.length > 0 &&
                      ufs.map((uf) => (
                        <div className="panel-body">
                          <button style={{ display: "none" }} onChange={this.recuperaValoresNovaFinanceira(uf)}></button>
                          <span>
                            <strong>{uf}</strong>
                          </span>
                          <div className="row">
                            <div className="col-md-3">
                              <Field
                                name={"valorTaxa" + uf}
                                disabled
                                label="Valor DETRAN"
                                required={true}
                                validate={[FieldValidation.requiredNot0]}
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field
                                name={"valorTaxaERC" + uf}
                                label="Valor ERC - Registradora"
                                required={true}
                                validate={[FieldValidation.requiredNot0]}
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field name={"valorTaxaTotal" + uf} label="Valor por Registro Total" component={TextField} type="text" disabled={true} normalize={FieldNormalize.DECIMAL} />
                            </div>
                            <div className="col-lg-3">
                              <Field
                                name={"dataVigencia" + uf}
                                label="Data início vigência"
                                required={true}
                                placeholder="00/00/0000"
                                data={this.props.dataVigencia}
                                component={TextDatePickerField}
                                type="text"
                                validate={[FieldValidation.date, FieldValidation.required]}
                                normalize={FieldNormalize.DATE}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <Field
                                name={"valorTaxaAlt" + uf}
                                disabled
                                label="Valor Alteração DETRAN"
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field
                                name={"valorTaxaAltERC" + uf}
                                label="Valor Alteração ERC - Registradora"
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field name={"valorTaxaAltTotal" + uf} label="Valor por Alteração Total" component={TextField} type="text" disabled={true} normalize={FieldNormalize.DECIMAL} />
                            </div>
                            <div className="col-md-3">
                              <Field
                                name={"codAgenteDetran" + uf}
                                label="Código do Agente no Detran"
                                required={uf === "PB" || uf === "MA" ? true : false}
                                component={TextField}
                                type="text"
                                validate={uf === "PB" || uf === "MA" ? [FieldValidation.required] : ""}
                                normalize={FieldNormalize.DECIMAL}
                                maxlength={18}
                              />
                            </div>
                            <div className="col-md-3">
                              <Field
                                name={"taxaPagaPorFinanceira" + uf}
                                label="Taxa Detran Paga por Financeira"
                                blank={false}
                                data={[
                                  { id: "0", name: "Não" },
                                  { id: "1", name: "Sim" },
                                ]}
                                component={SelectField}
                                type="text"
                                valueField="id"
                                textField="name"
                              />
                            </div>
                            {this.state.utilizaIntegExt && (
                              <div className="col-md-3">
                                <Field
                                  name={"valorIntegracaoExterna" + uf}
                                  label="Porcentagem parceiro"
                                  component={TextField}
                                  type="text"
                                  placeholder="0.000.000,00"
                                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                  maxlength={12}
                                />
                              </div>
                            )}
                            {/* {this.props.integracaoRoboSng == "S" && (
                              <div className="col-md-3">
                                <Field
                                  name={"valorIntegracaoSNG" + uf}
                                  label="Valor Integração SNG"
                                  component={TextField}
                                  type="text"
                                  validate={[FieldValidation.requiredNot0]}
                                  placeholder="0.000.000,00"
                                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                  maxlength={12}
                                />
                              </div>
                            )} */}
                            {/* {this.props.monitoramentoCrv == "S" && (
                              <>
                                <div className="col-md-3">
                                  <Field
                                    name={"habilitaMonitoramentoCrv" + uf}
                                    label="Habilita Monitoramento CRV na UF"
                                    blank={false}
                                    data={[
                                      { id: "N", name: "Não" },
                                      { id: "S", name: "Sim" },
                                    ]}
                                    component={SelectField}
                                    type="text"
                                    valueField="id"
                                    textField="name"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    name={"valorMonitoramentoCrv" + uf}
                                    label="Valor Monitoramento CRV"
                                    component={TextField}
                                    type="text"
                                    placeholder="0.000.000,00"
                                    normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                    maxlength={12}
                                  />
                                </div>
                              </>
                            )} */}
                          </div>
                        </div>
                      ))}
                    {this.props.financial && this.props.financial.id && (
                      <div className="panel-body">
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              name="ufTaxa"
                              label="UF"
                              blank={true}
                              data={
                                ufs && ufs.length > 0
                                  ? ufs.map((uf) => {
                                      return { id: uf, name: uf };
                                    })
                                  : []
                              }
                              valueField="id"
                              textField="name"
                              component={SelectField}
                              type="text"
                              onChange={this.recuperaValores.bind(this)}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="valorTaxa"
                              label="Valor DETRAN"
                              component={TextField}
                              disabled
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="valorTaxaERC"
                              label="Valor ERC - Registradora"
                              component={TextField}
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field name="valorTaxaTotal" label="Valor por Registro Total" component={TextField} type="text" disabled={true} normalize={FieldNormalize.DECIMAL} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              disabled={!this.state.adicionar}
                              name="dataVigencia"
                              label="Data início vigência"
                              validate={[FieldValidation.date]}
                              placeholder="00/00/0000"
                              component={TextDatePickerField}
                              type="text"
                              normalize={FieldNormalize.DATE}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="valorTaxaAlt"
                              label="Valor Alteração DETRAN"
                              disabled
                              component={TextField}
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="valorTaxaAltERC"
                              label="Valor Alteração ERC - Registradora"
                              component={TextField}
                              type="text"
                              placeholder="0.000.000,00"
                              normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                              maxlength={12}
                            />
                          </div>
                          <div className="col-md-3">
                            <Field name="valorTaxaAltTotal" label="Valor por Alteração Total" component={TextField} type="text" disabled={true} normalize={FieldNormalize.DECIMAL} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="codAgenteDetran" label="Código do Agente no Detran" component={TextField} type="text" normalize={FieldNormalize.DECIMAL} maxlength={18} />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="taxaPagaPorFinanceira"
                              label="Taxa Paga por Financeira"
                              blank={false}
                              data={[
                                { id: "0", name: "Não" },
                                { id: "1", name: "Sim" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                            />
                          </div>
                          {this.state.utilizaIntegExt && (
                            <div className="col-md-3">
                              <Field
                                name="valorIntegracaoExterna"
                                label="Porcentagem parceiro"
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                          )}
                          {/* {this.props.integracaoRoboSng == "S" && (
                            <div className="col-md-2">
                              <Field
                                name="valorIntegracaoSNG"
                                label="Valor Integração SNG"
                                component={TextField}
                                type="text"
                                placeholder="0.000.000,00"
                                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                maxlength={12}
                              />
                            </div>
                          )} */}
                          {/* {this.props.monitoramentoCrv == "S" && (
                            <>
                              <div className="col-md-2">
                                <Field
                                  name="habilitaMonitoramentoCrv"
                                  label="Habilita Monit. CRV na UF"
                                  blank={false}
                                  data={[
                                    { id: "N", name: "Não" },
                                    { id: "S", name: "Sim" },
                                  ]}
                                  component={SelectField}
                                  type="text"
                                  valueField="id"
                                  textField="name"
                                />
                              </div>
                              <div className="col-md-2">
                                <Field
                                  name="valorMonitoramentoCrv"
                                  label="Valor Monitoramento CRV"
                                  disabled={!this.props.habilitaValorMonit || this.props.habilitaValorMonit == "N"}
                                  component={TextField}
                                  type="text"
                                  placeholder="0.000.000,00"
                                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                                  maxlength={12}
                                />
                              </div>
                            </>
                          )} */}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-right">
                              <Link className="btn btn-default" onClick={this.handleOnCancel}>
                                {" "}
                                Cancelar
                              </Link>
                              {this.props.currentUser.funcionalidades.includes(34) && (
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      const errors = _.assign(validateVigenciaValores(values));
                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.handleClickInsertUpdateVigValores(values);
                                        resolve();
                                      }
                                    });
                                  })}
                                >
                                  {" "}
                                  {this.state.adicionar ? "Adicionar" : "Alterar"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="panel-body">
                          {this.props.valoresVigencia && this.props.valoresVigencia.length > 0 ? (
                            this.props.valoresVigencia.map((val) => {
                              return (
                                <div className="table-responsive">
                                  <p>
                                    <b>{val[0].uf}</b>
                                  </p>
                                  <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th className="text-center align-middle">Data de Vigência</th>
                                        <th className="text-center align-middle">Valor DETRAN</th>
                                        <th className="text-center align-middle">Valor ERC - Registradora</th>
                                        <th className="text-center align-middle">Valor Alteração DETRAN</th>
                                        <th className="text-center align-middle">Valor Alteração ERC - Registradora</th>
                                        <th className="text-center align-middle">Porcentagem parceiro</th>
                                        <th className="text-center align-middle">Código do Agente no Detran</th>
                                        <th className="text-center align-middle">Taxa Detran Paga por Financeira</th>
                                        {/* <th className="text-center align-middle">Valor Integração SNG</th> */}
                                        {/* <th className="text-center align-middle">Monitoramento CRV Habilitado</th>
                                        <th className="text-center align-middle">Valor Monitoramento CRV</th> */}
                                        {this.props.currentUser.funcionalidades.includes(34) && (
                                          <th className="text-center align-middle" style={{ width: "5%" }}>
                                            Ações
                                          </th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>{this.renderValoresVigenciaList(val)}</tbody>
                                  </table>
                                </div>
                              );
                            })
                          ) : (
                            <small>Nenhum registro encontrado</small>
                          )}
                        </div>
                      </div>
                    )}
                    <br></br>

                    <span>
                      <strong>Responsável</strong>
                    </span>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-4">
                          <Field name="nomeResponsavel" label="Nome" component={TextField} type="text" />
                        </div>
                        <div className="col-md-4">
                          <Field name="cpfResponsavel" label="CPF" normalize={FieldNormalize.CPF} component={TextField} type="text" validate={[FieldValidation.cpf]} />
                        </div>
                        <div className="col-md-4">
                          <Field name="emailResponsavel" label="Email" component={TextField} type="text" validate={[FieldValidation.email]} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <Field name="dddTelefoneResponsavel" label="DDD" normalize={FieldNormalize.NUMBER} component={TextField} type="text" maxlength={2} />
                        </div>
                        <div className="col-md-2">
                          <Field name="numTelefoneResponsavel" label="Telefone" normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" />
                        </div>
                        <div className="col-md-5">
                          <Field name="funcaoResponsavel" label="Função" component={TextField} type="text" maxlength={100} />
                        </div>
                        <div className="col-md-4">
                          <Field name="matriculaResponsavel" label="Matrícula" component={TextField} type="text" maxlength={40} />
                        </div>
                      </div>
                    </div>
                    <br></br>

                    {this.props.financial && this.props.financial.id && (
                      <>
                        <span>
                          <strong>Contatos</strong>
                        </span>
                        <div className="panel-body">
                          <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmitContact.bind(this))}>
                            <div className="row">
                              <div className="col-md-4">
                                <Field name="nomeContato" label="Nome" component={TextField} type="text" />
                              </div>
                              <div className="col-md-3">
                                <Field name="emailContato" label="E-mail" component={TextField} type="text" />
                              </div>
                              <div className="col-md-1">
                                <Field name="dddTelefoneContato" label="DDD" normalize={FieldNormalize.NUMBER} component={TextField} type="text" maxlength={2} />
                              </div>
                              <div className="col-md-2">
                                <Field name="numTelefoneContato" label="Telefone" normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" />
                              </div>
                              <div className="col-md-2">
                                <Field name="cargoContato" blank={true} label="Cargo" data={cargoContato} component={SelectField} type="text" textField="label" valueField="value" />
                              </div>
                            </div>
                            {this.props.currentUser.funcionalidades.includes(35) && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text-right">
                                    <Link className="btn btn-default" onClick={() => this.handleOnCancelContacts()}>
                                      Cancelar
                                    </Link>
                                    <Link className="btn btn-info" onClick={handleSubmit(this.formSubmitContact.bind(this))} ty>
                                      {" "}
                                      {this.state.contact ? "Alterar" : "Adicionar"}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </form>

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="hpanel">
                                <div className="panel-heading">Contatos cadastrados</div>
                                <div className="panel-body">
                                  <div className="table-responsive">
                                    {this.props.contacts && this.props.contacts.length > 0 ? (
                                      <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                        <thead>
                                          <tr>
                                            <th className="text-center align-middle">NOME</th>
                                            <th className="text-center align-middle">EMAIL</th>
                                            <th className="text-center align-middle">TELEFONE</th>
                                            <th className="text-center align-middle">CARGO</th>
                                            {this.props.currentUser.funcionalidades.includes(35) && <th className="text-center align-middle">AÇÕES</th>}
                                          </tr>
                                        </thead>
                                        <tbody>{this.renderListContacts()}</tbody>
                                      </table>
                                    ) : (
                                      <small>Nenhum registro encontrado</small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <br></br>

                    <div className="row">
                      <div className="col-md-12">
                        <Field name="emailsFaturamento" label="E-mails para faturamento" component={TextareaField} type="text" rows={2} />
                      </div>
                    </div>
                    <div className="panel-body">
                      {!this.props.delegaPara && (
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              name="desabilitaFiliaisEmails"
                              label="Desabilitar notificação matriz"
                              data={[
                                { id: "0", name: "Não" },
                                { id: "1", name: "Sim" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                              description="Indica se os registros das filiais serão enviados à matriz"
                            />
                          </div>
                          <div className="col-md-3">
                            <Field
                              name="visualizaFaturamentoFiliais"
                              label="Visualiza faturamento de filiais"
                              data={[
                                { id: "1", name: "Sim" },
                                { id: "0", name: "Não" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                              description="Indica se o faturamento das filiais poderão ser visualizadas pela matriz"
                            />
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-8">
                          <Field name="emailsNotificacaoErro" label="E-mails para envio de erros de contrato" component={TextareaField} type="text" rows={2} />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="periodicidadeNotificacaoErro"
                            label="Periodicidade Envio"
                            data={[
                              { id: "0", name: "Nenhuma" },
                              { id: "1", name: "Diário - 7am" },
                              { id: "2", name: "2x semana - Segunda e quinta 7am" },
                              { id: "3", name: "Semanal - Segunda 7am" },
                            ]}
                            component={SelectField}
                            type="text"
                            valueField="id"
                            textField="name"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <Field name="emailsImagem" label="E-mails para envio de imagem" component={TextareaField} type="text" rows={2} />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="periodicidadeImagem"
                            label="Periodicidade Envio"
                            data={[
                              { id: "1", name: "Nenhuma" },
                              { id: "2", name: "Semanal" },
                              { id: "3", name: "Quinzenal" },
                              { id: "4", name: "Mensal" },
                            ]}
                            component={SelectField}
                            type="text"
                            valueField="id"
                            textField="name"
                          />
                        </div>
                      </div>
                      {this.props.ufs && this.props.ufs.some((item) => item == "RS") && (
                        <div className="row">
                          <div className="col-md-8">
                            <Field name="emailsCertificadoDigital" label="E-mails Certificado Digital" component={TextareaField} type="text" rows={2} required validate={[FieldValidation.required]} />
                          </div>
                          <div className="col-md-4">
                            <Field
                              name="desabilitaEmailCertificadoDigital"
                              label="Desabilita envio"
                              data={[
                                { id: "0", name: "Não" },
                                { id: "1", name: "Sim" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12">
                        <Field name="observacao" label="Observação" component={TextareaField} type="text" rows={3} />
                      </div>
                    </div>
                    {this.props.financial && this.props.financial.id && (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="hpanel">
                            <div className="panel-heading">
                              {this.props.currentUser.funcionalidades.includes(36) && (
                                <div className="panel-tools">
                                  <Dropzone
                                    accept="application/pdf"
                                    name="file"
                                    className="dropzone-blank fallback"
                                    style={{ margin: "0 2px" }}
                                    multiple={false}
                                    onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}
                                  >
                                    <Link className="btn btn-xs btn-info">
                                      <i className="fas fa-plus" /> Cadastrar Documento
                                    </Link>
                                  </Dropzone>
                                </div>
                              )}
                              Documentos
                            </div>
                            <div className="panel-body">
                              <div className="table-responsive">
                                {this.props.documents && this.props.documents.length > 0 ? (
                                  <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th className="text-center align-middle" style={{ width: "25%" }}>
                                          Documento
                                        </th>
                                        <th className="text-center align-middle">Descrição</th>
                                        <th style={{ width: "15%" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>{this.renderDocumentsList()}</tbody>
                                  </table>
                                ) : (
                                  <small>Nenhum documento cadastrado</small>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" to="/alias/financial">
                            {" "}
                            Cancelar
                          </Link>
                          {this.props.currentUser.funcionalidades.includes(39) && (
                            <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                              Salvar
                            </button>
                          )}

                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                    {this.props.financial && this.props.financial.id && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="hpanel">
                            <div className="panel-heading">Histórico</div>

                            <div className="panel-body">
                              <div className="table-responsive">
                                {this.props.log && this.props.log.length > 0 ? (
                                  <ReactTable
                                    className="-striped -highlight"
                                    defaultPageSize={10}
                                    data={this.props.log}
                                    loadingText="Carregando..."
                                    noDataText="Nenhuma Informação encontrada"
                                    previousText="Anterior"
                                    nextText="Próximo"
                                    pageText="página"
                                    ofText="de"
                                    rowsText="linhas"
                                    showPageSizeOptions={false}
                                    minRows={1}
                                    columns={[
                                      {
                                        Header: "Data",
                                        accessor: "data",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        Cell: (row) => <div>{MaskDate.TO_BR_DATETIME(row.original.data)}</div>,
                                      },
                                      {
                                        Header: "Operação",
                                        accessor: "tipoEvento",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        Cell: (row) => (
                                          <div>
                                            {row.original.tipoEvento === "nova_financeira"
                                              ? "Cadastro Financeira"
                                              : row.original.tipoEvento === "alterar_financeira"
                                              ? "Alteração Financeira"
                                              : row.original.tipoEvento === "novo_documento"
                                              ? "Novo Documento"
                                              : row.original.tipoEvento === "delete_documento"
                                              ? "Exclusão Documento"
                                              : row.original.tipoEvento === "nova_vigencia"
                                              ? `Nova Vigência de Valores ${JSON.parse(row.original.dados).uf}`
                                              : row.original.tipoEvento === "alterar_vigencia"
                                              ? `Alteração Vigência de Valores ${JSON.parse(row.original.dados).uf}`
                                              : row.original.tipoEvento === "delete_vigencia"
                                              ? `Exclusão Vigência de Valores ${JSON.parse(row.original.dadosAnterior).uf ? JSON.parse(row.original.dadosAnterior).uf : ""}`
                                              : ""}
                                          </div>
                                        ),
                                      },
                                      {
                                        Header: "Nome",
                                        accessor: "nome",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                      },
                                      {
                                        Header: "IP",
                                        accessor: "ip",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                      },
                                    ]}
                                    SubComponent={(row) => {
                                      return (
                                        <div style={{ padding: "20px" }}>
                                          <ReactTable
                                            className="-striped -highlight"
                                            defaultPageSize={50}
                                            data={row.original.alteracoes}
                                            loadingText="Carregando..."
                                            noDataText="Nenhuma Informação encontrada"
                                            showPageSizeOptions={false}
                                            minRows={1}
                                            showPagination={false}
                                            columns={[
                                              {
                                                Header: "Campo",
                                                accessor: "label",
                                                className: "align-middle text-center",
                                                headerClassName: "font-extra-bold",
                                              },
                                              {
                                                Header: "Valor Anterior",
                                                accessor: "oldValue",
                                                className: "align-middle text-center",
                                                headerClassName: "font-extra-bold",
                                              },
                                              {
                                                Header: "Valor Atual",
                                                accessor: "newValue",
                                                className: "align-middle text-center",
                                                headerClassName: "font-extra-bold",
                                              },
                                            ]}
                                          />
                                        </div>
                                      );
                                    }}
                                  />
                                ) : (
                                  <small>Nenhum registro encontrado</small>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDocumentsList() {
    if (!this.props.documents) {
      return null;
    }
    return this.props.documents.map((document, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{document.nome}</td>
          <td className="text-center align-middle">{document.descricao}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(37) && (
                <Link className="btn btn-default btn-sm" onClick={this.handleClickDownload(document.id, document.nome)}>
                  <i className="fas fa-image"></i> Download{" "}
                </Link>
              )}
              {this.props.currentUser.funcionalidades.includes(38) && (
                <Link className="btn btn-default btn-sm" onClick={this.handleClickDelete(document.id)}>
                  <i className="fas fa-trash-alt"></i> Excluir{" "}
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  renderValoresVigenciaList(valoresVigencia) {
    if (!valoresVigencia) {
      return null;
    }
    return valoresVigencia.map((valores, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATE(valores.dataVigencia)}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BR(valores.valorTaxa)}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BR(valores.valorTaxaERC)}</td>
          <td className="text-center align-middle">{valores.valorTaxaAlt ? MaskDecimal.TO_BR(valores.valorTaxaAlt) : 0}</td>
          <td className="text-center align-middle">{valores.valorTaxaAltERC ? MaskDecimal.TO_BR(valores.valorTaxaAltERC) : 0}</td>
          <td className="text-center align-middle">{valores.valorIntegracaoExterna ? MaskDecimal.TO_BR(valores.valorIntegracaoExterna) : 0} %</td>
          <td className="text-center align-middle">{!valores.codAgenteDetran ? "Não informado" : valores.codAgenteDetran}</td>
          <td className="text-center align-middle">{!valores.taxaPagaPorFinanceira ? "Não" : "Sim"}</td>
          {/* <td className="text-center align-middle">{valores.valorIntegracaoSNG ? MaskDecimal.TO_BR(valores.valorIntegracaoSNG) : 0}</td> */}
          {/* <td className="text-center align-middle">{!valores.habilitaMonitoramentoCrv || valores.habilitaMonitoramentoCrv == "N" ? "Não" : "Sim"}</td> */}
          {/* <td className="text-center align-middle">{valores.valorMonitoramentoCrv ? MaskDecimal.TO_BR(valores.valorMonitoramentoCrv) : 0}</td> */}
          {this.props.currentUser.funcionalidades.includes(34) && (
            <td className="align-middle text-center">
              <div className="btn-group">
                {index === 0 && (
                  <button type="button" className="btn btn-sm btn-default" onClick={this.handleOnSelectValueClick(valores)}>
                    <i className="far fa-edit" title="Editar"></i>
                  </button>
                )}

                {valoresVigencia.length > 1 && (
                  <Link rende className="btn btn-default btn-sm" onClick={this.handleClickDelVigenciaValores(valores.id)}>
                    <i className="fas fa-trash-alt" title="Excluir"></i>
                  </Link>
                )}
              </div>
            </td>
          )}
        </tr>
      );
    });
  }

  renderListContacts() {
    if (!this.props.contacts) {
      return null;
    }
    return this.props.contacts.map((contact, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{contact.nome}</td>
          <td className="text-center align-middle">{contact.email}</td>
          <td className="text-center align-middle">{contact.dddTelefone ? `(${contact.dddTelefone}) ${contact.numTelefone}` : "Não informado"}</td>
          <td className="text-center align-middle">{contact.cargo ? (contact.cargo == 1 ? "Diretor" : contact.cargo == 2 ? "Gestor" : "Não especificado") : "Não informado"}</td>
          {this.props.currentUser.funcionalidades.includes(35) && (
            <td className="align-middle text-center">
              <div className="btn-group">
                <Link className="btn btn-sm btn-default" onClick={() => this.handleOnSelectContactClick(contact)} title="Editar">
                  <i className="far fa-edit"></i>
                </Link>
                <Link className="btn btn-sm btn-default" onClick={() => this.onActionDelete(this.setState({ contact: contact }))}>
                  <i className="fas fa-trash" title="Excluir"></i>
                </Link>
              </div>
            </td>
          )}
        </tr>
      );
    });
  }
}

function validate(values) {
  const errors = {};
  if (values.correspondente && values.correspondente === "S" && !values.delegaPara) {
    errors.correspondente = "Operadora é obrigatória caso a financeira seja correspondente!";
  }
  return errors;
}

function validateVigenciaValores(values) {
  const errors = {};
  if (!values.ufTaxa || values.ufTaxa.length === 0 || !values.ufTaxa.trim()) {
    errors.ufTaxa = "Campo obrigatório";
  } else if (values.ufTaxa === "PB" || values.ufTaxa === "MA") {
    if (!values.codAgenteDetran || values.codAgenteDetran.length === 0 || !values.codAgenteDetran.trim()) {
      errors.codAgenteDetran = "Campo obrigatório";
    }
  }
  if (!values.valorTaxa || values.valorTaxa.length === 0 || !values.valorTaxa.trim() || values.valorTaxa == "0,00") {
    errors.valorTaxa = "Campo obrigatório";
  }
  if (!values.valorTaxaERC || values.valorTaxaERC.length === 0 || !values.valorTaxaERC.trim() || values.valorTaxaERC == "0,00") {
    errors.valorTaxaERC = "Campo obrigatório";
  }
  if (!values.dataVigencia || values.dataVigencia.length === 0 || !values.dataVigencia.trim()) {
    errors.dataVigencia = "Campo obrigatório";
  }
  return errors;
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FINANCIAL_EDIT_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: FINANCIAL_EDIT_UNLOAD, payload }),
  onOpenUploadArchive: (payload) => dispatch({ type: UPLOAD_ARCHIVE_OPEN, payload }),
  onDocumentsLoad: (payload) => dispatch({ type: DOCUMENT_LIST_LOAD, payload }),
  onDocumentsUnload: (payload) => dispatch({ type: DOCUMENT_LIST_UNLOAD, payload }),
  onLogLoad: (payload) => dispatch({ type: FINANCIAL_LIST_LOG_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: FINANCIAL_LIST_LOAD, payload }),
  onValoresVigenciaLoad: (payload) => dispatch({ type: FINANCIAL_LIST_VALUES_PERIOD_LOAD, payload }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onLoadParceiro: (payload) => dispatch({ type: FINANCIAL_PARCEIRO_LOAD, payload }),
  onContactsLoad: (payload) => dispatch({ type: CONTACT_LIST_LOAD, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
});

const selector = formValueSelector("FinancialEditForm");
const mapStateToProps = (state) => ({
  ...state.financial,
  vlTaxa: selector(state, "ufs")
    ? selector(state, "ufs").map((uf) => {
        return {
          uf: uf,
          valor: selector(state, "valorTaxa" + uf) ? selector(state, "valorTaxa" + uf) : "0",
        };
      })
    : [],
  vlTaxaERC: selector(state, "ufs")
    ? selector(state, "ufs").map((uf) => {
        return {
          uf: uf,
          valor: selector(state, "valorTaxaERC" + uf) ? selector(state, "valorTaxaERC" + uf) : "0",
        };
      })
    : [],
  valorTaxaAltERC: selector(state, "ufs")
    ? selector(state, "ufs").map((uf) => {
        return {
          uf: uf,
          valor: selector(state, "valorTaxaAltERC" + uf) ? selector(state, "valorTaxaAltERC" + uf) : "0",
        };
      })
    : [],
  valorTaxaAlt: selector(state, "ufs")
    ? selector(state, "ufs").map((uf) => {
        return {
          uf: uf,
          valor: selector(state, "valorTaxaAlt" + uf) ? selector(state, "valorTaxaAlt" + uf) : "0",
        };
      })
    : [],
  vlTaxaUpd: selector(state, "valorTaxa") ? selector(state, "valorTaxa") : "0",
  vlTaxaERCUpd: selector(state, "valorTaxaERC") ? selector(state, "valorTaxaERC") : "0",
  valorTaxaAltERCUpd: selector(state, "valorTaxaAltERC") ? selector(state, "valorTaxaAltERC") : "0",
  valorTaxaAltUpd: selector(state, "valorTaxaAlt") ? selector(state, "valorTaxaAlt") : "0",
  ufs: selector(state, "ufs"),
  statusValue: selector(state, "status"),
  estadoValue: selector(state, "estado"),
  selectedUf: selector(state, "endUf"),
  dataAssinaturaContrato: selector(state, "dataAssinaturaContrato"),
  vigencia: selector(state, "vigencia"),
  //integracaoExterna: selector(state, 'integracaoExterna'),
  idParceiro: selector(state, "idParceiro"),
  // integracaoRoboSng: selector(state, "integracaoRoboSng"),
  // monitoramentoCrv: selector(state, "monitoramentoCrv"),
  // habilitaValorMonit: selector(state, "habilitaMonitoramentoCrv"),
  dataCriacao: selector(state, "dataCriacao"),
  delegaPara: selector(state, "delegaPara"),
  initialValues: state.financial.financial
    ? {
        ...state.financial.financial,
        vigencia: MaskDate.TO_BR(state.financial.financial.vigencia),
        dataAssinaturaContrato: MaskDate.TO_BR(state.financial.financial.dataAssinaturaContrato),
        correspondente: state.financial.financial.cnpjCorrespondente ? "S" : "N",
        ufs: state.financial.financial.ufs ? state.financial.financial.ufs.map((uf) => uf.uf) : [],
        delegaPara: state.financial.financial.delegaPara ? { id: state.financial.financial.delegaPara } : null,
        dataCriacao: MaskDate.TO_BR(state.financial.financial.dataCriacao),
      }
    : null,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "FinancialEditForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialEdit));
