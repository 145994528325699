import { RELATORIO_MA_LIST_LOAD, RELATORIO_MA_UNLOAD, FINANCIAL_RELATORIO_MA_LOAD, RELATORIO_DETAILS_MA_LOAD, RELATORIO_DETAILS_MA_UNLOAD } from "../../actions/Constants";
const INITIAL_STATE = { companies: [], all: [], detailsList: [], showDetails: false };

export default function relatorioDetranMA(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_RELATORIO_MA_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case RELATORIO_MA_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : [],
      };
    case RELATORIO_DETAILS_MA_LOAD:
      return {
        ...state,
        showDetails: true,
        detailsList: action.payload ? action.payload : [],
      };
    case RELATORIO_DETAILS_MA_UNLOAD:
      return {
        ...state,
        showDetails: false,
        detailsList: [],
      };
    case RELATORIO_MA_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
