import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../stylesheets/components/datepicker.css";
import moment from "moment";

class TextDatePickerField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: undefined,
      focused: false,
    };
  }

  onDateChange = (date) => {
    if (moment.isMoment(date)) {
      this.setState({ date });
      this.props.input.onChange(moment(date).format("DD/MM/YYYY"));
    }
  };

  onDateChangeSet = (choice) => {
    let chosenDate = null;
    if (choice === "30") {
      chosenDate = moment().subtract(30, "days").format("DD/MM/YYYY");
    } else if (choice === "today") {
      chosenDate = moment().format("DD/MM/YYYY");
    }
    this.props.input.onChange(chosenDate);
    this.setState({ date: chosenDate, focused: false });
  };

  onFocusChange = () => {
    this.setState({ focused: !this.state.focused });
  };

  render() {
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? "has-error" : ""} ${this.props.inline ? "row" : ""}`}>
        <label title="Abrir Calendário" className={`btn-dtpicker font-weight-bold ${this.props.inline ? "col-sm-2  col-form-label" : ""}`}>
          {this.props.label}
          {/* &nbsp;&nbsp;<button type="button" style={{ "all": "unset" }} onClick={this.onFocusChange}><i className="fas fa-calendar"></i> </button> */}

          <div className={` ${this.props.required && "datePickerRequired"} ${this.props.inline ? "col-sm-10" : "col-sm-2"}`}>
            <SingleDatePicker
              id={this.props.id}
              date={!this.props.meta.error && this.props.data ? moment(this.props.data, "DD/MM/YYYY") : null}
              onDateChange={this.onDateChange}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.onFocusChange(focused)}
              numberOfMonths={1}
              withPortal={true}
              placeholder={this.props.placeholder ? this.props.placeholder : undefined}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel={true}
              daySize={this.props.daySize === "sm" ? 35 : 37}
              required={this.props.required}
              renderCalendarInfo={() => (
                <div className="actions-container">
                  <button className="btn-one" onClick={() => this.onDateChangeSet("30")}>
                    30 Dias Atrás
                  </button>
                  <button className="btn-two" onClick={() => this.onDateChangeSet("today")}>
                    Hoje
                  </button>
                  <button className="btn-clear" onClick={() => this.onDateChangeSet("clear")}>
                    Zerar
                  </button>
                </div>
              )}
            />
            {this.props.description && <span className="help-block small">{this.props.description}</span>}
          </div>
        </label>
        <div className={`${this.props.inline ? "col-sm-10" : ""}`}>
          <div className="d-flex">
            <div className="dtpInputContainer">
              <input
                {...this.props.input}
                title={this.props.title}
                type={this.props.type}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                maxlength={this.props.maxlength ? this.props.maxlength : ""}
                className={`${this.props.required ? (this.props.input.value && !this.props.meta.error ? "field-valid" : "field-required") : ""} ${
                  this.props.small ? "form-control form-control-sm" : "form-control"
                }`}
              />
            </div>
            <div className="dtpBtnContainer">
              <button type="button" onClick={this.onFocusChange} disabled={this.props.disabled} className="btn btn-secondary btn-block">
                <i className="fas fa-calendar"></i>{" "}
              </button>
            </div>
          </div>
          {this.props.description && <span className="help-block small">{this.props.description}</span>}
          <p className="text-danger">{this.props.meta.touched ? this.props.meta.error : ""}</p>
        </div>
      </div>
    );
  }
}

TextDatePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  inline: PropTypes.bool,
  description: PropTypes.string,
  small: PropTypes.bool,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  data: PropTypes.string,
};

export default TextDatePickerField;
