import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { Link, browserHistory } from "react-router";
import {} from "../../actions/Constants";
import { SketchPicker } from "react-color";
import TextField from "../../components/Fields/TextField";
import reactCSS from "reactcss";
import Dropzone from "react-dropzone";
import { hexToRgba, rgbaToHex } from "hex-and-rgba";
import api from "../../api/apiUtil";
import { toastr } from "react-redux-toastr";
import SelectField from "../../components/Fields/SelectField";
import { CONFIG_LOAD, CONFIG_UNLOAD, CONFIRM_ACTION_OPEN } from "../../actions/Constants";
import fileDownload from "js-file-download";

const initialState = {
  displayColorPicker: false,
  colorHex: "#62CB31",
  color: {
    r: null,
    g: null,
    b: null,
    a: null,
  },
  displayColorPicker2: false,
  colorHex2: "#3498DB",
  color2: {
    r: null,
    g: null,
    b: null,
    a: null,
  },
  displayColorPicker3: false,
  colorHex3: "#E74C3C",
  color3: {
    r: null,
    g: null,
    b: null,
    a: null,
  },
  displayColorPicker4: false,
  colorHex4: "#6A6C6F",
  color4: {
    r: null,
    g: null,
    b: null,
    a: null,
  },
  displayColorPicker5: false,
  colorHex5: "#f1f3f6",
  color5: {
    r: null,
    g: null,
    b: null,
    a: null,
  },
  imgLogo: null,
  imgFundo: null,
};

const Imagem = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} className="imageConfig" />;
class Configuracao extends React.Component {
  constructor() {
    super();
    this.state = initialState;
  }

  componentDidMount() {
    this.recuperaConfiguracao();
  }

  async recuperaConfiguracao() {
    await Promise.resolve(api.Configuracao.getPorAtributo("template")).then((res) => {
      if (res) {
        this.props.onConfigLoad(JSON.parse(res.valor));
      } else {
        this.props.onConfigLoad(null);
      }
      this.montaRgba();
    });
  }

  montaRgba() {
    this.setState(initialState);
    if (this.props.config) {
      this.setState({
        colorHex: "#" + this.props.config.color1,
        colorHex2: "#" + this.props.config.color2,
        colorHex3: "#" + this.props.config.color3,
        colorHex4: "#" + this.props.config.color4,
        colorHex5: "#" + this.props.config.color5,
        imgLogo: this.props.config.logo_sistema,
        imgFundo: this.props.config.img_fundo_login,
      });
    }
    this.montaEstados();
  }

  montaEstados() {
    let rgba, rgba2, rgba3, rgba4, rgba5;
    rgba = hexToRgba(this.state.colorHex);
    rgba2 = hexToRgba(this.state.colorHex2);
    rgba3 = hexToRgba(this.state.colorHex3);
    rgba4 = hexToRgba(this.state.colorHex4);
    rgba5 = hexToRgba(this.state.colorHex5);

    this.setState({
      color: {
        r: rgba[0],
        g: rgba[1],
        b: rgba[2],
        a: rgba[3],
      },
      color2: {
        r: rgba2[0],
        g: rgba2[1],
        b: rgba2[2],
        a: rgba2[3],
      },
      color3: {
        r: rgba3[0],
        g: rgba3[1],
        b: rgba3[2],
        a: rgba3[3],
      },
      color4: {
        r: rgba4[0],
        g: rgba4[1],
        b: rgba4[2],
        a: rgba4[3],
      },
      color5: {
        r: rgba5[0],
        g: rgba5[1],
        b: rgba5[2],
        a: rgba5[3],
      },
    });
  }

  formSubmit(values) {
    var props = this.props;
    let dados = {
      color1: this.state.colorHex.substr(1), // NECESSÁRIO REMOVER # POR CONTA DE UMA VALIDAÇÃO NO BACKEND NO INDEX.JS...
      color2: this.state.colorHex2.substr(1),
      color3: this.state.colorHex3.substr(1),
      color4: this.state.colorHex4.substr(1),
      color5: this.state.colorHex5.substr(1),
      img_logo_sistema: this.state.imgLogo,
      img_fundo_login: this.state.imgFundo,
      header: values.header,
      footer: values.footer,
      copyright: values.copyright,
      orientacaoMenu: !values.orientacaoMenu ? "0" : values.orientacaoMenu,
    };

    Promise.resolve(api.Configuracao.configLayout(dados))
      .then((response) => {
        this.recuperaConfiguracao();
        toastr.success("Sucesso", "Configuração cadastrada.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  onActionDelete() {
    this.props.onActionOpen({
      description: `Confirma a restaurar as configurações?`,
      onSuccess: this.handleClickDelete.bind(this),
    });
  }

  handleClickDelete = () => {
    var props = this.props;
    Promise.resolve(api.Configuracao.deleteConfigPorAtributo("template"))
      .then((response) => {
        this.recuperaConfiguracao();
        this.clearValues();
        toastr.success("Sucesso", "Configuração excluída!");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  clearValues() {
    this.props.dispatch(change("ConfiguracaoForm", "orientacaoMenu", null));
    this.props.dispatch(untouch("ConfiguracaoForm", "orientacaoMenu"));
    this.props.dispatch(change("ConfiguracaoForm", "footer", null));
    this.props.dispatch(untouch("ConfiguracaoForm", "footer"));
    this.props.dispatch(change("ConfiguracaoForm", "header", null));
    this.props.dispatch(untouch("ConfiguracaoForm", "header"));
    this.props.dispatch(change("ConfiguracaoForm", "copyright", null));
    this.props.dispatch(untouch("ConfiguracaoForm", "copyright"));
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClick2 = () => {
    this.setState({ displayColorPicker2: !this.state.displayColorPicker2 });
  };

  handleClick3 = () => {
    this.setState({ displayColorPicker3: !this.state.displayColorPicker3 });
  };

  handleClick4 = () => {
    this.setState({ displayColorPicker4: !this.state.displayColorPicker4 });
  };

  handleClick5 = () => {
    this.setState({ displayColorPicker5: !this.state.displayColorPicker5 });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleClose2 = () => {
    this.setState({ displayColorPicker2: false });
  };

  handleClose3 = () => {
    this.setState({ displayColorPicker3: false });
  };

  handleClose4 = () => {
    this.setState({ displayColorPicker4: false });
  };

  handleClose5 = () => {
    this.setState({ displayColorPicker5: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb, colorHex: color.hex });
  };

  handleChange2 = (color) => {
    this.setState({ color2: color.rgb, colorHex2: color.hex });
  };

  handleChange3 = (color) => {
    this.setState({ color3: color.rgb, colorHex3: color.hex });
  };

  handleChange4 = (color) => {
    this.setState({ color4: color.rgb, colorHex4: color.hex });
  };

  handleChange5 = (color) => {
    this.setState({ color5: color.rgb, colorHex5: color.hex });
  };

  onDropLogo(files) {
    var file = files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      this.setState({
        imgLogo: reader.result.substring(reader.result.indexOf(",") + 1),
      });
    }.bind(this);
    reader.readAsDataURL(file);
  }

  onDropFundo(files) {
    var file = files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      this.setState({
        imgFundo: reader.result.substring(reader.result.indexOf(",") + 1),
      });
    }.bind(this);
    reader.readAsDataURL(file);
  }

  onDrop(files) {
    var file = files[0];
    var textType = /text.*/;
    if (file.type.match(textType)) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var content = reader.result;
        //Here the content has been read successfuly
        var result = JSON.parse(content);
        this.setState({
          colorHex: "#" + result.color1,
          colorHex2: "#" + result.color2,
          colorHex3: "#" + result.color3,
          colorHex4: "#" + result.color4,
          colorHex5: "#" + result.color5,
          imgLogo: result.logo_sistema,
          imgFundo: result.img_fundo_login,
        });
        this.props.dispatch(change("ConfiguracaoForm", "orientacaoMenu", result.orientacaoMenu));
        this.props.dispatch(change("ConfiguracaoForm", "footer", result.footer));
        this.props.dispatch(change("ConfiguracaoForm", "header", result.header));
        this.props.dispatch(change("ConfiguracaoForm", "copyright", result.copyright));
        this.montaEstados();
      }.bind(this);
      reader.readAsText(file);
    }
  }

  handleClickDownload = () => {
    if (this.props.config) {
      fileDownload(JSON.stringify(this.props.config), "configuracao.txt");
      toastr.success("Sucesso", "Arquivo Gerado.");
    } else {
      toastr.warning("Aviso", "Configuração não cadastrada.");
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        color2: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a})`,
        },
        color3: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color3.r}, ${this.state.color3.g}, ${this.state.color3.b}, ${this.state.color3.a})`,
        },
        color4: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color4.r}, ${this.state.color4.g}, ${this.state.color4.b}, ${this.state.color4.a})`,
        },
        color5: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color5.r}, ${this.state.color5.g}, ${this.state.color5.b}, ${this.state.color5.a})`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
          float: "right",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-info btn-xs" onClick={() => this.handleClickDownload()}>
                      <i className="fa fa-download"></i> Baixar Configurações
                    </Link>
                    <Dropzone accept=".txt" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={this.onDrop.bind(this)}>
                      <Link type="button" className="btn btn-info btn-xs">
                        <i className="fa fa-upload" /> Carregar Arquivo
                      </Link>
                    </Dropzone>
                  </div>
                  Registros
                </div>
                <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <span>
                            <strong>Imagem de fundo da tela de login:</strong>{" "}
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <div>
                            {this.state.imgFundo && <Imagem data={this.state.imgFundo} />}
                            <Dropzone accept="image/*" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDropFundo.bind(this)}>
                              <Link className="btn btn-xs">
                                <i className="fas fa-upload" /> Alterar Imagem
                              </Link>
                            </Dropzone>
                          </div>
                        </div>
                        <p></p>
                        <div className="col-md-12">
                          <span>
                            <strong>Logo do sistema:</strong>{" "}
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <div>
                            {this.state.imgLogo && <Imagem data={this.state.imgLogo} />}
                            <Dropzone accept="image/*" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDropLogo.bind(this)}>
                              <Link className="btn btn-xs">
                                <i className="fas fa-upload" /> Alterar Imagem
                              </Link>
                            </Dropzone>
                          </div>
                        </div>
                        <p></p>
                        <div className="col-md-4">
                          <Field
                            name="orientacaoMenu"
                            label="Orientação do Menu"
                            data={[
                              { id: 0, desc: "Vertical" },
                              { id: 1, desc: "Horizontal" },
                            ]}
                            component={SelectField}
                            type="text"
                            textField="desc"
                            valueField="id"
                          />
                        </div>
                        <div className="col-md-12">
                          <Field name="header" label="Cabeçalho" component={TextField} type="text" maxlength={50} />
                        </div>
                        <div className="col-md-12">
                          <Field name="footer" label="Rodapé" component={TextField} type="text" maxlength={100} />
                        </div>
                        <div className="col-md-12">
                          <Field name="copyright" label="Copyright" component={TextField} type="text" maxlength={100} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-8">
                          <span style={{ verticalAlign: "super" }}>
                            <strong>Cor 1 (botão sucesso):</strong>{" "}
                          </span>
                          <div style={styles.swatch} onClick={this.handleClick}>
                            <div style={styles.color} />
                          </div>
                          {this.state.displayColorPicker ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleClose} />
                              <SketchPicker color={this.state.color} onChange={this.handleChange} />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                          <span style={{ verticalAlign: "super" }}>
                            <strong>Cor 2 (botão info):</strong>{" "}
                          </span>
                          <div style={styles.swatch} onClick={this.handleClick2}>
                            <div style={styles.color2} />
                          </div>
                          {this.state.displayColorPicker2 ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleClose2} />
                              <SketchPicker color={this.state.color2} onChange={this.handleChange2} />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                          <span style={{ verticalAlign: "super" }}>
                            <strong>Cor 3 (botão erro):</strong>{" "}
                          </span>
                          <div style={styles.swatch} onClick={this.handleClick3}>
                            <div style={styles.color3} />
                          </div>
                          {this.state.displayColorPicker3 ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleClose3} />
                              <SketchPicker color={this.state.color3} onChange={this.handleChange3} />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                          <span style={{ verticalAlign: "super" }}>
                            <strong>Cor 4 (bordas, cor do texto):</strong>{" "}
                          </span>
                          <div style={styles.swatch} onClick={this.handleClick4}>
                            <div style={styles.color4} />
                          </div>
                          {this.state.displayColorPicker4 ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleClose4} />
                              <SketchPicker color={this.state.color4} onChange={this.handleChange4} />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                          <span style={{ verticalAlign: "super" }}>
                            <strong>Cor 5 (fundo do sistema):</strong>{" "}
                          </span>
                          <div style={styles.swatch} onClick={this.handleClick5}>
                            <div style={styles.color5} />
                          </div>
                          {this.state.displayColorPicker5 ? (
                            <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleClose5} />
                              <SketchPicker color={this.state.color5} onChange={this.handleChange5} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-info" onClick={() => this.onActionDelete()}>
                            {" "}
                            Restaurar Padrões
                          </Link>
                          <button className="btn btn-success" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            Salvar
                          </button>
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onConfigLoad: (payload) => dispatch({ type: CONFIG_LOAD, payload }),
  onConfigUnload: (payload) => dispatch({ type: CONFIG_UNLOAD, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
});

const mapStateToProps = (state) => ({
  ...state.configuracao,
  initialValues: state.configuracao.config ? { ...state.configuracao.config } : null,
});

const form = reduxForm({
  form: "ConfiguracaoForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Configuracao));
