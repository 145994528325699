import {CONFIRM_NOT_HOMOLOGATE_OPEN, CONFIRM_NOT_HOMOLOGATE_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, description: null, onSuccess: null };

export default function confirmNotHomologate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_NOT_HOMOLOGATE_OPEN:
      return {
        ...state,
        open: true,
        description: action.payload.description,
        onSuccess: action.payload.onSuccess,
      };
    case CONFIRM_NOT_HOMOLOGATE_CLOSE:
    return {
      ...state,
      open: false,
      description: null,
      onSuccess: null,
    };

    // initial state
    default:
      return state;
  }
}
