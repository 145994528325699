import {
  CONTRACT_NOT_REGISTRED_LOAD,
  CONTRACT_NO_IMAGE_LOAD,
  CONTRACT_TOTAL_LOAD,
  CONTRACT_LIST_FILTER_SELECTED,
  CONTRACT_LIST_COMPANIES_LOAD,
  CONTRACT_REGISTERED_LOAD,
  CONTRACT_LIST_UNLOAD,
  CONTRACT_TOTAIS_LOAD,
} from "../../actions/Constants";

const INITIAL_STATE = { notRegistred: [], noImage: [], selectedFilter: "NR", contractList: null, qtdContracts: [{ semImagem: 0, naoEnviados: 0 }] };

export default function contractList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTRACT_LIST_UNLOAD:
      return INITIAL_STATE;
    case CONTRACT_NOT_REGISTRED_LOAD:
      return {
        ...state,
        notRegistred: action.payload ? action.payload : null,
      };
    case CONTRACT_NO_IMAGE_LOAD:
      return {
        ...state,
        noImage: action.payload ? action.payload : null,
      };
    case CONTRACT_TOTAL_LOAD:
      return {
        ...state,
        total: action.payload ? action.payload : null,
      };
    case CONTRACT_REGISTERED_LOAD:
      return {
        ...state,
        registered: action.payload ? action.payload : null,
      };
    case CONTRACT_LIST_FILTER_SELECTED:
      return {
        ...state,
        selectedFilter: action.payload ? action.payload : "NR",
      };
    case CONTRACT_LIST_COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case CONTRACT_TOTAIS_LOAD:
      return {
        ...state,
        qtdContracts: action.payload ? action.payload : null,
      };
    // initial state
    default:
      return state;
  }
}
