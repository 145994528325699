import {
  FINANCIAL_DASHBOARD_LIST_LOAD,
  FINANCIAL_DASHBOARD_LIST_UNLOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD,
  FINANCIAL_DASHBOARD_COMPANIES_LOAD,
  UF_SYSTEM_LOAD,
  FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_LOAD,
  FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO,
  FINANCIAL_INVALID,
  FINANCIAL_LIST_FILTER,
} from "../../actions/Constants";

const INITIAL_STATE = {
  all: [],
  financialDashboard: null,
  contractsExpired: {},
  contractsExpired7: {},
  contractsExpired15: {},
  contractsExpired30: {},
  contractsNotRegistred: 0,
  contractsRegistredError: 0,
  contractsRegistredWithoutImage: 0,
  contractsRegistred: 0,
  contractsCancel: 0,
  ufSystem: [],
  listFinancialInvalid: [],
  listFilter: [],
};

export default function financialDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_DASHBOARD_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_LIST_UNLOAD:
      return INITIAL_STATE;
    case FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD:
      return {
        ...state,
        contractsExpired: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD:
      return {
        ...state,
        contractsExpired7: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD:
      return {
        ...state,
        contractsExpired15: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD:
      return {
        ...state,
        contractsExpired30: action.payload ? action.payload : null,
      };
    case UF_SYSTEM_LOAD:
      return {
        ...state,
        ufSystem: action.payload ? action.payload : [],
      };
    case FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD:
      return {
        ...state,
        contractsNotRegistred: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD:
      return {
        ...state,
        contractsRegistredError: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD:
      return {
        ...state,
        contractsRegistredWithoutImage: action.payload ? action.payload : null,
      };
    case FINANCIAL_DASHBOARD_REGISTRED_LOAD:
      return {
        ...state,
        contractsRegistred: action.payload ? action.payload : null,
      };
    case FINANCIAL_INVALID:
      return {
        ...state,
        listFinancialInvalid: action.payload ? action.payload : [],
      };
    case FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO:
      return {
        ...state,
        contractsExpired: [],
        contractsExpired7: [],
        contractsExpired15: [],
        contractsExpired30: [],
        contractsNotRegistred: [],
        contractsRegistredError: [],
        contractsRegistredWithoutImage: [],
        contractsRegistred: [],
      };
    case FINANCIAL_LIST_FILTER:
      return {
        ...state,
        listFilter: action.payload ? action.payload : null,
      };
    default:
      return state;
  }
}
