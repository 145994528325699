import React from "react";
import { connect } from 'react-redux';
import { reduxForm} from 'redux-form';
import _ from 'lodash';
import Title from '../../components/Layout/Title';
import { STATUS_DETRAN_LOAD, STATUS_DETRAN_UNLOAD, STATUS_DETRAN_LOAD_AC } from '../../actions/Constants';
import api from '../../api/apiUtil';
import ToggleButton from 'react-toggle-button';

class StatusDetran extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            statusDetrans: []
        }
    }

    componentDidMount() {
        if(!this.props.currentUser.idFinanceira){
            Promise.resolve(api.Ufs.system()).then(res => {                
                for(const r of res){      
                    var ret= {}              
                    ret[r] = {}
                    ret[r] = {
                        "qtdAguardandoDetran": 0,
                        "status": ''
                    }
                    this.setState({statusDetrans: [...this.state.statusDetrans, ret]})
                }
                res.forEach(function (uf) {
                    this.recuperaStatus(uf);
                }.bind(this))
            })            
        }
    }

    recuperaStatus(uf){
        var props = this.props;
        var ret = {};
        Promise.resolve(api.Detrans.statusDetranUF(uf)).then(res => {            
            ret[Object.keys(res)] = {}
            ret[Object.keys(res)] = {
                "qtdAguardandoDetran": res[Object.keys(res)].qtdAguardandoDetran,
                "status": res[Object.keys(res)].status
            }
            this.alteraStatus(uf, ret);
          }).catch(function(resp) {
            if (resp.message === 'Unauthorized' || resp.message === 'jwt expired') {
                api.Auth.logout();
                props.onLogout();
            }else{
                ret[uf] = {}
                ret[uf] = {
                    "qtdAguardandoDetran": 0,
                    "status": 'Erro'
                }
                this.alteraStatus(uf, ret);
            }
        }.bind(this));
    }

    alteraStatus(uf, ret){
        for (const value of this.state.statusDetrans) {
            if(Object.keys(value)[0] === uf){
                value[uf].qtdAguardandoDetran = ret[uf].qtdAguardandoDetran
                value[uf].status = ret[uf].status	
                this.forceUpdate()	
                break;
            }
        }
    }

    componentWillUnmount() {
        this.props.onListUnloadStatusDetran();
    }

    render() {
        return (
            <div>
                <Title routes={this.props.routes} params={this.props.params} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="hpanel stats">
                            <div className="panel-body h-100">
                                {this.comunicacaoDetran()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    comunicacaoDetran() {
        if (!this.state.statusDetrans) {
            return null;
        }
        var estados = [];
        this.state.statusDetrans.forEach(key => {
            estados.push(key);
        })

        return estados.map((value, index) => {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <ul className="list-group m-t-sm">
                            <li className="list-group-item">
                                <div className="d-flex justify-content-between">
                                    <div>Comunicação Detran {Object.keys(estados[index])[0]}</div>
                                    <div>
                                        <ToggleButton
                                            value={(value[Object.keys(estados[index])[0]].status == "OK" ? true : false)}
                                            onToggle={() => ({})}
                                            colors={{
                                                activeThumb: {
                                                    base: 'rgb(250,250,250)',
                                                },
                                                inactiveThumb: {
                                                    base: 'rgb(250,250,250)',
                                                },
                                                active: {
                                                    base: 'rgb(98, 203, 49)',
                                                    hover: 'rgb(98, 203, 49)',
                                                },
                                                inactive: {
                                                    base: 'rgb(237, 5, 5)',
                                                    hover: 'rgb(237, 5, 5)',
                                                }
                                            }} />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="list-group m-t-sm">
                            <li className="list-group-item">
                                <div className="d-flex justify-content-between">
                                    <div>Aguardando Detran {Object.keys(estados[index])[0]}</div>
                                    <div>
                                        {value[Object.keys(estados[index])[0]].qtdAguardandoDetran > 0 ? (
                                            <span
                                                className="font-weight-bold float-left text-danger"
                                            >
                                                {value[Object.keys(estados[index])[0]].qtdAguardandoDetran}
                                            </span>
                                        ) : (
                                            "0"
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        })
    }

}

const mapDispatchToProps = dispatch => ({
    onListLoadStatusDetran: payload => dispatch({ type: STATUS_DETRAN_LOAD, payload }),
    onListUnloadStatusDetran: payload => dispatch({ type: STATUS_DETRAN_UNLOAD, payload }),
    onListLoadStatusDetranAC: payload => dispatch({ type: STATUS_DETRAN_LOAD_AC, payload }),
});

const mapStateToProps = state => ({
    ...state.statusDetran,
    currentUser: state.common.currentUser,
});

const form = reduxForm({
    form: 'StatusDetranForm',
});

export default connect(mapStateToProps, mapDispatchToProps)(form(StatusDetran));