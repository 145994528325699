import {PAYMENT_SLIP_MODAL_OPEN, PAYMENT_SLIP_MODAL_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, pay: null };

export default function paymentSlipModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PAYMENT_SLIP_MODAL_OPEN:
      return {
        ...state,
        open: true,
        pay: action.payload,
      };
    case PAYMENT_SLIP_MODAL_CLOSE:
    return {
      ...state,
      open: false,
    };

    // initial state
    default:
      return state;
  }
}
