import React, { Component, } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {Link} from 'react-router';
import Modal from 'react-bootstrap4-modal';
import {toastr} from 'react-redux-toastr'

import { CONFIRM_ACTION_CLOSE } from '../../actions/Constants';
import api from '../../api/apiUtil';

import TextField from '../../components/Fields/TextField';
import LabelField from '../../components/Fields/LabelField';
import FieldValidation from '../../components/Validation/FieldValidation';
import TextareaField from '../../components/Fields/TextareaField';

class ConfirmAction extends Component {

  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
  }

  formSubmit(props) {
    this.props.onSuccess(props);
    this.props.onClose();
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
        <div className="modal-header">
          <div>
            <h5 className="modal-title">{this.props.description}</h5>
          </div>
        </div>
        {this.props.justification && 
          <div className="modal-body">
            <Field name="justificativa" label="Justificativa" component={TextareaField} type="text" description="Justificativa da não aprovação" rows={5} validate={[ FieldValidation.required ]}/>
          </div>
        }        
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={this.onCloseClick} title="Não confirmar a operação e voltar para a tela anterior">
            Cancelar
          </button>
          <button type="button" className="btn btn-primary" title="Confirma a operação" onClick={handleSubmit(this.formSubmit.bind(this))}>
            Confirmar
          </button>
        </div>
        </form>
      </Modal>
    )

  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.confirmAction,
});

const mapDispatchToProps = dispatch => ({
  onClose: payload => dispatch({ type: CONFIRM_ACTION_CLOSE }),
});

const form = reduxForm({
  form: 'ConfirmActionForm'
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfirmAction));
