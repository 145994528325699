import React from 'react';
import Modal from 'react-bootstrap4-modal';

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {

    return (
        <Modal visible={showModal} onHide={handleClose}>
            <form id="loginForm">
                <div className="modal-header">
                <div>
                    <h4 >Sua sessão será finalizada por inatividade em <span id="time">00:30</span> segundos! Deseja permanecer no sistema?</h4>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={handleClose}>
                    Sim
                </button>
                <button type="button" className="btn btn-danger" onClick={handleLogout}>
                    Não
                </button>
                
                </div>
            </form>
        </Modal>
    )
}