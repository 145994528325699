import {NEWS_LIST_LOAD, NEWS_LIST_UNLOAD, NEWS_LOAD_UFS} from '../../actions/Constants';
  
  const INITIAL_STATE = { all: [], ufs: []};

  export default function news(state = INITIAL_STATE, action) {
    switch (action.type) {
      case NEWS_LIST_LOAD:
        return {
          ...state,
          all: action.payload ? action.payload : null,
        };
      case NEWS_LOAD_UFS:
        return {
          ...state,
          ufs: action.payload ? action.payload : [],
        };
      case NEWS_LIST_UNLOAD:
        return INITIAL_STATE;
      default:
        return state;
    }
  }