import {
  ALIAS_PROFILE_LIST_LOAD,
  ALIAS_PROFILE_LIST_UNLOAD,
  ALIAS_FEATURES_LIST_LOAD,
  ALIAS_FEATURES_LIST_UNLOAD,
  ALIAS_FEATURES_LIST_PERFIL_LOAD,
  ALIAS_FEATURES_LIST_PERFIL_UNLOAD,
  ALIAS_GROUP_VERIFY_LOAD,
  ALIAS_GROUP_VERIFY_UNLOAD,
  ALIAS_GROUP_CLICK,
  ALIAS_GROUP_UNLOAD_CLICK,
} from "../../actions/Constants";

const INITIAL_STATE = { allProfile: [], allfeatures: [], allfeaturesProfile: [], allGruposVerificados: [], disabledEnabledClicado: false };

export default function aliasProfile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALIAS_PROFILE_LIST_LOAD:
      return {
        ...state,
        allProfile: action.payload ? action.payload : null,
      };

    case ALIAS_FEATURES_LIST_LOAD:
      return {
        ...state,
        allfeatures: action.payload ? action.payload : null,
      };
    case ALIAS_FEATURES_LIST_PERFIL_LOAD:
      return {
        ...state,
        allfeaturesProfile: action.payload ? action.payload : null,
      };
    case ALIAS_GROUP_VERIFY_LOAD:
      return {
        ...state,
        allGruposVerificados: action.payload ? action.payload : null,
      };
    case ALIAS_GROUP_CLICK:
      return {
        ...state,
        disabledEnabledClicado: true,
      };
    case ALIAS_GROUP_UNLOAD_CLICK:
      return {
        ...state,
        disabledEnabledClicado: false,
      };
    case ALIAS_GROUP_VERIFY_UNLOAD:
    case ALIAS_PROFILE_LIST_UNLOAD:
    case ALIAS_FEATURES_LIST_UNLOAD:
    case ALIAS_FEATURES_LIST_PERFIL_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
