import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownList } from "react-widgets";

import AsyncSelect from "react-select/async";

import api from "../../api/apiUtil";

const loadOptions = (inputValue, callback) => {
  if (inputValue && inputValue.length >= 2) {
    Promise.resolve(api.Vehicles.getByBrandModel({ marca: inputValue })).then((values) => {
      callback(values);
    });
  } else {
    callback([]);
  }
};

class DropdownListFieldBrandModel extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);

    this.state = { brands: [] };
  }

  componentWillMount() {
    //Promise.resolve(api.Vehicles.getByBrandModel('')).then(values => { this.setState({brands: values}); });
  }

  loadOptions(inputValue, callback) {
    Promise.resolve(api.Vehicles.getByBrandModel(inputValue)).then((values) => {
      callback(values);
    });
  }

  handleChange(props) {
    console.log("--->PROPS==>", props);
    if (props.value && props.value.length >= 3) {
      Promise.resolve(api.Vehicles.getByBrandModel(props.value)).then((values) => {
        console.log("TAMANHO:", values.length);
        this.setState({ brands: values });
      });
    }
  }

  render() {
    const { label, options, input, rest } = this.props;
    console.log("->->", input);
    return (
      <div className={`form-group ${this.props.meta.touched && !this.props.meta.valid ? "has-error" : ""}`}>
        <label className={`font-weight-bold ${this.props.inline ? "col-sm-2  col-form-label" : ""}`}>{label}</label>
        <AsyncSelect
          name={input.name}
          value={{ descricao: input.value }}
          loadOptions={loadOptions}
          className={`${this.props.required ? (this.props.meta.valid && this.props.input.value ? "field-valid-select" : "form-control field-required-select") : ""}`}
          getOptionLabel={(option) => option.descricao}
          getOptionValue={(option) => option.descricao}
          onChange={(value) => {
            this.props.input.onChange(value ? value.descricao : null);
          }}
          noOptionsMessage={() => "Pesquise a marca e modelo."}
          isClearable
        />

        <p className="text-danger">{this.props.meta.touched ? this.props.meta.error : ""}</p>
      </div>
    );
  }
}

DropdownListFieldBrandModel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  required: PropTypes.bool,
};

/**
<DropdownList id="mm" {...input} messages={{emptyList: 'Pesquise a marca e modelo.'}}
data={this.state.brands} // requires value to be an array
textField="descricao"
valueField="descricao"
filter='contains'
onSearch={value => this.handleChange_({ value })}
onChange={(value) => {
    this.props.input.onChange(value.descricao);
  }
}

value={input.value || []}
{...rest}

/>
*/

export default DropdownListFieldBrandModel;
