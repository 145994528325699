import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError, change, untouch } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { UF_SYSTEM_LOAD, ERROS_DETRAN_LOAD, ERROS_DETRAN_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import SelectField from "../../components/Fields/SelectField";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import TextareaField from "../../components/Fields/TextareaField";

class ErroDetranEmail extends React.Component {
  componentDidMount() {
    this.props.onUfSystemLoad(api.Ufs.sigla_system());
  }

  componentWillUnmount() {
    this.props.onErrosDetranUnload();
  }

  async formSubmit(values) {
    var props = this.props;
    var param = {
      uf: values.uf,
      retornoDetran: values.retornoDetran,
      mensagem: values.mensagem,
    };
    Promise.resolve(api.Configuracao.createErroDetran(param))
      .then((ret) => {
        this.setState({ modalOpen: false });
        toastr.success("Sucesso", "Erro cadastrado");
        this.props.onErrosDetranLoad(api.Configuracao.getErrosDetran(param.uf));
        this.clearForm();
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  clearForm = () => {
    this.props.dispatch(change("ErroDetranEmailForm", "retornoDetran", null));
    this.props.dispatch(untouch("ErroDetranEmailForm", "retornoDetran"));
    this.props.dispatch(change("ErroDetranEmailForm", "mensagem", null));
    this.props.dispatch(untouch("ErroDetranEmailForm", "mensagem"));
  };

  async recuperaErros(event) {
    this.props.onErrosDetranLoad(await Promise.resolve(api.Configuracao.getErrosDetran(event.target.value)));
  }

  delete = (id) => {
    var props = this.props;
    Promise.resolve(api.Configuracao.delErroDetran(id))
      .then((ret) => {
        toastr.success("Sucesso", "Erro excluído");
        this.props.onErrosDetranLoad(api.Configuracao.getErrosDetran(props.selectedUf));
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Erros cadastrados para notificar cliente" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="row">
                  <div className="col-md-2">
                    <Field name="uf" label="Selecione a UF" data={this.props.ufSystem} blank={true} textField="sigla" component={SelectField} type="text" onChange={this.recuperaErros.bind(this)} />
                  </div>
                </div>
                {this.props.selectedUf && (
                  <div className="panel-body">
                    {this.props.currentUser.funcionalidades.includes(64) && (
                      <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                        <div className="row">
                          <div className="col-md-6">
                            <Field name="retornoDetran" label="Retorno Detran" component={TextareaField} type="text" rows={3} required validate={FieldValidation.required} />
                          </div>
                          <div className="col-md-6">
                            <Field name="mensagem" label="Mensagem E-mail" component={TextareaField} type="text" rows={3} required validate={FieldValidation.required} />
                          </div>
                          <div className="col-lg-2 align-self-end form-group">
                            <button className="btn btn-success" type="submit">
                              Salvar
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                    <hr></hr>
                    {this.props.errosDetran && this.props.errosDetran.length > 0 ? (
                      <div className="table-responsive">
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th className="text-center align-middle">Retorno Detran</th>
                              <th className="text-center align-middle">Mensagem E-mail</th>
                              <th className="text-center align-middle" style={{ width: "10%" }}>
                                Data Criação
                              </th>
                              <th style={{ width: "5%" }}></th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    if (!this.props.errosDetran) {
      return null;
    }
    return this.props.errosDetran.map((valor, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{valor.retornoDetran}</td>
          <td className="text-center align-middle">{valor.mensagem}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR(valor.dataCriacao)}</td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(65) && (
                <Link className="btn btn-sm btn-default" onClick={() => this.delete(valor.id)}>
                  <i className="fas fa-trash" title="Excluir"></i>
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onErrosDetranLoad: (payload) => dispatch({ type: ERROS_DETRAN_LOAD, payload }),
  onErrosDetranUnload: (payload) => dispatch({ type: ERROS_DETRAN_UNLOAD, payload }),
});

const selector = formValueSelector("ErroDetranEmailForm");
const mapStateToProps = (state) => ({
  ...state.erroDetranEmail,
  selectedUf: selector(state, "uf"),
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "ErroDetranEmailForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ErroDetranEmail));
