import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import SelectField from '../../components/Fields/SelectField';

import api from '../../api/apiUtil';


class CityField extends Component {

  constructor(props) {
    super(props);
    this.state = {cities: []};
  }

  componentWillMount() {
    if (this.props.selectedUf) {
      Promise.resolve(api.Ufs.cities(this.props.selectedUf)).then(values => { this.setState({cities: values}); });
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedUf) {
      if ((this.props.selectedUf !== nextProps.selectedUf) && nextProps.selectedUf) {
        Promise.resolve(api.Ufs.cities(nextProps.selectedUf.sigla ? nextProps.selectedUf.sigla : nextProps.selectedUf)).then(values => { this.setState({cities: values}); });
      }
    }

  }

  render() {
    if (this.props.ufs) {
      return (
      <Field name={this.props.name} id={this.props.name} label={this.props.label ? this.props.label : 'Município'} component={SelectField}
        data={this.state.cities} textField="municipio" valueField={this.props.valueField ? this.props.valueField : 'municipio'} small={false} blank={true} required={this.props.required}
        type="text" description={this.props.description ? this.props.description : null} validate={this.props.validate ? this.props.validate : []}
      />
      )
    }
    else {
      return null;
    }
  }
}

CityField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  validate: PropTypes.array,
  selectedUf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  valueField: PropTypes.string,
  required: PropTypes.bool,
};

const mapStateToProps = state => ({
  ...state.uf,
});

const mapDispatchToProps = dispatch => ({
  //onLoad: (payload) => dispatch({ type: UF_LOAD, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CityField);
