import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router";
import Modal from "react-bootstrap4-modal";
import { toastr } from "react-redux-toastr";

import { CONFIRM_OPERATION_CLOSE, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";

import TextField from "../../components/Fields/TextField";
import LabelField from "../../components/Fields/LabelField";
import TextIconField from "../../components/Fields/TextIconField";

class ConfirmOperation extends Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.onClose();
  }

  formSubmit(props) {
    var propsProperty = this.props;
    if (this.props.currentUser.desabilitaSenhaOperacao != 1) {
      Promise.resolve(api.Auth.checkOperationPassword(this.props.currentUser.user, props.passwordOperation))
        .then((auth) => {
          this.props.onSuccess();
          this.props.reset();
          this.props.onClose();
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            propsProperty.onClose();
            api.Auth.logout();
            propsProperty.onLogout();
          } else {
            toastr.warning("Atenção", "Senha inválida!");
          }
        });
    } else {
      this.props.onSuccess();
      this.props.reset();
      this.props.onClose();
    }
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <Modal visible={this.props.open} onClickBackdrop={this.onCloseClick}>
        <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
          <div className="modal-header">
            <div>
              <h5 className="modal-title">Confirmar Operação</h5>
              <span>
                <strong>{this.props.description}</strong>
              </span>
            </div>
          </div>
          {this.props.currentUser.desabilitaSenhaOperacao != 1 && (
            <div className="modal-body">
              <Field label="Usuário" name="usuario" component={LabelField} valueData={this.props.currentUser.user} type="text" />
              <Field
                name="passwordOperation"
                label="Senha de operação"
                component={TextIconField}
                type="password"
                description="Sua senha de operação"
                placeholder="******"
                title="Digite sua senha de operação"
                describePassword={true}
              />
            </div>
          )}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.onCloseClick} title="Não confirmar a operação e voltar para a tela anterior">
              Cancelar
            </button>
            <button type="button" className="btn btn-primary" title="Confirma a operação" onClick={handleSubmit(this.formSubmit.bind(this))}>
              Confirmar operação
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
  ...state.confirmOperation,
  initialValues: { username: state.common.currentUser ? state.common.currentUser.user : "", passwordOperation: "" },
});

const mapDispatchToProps = (dispatch) => ({
  onClose: (payload) => dispatch({ type: CONFIRM_OPERATION_CLOSE }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const form = reduxForm({
  form: "ConfirmOperationForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfirmOperation));
