import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr'

import "../../stylesheets/main.css";

import { REDIRECTED } from '../../actions/Constants';

class HomeApp extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      this.context.router.replace(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  render() {
    return (
      <div >
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          transitionIn="bounceInDown"
          transitionOut="bounceOutUp"
          progressBar/>

        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onRedirect: () => dispatch({ type: REDIRECTED }),
});

HomeApp.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeApp);
