import {CONFIRM_GERENCIAR_AUTENTICACAO_OPEN, CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, idFinanceira: null, onSuccess: null, gerenciarAutenticacao: false };

export default function confirmGerenciarAutenticacao(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_GERENCIAR_AUTENTICACAO_OPEN:
      return {
        ...state,
        open: true,
        idFinanceira: action.payload.idFinanceira,
        gerenciarAutenticacao: action.payload.gerenciarAutenticacao        
      };
    case CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE:
      return INITIAL_STATE;
    // initial state
    default:
      return state;
  }
}
