import { SPREADSHEET_ALL_COMPANIES_LOAD, SPREADSHEET_UNLOAD, SPREADSHEET_VISAO_FINANCEIRA_LOAD } from "../../actions/Constants";

const INITIAL_STATE = {
  allVisao: [],
  companies: [],
};

export default function spreadsheet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SPREADSHEET_ALL_COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case SPREADSHEET_VISAO_FINANCEIRA_LOAD:
      return {
        ...state,
        allVisao: action.payload ? action.payload : [],
      };
    case SPREADSHEET_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
