import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store.js";
import { router } from "./router.js";
import "moment/locale/pt-br";
import { unregister } from "./registerServiceWorker";
import "react-table/react-table.css";
import { detectIncognito } from "detectincognitojs";
//import ErrorBoundary from "./ErrorBoundary";

detectIncognito().then((result) => {
  //console.log(result.browserName, result.isPrivate);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const recovery = urlParams.get("recovery");
  ReactDOM.render(
    (process.env.REACT_APP_ENVIRONMENT == "production" || process.env.REACT_APP_ENVIRONMENT == "homolog") && !result.isPrivate && !recovery && !document.referrer.includes("aliasnet.com.br") ? (
      window.location.replace("https://www.aliasnet.com.br")
    ) : (
      <Provider store={store}>
        {/*<ErrorBoundary>*/}
        {router}
        {/*</ErrorBoundary>*/}
      </Provider>
    ),
    document.getElementById("app")
  );
});

unregister();
