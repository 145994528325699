import {USERS_LOAD, 
  USERS_UNLOAD, 
  USERS_LIST_LOAD, 
  USERS_USER_SELECT, 
  USER_LIST_LOG_LOAD, 
  USERS_CORRESPONDENTES_LOAD, 
  USERS_ONLINE_LIST_LOAD,
  USER_LIST_LOG_AUDIT_LOAD,
  USER_LIST_LOG_AUDIT_UNLOAD,
  EVENTS_LIST_LOAD,
  USERS_LIST_LOAD_AUDIT,
  USERS_LIST_LOAD_AUDIT_UNLOAD,
  USER_LOG_AUDIT_LOAD,
  USERS_LOAD_UFS,
  USERS_UNLOAD_UFS,
  USERS_LIST_FINANCIAL_LOAD} from '../../actions/Constants';

const INITIAL_STATE = { all: [], user: null, roles: [], log:[], correspondentes:[], usersOnline:[], allUsersAudit: [], allEvents: [], ufs: [], financeiras: []};

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS_LOAD:
      return {
        ...state,
        roles: action.payload ? action.payload[0] : [],
      };
    case USERS_UNLOAD:
    case USER_LIST_LOG_AUDIT_UNLOAD:
    case USERS_LIST_LOAD_AUDIT_UNLOAD:
    case USERS_UNLOAD_UFS:
      return INITIAL_STATE;

    case USERS_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : [],
        user: null,
      };
    case USERS_USER_SELECT:
      return {
        ...state,
        user: action.payload ? action.payload : null,
      };
      
    case USER_LIST_LOG_LOAD:
      return {
        ...state, 
        log: action.payload ? action.payload : []
      }
    case USERS_CORRESPONDENTES_LOAD:
      return {
        ...state, 
        correspondentes: action.payload ? action.payload : []
      }
    case USERS_ONLINE_LIST_LOAD:
      return {
        ...state, 
        usersOnline: action.payload ? action.payload : []
      }
    case USER_LIST_LOG_AUDIT_LOAD:
      return {
        ...state,
        allUsersAudit: action.payload ? action.payload : []
      }
    case EVENTS_LIST_LOAD:
      return {
        ...state,
        allEvents: action.payload ? action.payload : []
      }
    case USERS_LIST_LOAD_AUDIT:
      return {
        ...state,
        all: action.payload ? action.payload : []
      }
    case USER_LOG_AUDIT_LOAD:
      return {
        ...state,
        user: action.payload ? action.payload : null
      }
    case USERS_LOAD_UFS:
      return {
        ...state,
        ufs: action.payload ? action.payload : [],
      };
    case USERS_LIST_FINANCIAL_LOAD:
      return {
        ...state, 
        financeiras: action.payload ? action.payload : []
      }
    // initial state
    default:
      return state;
  }
}
