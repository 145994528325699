import moment from 'moment';

const TO_DATABASE = value => {
  return value ? moment(value, ["DD/MM/YYYY",'YYYY-MM-DD HH:mm:ss']).format("YYYY-MM-DD HH:mm:ss") : value;
}

const TO_DATABASE_DATE = value => {
  return value ? moment(value, ["DD/MM/YYYY",'YYYY-MM-DD HH:mm:ss']).format("YYYY-MM-DD") : value;
}

const TO_BR = value => {
  return value ? moment(value).format("DD/MM/YYYY") : value;
}

const TO_BR_DATETIME = value => {
  return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : value;
}

const TO_BR_DATE = value => {
    return value ? moment(value).format("DD/MM/YYYY") : value;
  }

const TO_YYYY_MM_DD = value => {
  return value ? moment(value).format("YYYY-MM-DD") : value;
}

const TO_MONTH_YEAR = value => {
  return value ? moment(value, ["YYYYMM",'YYYY-MM']).locale('pt-br').format("MMM/YYYY") : value;
}

const TO_LAST_DAY = value => {
  return value ? moment(value, ["YYYYMM",'YYYY-MM']).endOf('month').format("YYYY-MM-DD") : value;
}

const TO_MONTH = value => {
  return value ? moment(value, ["YYYYMM",'YYYY-MM']).locale('pt-br').format("MMMM") : value;
}

const IS_AFTER = (value1, value2) => {
  return value1 && value2 ? moment(moment(value1).format("YYYY-MM-DD")).isAfter(moment(value2).format("YYYY-MM-DD")) : false;
}

export default {
  TO_DATABASE,
  TO_BR,
  TO_BR_DATETIME,
  TO_BR_DATE,
  TO_DATABASE_DATE,
  TO_MONTH_YEAR,
  TO_LAST_DAY,
  TO_MONTH,
  TO_YYYY_MM_DD,
  IS_AFTER
};
