import {CONFIRM_ACTION_OPEN, CONFIRM_ACTION_CLOSE} from '../../actions/Constants';

const INITIAL_STATE = { open: false, description: null, onSuccess: null, justification: false, justificativa: '' };

export default function confirmAction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_ACTION_OPEN:
      return {
        ...state,
        open: true,
        description: action.payload.description,
        onSuccess: action.payload.onSuccess,
        justification: action.payload.justification,
        justificativa: ''
      };
    case CONFIRM_ACTION_CLOSE:
    return {
      ...state,
      open: false,
      description: null,
      onSuccess: null,
      justification: false,
      justificativa: ''
    };

    // initial state
    default:
      return state;
  }
}
