import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, change, formValueSelector } from "redux-form";
import _, { values } from "lodash";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { INTEGRATION_CERT_LOAD, INTEGRATION_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import TextField from "../../components/Fields/TextField";
import Dropzone from "react-dropzone";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import OtherUtils from "../../components/Util/OtherUtils";
import TextIconField from "../../components/Fields/TextIconField";
class Integration extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
      fileBase64: null,
      editando: false,
      editandoSng: false,
      editandoDetranPr: false,
      loading: false,
      fileBase64Rsng: null,
      editandoRsng: false,
    };
  }

  componentDidMount() {
    Promise.resolve(api.Configuracao.getDadosIntegracoes()).then((res) => {
      this.loadingProperties(res);
    });
  }

  componentWillUnmount() {
    this.props.onIntegrationUnload();
  }

  loadingProperties(parm) {
    let result = parm.find((item) => {
      return item.tipo === 1;
    });
    if (result) {
      this.props.onIntegrationCertLoad(result);
      this.handleOnSelectCert(result);
    }
    let resultSng = parm.find((item) => {
      return item.tipo === 2;
    });
    if (resultSng) {
      this.handleOnSelectSng(resultSng);
      this.setState({ editandoSng: false });
    } else {
      this.setState({ editandoSng: true });
    }

    let resultDetranPR = parm.find((item) => {
      return item.tipo === 4;
    });
    if (resultDetranPR) {
      this.handleOnSelectDetranPr(resultDetranPR);
      this.setState({ editandoDetranPr: false });
    } else {
      this.setState({ editandoDetranPr: true });
    }

    let resultRsng = parm.find((item) => {
      return item.tipo === 5;
    });
    if (resultRsng) {
      this.handleOnSelectRsng(resultRsng);
      this.setState({ editandoRsng: false });
    } else {
      this.setState({ editandoRsng: true });
    }

    this.setState({ loading: true, editando: false });
  }

  onDrop(files) {
    if (!files[0] || (files[0].type !== "pfx" && files[0].type !== "application/x-pkcs12")) {
      toastr.info("Atenção", "É permitido anexar somente .pfx.");
    } else {
      var file = files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        this.setState({
          fileBase64: reader.result.substring(reader.result.indexOf(",") + 1),
        });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  }

  onDropRsng(files) {
    if (!files[0] || !files[0].type.includes("zip")) {
      toastr.info("Atenção", "É permitido anexar somente .zip.");
    } else {
      var file = files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        this.setState({
          fileBase64Rsng: reader.result.substring(reader.result.indexOf(",") + 1),
        });
      }.bind(this);
      reader.readAsDataURL(file);
    }
  }

  cadastrar = (values) => {
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (!this.state.fileBase64) {
      toastr.warning("Atenção", "É obrigatório anexar o arquivo do certificado.");
    } else {
      var props = this.props;
      let param = {
        tipo: 1,
        dados: {
          arquivo: this.state.fileBase64,
          chave: values.senha,
        },
      };
      let result = OtherUtils.encrypt(Buffer.from(JSON.stringify(param)), new Date().toISOString().slice(0, 10)).toString("base64");
      Promise.resolve(api.Configuracao.insereIntegracao({ dados: result }))
        .then((ret) => {
          Promise.resolve(api.Configuracao.getDadosIntegracoes()).then((res) => {
            this.loadingProperties(res);
            this.setState({ fileBase64: null });
            this.props.dispatch(change("IntegrationForm", "senha", null));
          });
          toastr.success("Sucesso", "Certificado cadastrado.");
        })
        .catch(function (resp) {
          if (resp.status === 403) {
            toastr.warning("Atenção", resp.response.body.message);
          } else if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else if (!resp.status) {
            toastr.warning("Atenção", resp);
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  validateValues(values) {
    const errors = {};
    if (!values.senha || values.senha.length === 0) {
      errors.senha = "Campo obrigatório";
    }
    return errors;
  }

  validateValuesSng(values) {
    const errors = {};
    if (!values.codigo || values.codigo.length === 0) {
      errors.codigo = "Campo obrigatório";
    }
    if (!values.subcodigo || values.subcodigo.length === 0) {
      errors.subcodigo = "Campo obrigatório";
    }
    if (!values.login1 || values.login1.length === 0) {
      errors.login1 = "Campo obrigatório";
    }
    if (!values.login2 || values.login2.length === 0) {
      errors.login2 = "Campo obrigatório";
    }
    if (!values.senhaSng || values.senhaSng.length === 0) {
      errors.senhaSng = "Campo obrigatório";
    }
    if (!values.cpf || values.cpf.length === 0) {
      errors.cpf = "Campo obrigatório";
    }
    if (!values.email || values.email.length === 0) {
      errors.email = "Campo obrigatório";
    }
    return errors;
  }

  validateValuesDetranPr(values) {
    const errors = {};
    if (!values.clientId || values.clientId.length === 0) {
      errors.clientId = "Campo obrigatório";
    }
    if (!values.clientSecret || values.clientSecret.length === 0) {
      errors.clientSecret = "Campo obrigatório";
    }
    return errors;
  }

  handleOnSelectCert(cert) {
    this.props.dispatch(change("IntegrationForm", "dataCadastro", MaskDate.TO_BR_DATETIME(cert.dataCadastro)));
    this.props.dispatch(change("IntegrationForm", "dataVencimento", MaskDate.TO_BR_DATETIME(cert.dataVencimento)));
  }

  handleOnSelectSng(param) {
    this.props.dispatch(change("IntegrationForm", "dataCadastroSng", MaskDate.TO_BR_DATETIME(param.dataCadastro)));
  }

  handleOnSelectDetranPr(param) {
    this.props.dispatch(change("IntegrationForm", "dataCadastroDetranPR", MaskDate.TO_BR_DATETIME(param.dataCadastro)));
  }

  handleOnSelectRsng(param) {
    this.props.dispatch(change("IntegrationForm", "dataCadastroRsng", MaskDate.TO_BR_DATETIME(param.dataCadastro)));
  }

  cadastrarSNG = (values) => {
    const errors = this.validateValuesSng(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      var props = this.props;
      let param = {
        tipo: 2,
        dados: {
          codigo: values.codigo,
          subcodigo: values.subcodigo,
          login1: values.login1,
          login2: values.login2,
          senha: values.senhaSng,
          cpf: values.cpf,
          email: values.email,
        },
      };
      let result = OtherUtils.encrypt(Buffer.from(JSON.stringify(param)), new Date().toISOString().slice(0, 10)).toString("base64");
      Promise.resolve(api.Configuracao.insereIntegracao({ dados: result }))
        .then((ret) => {
          Promise.resolve(api.Configuracao.getDadosIntegracoes()).then((res) => {
            this.loadingProperties(res);
            this.clearFormSng();
          });
          toastr.success("Sucesso", "Credenciais cadastradas.");
        })
        .catch(function (resp) {
          if (resp.status === 403) {
            toastr.warning("Atenção", resp.response.body.message);
          } else if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else if (!resp.status) {
            toastr.warning("Atenção", resp);
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  cadastrarDetranPr = (values) => {
    const errors = this.validateValuesDetranPr(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      var props = this.props;
      let param = {
        tipo: 3,
        dados: {
          client_id: values.clientId,
          client_secret: values.clientSecret,
        },
      };
      let result = OtherUtils.encrypt(Buffer.from(JSON.stringify(param)), new Date().toISOString().slice(0, 10)).toString("base64");
      Promise.resolve(api.Configuracao.insereIntegracao({ dados: result }))
        .then((ret) => {
          Promise.resolve(api.Configuracao.getDadosIntegracoes()).then((res) => {
            this.loadingProperties(res);
            this.clearFormDetranPr();
          });
          toastr.success("Sucesso", "Credenciais cadastradas.");
        })
        .catch(function (resp) {
          if (resp.status === 403) {
            toastr.warning("Atenção", resp.response.body.message);
          } else if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else if (!resp.status) {
            toastr.warning("Atenção", resp);
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  cadastrarRsng = (values) => {
    const errors = this.validateValuesRsng(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (!this.state.fileBase64Rsng) {
      toastr.warning("Atenção", "É obrigatório anexar o arquivo .zip.");
    } else {
      var props = this.props;
      let param = {
        tipo: 5,
        dados: {
          pacote: this.state.fileBase64Rsng,
          chave: values.chaveAcessoRsng,
        },
      };
      let result = OtherUtils.encrypt(Buffer.from(JSON.stringify(param)), new Date().toISOString().slice(0, 10)).toString("base64");
      Promise.resolve(api.Configuracao.insereIntegracao({ dados: result }))
        .then((ret) => {
          Promise.resolve(api.Configuracao.getDadosIntegracoes()).then((res) => {
            this.loadingProperties(res);
            this.setState({ fileBase64Rsng: null });
            this.props.dispatch(change("IntegrationForm", "chaveAcessoRsng", null));
          });
          toastr.success("Sucesso", "Certificado cadastrado.");
        })
        .catch(function (resp) {
          if (resp.status === 403) {
            toastr.warning("Atenção", resp.response.body.message);
          } else if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else if (!resp.status) {
            toastr.warning("Atenção", resp);
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  validateValuesRsng(values) {
    const errors = {};
    if (!values.chaveAcessoRsng || values.chaveAcessoRsng.length === 0) {
      errors.chaveAcessoRsng = "Campo obrigatório";
    }
    return errors;
  }

  clearFormSng = () => {
    this.props.dispatch(change("IntegrationForm", "codigo", null));
    this.props.dispatch(change("IntegrationForm", "subcodigo", null));
    this.props.dispatch(change("IntegrationForm", "login1", null));
    this.props.dispatch(change("IntegrationForm", "login2", null));
    this.props.dispatch(change("IntegrationForm", "senhaSng", null));
    this.props.dispatch(change("IntegrationForm", "cpf", null));
    this.props.dispatch(change("IntegrationForm", "email", null));
  };

  clearFormDetranPr = () => {
    this.props.dispatch(change("IntegrationForm", "clientId", null));
    this.props.dispatch(change("IntegrationForm", "clientSecret", null));
  };

  render() {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content">
          <div className="row">
            {this.props.currentUser.funcionalidades.includes(46) && (
              <div className="col-lg-12">
                <form className="form-horizontal">
                  <div className="hpanel">
                    <div className="panel-heading">CERTIFICADO DIGITAL</div>
                    <div className="panel-body col-md-12">
                      {!this.state.editando && this.props.dadosCert ? (
                        <span>
                          <div className="m-b-md">
                            <h5>Dados do Certificado Cadastrado - Modelo A1</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="dataCadastro" label="Data de cadastro" component={TextField} type="text" normalize={FieldNormalize.DATE} disabled />
                            </div>
                            <div className="col-md-2">
                              <Field name="dataVencimento" label="Data de vencimento" component={TextField} type="text" normalize={FieldNormalize.DATE} disabled />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                <button className="btn btn-primary" type="button" onClick={() => this.setState({ editando: !this.state.editando })}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      ) : (
                        <span>
                          <div className="m-b-md">
                            <h5>Adicionar Certificado Digital - Modelo A1</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Dropzone accept=".pfx" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDrop.bind(this)}>
                                <button className="btn btn-success" type="button">
                                  <i className="fa fa-upload" /> Anexar arquivo
                                </button>
                              </Dropzone>
                            </div>
                            <div className="col-md-6">
                              <h6 className="font-weight-bold">{this.state.fileBase64 ? `Certificado anexado com sucesso!` : ""}</h6>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                required={true}
                                name="senha"
                                label="Senha do certificado"
                                component={TextIconField}
                                type="password"
                                description="Sua senha do certificado"
                                placeholder="******"
                                title="Digite a senha do certificado"
                                describePassword={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                {this.props.dadosCert && this.state.editando && (
                                  <button className="btn btn-default" type="button" onClick={() => this.setState({ editando: !this.state.editando })}>
                                    Cancelar
                                  </button>
                                )}
                                <button className="btn btn-success" type="button" onClick={this.props.handleSubmit(this.cadastrar.bind(this))}>
                                  Enviar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
            {false && this.props.currentUser.funcionalidades.includes(47) && this.props.currentUser.integracaoRoboSng == "S" && (
              <div className="col-lg-12">
                <form className="form-horizontal">
                  <div className="hpanel">
                    <div className="panel-heading">CREDENCIAIS SNG</div>
                    <div className="panel-body col-md-12">
                      {!this.state.editandoSng ? (
                        <span>
                          <div className="m-b-md">
                            <h5>Dados da credencial cadastrada</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="dataCadastroSng" label="Data de cadastro" component={TextField} type="text" normalize={FieldNormalize.DATE} disabled />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                <button className="btn btn-primary" type="button" onClick={() => this.setState({ editandoSng: !this.state.editandoSng })}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      ) : (
                        <span>
                          <div className="m-b-md">
                            <h5>Adicionar Credenciais SNG</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="codigo" label="Código" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="subcodigo" label="Subcódigo" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-1">
                              <Field name="login1" label="Login" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                            </div>
                            <div className="col-md-1">
                              <Field name="login2" label="&nbsp;" component={TextField} type="text" required={true} normalize={FieldNormalize.UPPERCASE} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Field
                                required={true}
                                name="senhaSng"
                                component={TextIconField}
                                type="password"
                                description="Senha de Acesso SNG"
                                placeholder="******"
                                label="Senha"
                                describePassword={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field
                                name="cpf"
                                normalize={FieldNormalize.CPF}
                                validate={[FieldValidation.cpf]}
                                description="CPF do usuário do SNG"
                                label="CPF"
                                component={TextField}
                                type="text"
                                required={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field required={true} name="email" label="Email" component={TextField} type="text" description="Email de Acesso SNG" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                {this.state.editandoSng && this.props.dataCadastroSng && (
                                  <button className="btn btn-default" type="button" onClick={() => this.setState({ editandoSng: !this.state.editandoSng })}>
                                    Cancelar
                                  </button>
                                )}
                                <button className="btn btn-success" type="button" onClick={this.props.handleSubmit(this.cadastrarSNG.bind(this))}>
                                  Salvar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
            {false && this.props.currentUser.funcionalidades.includes(49) && (
              <div className="col-lg-12">
                <form className="form-horizontal">
                  <div className="hpanel">
                    <div className="panel-heading">CREDENCIAIS GECON DETRAN-PR</div>
                    <div className="panel-body col-md-12">
                      {!this.state.editandoDetranPr ? (
                        <span>
                          <div className="m-b-md">
                            <h5>Dados da credencial cadastrada</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="dataCadastroDetranPR" label="Data de cadastro" component={TextField} type="text" normalize={FieldNormalize.DATE} disabled />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                <button className="btn btn-primary" type="button" onClick={() => this.setState({ editandoDetranPr: !this.state.editandoDetranPr })}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      ) : (
                        <span>
                          <div className="m-b-md">
                            <h5>Adicionar Credenciais GECON DETRAN-PR</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="clientId" label="Usuário (CPF)" component={TextField} type="text" required={true} normalize={FieldNormalize.CPF} />
                            </div>
                            <div className="col-md-2">
                              <Field name="clientSecret" label="Senha" component={TextIconField} type="password" required={true} describePassword={true} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                {this.state.editandoDetranPr && this.props.dataCadastroDetranPR && (
                                  <button className="btn btn-default" type="button" onClick={() => this.setState({ editandoDetranPr: !this.state.editandoDetranPr })}>
                                    Cancelar
                                  </button>
                                )}
                                <button className="btn btn-success" type="button" onClick={this.props.handleSubmit(this.cadastrarDetranPr.bind(this))}>
                                  Salvar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}

            {this.props.currentUser.funcionalidades.includes(50) && this.props.currentUser.monitoramentoCrv == "S" && (
              <div className="col-lg-12">
                <form className="form-horizontal">
                  <div className="hpanel">
                    <div className="panel-heading">PACOTE DE INTEGRAÇÃO RSNG</div>
                    <div className="panel-body col-md-12">
                      {!this.state.editandoRsng ? (
                        <span>
                          <div className="m-b-md">
                            <h5>Dados do pacote cadastrado</h5>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="dataCadastroRsng" label="Data de cadastro" component={TextField} type="text" normalize={FieldNormalize.DATE} disabled />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                <button className="btn btn-primary" type="button" onClick={() => this.setState({ editandoRsng: !this.state.editandoRsng })}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      ) : (
                        <span>
                          <div className="m-b-md">
                            <span className="font-weight-bold">Pacote de Acessos com Certificados Fornecidos Pela B3 (.zip)</span>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Dropzone accept=".zip" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDropRsng.bind(this)}>
                                <button className="btn btn-success" type="button">
                                  <i className="fa fa-upload" /> Anexar arquivo (.zip)
                                </button>
                              </Dropzone>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                required={true}
                                name="chaveAcessoRsng"
                                label="Chave de Acesso de Usuário Institucional"
                                component={TextIconField}
                                type="password"
                                placeholder="******"
                                describePassword={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-right">
                                {this.state.editandoRsng && this.props.dataCadastroRsng && (
                                  <button className="btn btn-default" type="button" onClick={() => this.setState({ editandoRsng: !this.state.editandoRsng })}>
                                    Cancelar
                                  </button>
                                )}
                                <button className="btn btn-success" type="button" onClick={this.props.handleSubmit(this.cadastrarRsng.bind(this))}>
                                  Salvar
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onIntegrationCertLoad: (payload) => dispatch({ type: INTEGRATION_CERT_LOAD, payload }),
  onIntegrationUnload: (payload) => dispatch({ type: INTEGRATION_UNLOAD, payload }),
});

const selector = formValueSelector("IntegrationForm");
const mapStateToProps = (state) => ({
  ...state.integration,
  currentUser: state.common.currentUser,
  dataCadastroSng: selector(state, "dataCadastroSng"),
  dataCadastroDetranPR: selector(state, "dataCadastroDetranPR"),
  dataCadastroRsng: selector(state, "dataCadastroRsng"),
});

const form = reduxForm({
  form: "IntegrationForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Integration));
