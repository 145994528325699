import React from "react";
import { connect } from 'react-redux';
import { reduxForm} from 'redux-form';
import { Link, browserHistory } from 'react-router';
import MaskDate from '../../components/Util/MaskDate';

import { USER_LIST_LOG_LOAD } from '../../actions/Constants';
import api from '../../api/apiUtil';

import Title from '../../components/Layout/Title';
import ReactTable from 'react-table'

class UserLogAcess extends React.Component {

  componentWillMount() {
    this.props.onListLoad(api.Users.allLogAcess(this.props.params.slug));
  }

  render() {
    return (
        <div>
          <Title routes = {this.props.routes} params = {this.props.params} description = "" /> <div className="content">

          <div className="row">
            <div className = "col-lg-12" >
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                  <Link className="btn btn-default " onClick={browserHistory.goBack}><i className="fas fa-arrow-left"></i> Voltar</Link>
                  </div>
                  <p>Acessos</p>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <ReactTable className="-striped -highlight"
                    data={this.props.log} previousText="Anterior" nextText="Próximo" loadingText="Carregando..."
                    noDataText="Nenhum registro encontrado" pageText="página" ofText="de" rowsText="linhas"
                    columns={[{
                      Header: 'DATA/HORÁRIO DE ACESSO',
                      accessor: 'row',
                      className: "align-middle text-center",
                      headerClassName: "font-extra-bold",
                      Cell: row => (<div>{MaskDate.TO_BR_DATETIME(row.original.data)}</div>)
                    }, {
                      Header: 'IP',
                      accessor: 'ip',
                      className: "align-middle text-center",
                      headerClassName: "font-extra-bold",
                    }]} defaultPageSize={20} showPageSizeOptions={false} minRows={1}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onListLoad: payload => dispatch({ type: USER_LIST_LOG_LOAD, payload }),
});

const mapStateToProps = state => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: 'UserLogAcessForm'
});

export default connect(mapStateToProps,mapDispatchToProps)(form(UserLogAcess));
