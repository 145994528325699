import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, change, formValueSelector } from "redux-form";
import _, { values } from "lodash";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { INTEGRATION_CERT_LOAD, INTEGRATION_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import TextField from "../../components/Fields/TextField";
import Dropzone from "react-dropzone";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import OtherUtils from "../../components/Util/OtherUtils";
import SelectField from "../../components/Fields/SelectField";
import { Link, browserHistory } from "react-router";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import ToggleButton from "react-toggle-button";
import ReactTable from "react-table";
import Modal from "react-bootstrap4-modal";

class AcompanhamentoSng extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  clearFormSng = () => {
    this.props.dispatch(change("IntegrationForm", "codigo", null));
    this.props.dispatch(change("IntegrationForm", "subcodigo", null));
    this.props.dispatch(change("IntegrationForm", "login1", null));
    this.props.dispatch(change("IntegrationForm", "login2", null));
    this.props.dispatch(change("IntegrationForm", "senhaSng", null));
    this.props.dispatch(change("IntegrationForm", "cpf", null));
    this.props.dispatch(change("IntegrationForm", "email", null));
  };

  formSubmit = async (values) => {};

  cadastrarSNG = (values) => {};

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <form className="form-horizontal">
                <div className="hpanel">
                  {/* <div className="panel-heading"> UFS Habilitadas</div> */}
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <Link className="btn btn-default" to="/secure/configuracaoSng">
                        <i className="fas fa-wrench"></i> Configurações
                      </Link>
                      <Link className="btn btn-info">
                        {" "}
                        <i className="fas fa-sync"></i> Atualizar{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="panel-heading">Última atualização: </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="table-responsive">
                        <ReactTable
                          className="-striped -highlight"
                          defaultPageSize={20}
                          data={this.props.all}
                          loadingText="Carregando..."
                          noDataText="Nenhuma Informação encontrada"
                          rowsText="linhas"
                          showPageSizeOptions={false}
                          minRows={1}
                          showPagination={true}
                          previousText="Anterior"
                          nextText="Próximo"
                          pageText="página"
                          ofText="de"
                          columns={[
                            {
                              Header: "Data Coleta",
                              accessor: "dataColeta",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Chassi",
                              accessor: "chassi",
                              className: "align-middle text-center",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Gravame",
                              accessor: "gravame",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Tipo",
                              accessor: "tipo",
                              className: "align-middle text-center",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Status",
                              accessor: "status",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Data Processado",
                              accessor: "dataProcessado",
                              className: "align-middle text-center",
                              headerClassName: "font-extra-bold",
                            },
                            {
                              Header: "Ação",
                              className: "align-middle text-center",
                              headerClassName: "font-extra-bold",
                              width: 190,
                              Cell: (row) => (
                                <div className="btn-group">
                                  <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                    {" "}
                                    Download{" "}
                                  </Link>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const selector = formValueSelector("AcompanhamentoSngForm");
const mapStateToProps = (state) => ({
  ...state.configuracaoSng,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "AcompanhamentoSngForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AcompanhamentoSng));
