import {
  FINANCIAL_LIST_LOAD,
  FINANCIAL_LIST_UNLOAD,
  FINANCIAL_EDIT_LOAD,
  FINANCIAL_EDIT_UNLOAD,
  FINANCIAL_INVOICE_LIST_LOAD,
  DOCUMENT_LIST_LOAD,
  DOCUMENT_LIST_UNLOAD,
  FINANCIAL_LIST_LOG_LOAD,
  USERS_ONLINE_LIST_LOAD,
  FINANCIAL_LIST_VALUES_PERIOD_LOAD,
  UF_SYSTEM_LOAD,
  FINANCIAL_PARCEIRO_LOAD,
  CONTACT_LIST_LOAD,
} from "../../actions/Constants";

const INITIAL_STATE = { all: [], financial: null, editLoad: false, registros: [], documents: [], log: [], usersOnline: [], valoresVigencia: [], ufSystem: [], parceiros: [], contacts: [] };

export default function financial(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };
    case FINANCIAL_INVOICE_LIST_LOAD:
      return {
        ...state,
        registros: action.payload ? action.payload : null,
      };
    case FINANCIAL_EDIT_LOAD:
      return {
        ...state,
        financial: action.payload ? action.payload : null,
        editLoad: true,
      };
    case DOCUMENT_LIST_LOAD:
      return {
        ...state,
        documents: action.payload ? action.payload : null,
      };
    case FINANCIAL_LIST_LOG_LOAD:
      return {
        ...state,
        log: action.payload ? action.payload : null,
      };
    case USERS_ONLINE_LIST_LOAD:
      return {
        ...state,
        usersOnline: action.payload ? action.payload : [],
      };
    case FINANCIAL_LIST_VALUES_PERIOD_LOAD:
      return {
        ...state,
        valoresVigencia: action.payload ? action.payload : [],
      };
    case UF_SYSTEM_LOAD:
      return {
        ...state,
        ufSystem: action.payload ? action.payload : [],
      };
    case FINANCIAL_PARCEIRO_LOAD:
      return {
        ...state,
        parceiros: action.payload ? action.payload : [],
      };
    case CONTACT_LIST_LOAD:
      return {
        ...state,
        contacts: action.payload ? action.payload : [],
      };
    case FINANCIAL_LIST_UNLOAD:
    case FINANCIAL_EDIT_UNLOAD:
    case DOCUMENT_LIST_UNLOAD:
      return INITIAL_STATE;

    default:
      return state;
  }
}
