import {MANAGEMENT_REPORT_LOAD, MANAGEMENT_REPORT_UNLOAD, MANAGEMENT_REPORT_LIST_LOAD, UF_SYSTEM_LOAD, MANAGEMENT_REPORT_PARCEIRO_LOAD} from '../../actions/Constants';

const INITIAL_STATE = { companies: [], data: [], parceiros: []};

export default function managementReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MANAGEMENT_REPORT_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
        data: [],
      };

    case MANAGEMENT_REPORT_LIST_LOAD:
      return {
        ...state,
        data: action.payload ? action.payload : null,
      };

    case MANAGEMENT_REPORT_UNLOAD:
      return INITIAL_STATE;

    case UF_SYSTEM_LOAD:
      return {
        ...state, 
        ufSystem: action.payload ? action.payload : []
      }
    case MANAGEMENT_REPORT_PARCEIRO_LOAD:
      return {
        ...state, 
        parceiros: action.payload ? action.payload : []
      }
    default:
      return state;
  }
}
