import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";

import {
  ALIAS_FEATURES_LIST_LOAD,
  ALIAS_FEATURES_LIST_UNLOAD,
  ALIAS_FEATURES_LIST_PERFIL_LOAD,
  ALIAS_FEATURES_LIST_PERFIL_UNLOAD,
  ALIAS_GROUP_VERIFY_LOAD,
  ALIAS_GROUP_VERIFY_UNLOAD,
  ALIAS_GROUP_CLICK,
  ALIAS_GROUP_UNLOAD_CLICK,
  LOGOUT,
} from "../../actions/Constants";

import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import { toastr } from "react-redux-toastr";

var totalQuadrante1;
var totalQuadrante2;
var totalQuadrante3;
var posicaoAtual;
var listChecked = new Set();

class AliasProfileFeatures extends React.Component {
  componentWillMount() {
    Promise.resolve(api.Feature.allAlias()).then((resultsFeature) => {
      Promise.resolve(api.Feature.perfilAlias(this.props.params.slug)).then((resultsFeaturePerfil) => {
        let gruposDistintos = resultsFeature.filter(function (a) {
          return !this[a.grupo] && (this[a.grupo] = true);
        }, Object.create(null));

        let allgruposVerificados = [];
        gruposDistintos.forEach(function (value) {
          let allFuncionalidadesGrupo = resultsFeature.filter((r) => r.grupo === value.grupo);
          var grupoControle = {
            grupo: value.grupo,
            todasfuncAtribuidas: true,
          };
          allgruposVerificados.push(grupoControle);
          for (var funcGrupo of allFuncionalidadesGrupo) {
            if (resultsFeaturePerfil.filter((res) => res.idFuncionalidadeAlias == funcGrupo.id).length < 1) {
              //VERIFICA SE TEM ALGUMA FUNCIONALIDADE DO GRUPO SEM ATRIBUIÇÃO AO PERFIL
              let objIndex = allgruposVerificados.findIndex((obj) => obj.grupo == funcGrupo.grupo);
              allgruposVerificados[objIndex].todasfuncAtribuidas = false;
              break;
            }
          }
        });
        this.props.onListGroupVerify(allgruposVerificados);
        this.props.onListLoadFeaturePerfil(resultsFeaturePerfil);
      });
      this.props.onListLoadFeature(resultsFeature);
    });
  }

  componentWillUnmount() {
    this.props.onUnloadFeature();
    this.props.onListUnloadFeaturePerfil();
    this.props.onListUnloadGroupVerify();
    listChecked = new Set();
  }

  formSubmit(values) {
    var props = this.props;
    var perfilFuncionalidade = {
      idPerfilAlias: this.props.params.slug,
      idsFuncionalidadeAlias: [...listChecked],
    };
    Promise.resolve(api.Feature.insertUpdateProfileFeaturesAlias(perfilFuncionalidade))
      .then((ret) => {
        toastr.success("Sucesso", "Perfil alterado");
        this.props.reset();
        this.props.onListLoadFeature(Promise.resolve(api.Feature.allAlias()));
        this.props.onListLoadFeaturePerfil(Promise.resolve(api.Feature.perfilAlias(this.props.params.slug)));
        this.props.onUnloadDisabledEnabledClick();
        listChecked = new Set();
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleInputChange(value) {
    var funcionalidadesFilter;
    if (value.target.name == " Menu") {
      funcionalidadesFilter = this.props.allfeatures.filter(function (func) {
        return func.funcionalidadePai == value.target.id;
      });
    }

    if (value.target.checked) {
      if (funcionalidadesFilter) {
        funcionalidadesFilter.map((funcionalidade, index) => {
          document.getElementById(funcionalidade.id).disabled = false;
        });
      }
      listChecked.add(parseInt(value.target.id));
    } else {
      if (funcionalidadesFilter) {
        funcionalidadesFilter.map((funcionalidade, index) => {
          document.getElementById(funcionalidade.id).checked = false;
          document.getElementById(funcionalidade.id).disabled = true;
          listChecked.delete(funcionalidade.id);
        });
      }
      listChecked.delete(parseInt(value.target.id));
    }

    let funcionalidadeSel = this.props.allfeatures.filter((f) => f.id == value.target.id);
    let allFuncionalidadesGrupo = this.props.allfeatures.filter((f) => f.grupo === funcionalidadeSel[0].grupo);
    let listGrupos = this.props.allGruposVerificados.slice();
    let index = listGrupos.findIndex((obj) => obj.grupo == funcionalidadeSel[0].grupo);
    listGrupos.splice(index, 1);
    var grupoControle = {
      grupo: funcionalidadeSel[0].grupo,
      todasfuncAtribuidas: true,
    };

    for (var funcGrupo of allFuncionalidadesGrupo) {
      if (Array.from(listChecked).filter((res) => res == funcGrupo.id).length < 1) {
        grupoControle.todasfuncAtribuidas = false;
        break;
      }
    }
    this.props.onListGroupVerify([grupoControle, ...listGrupos]);
    this.props.onDisabledEnabledClick();
  }

  state = {
    accordion: false,
  };

  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  };

  handleDisabledEnableAll() {
    let e = window.event;
    let value = e.target.id || e.srcElement.id;

    let funcionalidadesFilter = this.props.allfeatures.filter((f) => f.grupo === value);
    let grupo = this.props.allGruposVerificados.filter((g) => g.grupo === value);
    let listGrupos = this.props.allGruposVerificados.slice();
    let temMenu = funcionalidadesFilter.filter((f) => f.nome === " Menu").length > 0;

    funcionalidadesFilter.forEach(function (func) {
      document.getElementById(func.id).checked = !grupo[0].todasfuncAtribuidas;

      if (temMenu && func.nome !== " Menu") {
        document.getElementById(func.id).disabled = grupo[0].todasfuncAtribuidas;
      }

      if (grupo[0].todasfuncAtribuidas) {
        listChecked.delete(func.id);
      } else {
        listChecked.add(func.id);
      }
    });

    let index = listGrupos.findIndex((obj) => obj.grupo == grupo[0].grupo);
    listGrupos.splice(index, 1);
    this.props.onListGroupVerify([{ grupo: grupo[0].grupo, todasfuncAtribuidas: !grupo[0].todasfuncAtribuidas }, ...listGrupos]);
    this.props.onDisabledEnabledClick();
  }

  getItems(quadrante) {
    var distinctGrupos = this.props.allfeatures.filter(function (a) {
      return !this[a.grupo] && (this[a.grupo] = true);
    }, Object.create(null));
    var allFeaturesProfile = this.props.allfeaturesProfile;

    var qtdTotal = distinctGrupos.length;
    var total = qtdTotal / 3;
    const items = [];

    if (quadrante == 1) {
      totalQuadrante1 = Math.ceil(total);
      var paiChecked = [];
      posicaoAtual = 0;
      for (let i = 0; i < totalQuadrante1; i++) {
        var funcionalidadesFilter = this.props.allfeatures.filter(function (func) {
          return func.grupo == distinctGrupos[posicaoAtual].grupo;
        });

        items.push(
          <Panel header={`${distinctGrupos[i].grupo}`} key={distinctGrupos[i].grupo}>
            <button
              className="btn btn-xs btn-info"
              type="button"
              id={distinctGrupos[i].grupo}
              onClick={() => {
                this.handleDisabledEnableAll();
              }}
            >
              {this.props.allGruposVerificados.filter((g) => g.grupo == distinctGrupos[i].grupo && g.todasfuncAtribuidas).length > 0 ? "Desmarcar todas" : "Marcar todas"}
            </button>
            <br />
            <br />
            {funcionalidadesFilter.map((funcionalidade, index) => {
              var checked = false;
              var desabilita = false;
              if (allFeaturesProfile && allFeaturesProfile.length > 0 && allFeaturesProfile.map((f) => f.idFuncionalidadeAlias).includes(funcionalidade.id)) {
                !this.props.disabledEnabledClicado ? listChecked.add(funcionalidade.id) : "";
                if (funcionalidade.nome == " Menu") {
                  paiChecked.push(funcionalidade.id);
                  checked = true;
                } else if ((funcionalidade.funcionalidadePai && paiChecked.includes(funcionalidade.funcionalidadePai)) || !funcionalidade.funcionalidadePai) {
                  checked = true;
                } else {
                  desabilita = true;
                }
              } else {
                if (funcionalidade.funcionalidadePai && !paiChecked.includes(funcionalidade.funcionalidadePai)) {
                  desabilita = true;
                }
                checked = false;
              }
              return (
                <div>
                  <label>
                    <input type="checkbox" disabled={desabilita} name={funcionalidade.nome} id={funcionalidade.id} defaultChecked={checked} onChange={this.handleInputChange.bind(this)} />
                    {" " + funcionalidade.nome}
                  </label>
                </div>
              );
            })}
          </Panel>
        );
        posicaoAtual++;
      }
    } else if (quadrante == 2) {
      totalQuadrante2 = Math.round(total);
      var paiChecked = [];
      for (let i = 0; i < totalQuadrante2; i++) {
        var funcionalidadesFilter = this.props.allfeatures.filter(function (func) {
          return func.grupo == distinctGrupos[posicaoAtual].grupo;
        });
        items.push(
          <Panel header={`${distinctGrupos[posicaoAtual].grupo}`} key={distinctGrupos[posicaoAtual].grupo}>
            <button
              className="btn btn-xs btn-info"
              type="button"
              id={distinctGrupos[posicaoAtual].grupo}
              onClick={() => {
                this.handleDisabledEnableAll();
              }}
            >
              {this.props.allGruposVerificados.filter((g) => g.grupo == distinctGrupos[posicaoAtual].grupo && g.todasfuncAtribuidas).length > 0 ? "Desmarcar todas" : "Marcar todas"}
            </button>
            <br />
            <br />
            {funcionalidadesFilter.map((funcionalidade, index) => {
              var checked = false;
              var desabilita = false;
              if (allFeaturesProfile && allFeaturesProfile.length > 0 && allFeaturesProfile.map((f) => f.idFuncionalidadeAlias).includes(funcionalidade.id)) {
                !this.props.disabledEnabledClicado ? listChecked.add(funcionalidade.id) : "";
                if (funcionalidade.nome == " Menu") {
                  paiChecked.push(funcionalidade.id);
                  checked = true;
                } else if ((funcionalidade.funcionalidadePai && paiChecked.includes(funcionalidade.funcionalidadePai)) || !funcionalidade.funcionalidadePai) {
                  checked = true;
                } else {
                  desabilita = true;
                }
              } else {
                if (funcionalidade.funcionalidadePai && !paiChecked.includes(funcionalidade.funcionalidadePai)) {
                  desabilita = true;
                }
                checked = false;
              }
              return (
                <div>
                  <label>
                    <input type="checkbox" disabled={desabilita} name={funcionalidade.nome} id={funcionalidade.id} defaultChecked={checked} onChange={this.handleInputChange.bind(this)} />
                    {" " + funcionalidade.nome}
                  </label>
                </div>
              );
            })}
          </Panel>
        );
        posicaoAtual++;
      }
    } else if (quadrante == 3) {
      totalQuadrante3 = Math.trunc(total);
      var paiChecked = [];
      for (let i = 0; i < totalQuadrante3; i++) {
        var funcionalidadesFilter = this.props.allfeatures.filter(function (func) {
          return func.grupo == distinctGrupos[posicaoAtual].grupo;
        });
        items.push(
          <Panel header={`${distinctGrupos[posicaoAtual].grupo}`} key={distinctGrupos[posicaoAtual].grupo}>
            <button
              className="btn btn-xs btn-info"
              type="button"
              id={distinctGrupos[posicaoAtual].grupo}
              onClick={() => {
                this.handleDisabledEnableAll();
              }}
            >
              {this.props.allGruposVerificados.filter((g) => g.grupo == distinctGrupos[posicaoAtual].grupo && g.todasfuncAtribuidas).length > 0 ? "Desmarcar todas" : "Marcar todas"}
            </button>
            <br />
            <br />
            {funcionalidadesFilter.map((funcionalidade, index) => {
              var checked = false;
              var desabilita = false;
              if (allFeaturesProfile && allFeaturesProfile.length > 0 && allFeaturesProfile.map((f) => f.idFuncionalidadeAlias).includes(funcionalidade.id)) {
                !this.props.disabledEnabledClicado ? listChecked.add(funcionalidade.id) : "";
                if (funcionalidade.nome == " Menu") {
                  paiChecked.push(funcionalidade.id);
                  checked = true;
                } else if ((funcionalidade.funcionalidadePai && paiChecked.includes(funcionalidade.funcionalidadePai)) || !funcionalidade.funcionalidadePai) {
                  checked = true;
                } else {
                  desabilita = true;
                }
              } else {
                if (funcionalidade.funcionalidadePai && !paiChecked.includes(funcionalidade.funcionalidadePai)) {
                  desabilita = true;
                }
                checked = false;
              }
              return (
                <div>
                  <label>
                    <input type="checkbox" disabled={desabilita} name={funcionalidade.nome} id={funcionalidade.id} defaultChecked={checked} onChange={this.handleInputChange.bind(this)} />
                    {" " + funcionalidade.nome}
                  </label>
                </div>
              );
            })}
          </Panel>
        );
        posicaoAtual++;
      }
    }
    return items;
  }

  render() {
    const { handleSubmit, reset } = this.props;
    const accordion = this.state.accordion;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Configuração de Perfil de Usuário Alias" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Funcionalidades</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-4">
                        <Collapse accordion={accordion} onChange={this.onChange}>
                          {this.getItems(1)}
                        </Collapse>
                      </div>

                      <div className="col-md-4">
                        <Collapse accordion={accordion} onChange={this.onChange}>
                          {this.getItems(2)}
                        </Collapse>
                      </div>

                      <div className="col-md-4">
                        <Collapse accordion={accordion} onChange={this.onChange}>
                          {this.getItems(3)}
                        </Collapse>
                      </div>
                    </div>{" "}
                    <br></br>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                          <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFuncionalidades(grupo) {
    if (!this.props.allfeatures) {
      return null;
    }

    var funcionalidadesDoGrupo = [];
    for (let i = 0; i < this.props.allfeatures.length; i++) {
      if (this.props.allfeatures[i].grupo === grupo) {
        funcionalidadesDoGrupo.push(this.props.allfeatures[i]);
      }
    }

    return this.funcionalidadesDoGrupo.map((funcionalidade, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{funcionalidade.nome}</td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoadFeature: (payload) => dispatch({ type: ALIAS_FEATURES_LIST_LOAD, payload }),
  onUnloadFeature: (payload) => dispatch({ type: ALIAS_FEATURES_LIST_UNLOAD, payload }),
  onListLoadFeaturePerfil: (payload) => dispatch({ type: ALIAS_FEATURES_LIST_PERFIL_LOAD, payload }),
  onListUnloadFeaturePerfil: (payload) => dispatch({ type: ALIAS_FEATURES_LIST_PERFIL_UNLOAD, payload }),
  onListGroupVerify: (payload) => dispatch({ type: ALIAS_GROUP_VERIFY_LOAD, payload }),
  onListUnloadGroupVerify: (payload) => dispatch({ type: ALIAS_GROUP_VERIFY_UNLOAD, payload }),
  onDisabledEnabledClick: (payload) => dispatch({ type: ALIAS_GROUP_CLICK }),
  onUnloadDisabledEnabledClick: (payload) => dispatch({ type: ALIAS_GROUP_UNLOAD_CLICK }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.aliasProfile,
});

const form = reduxForm({
  form: "AliasProfileFeaturesForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AliasProfileFeatures));
