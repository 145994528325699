import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import Dropzone from "react-dropzone";

import { LOTE_LIST_LOAD, LOTE_LIST_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
import fileDownload from "js-file-download";

class Lote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detalhar: [],
    };
  }

  componentDidMount() {
    if (this.props.currentUser.cnpj == "00000000000191") {
      this.props.onListLoad(api.Contracts.getLoteBB());
    } else if (this.props.currentUser.cnpj == "10371492000185") {
      this.props.onListLoad(api.Contracts.getLotePAN());
    } else if (this.props.currentUser.cnpj === "92228410000102" || this.props.currentUser.cnpj === "60850229000147") {
      this.props.onListLoad(api.Contracts.getLoteOMNI());
    } else {
      this.props.onListLoad(api.Contracts.getLoteBV());
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    if (this.props.currentUser.cnpj == "00000000000191") {
      Promise.resolve(api.Contracts.sendLoteBB(filesToUpload[0]))
        .then((response) => {
          this.props.onListLoad(api.Contracts.getLoteBB());
          toastr.success("Sucesso", "Arquivo enviado.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.warning("Atenção", resp.response.body);
          }
        });
    } else if (this.props.currentUser.cnpj == "10371492000185") {
      if (filesToUpload[0].type == "text/plain") {
        Promise.resolve(api.Contracts.sendLotePAN(filesToUpload[0]))
          .then((response) => {
            this.props.onListLoad(api.Contracts.getLotePAN());
            toastr.success("Sucesso", "Arquivo enviado.");
          })
          .catch(function (resp) {
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.warning("Atenção", resp.response.body);
            }
          });
      } else {
        Promise.resolve(api.Contracts.sendLotePANXLS(filesToUpload[0]))
          .then((response) => {
            this.props.onListLoad(api.Contracts.getLotePAN());
            toastr.success("Sucesso", "Arquivo enviado.");
          })
          .catch(function (resp) {
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.warning("Atenção", resp.response.body);
            }
          });
      }
    } else if (this.props.currentUser.cnpj === "92228410000102" || this.props.currentUser.cnpj === "60850229000147") {
      Promise.resolve(api.Contracts.sendLoteOMNIXLS(filesToUpload[0]))
        .then((response) => {
          this.props.onListLoad(api.Contracts.getLoteOMNI());
          toastr.success("Sucesso", "Arquivo enviado.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.warning("Atenção", resp.response.body);
          }
        });
    } else {
      Promise.resolve(api.Contracts.sendLoteBV(filesToUpload[0]))
        .then((response) => {
          this.props.onListLoad(api.Contracts.getLoteBV());
          toastr.success("Sucesso", "Arquivo enviado.");
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.warning("Atenção", resp.response.body);
          }
        });
    }
  };

  handleClickDownload = (value) => {
    var props = this.props;
    Promise.resolve(this.props.currentUser.cnpj == "59285411000113" ? api.Contracts.recuperaRespostasPAN(value.id) : api.Contracts.getLote(value.id))
      .then((response) => {
        fileDownload(response, this.props.currentUser.cnpj == "59285411000113" ? value.nome.substring(0, 3) + "RET_" + value.nome.substring(3) : value.nome);
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  async recuperaDadosContrato(value) {
    if (this.state.detalhar.findIndex((val) => val.idLoteApi == value) == -1) {
      await Promise.resolve(api.Contracts.getContractsByIdLote(value)).then((res) => {
        _.forEach(res, function (r) {
          r.hash = value;
        });
        this.setState({ detalhar: [...this.state.detalhar, ...res] });
      });
    }
  }

  filteredDetails(id) {
    return this.state.detalhar
      .filter((detalhe) => detalhe.idLoteApi == id)
      .map((det, index) => {
        return (
          <tr key={index}>
            <td className="text-center align-middle">{det.uf}</td>
            <td className="text-center align-middle">{det.numero}</td>
            <td className="text-center align-middle">
              {det.status == 0 || det.status == 8
                ? "Contrato Criado"
                : det.status == 2
                ? "Contrato Registrado"
                : det.status == 3
                ? "Imagem do Contrato Enviada"
                : det.status == 5
                ? "Contrato Baixado"
                : det.status == 6
                ? "Contrato Cancelado"
                : det.status == 7
                ? "Contrato Averbado"
                : det.status == 11
                ? "Contrato Em Processamento"
                : det.status == 90
                ? "Erro no registro do Contrato"
                : det.status == 91
                ? "Contrato Excluído"
                : det.status}
            </td>
            <td className="text-center align-middle">{det.chassi}</td>
            <td className="text-center align-middle">{det.numGravame}</td>
          </tr>
        );
      });
  }

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Upload/Download Lote Arquivos" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {this.props.currentUser.cnpj == "10371492000185" && (
                      <Dropzone accept=".xlsx" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                        <button type="button" className="btn btn-primary btn-xs">
                          <i className="fa fa-upload" /> Enviar Novo Arquivo (.xls)
                        </button>
                      </Dropzone>
                    )}
                    {(this.props.currentUser.cnpj === "92228410000102" || this.props.currentUser.cnpj === "60850229000147") && (
                      <Dropzone accept=".xlsx" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                        <button type="button" className="btn btn-primary btn-xs">
                          <i className="fa fa-upload" /> Enviar Novo Arquivo (.xls)
                        </button>
                      </Dropzone>
                    )}
                    {this.props.currentUser.cnpj !== "92228410000102" &&
                      this.props.currentUser.cnpj !== "60850229000147" &&
                      this.props.currentUser.cnpj !== "06044551000133" &&
                      this.props.currentUser.cnpj !== "10371492000185" && (
                        <Dropzone accept=".txt" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}>
                          <button type="button" className="btn btn-primary btn-xs">
                            <i className="fa fa-upload" /> Enviar Novo Arquivo (.txt)
                          </button>
                        </Dropzone>
                      )}
                  </div>
                  Arquivos enviados
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={50}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        getTdProps={(state, rowInfo, column, instance) => {
                          return {
                            onClick: async (e, handleOriginal) => {
                              if (column.Header == "Detalhar") {
                                await this.recuperaDadosContrato(rowInfo.original.id);
                              }

                              if (handleOriginal) {
                                handleOriginal();
                              }
                              // IMPORTANT! React-Table uses onClick internally to trigger
                              // events like expanding SubComponents and pivots.
                              // By default a custom 'onClick' handler will override this functionality.
                              // If you want to fire the original onClick handler, call the
                              // 'handleOriginal' function.
                            },
                          };
                        }}
                        SubComponent={(row) => {
                          return (
                            <div className="panel-body">
                              <div className="table-responsive">
                                <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th className="text-center align-middle">UF</th>
                                      <th className="text-center align-middle">Número do Contrato</th>
                                      <th className="text-center align-middle">Situação</th>
                                      <th className="text-center align-middle">Chassi</th>
                                      <th className="text-center align-middle">Gravame</th>
                                    </tr>
                                  </thead>
                                  <tbody>{this.filteredDetails(row.original.id)}</tbody>
                                </table>
                              </div>
                            </div>
                          );
                        }}
                        columns={[
                          {
                            expander: true,
                            Header: "Detalhar",
                            width: 65,
                            headerClassName: "font-extra-bold",
                            Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <span>&#x2bc6;</span> : <span>&#x2bc8;</span>}</div>,
                            style: {
                              cursor: "pointer",
                              fontSize: 15,
                              padding: "0",
                              textAlign: "center",
                              userSelect: "none",
                            },
                          },
                          {
                            Header: "Data Inclusão",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowData.original.data)}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Nome do Arquivo",
                            accessor: "nome",
                            headerClassName: "font-extra-bold",
                            Cell: (rowDataNome) => {
                              return (
                                <div>
                                  {rowDataNome.original.nome && !rowDataNome.original.nome2 ? (
                                    <span>{rowDataNome.original.nome}</span>
                                  ) : (
                                    <>
                                      <span>Arquivo Envio: {rowDataNome.original.nome}</span>
                                      <br />
                                      <span>Arquivo Retorno: {rowDataNome.original.nome2}</span>
                                    </>
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Ação",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 350,
                            show: this.props.currentUser.cnpj !== "10371492000185",
                            Cell: (row) => {
                              return (
                                <div>
                                  <div className="btn-group">
                                    <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(row.original)}>
                                      {" "}
                                      Download{" "}
                                    </Link>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: LOTE_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: LOTE_LIST_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.lote,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "LoteForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Lote));
