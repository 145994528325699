import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    "&.field-valid": {
      borderLeft: "6px solid rgb(30, 160, 52)",
      borderRadius: "8px",
    },
    "&.field-required": {
      borderLeft: "6px solid red",
      borderRadius: "8px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red",
        },
        "&:hover fieldset": {
          borderColor: "red",
        },
        "&.Mui-focused fieldset": {
          borderColor: "red",
        },
      },
    },
  },
  textField: {
    "& .MuiInputBase-root": {
      height: "40px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
    },
  },

  popper: {
    zIndex: 1,
  },
}));

const CustomPopper = function (props) {
  const classes = useStyles();
  return <Popper {...props} className={classes.popper} placement="bottom-start" />;
};

const SelectFieldAddOption = ({ input, label, meta: { touched, error, valid }, data, valueField, textField, blank, inline, small, description, required, disabled }) => {
  const classes = useStyles();

  const options = data.map((option) => ({
    value: option[valueField || "id"],
    label: option[textField || "name"],
  }));

  if (blank) {
    options.unshift({ value: "", label: "" });
  }

  return (
    <div className={`form-group ${touched && !valid ? "has-error" : ""} ${inline ? "row" : ""} field-select-fixo`}>
      <label className={`font-weight-bold ${inline ? "col-sm-2  col-form-label" : ""}`}>{label}</label>
      <div className={`${inline ? "col-sm-10" : ""}`}>
        <Autocomplete
          className={classes.root}
          options={options}
          getOptionLabel={(option) => option.label}
          freeSolo
          PopperComponent={CustomPopper}
          renderInput={(params) => (
            <TextField
              {...params}
              className={`${classes.input} ${classes.textField} ${required ? (valid && input.value ? "field-valid" : "field-required") : ""}`}
              variant="outlined"
              size="small"
              placeholder="Selecione ou digite"
              disabled={disabled}
            />
          )}
          value={input.value ? { value: input.value, label: input.value } : null}
          onChange={(event, newValue) => {
            input.onChange(newValue ? newValue.value : "");
          }}
          onInputChange={(event, newInputValue) => {
            input.onChange(newInputValue);
          }}
        />
        {description && <span className="help-block small">{description}</span>}
        <p className="text-danger">{touched ? error : ""}</p>
      </div>
    </div>
  );
};

export default SelectFieldAddOption;
