import { UF_SYSTEM_LOAD, ERROS_DETRAN_LOAD, ERROS_DETRAN_UNLOAD } from "../../actions/Constants";

const INITIAL_STATE = { ufSystem: [], valoresDetran: [] };

export default function erroDetranEmail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UF_SYSTEM_LOAD:
      return {
        ...state,
        ufSystem: action.payload ? action.payload : [],
      };
    case ERROS_DETRAN_LOAD:
      return {
        ...state,
        errosDetran: action.payload ? action.payload : [],
      };
    case ERROS_DETRAN_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
