import React from "react";

const TextareaField = ({ input, label, type, rows, placeholder, meta: { touched, error, valid, asyncValidating, inline, description }, onBlur, disabled, required, small }) => (
  <div className={`form-group ${touched && !valid ? "has-error" : ""} ${inline ? "row" : ""}`}>
    <label className={`font-weight-bold ${inline ? "col-sm-2  col-form-label" : ""}`}>{label}</label>
    <div className={`${inline ? "col-sm-10" : ""}`}>
      <textarea
        {...input}
        type={type}
        rows={rows}
        className={`${required ? (valid && input.value ? "field-valid" : "field-required") : ""} ${small ? "form-control form-control-sm" : "form-control"}`}
        disabled={disabled}
        style={{ resize: "none" }}
        placeholder={placeholder}
      />
      {description && <span className="help-block small">{description}</span>}
      <p className="text-danger">{touched ? error : ""}</p>
    </div>
  </div>
);

export default TextareaField;
