import React from "react";
import _ from "lodash";
import styled from "styled-components";
import logoAlias from "../../img/logo-alias-branco.png";

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: #0a0e16;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-right: 50px;
`;

const Menu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  margin-right: 50px;
  position: relative;
`;

const MenuLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 15px;
`;

const Submenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  z-index: 1000;

  ${MenuItem}:hover & {
    display: block;
  }
`;

const SubmenuItem = styled.a`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
`;

const SubmenuIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: #4caf50;
  border-radius: 3px;
`;

const SubmenuText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmenuTitle = styled.span`
  font-weight: bold;
`;

const SubmenuDescription = styled.span`
  font-size: 12px;
  color: #aaa;
`;

const HeaderSpacer = styled.div`
  height: 50px;
`;

class HeaderAlias extends React.Component {
  render() {
    return (
      <>
        <HeaderContainer>
          <MenuLink href="https://aliasnet.com.br/" target="_blank">
            <Logo src={logoAlias} alt="Alias Tecnologia" />
          </MenuLink>

          <nav>
            <Menu>
              <MenuItem>
                <MenuLink href="#">Produtos ▼</MenuLink>
                <Submenu>
                  <SubmenuItem href="https://aliasnet.com.br/e-registro/" target="_blank">
                    <SubmenuIcon />
                    <SubmenuText>
                      <SubmenuTitle>E-Registro</SubmenuTitle>
                      <SubmenuDescription>Contratos de financiamento veicular</SubmenuDescription>
                    </SubmenuText>
                  </SubmenuItem>
                </Submenu>
              </MenuItem>
              <MenuItem>
                <MenuLink href="https://aliasnet.com.br/sobre/" target="_blank">
                  Sobre
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink href="https://aliasnet.com.br/noticias/" target="_blank">
                  Notícias
                </MenuLink>
              </MenuItem>
            </Menu>
          </nav>
        </HeaderContainer>
        <HeaderSpacer />
      </>
    );
  }
}

export default HeaderAlias;
