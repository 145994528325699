import React from "react";
import PropTypes from "prop-types";
import packageJson from "./../../../package.json";

export default class Footer extends React.Component {
  render() {
    if (process.env.REACT_APP_LOGO_URL) {
      return (
        <footer className="footer fixed">
          <span className="float-right">Registro de contrato de financiamento de veículos</span>
        </footer>
      );
    } else {
      return (
        <>
          {/* <div class="fixed-bottom">
          <footer className="footer fixed">
            <span className="float-right">
              {this.props.footer && this.props.footer !== null ? (
                <strong>{this.props.footer}</strong>
              ) : (
                <span>
                  <strong>E-REGISTRO</strong> - Registro de contrato de financiamento de veículos - v{packageJson.version}
                </span>
              )}
            </span>

            <span>&copy; {this.props.copyright && this.props.copyright !== null ? this.props.copyright : "2018 Alias"}</span>
          </footer>
        </div> */}
          <div class="fixed-bottom">
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-3f29e05 elementor-section-full_width elementor-section-height-default snipcss-S8BPc tether-element-attached-top tether-element-attached-center tether-target-attached-top tether-target-attached-center"
              data-id="3f29e05"
              data-element_type="section"
              data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
            >
              <div class="elementor-container elementor-column-gap-no">
                <div class="elementor-row">
                  <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6acca33" data-id="6acca33" data-element_type="column">
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div
                          class="elementor-element elementor-element-3f94fe3 elementor-widget elementor-widget-heading"
                          data-id="3f94fe3"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="row">
                            <div className="col-md-6 text-left">
                              <h2 className="elementor-heading-title elementor-size-default">COPYRIGHT © ALIAS TECNOLOGIA {new Date().getFullYear()} - TODOS OS DIREITOS RESERVADOS</h2>
                            </div>
                            <div className="col-md-6 text-right">
                              <h2 className="elementor-heading-title elementor-size-default">E-REGISTRO - REGISTRO DE CONTRATO DE FINANCIAMENTO DE VEÍCULOS - v{packageJson.version}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      );
    }
  }
}

Footer.propTypes = {
  footer: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
};
