import React, { Component, } from 'react'; import PropTypes from 'prop-types';
import Breadcrumbs from 'react-breadcrumbs';
import _ from 'lodash';

import ServiceStatus from '../../containers/ServiceStatus/ServiceStatus';

export default class Title extends Component {



  render() {


    const { routes } = this.props;
    const { params } = this.props;
    let title;
    if (params && params.title) {
      title = params.title;
    } else {
      title = (_.last(routes,1)).name;
    }


    return (
      <div className="normalheader small-header">
        <div className="hpanel">
            <div className="panel-body">
              <ServiceStatus />
                <div id="hbreadcrumb" className="float-right">
                  <Breadcrumbs
                    routes={routes}
                    params={params}
                    createSeparator=" / "
                    wrappingComponent="ol" className="hbreadcrumb"
                  />
                </div>
                <h2 className="font-light m-b-xs">
                    {title}
                </h2>
                <small>{this.props.description && this.props.description }</small>
            </div>
        </div>
      </div>
    );
  }
}

Title.propTypes = {
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  description: PropTypes.string,
};
