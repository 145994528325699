const CEP = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length < 5) {
      return onlyNums;
    }

    if (onlyNums.length === 5) {
      return onlyNums + "-";
    }

    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
  } else {
    if (onlyNums.length <= 5) {
      return onlyNums;
    }
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
  }
};

const CPF = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + ".";
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + "." + onlyNums.slice(3) + ".";
    }
    if (onlyNums.length === 9) {
      return onlyNums.slice(0, 3) + "." + onlyNums.slice(3, 6) + "." + onlyNums.slice(6) + "-";
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + "." + onlyNums.slice(3);
  }
  if (onlyNums.length <= 9) {
    return onlyNums.slice(0, 3) + "." + onlyNums.slice(3, 6) + "." + onlyNums.slice(6);
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
};

const CNPJ = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length <= 2) {
      return onlyNums;
    }

    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
  } else {
    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 5) {
      return onlyNums.slice(0, 2) + "." + onlyNums.slice(2);
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;
    }
    if (onlyNums.length <= 12) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
    }
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
  }
};

const CPF_CNPJ = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 11) {
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
    } else {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
    }
  } else {
    if (onlyNums.length <= 11) {
      if (onlyNums.length <= 3) {
        return onlyNums;
      }
      if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + "." + onlyNums.slice(3);
      }
      if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + "." + onlyNums.slice(3, 6) + "." + onlyNums.slice(6);
      }
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
    } else {
      if (onlyNums.length <= 2) {
        return onlyNums;
      }
      if (onlyNums.length <= 5) {
        return onlyNums.slice(0, 2) + "." + onlyNums.slice(2);
      }
      if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;
      }
      if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
      }
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
    }
  }
};

const CPF_EMAIL = (value, previousValue) => {
  if (!isNaN(Number(value.replace(/\-/g, "").replace(/\./g, "")))) {
    if (!value) {
      return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (onlyNums.length === 3) {
        return onlyNums + ".";
      }
      if (onlyNums.length === 6) {
        return onlyNums.slice(0, 3) + "." + onlyNums.slice(3) + ".";
      }
      if (onlyNums.length === 9) {
        return onlyNums.slice(0, 3) + "." + onlyNums.slice(3, 6) + "." + onlyNums.slice(6) + "-";
      }
    }
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 6) {
      return onlyNums.slice(0, 3) + "." + onlyNums.slice(3);
    }
    if (onlyNums.length <= 9) {
      return onlyNums.slice(0, 3) + "." + onlyNums.slice(3, 6) + "." + onlyNums.slice(6);
    }
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
  } else {
    return value;
  }
};

const CELULAR = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
};

const PHONE = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length <= 2) {
      return onlyNums;
    }

    if (onlyNums.length <= 10) {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(6, 11)}`;
    } else {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
    }
  } else {
    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 6) {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
    }
    if (onlyNums.length <= 10) {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(6, 11)}`;
    } else {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
    }
  }
};

const DDD = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  return `${onlyNums.slice(0, 2)}`;
};

const PHONE_N_DDD = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 9)}`;
    } else {
      return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
    }
  } else {
    if (onlyNums.length <= 4) {
      return onlyNums;
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 9)}`;
    } else {
      return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
    }
  }
};

const PLATE = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const c = value.replace(/[^a-zA-Z0-9 _]/g, "").toUpperCase();
  if (!previousValue || value.length > previousValue.length) {
    return `${c.slice(0, 3)}-${c.slice(3, 7)}`;
  } else {
    if (c.length <= 3) {
      return c;
    }
    return `${c.slice(0, 3)}-${c.slice(3, 7)}`;
  }
};

const DATE = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const c = value.replace(/[^0-9]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    return `${c.slice(0, 2)}/${c.slice(2, 4)}/${c.slice(4, 8)}`;
  } else {
    if (c.length <= 2) {
      return `${c.slice(0, 2)}`;
    }
    if (c.length <= 4) {
      return `${c.slice(0, 2)}/${c.slice(2, 4)}`;
    }
    return `${c.slice(0, 2)}/${c.slice(2, 4)}/${c.slice(4, 8)}`;
  }
};

const NUMBER = (value) => {
  return value.replace(/[^\d]/g, "");
};

const DECIMAL = (value) => {
  value = value.replace(/[^0-9,]/g, ""); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split(",");

  // Remove any leading 0s apart from single 0
  if (sections[0] !== "0" && sections[0] !== "00") {
    sections[0] = sections[0].replace(/^0+/, "");
  } else {
    sections[0] = "0";
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + "," + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf(",") !== -1) {
    return sections[0] + ",";
    // Otherwise, return only section
  } else {
    return sections[0];
  }
};

const PERCENT_6_3 = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + ",";
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + "," + onlyNums.slice(3);
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + "," + onlyNums.slice(3);
  }
  return `${onlyNums.slice(0, 3)},${onlyNums.slice(3, 6)}`;
};

function PERCENT_INPUT_FORMAT(value) {
  if (value == "" || value == "0,0") {
    return null;
  }
  const v = ((value.replace(/\D/g, "") / 100).toFixed(2) + "").split(".");

  const m = v[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g);

  for (let i = 0; i < m.length; i++) m[i] = m[i].split("").reverse().join("") + ".";

  const r = m.reverse().join("");

  return r.substring(0, r.lastIndexOf(".")) + "," + v[1];
}

function PERCENT_INPUT_FORMAT_3(value) {
  if (value == "" || value == "0,0") {
    return null;
  }
  const v = ((value.replace(/\D/g, "") / 1000).toFixed(3) + "").split(".");

  const m = v[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g);

  for (let i = 0; i < m.length; i++) m[i] = m[i].split("").reverse().join("") + ".";

  const r = m.reverse().join("");

  return r.substring(0, r.lastIndexOf(".")) + "," + v[1];
}

const DECIMAL_MAX_9 = (value) => {
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length >= 9) {
    return `${onlyNums.slice(0, 7)},${onlyNums.slice(7, 9)}`;
  }

  value = value.replace(/[^0-9,]/g, ""); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split(",");

  // Remove any leading 0s apart from single 0
  if (sections[0] !== "0" && sections[0] !== "00") {
    sections[0] = sections[0].replace(/^0+/, "");
  } else {
    sections[0] = "0";
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + "," + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf(",") !== -1) {
    return sections[0] + ",";
    // Otherwise, return only section
  } else {
    return sections[0];
  }
};

const UPPERCASE = (value) => {
  if (value) {
    return value.toUpperCase();
  } else {
    return value;
  }
};

const NO_SP_CHARACTERS = (value) => {
  if (value) {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  } else {
    return value;
  }
};

const HHMM = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const c = value.replace(/[^0-9]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    return `${c.slice(0, 2)}:${c.slice(2, 4)}`;
  } else {
    if (c.length <= 2) {
      return `${c.slice(0, 2)}`;
    }
    if (c.length <= 4) {
      return `${c.slice(0, 2)}:${c.slice(2, 4)}`;
    }
    return `${c.slice(0, 2)}:${c.slice(2, 4)}`;
  }
};

const CHASSI = (value) => {
  if (!value) {
    return value;
  }
  return value.trim();
};

export default {
  PERCENT_INPUT_FORMAT,
  PERCENT_INPUT_FORMAT_3,
  CEP,
  CPF,
  PHONE,
  CELULAR,
  CPF_EMAIL,
  CNPJ,
  PLATE,
  CPF_CNPJ,
  DATE,
  NUMBER,
  DECIMAL,
  PERCENT_6_3,
  DECIMAL_MAX_9,
  PHONE_N_DDD,
  UPPERCASE,
  DDD,
  NO_SP_CHARACTERS,
  HHMM,
  CHASSI,
};
