import {MANAGEMENT_REPORT_ERROR_LOAD, MANAGEMENT_REPORT_ERROR_UNLOAD, MANAGEMENT_REPORT_ERROR_LIST, UF_SYSTEM_LOAD} from '../../actions/Constants';

const INITIAL_STATE = { companies: [], data: []};

export default function managementReportError(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MANAGEMENT_REPORT_ERROR_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
        data: [],
      };

    case MANAGEMENT_REPORT_ERROR_LIST:
      return {
        ...state,
        data: action.payload ? action.payload : null,
      };

    case MANAGEMENT_REPORT_ERROR_UNLOAD:
      return INITIAL_STATE;

    case UF_SYSTEM_LOAD:
      return {
        ...state, 
        ufSystem: action.payload ? action.payload : []
      }
    default:
      return state;
  }
}