import {FINANCIAL_REPORT_PR_LOAD, FINANCIAL_REPORT_PR_UNLOAD, FINANCIAL_REPORT_PR_LIST_LOAD, FINANCIAL_REPORT_DETAILS_PR_LOAD, FINANCIAL_REPORT_DETAILS_PR_UNLOAD} from '../../../actions/Constants';

const INITIAL_STATE = { companies: [], all: [], detailsList: [] , showDetails: false, pages: 0, page: 0};

export default function financialReportPR(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FINANCIAL_REPORT_PR_LOAD:
      return {
        ...state,
        companies: action.payload ? action.payload : null,
      };
    case FINANCIAL_REPORT_PR_LIST_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : null,
      };

    case FINANCIAL_REPORT_DETAILS_PR_LOAD:
      return {
        ...state,
        showDetails: true,
        detailsList: action.payload ? action.payload[0] : [],
        pages: action.payload ? action.payload[1] : 0,
        page: action.payload ? action.payload[2] : 0,
      };

    case FINANCIAL_REPORT_DETAILS_PR_UNLOAD:
      return {
        ...state,
        showDetails: false,
        detailsList: [],
        pages: 0,
        page: 0,
      };

    case FINANCIAL_REPORT_PR_UNLOAD:
      return INITIAL_STATE;


    default:
      return state;
  }
}
